import { memo } from "react";
import { ColDef } from "ag-grid-community";

import { AppGridAction } from "./cell-renderer";
import { AppGridColDefParams } from "../../../../models";
import { getUserFullName } from "../../../../utils";
import { WorkspacePermissionType } from "../../../../hooks";
import { AppGridToolTip } from "./AppGridToolTip";

export interface AppMapGridColDefParams
    extends AppGridColDefParams,
        WorkspacePermissionType {
    toShortDate: (date: number | Date | string) => string;
    isGrantedClientAdmin?: boolean;
}

export const appGridColDef = ({
    onPressEdit,
    onPressDelete,
    toShortDate,
    onPressClone,
    isOwner,
    isWrite,
    isRead,
    isGrantedClientAdmin,
}: AppMapGridColDefParams): ColDef[] => [
    {
        colId: "id",
        headerCheckboxSelection: true,
        checkboxSelection: true,
        maxWidth: 50,
    },
    {
        field: "name",
        sort: "asc",
        cellRenderer: memo(AppGridToolTip),
    },
    { field: "subTitle", cellRenderer: memo(AppGridToolTip) },
    {
        headerName: "app.map.list:column.creator",
        valueGetter: ({ data }) => getUserFullName(data?.creator),
    },
    {
        field: "createdAt",
        valueGetter: ({ data }) => toShortDate(data.createdAt),
    },
    {
        field: "updatedAt",
        valueGetter: ({ data }) => toShortDate(data.updatedAt),
    },
    {
        sortable: false,
        cellRenderer: memo(AppGridAction),
        cellRendererParams: {
            onPressEdit,
            onPressDelete,
            onPressClone,
            isOwner,
            isWrite,
            isRead,
            isGrantedClientAdmin,
        },
        maxWidth: 50,
        cellClass: "center-column",
    },
];
