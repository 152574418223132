import { FC, PropsWithChildren, useEffect, useState } from "react";

import "./assets/scss/style.scss";

interface AppInlineInputProps extends PropsWithChildren {
    show: boolean;
    initialText: string;
    className?: string;
    size?: "xs" | "sm" | "md" | "lg";
    onHide: () => void;
    onUpdate: (text: string) => void;
    maxLength?: number;
    inputType?: "area" | "input";
    textAreaSize?: "xs" | "small" | "medium" | "large";
}

export const AppInlineInput: FC<AppInlineInputProps> = ({
    show,
    initialText,
    className = "",
    size = "sm",
    children,
    onHide,
    onUpdate,
    maxLength,
    inputType = "input",
    textAreaSize = "small",
}) => {
    // state
    const [text, setText] = useState(initialText);

    const sizeClass = size ? `app-inline-input--${size}` : "";

    const sizeTextArea = textAreaSize ? `type-area--${textAreaSize}` : "";

    const adjustTextareaHeight = (textarea: HTMLTextAreaElement) => {
        textarea.style.height = "18px";
        textarea.style.height = `${textarea.scrollHeight}px`;
    };

    useEffect(() => {
        const textareas = document.querySelectorAll<HTMLTextAreaElement>(
            ".app-inline-input.type-area",
        );
        textareas.forEach((textarea) => {
            adjustTextareaHeight(textarea);
            const handleInput = () => adjustTextareaHeight(textarea);
            textarea.addEventListener("input", handleInput);

            return () => {
                textarea.removeEventListener("input", handleInput);
            };
        });
    }, [show]);

    return (
        <>
            {show ? (
                inputType === "input" ? (
                    <input
                        type="text"
                        value={text}
                        onChange={(e) => setText(e.target.value)}
                        onBlur={() => {
                            onHide();
                            if (text !== initialText) {
                                onUpdate(text);
                            }
                        }}
                        onKeyDown={(e) => {
                            if (e.key === "Enter" || e.key === "Escape") {
                                e.preventDefault();
                                e.stopPropagation();

                                onHide();
                                if (text !== initialText) {
                                    onUpdate(text);
                                }
                            }
                        }}
                        autoFocus
                        className={`app-inline-input ${sizeClass} form-control border-0 ${className}`}
                        maxLength={maxLength}
                    />
                ) : (
                    <textarea
                        value={text}
                        onChange={(e) => {
                            setText(e.target.value);
                        }}
                        onBlur={() => {
                            onHide();
                            if (text !== initialText) {
                                onUpdate(text);
                            }
                        }}
                        onKeyDown={(e) => {
                            if (e.key === "Enter" || e.key === "Escape") {
                                e.preventDefault();
                                e.stopPropagation();

                                onHide();
                                if (text !== initialText) {
                                    onUpdate(text);
                                }
                            }
                        }}
                        autoFocus
                        className={`app-inline-input type-area ${sizeTextArea} ${sizeClass} form-control border-0 ${className}`}
                        maxLength={maxLength}
                    />
                )
            ) : (
                children
            )}
        </>
    );
};
