import { memo } from "react";
import { ColDef } from "ag-grid-community";

import {
    AppGridAction,
    AppGridAmount,
    AppGridDate,
    AppGridInvoiceId,
    AppGridManualInvoice,
    AppGridStatus,
} from "./cell-renderer";
import { AppGridColDefParams } from "../../../../models";

export interface AppInvoiceGridColDefParams extends AppGridColDefParams {
    isSuperAdmin: boolean;
    onPressDownload: (id: number) => void;
    onChangeStatus: (id: number, status: string) => void;
    onPressDelete: (id: number) => void;
}

export const appGridColDef = ({
    isSuperAdmin,
    onPressDownload,
    onChangeStatus,
    onPressDelete,
}: AppInvoiceGridColDefParams): ColDef[] => [
    {
        field: "id",
        headerName: "admin.subscription.billing:column.id",
        sort: "desc",
        cellRenderer: memo(AppGridInvoiceId),
    },
    { field: "period", cellRenderer: memo(AppGridDate) },
    {
        field: "status",
        cellRenderer: memo(AppGridStatus),
        cellClass: "d-flex",
    },
    {
        field: "totalAmount",
        headerName: "admin.subscription.billing:column.totalAmount",
        cellRenderer: memo(AppGridAmount),
    },
    {
        field: "noOfUsers",
        headerName: "admin.subscription.billing:column.noOfUsers",
    },
    { field: "name", headerName: "admin.subscription.billing:column.name" },
    {
        field: "creator",
        hide: !isSuperAdmin,
        cellRenderer: memo(AppGridManualInvoice),
    },
    {
        sortable: false,
        cellRenderer: memo(AppGridAction),
        cellRendererParams: {
            onPressDownload,
            onChangeStatus,
            onPressDelete,
        },
        maxWidth: 50,
        cellClass: "center-column",
    },
];
