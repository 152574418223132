import { FC, useEffect, useState } from "react";
import { useParams } from "react-router-dom";

import { useDateTime, useMapAction, useTranslation } from "../../../hooks";
import { ActionPost, Map, PActionPost } from "../../../models";
import {
    AppConfirmModal,
    AppGrid,
    AppLoader,
    AppPageHeader,
} from "../../../components";
import { ActionPostApi, MapApi } from "../../../apis";
import { errorToast, successToast } from "../../../utils";
import { ACTION_POST_STATUS } from "../../../config";
import { appGridColDef } from "./app-grid";

export const MapKanBanArchivePage: FC = () => {
    // hooks
    const { t } = useTranslation();
    const { id } = useParams();
    const { toShortDate } = useDateTime();

    // context
    const { actionSetMap, actionReSetMap } = useMapAction();

    // state
    const [loading, setLoading] = useState(false);
    const [data, setData] = useState<ActionPost[]>([]);
    const [showConfirm, setShowConfirm] = useState(0);

    const fetchData = (search = "") => {
        actionReSetMap();

        if (!id) {
            return;
        }

        setLoading(true);

        MapApi.findById<Map>(+id).then(({ errorMessage, response }) => {
            if (errorMessage) {
                errorToast(errorMessage);
            } else if (response) {
                actionSetMap(response);
            }
        });

        ActionPostApi.find<ActionPost>(1, {
            "map.id": id,
            pagination: false,
            status: [ACTION_POST_STATUS.ACTIONPOST_STATUS_ARCHIVE],
            "order[dueDate]": "desc",
            ...(search ? { action_post_search: search } : {}),
        })
            .then(({ response, errorMessage }) => {
                if (errorMessage) {
                    errorToast(errorMessage);
                } else if (response !== null) {
                    setData(response.items);
                }
            })
            .finally(() => setLoading(false));
    };

    useEffect(() => {
        fetchData();
    }, []);

    const handleDelete = (itemId: number) => {
        ActionPostApi.deleteById(itemId).then(({ errorMessage }) => {
            if (errorMessage) {
                errorToast(errorMessage);
            } else {
                successToast(
                    t("app.map.kanban.archive.list:delete.toast.success"),
                );
                setShowConfirm(0);
                fetchData();
            }
        });
    };

    const handleMoveToKanBan = (itemId: number) => {
        ActionPostApi.update<ActionPost, PActionPost>(itemId, {
            status: ACTION_POST_STATUS.ACTIONPOST_STATUS_COMPLETED,
        }).then(({ response, errorMessage }) => {
            if (errorMessage) {
                errorToast(errorMessage);
            } else if (response !== null) {
                setData((prev) => prev.filter((x) => x.id !== itemId));
            }
        });
    };

    return (
        <div className="kanban-archive-page">
            {loading && <AppLoader isFullScreen />}

            <AppPageHeader
                title="app.map.kanban.archive.list:header.title"
                onSearchChange={fetchData}
                showToolbar
                isGrantedControl={false}
            />

            <AppConfirmModal
                show={!!showConfirm}
                icon="warning"
                title="app.map.kanban.archive.item:delete.confirm.modal.title"
                description="app.map.kanban.archive.item:delete.confirm.modal.description"
                onHide={() => setShowConfirm(0)}
                nextAction={() => handleDelete(showConfirm)}
            />

            <AppGrid
                columnDefs={appGridColDef({
                    onPressDelete: setShowConfirm,
                    onPressMove: handleMoveToKanBan,
                    toShortDate,
                })}
                rowData={data}
                isLoading={loading}
            />
        </div>
    );
};
