export enum OnboardClientAdminTabEnum {
    USER = "User",
    CLIENT = "Company",
    CHOOSE_PLAN = "Choose your plan",
    PAYMENT = "Payment",
    COMPLETE = "Complete",
}

export enum OnboardUserTabEnum {
    USER = "User",
}
