import { FC, useEffect } from "react";
import { useController, useFieldArray, useFormContext } from "react-hook-form";

import { AppFormInputWrapper, AppFormInputWrapperProps } from "../AppForm";
import { AppUploader } from "./AppUploader";
import { AppUploaderProps } from "./AppUploader/AppUploader";
import { isNumber, isString } from "../../utils";
import { PDocument } from "../../models";

interface AppFormUploaderProps
    extends AppFormInputWrapperProps,
        AppUploaderProps {}

export const AppFormUploader: FC<AppFormUploaderProps> = ({ ...props }) => {
    // hooks
    const { control } = useFormContext();
    const { field } = useController({
        name: props.name,
        control,
    });

    const { append, remove, fields } = useFieldArray({
        name: props.name,
        control,
    });

    // local state
    const isMultiple = props.maxFiles && props.maxFiles !== 1;

    useEffect(() => {
        if (isMultiple) {
            field.onChange(fields);
        }
    }, [fields.length]);

    const getDefaultValues = (): PDocument[] => {
        if (isMultiple) {
            return fields as unknown as PDocument[];
        }

        return field.value ? [{ fileName: field.value }] : [];
    };

    const uploaderProps = {
        name: props.name,
        ref: field.ref,
        defaultValues: getDefaultValues(),
        maxSize: props.maxSize,
        maxFiles: props.maxFiles,
        fileInfo: props.fileInfo,
        cbFileUploading: props.cbFileUploading,
        withCropper: props.withCropper,
        hasDownload: props.hasDownload,
    };

    return (
        <AppFormInputWrapper {...props}>
            <AppUploader
                {...uploaderProps}
                handleOnChange={(value, removeIndex) => {
                    if (isMultiple) {
                        // for multi upload
                        if (isString(value)) {
                            if (isNumber(removeIndex)) {
                                remove(removeIndex);
                            }
                        } else {
                            append(value);
                        }
                    } else {
                        // for single upload
                        if (isString(value)) {
                            field.onChange("");
                        } else {
                            field.onChange(value.fileName);
                        }
                    }
                }}
            />
        </AppFormInputWrapper>
    );
};
