import * as yup from "yup";
import { validation } from "../../../utils";

export const schema = () =>
    yup.object().shape({
        contactBill: yup.string().nullable().max(validation.title.max),
        vatNo: yup.string().nullable().max(validation.title.max),

        isSameAsCompany: yup.boolean(),

        addressBill: yup.string().nullable().max(validation.title.max),
        regionBill: yup.string().nullable().max(validation.title.max),
        postCodeBill: yup.string().nullable(),
        cityBill: yup.string().nullable().max(validation.title.max),
        countryBill: yup.string().nullable(),
    });
