import React, {
    FC,
    PropsWithChildren,
    createContext,
    useEffect,
    useReducer,
} from "react";

import {
    ActionPost,
    ActionTask,
    Map,
    OptionType,
    SubTask,
    Task,
    TaskPost,
    Workspace,
    WorkspacePermission,
} from "../models";
import {
    MapActionEnum,
    errorToast,
    getIdFromResourceUrl,
    isString,
    reorder,
    reorderWithDiffParent,
} from "../utils";
import { WorkspaceApi } from "../apis";
import { ACTION_POST_STATUS } from "../config";

export interface MapState {
    map: Map | null;
    tasks: Task[];
    actionTasks: ActionTask[];
    actionPosts: ActionPost[];
    responsibleUserOptions: OptionType[];
    taskPostTagsOptions: OptionType[];
    actionPostTagsOptions: OptionType[];
    workspacePermissions: WorkspacePermission[];
}

const initialState: MapState = {
    map: null,
    tasks: [],
    actionTasks: [],
    actionPosts: [],
    responsibleUserOptions: [],
    taskPostTagsOptions: [],
    actionPostTagsOptions: [],
    workspacePermissions: [],
};

type ChangeOrder = {
    source: number;
    destination: number;
};

type MapAction =
    | { type: MapActionEnum.SET_MAP; payload: Map | null }
    | { type: MapActionEnum.SET_MAP_DETAIL; payload: Map | null }
    | { type: MapActionEnum.SET_MAP_PORTFOLIO; payload: Map | null }
    | {
          type: MapActionEnum.SET_MAP_KANBAN;
          payload: ActionPost[];
      }
    | { type: MapActionEnum.RESET_MAP }
    | {
          type: MapActionEnum.CHANGE_TASK_ORDER;
          payload: ChangeOrder;
      }
    | {
          type: MapActionEnum.CHANGE_SUB_TASK_ORDER;
          payload: { taskId: number; oldTaskId?: number } & ChangeOrder;
      }
    | {
          type: MapActionEnum.CHANGE_TASK_POST_ORDER;
          payload: {
              taskId: number;
              subTaskId: number;
              oldTaskId?: number;
              oldSubTaskId?: number;
          } & ChangeOrder;
      }
    | {
          type: MapActionEnum.CHANGE_ACTION_TASK_ORDER;
          payload: ChangeOrder;
      }
    | {
          type: MapActionEnum.CHANGE_ACTION_POST_ORDER;
          payload: {
              actionTaskId?: number;
              oldActionTaskId?: number;
          } & ChangeOrder;
      }
    | {
          type: MapActionEnum.CREATE_TASK;
          payload: Task;
      }
    | {
          type: MapActionEnum.UPDATE_TASK;
          payload: { id: number; name: string };
      }
    | {
          type: MapActionEnum.DELETE_TASK;
          payload: number;
      }
    | {
          type: MapActionEnum.CREATE_SUB_TASK;
          payload: { taskId: number; item: SubTask };
      }
    | {
          type: MapActionEnum.UPDATE_SUB_TASK;
          payload: { taskId: number; id: number; name: string };
      }
    | {
          type: MapActionEnum.DELETE_SUB_TASK;
          payload: { taskId: number; id: number };
      }
    | {
          type: MapActionEnum.CREATE_TASK_POST;
          payload: { taskId: number; subTaskId: number; item: TaskPost };
      }
    | {
          type: MapActionEnum.UPDATE_TASK_POST;
          payload: {
              taskId: number;
              subTaskId: number;
              id: number;
              item: TaskPost;
          };
      }
    | {
          type: MapActionEnum.DELETE_TASK_POST;
          payload: { taskId: number; subTaskId: number; id: number };
      }
    | {
          type: MapActionEnum.CREATE_ACTION_TASK;
          payload: ActionTask;
      }
    | {
          type: MapActionEnum.UPDATE_ACTION_TASK;
          payload: { id: number; item: ActionTask };
      }
    | {
          type: MapActionEnum.DELETE_ACTION_TASK;
          payload: number;
      }
    | {
          type: MapActionEnum.CREATE_ACTION_POST;
          payload: { item: ActionPost; actionTaskId?: number };
      }
    | {
          type: MapActionEnum.UPDATE_ACTION_POST;
          payload: { id: number; item: ActionPost; actionTaskId?: number };
      }
    | {
          type: MapActionEnum.DELETE_ACTION_POST;
          payload: { id: number; actionTaskId?: number };
      }
    | {
          type: MapActionEnum.SET_RESPONSIBLE_USER_OPTIONS;
          payload: OptionType[];
      }
    | {
          type: MapActionEnum.SET_TASK_POST_TAGS;
          payload: OptionType[];
      }
    | {
          type: MapActionEnum.SET_ACTION_POST_TAGS;
          payload: OptionType[];
      }
    | {
          type: MapActionEnum.SET_WORKSPACE_PERMISSIONS;
          payload: WorkspacePermission[];
      };

const mapReducer = (state: MapState, action: MapAction): MapState => {
    switch (action.type) {
        case MapActionEnum.SET_MAP:
            return {
                ...state,
                map: action.payload,
            };

        case MapActionEnum.SET_MAP_DETAIL:
            return {
                ...state,
                map: action.payload,
                tasks: action.payload?.tasks || [],
                actionTasks: action.payload?.actionTasks || [],
            };

        case MapActionEnum.SET_MAP_PORTFOLIO:
            return {
                ...state,
                map: action.payload,
                actionTasks:
                    action.payload?.actionTasks?.map(
                        (x) =>
                            ({
                                ...x,
                                actionPosts: x.actionPosts?.filter(
                                    (y) =>
                                        ![
                                            ACTION_POST_STATUS.ACTIONPOST_STATUS_COMPLETED,
                                            ACTION_POST_STATUS.ACTIONPOST_STATUS_ARCHIVE,
                                        ].includes(y.status),
                                ),
                            } as ActionTask),
                    ) || [],
            };

        case MapActionEnum.SET_MAP_KANBAN:
            return {
                ...state,
                actionPosts: action.payload,
            };

        case MapActionEnum.RESET_MAP:
            return {
                ...initialState,
            };

        case MapActionEnum.CHANGE_TASK_ORDER:
            return {
                ...state,
                tasks: reorder<Task>(
                    state.tasks,
                    action.payload.source,
                    action.payload.destination,
                ),
            };

        case MapActionEnum.CHANGE_SUB_TASK_ORDER:
            // for order change b/w different parents
            if (action.payload.oldTaskId) {
                const sourceSubTasks = state.tasks.find(
                    (x) => x.id === action.payload.oldTaskId,
                )?.subTasks;

                const destinationSubTasks = state.tasks.find(
                    (x) => x.id === action.payload.taskId,
                )?.subTasks;

                const [updatedSourceSubTasks, updatedDestinationSubTasks] =
                    reorderWithDiffParent<SubTask>(
                        sourceSubTasks || [],
                        destinationSubTasks || [],
                        action.payload.source,
                        action.payload.destination,
                    );

                return {
                    ...state,
                    tasks: state.tasks.map((task) => {
                        if (task.id === action.payload.oldTaskId) {
                            return {
                                ...task,
                                subTasks: updatedSourceSubTasks,
                            } as Task;
                        }

                        if (task.id === action.payload.taskId) {
                            return {
                                ...task,
                                subTasks: updatedDestinationSubTasks,
                            } as Task;
                        }

                        return task;
                    }),
                };
            }

            // for normal order change
            return {
                ...state,
                tasks: state.tasks.map((task) =>
                    task.id === action.payload.taskId
                        ? ({
                              ...task,
                              subTasks: reorder<SubTask>(
                                  task.subTasks || [],
                                  action.payload.source,
                                  action.payload.destination,
                              ),
                          } as Task)
                        : task,
                ),
            };

        case MapActionEnum.CHANGE_TASK_POST_ORDER:
            // for order change b/w different parents
            if (action.payload.oldTaskId && action.payload.oldSubTaskId) {
                const sourceTaskPosts = state.tasks
                    .find((x) => x.id === action.payload.oldTaskId)
                    ?.subTasks?.find(
                        (y) => y.id === action.payload.oldSubTaskId,
                    )?.taskPosts;

                const destinationTaskPosts = state.tasks
                    .find((x) => x.id === action.payload.taskId)
                    ?.subTasks?.find(
                        (y) => y.id === action.payload.subTaskId,
                    )?.taskPosts;

                const [updatedSourceTaskPosts, updatedDestinationTaskPosts] =
                    reorderWithDiffParent<TaskPost>(
                        sourceTaskPosts || [],
                        destinationTaskPosts || [],
                        action.payload.source,
                        action.payload.destination,
                    );

                const getUpdatedSubTasks = (task: Task) =>
                    (task.subTasks || []).map((subTask) => {
                        if (subTask.id === action.payload.oldSubTaskId) {
                            return {
                                ...subTask,
                                taskPosts: updatedSourceTaskPosts,
                            };
                        }

                        if (subTask.id === action.payload.subTaskId) {
                            return {
                                ...subTask,
                                taskPosts: updatedDestinationTaskPosts,
                            };
                        }

                        return subTask;
                    });

                return {
                    ...state,
                    tasks: state.tasks.map((task) => {
                        if (task.id === action.payload.oldTaskId) {
                            return {
                                ...task,
                                subTasks: getUpdatedSubTasks(task),
                            } as Task;
                        }

                        if (
                            task.id === action.payload.taskId &&
                            action.payload.oldTaskId !== action.payload.taskId // if task id same then it will update record twice redundant (NOTE: it won't impact anything except performance)
                        ) {
                            return {
                                ...task,
                                subTasks: getUpdatedSubTasks(task),
                            } as Task;
                        }

                        return task;
                    }),
                };
            }

            // for normal order change
            return {
                ...state,
                tasks: state.tasks.map((task) =>
                    task.id === action.payload.taskId
                        ? ({
                              ...task,
                              subTasks: (task.subTasks || []).map((subTask) =>
                                  subTask.id === action.payload.subTaskId
                                      ? ({
                                            ...subTask,
                                            taskPosts: reorder<TaskPost>(
                                                subTask.taskPosts || [],
                                                action.payload.source,
                                                action.payload.destination,
                                            ),
                                        } as SubTask)
                                      : subTask,
                              ),
                          } as Task)
                        : task,
                ),
            };

        case MapActionEnum.CHANGE_ACTION_TASK_ORDER:
            return {
                ...state,
                actionTasks: reorder<ActionTask>(
                    state.actionTasks,
                    action.payload.source,
                    action.payload.destination,
                ),
            };

        case MapActionEnum.CHANGE_ACTION_POST_ORDER:
            // for portfolio page
            if (action.payload.actionTaskId) {
                // for order change b/w different parents
                if (action.payload.oldActionTaskId) {
                    const sourceActionPosts = state.actionTasks.find(
                        (x) => x.id === action.payload.oldActionTaskId,
                    )?.actionPosts;

                    const destinationActionPosts = state.actionTasks.find(
                        (x) => x.id === action.payload.actionTaskId,
                    )?.actionPosts;

                    const [
                        updatedSourceActionPosts,
                        updatedDestinationActionPosts,
                    ] = reorderWithDiffParent<ActionPost>(
                        sourceActionPosts || [],
                        destinationActionPosts || [],
                        action.payload.source,
                        action.payload.destination,
                    );

                    return {
                        ...state,
                        actionTasks: state.actionTasks.map((x) => {
                            if (x.id === action.payload.oldActionTaskId) {
                                return {
                                    ...x,
                                    actionPosts: updatedSourceActionPosts,
                                } as ActionTask;
                            }

                            if (x.id === action.payload.actionTaskId) {
                                return {
                                    ...x,
                                    actionPosts: updatedDestinationActionPosts,
                                } as ActionTask;
                            }

                            return x;
                        }),
                    };
                }

                // for normal order change
                return {
                    ...state,
                    actionTasks: state.actionTasks.map((x) =>
                        x.id === action.payload.actionTaskId
                            ? ({
                                  ...x,
                                  actionPosts: reorder<ActionPost>(
                                      x.actionPosts || [],
                                      action.payload.source,
                                      action.payload.destination,
                                  ),
                              } as ActionTask)
                            : x,
                    ),
                };
            }

            // for kan-ban page
            return {
                ...state,
                actionPosts: reorder<ActionPost>(
                    state.actionPosts,
                    action.payload.source,
                    action.payload.destination,
                ),
            };

        case MapActionEnum.CREATE_TASK:
            return {
                ...state,
                tasks: [...state.tasks, action.payload],
            };

        case MapActionEnum.UPDATE_TASK:
            return {
                ...state,
                tasks: state.tasks.map((x) =>
                    x.id === action.payload.id
                        ? ({ ...x, name: action.payload.name } as Task)
                        : x,
                ),
            };

        case MapActionEnum.DELETE_TASK:
            return {
                ...state,
                tasks: state.tasks.filter((x) => x.id !== action.payload),
            };

        case MapActionEnum.CREATE_SUB_TASK:
            return {
                ...state,
                tasks: state.tasks.map((x) =>
                    x.id === action.payload.taskId
                        ? ({
                              ...x,
                              subTasks: [
                                  ...(x.subTasks || []),
                                  action.payload.item,
                              ],
                          } as Task)
                        : x,
                ),
            };

        case MapActionEnum.UPDATE_SUB_TASK:
            return {
                ...state,
                tasks: state.tasks.map((x) =>
                    x.id === action.payload.taskId
                        ? ({
                              ...x,
                              subTasks: (x.subTasks || []).map((y) =>
                                  y.id === action.payload.id
                                      ? { ...y, name: action.payload.name }
                                      : y,
                              ),
                          } as Task)
                        : x,
                ),
            };

        case MapActionEnum.DELETE_SUB_TASK:
            return {
                ...state,
                tasks: state.tasks.map((x) =>
                    x.id === action.payload.taskId
                        ? ({
                              ...x,
                              subTasks: (x.subTasks || []).filter(
                                  (y) => y.id !== action.payload.id,
                              ),
                          } as Task)
                        : x,
                ),
            };

        case MapActionEnum.CREATE_TASK_POST:
            return {
                ...state,
                tasks: state.tasks.map((x) =>
                    x.id === action.payload.taskId
                        ? ({
                              ...x,
                              subTasks: (x.subTasks || []).map((y) =>
                                  y.id === action.payload.subTaskId
                                      ? {
                                            ...y,
                                            taskPosts: [
                                                ...(y.taskPosts || []),
                                                action.payload.item,
                                            ],
                                        }
                                      : y,
                              ),
                          } as Task)
                        : x,
                ),
            };

        case MapActionEnum.UPDATE_TASK_POST:
            return {
                ...state,
                tasks: state.tasks.map((x) =>
                    x.id === action.payload.taskId
                        ? ({
                              ...x,
                              subTasks: (x.subTasks || []).map((y) =>
                                  y.id === action.payload.subTaskId
                                      ? {
                                            ...y,
                                            taskPosts: (y.taskPosts || []).map(
                                                (z) =>
                                                    z.id === action.payload.id
                                                        ? {
                                                              ...z,
                                                              ...action.payload
                                                                  .item,
                                                          }
                                                        : z,
                                            ),
                                        }
                                      : y,
                              ),
                          } as Task)
                        : x,
                ),
            };

        case MapActionEnum.DELETE_TASK_POST:
            return {
                ...state,
                tasks: state.tasks.map((x) =>
                    x.id === action.payload.taskId
                        ? ({
                              ...x,
                              subTasks: (x.subTasks || []).map((y) =>
                                  y.id === action.payload.subTaskId
                                      ? {
                                            ...y,
                                            taskPosts: (
                                                y.taskPosts || []
                                            ).filter(
                                                (z) =>
                                                    z.id !== action.payload.id,
                                            ),
                                        }
                                      : y,
                              ),
                          } as Task)
                        : x,
                ),
            };

        case MapActionEnum.CREATE_ACTION_TASK:
            return {
                ...state,
                actionTasks: [...state.actionTasks, action.payload],
            };

        case MapActionEnum.UPDATE_ACTION_TASK:
            return {
                ...state,
                actionTasks: state.actionTasks.map((x) =>
                    x.id === action.payload.id
                        ? ({ ...x, ...action.payload.item } as ActionTask)
                        : x,
                ),
            };

        case MapActionEnum.DELETE_ACTION_TASK:
            return {
                ...state,
                actionTasks: state.actionTasks.filter(
                    (x) => x.id !== action.payload,
                ),
            };

        case MapActionEnum.CREATE_ACTION_POST:
            // for portfolio page
            if (action.payload.actionTaskId) {
                return {
                    ...state,
                    actionTasks: state.actionTasks.map((x) =>
                        x.id === action.payload.actionTaskId
                            ? ({
                                  ...x,
                                  actionPosts: [
                                      ...(x.actionPosts || []),
                                      action.payload.item,
                                  ],
                              } as ActionTask)
                            : x,
                    ),
                };
            }

            // for kan-ban page
            return {
                ...state,
                actionPosts: [...state.actionPosts, action.payload.item],
            };

        case MapActionEnum.UPDATE_ACTION_POST:
            // for portfolio page
            if (action.payload.actionTaskId) {
                return {
                    ...state,
                    actionTasks: state.actionTasks.map((x) =>
                        x.id === action.payload.actionTaskId
                            ? ({
                                  ...x,
                                  actionPosts: (x.actionPosts || []).map((y) =>
                                      y.id === action.payload.id
                                          ? { ...y, ...action.payload.item }
                                          : y,
                                  ),
                              } as ActionTask)
                            : x,
                    ),
                };
            }

            // for kan-ban page
            return {
                ...state,
                actionPosts: state.actionPosts.map((x) =>
                    x.id === action.payload.id
                        ? ({ ...x, ...action.payload.item } as ActionPost)
                        : x,
                ),
            };

        case MapActionEnum.DELETE_ACTION_POST:
            // for portfolio page
            if (action.payload.actionTaskId) {
                return {
                    ...state,
                    actionTasks: state.actionTasks.map((x) =>
                        x.id === action.payload.actionTaskId
                            ? ({
                                  ...x,
                                  actionPosts: (x.actionPosts || []).filter(
                                      (y) => y.id !== action.payload.id,
                                  ),
                              } as ActionTask)
                            : x,
                    ),
                };
            }

            // for kan-ban page
            return {
                ...state,
                actionPosts: state.actionPosts.filter(
                    (x) => x.id !== action.payload.id,
                ),
            };

        case MapActionEnum.SET_RESPONSIBLE_USER_OPTIONS:
            return {
                ...state,
                responsibleUserOptions: action.payload,
            };

        case MapActionEnum.SET_TASK_POST_TAGS:
            return {
                ...state,
                taskPostTagsOptions: action.payload,
            };

        case MapActionEnum.SET_ACTION_POST_TAGS:
            return {
                ...state,
                actionPostTagsOptions: action.payload,
            };

        case MapActionEnum.SET_WORKSPACE_PERMISSIONS:
            return {
                ...state,
                workspacePermissions: action.payload,
            };

        default:
            return state;
    }
};

interface MapContextType {
    state: MapState;
    dispatch: React.Dispatch<MapAction>;
}

export const MapContext = createContext<MapContextType>({
    state: initialState,
    dispatch: () => null,
});

export const MapProvider: FC<PropsWithChildren> = ({ children }) => {
    // reducer
    const [state, dispatch] = useReducer(mapReducer, initialState);

    const fetchWorkspacePermissions = (workspaceId: number) => {
        WorkspaceApi.findById<Workspace>(workspaceId).then(
            ({ errorMessage, response }) => {
                if (errorMessage) {
                    errorToast(errorMessage);
                } else if (response) {
                    dispatch({
                        type: MapActionEnum.SET_WORKSPACE_PERMISSIONS,
                        payload: response.workspacePermissions,
                    });
                }
            },
        );
    };

    useEffect(() => {
        if (
            state.map?.workspace &&
            isString(state.map?.workspace) &&
            getIdFromResourceUrl(state.map?.workspace)
        ) {
            fetchWorkspacePermissions(
                getIdFromResourceUrl(state.map.workspace),
            );
        }
    }, [state.map?.workspace]);

    return (
        <MapContext.Provider value={{ state, dispatch }}>
            {children}
        </MapContext.Provider>
    );
};
