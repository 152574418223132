import { FC, useState } from "react";
import { useController, useFormContext } from "react-hook-form";

import { AppSVGIcon } from "../AppSVGIcon";
import {
    AppFormInputWrapper,
    AppFormInputWrapperProps,
} from "./AppFormInputWrapper";
import { AppPasswordStrengthBar } from "./AppPasswordStrengthBar";

interface AppFormInputProps extends AppFormInputWrapperProps {
    icon?: string;
    showPasswordStrength?: boolean;
}

export const AppFormInput: FC<AppFormInputProps> = ({
    id,
    name,
    inputClassName = "",
    type = "text",
    icon,
    showPasswordStrength = false,
    ...props
}) => {
    // hooks
    const { register, control } = useFormContext();
    const { field } = useController({
        name,
        control,
    });

    // state
    const [inputType, setInputType] = useState(type);

    // local state
    const controlId = id || name;

    const handleShowPassword = () => {
        setInputType((prev) => (prev === "password" ? "text" : "password"));
    };

    const renderIcon = () => {
        if (type === "password") {
            return (
                <AppSVGIcon
                    icon={inputType === "password" ? "eye-slash" : "eye"}
                    className="app-form-input--icon--right"
                    onClick={handleShowPassword}
                />
            );
        }

        if (!icon) {
            return <></>;
        }

        return (
            <AppSVGIcon icon={icon} className="app-form-input--icon--right" />
        );
    };

    const renderInput = () => {
        const withIcon =
            type === "password" || icon
                ? "app-form-input--with-icon--right"
                : "";

        return (
            <div className="app-form-input">
                <input
                    {...props}
                    {...register(name)}
                    id={controlId}
                    className={`form-control ${withIcon} ${inputClassName}`}
                    type={inputType}
                    onChange={field.onChange}
                />

                {renderIcon()}
            </div>
        );
    };

    return (
        <AppFormInputWrapper id={id} name={name} {...props}>
            {renderInput()}

            <AppPasswordStrengthBar
                show={showPasswordStrength}
                password={field.value}
            />
        </AppFormInputWrapper>
    );
};
