import { useContext } from "react";

import { SubscriptionActionEnum, errorToast } from "../utils";
import { SubscriptionContext, SubscriptionState } from "../contexts";
import { PLAN_PROFESSIONAL } from "../config";
import { Card, Invoice } from "../models";
import { ClientApi } from "../apis";

interface SubscriptionActionType extends SubscriptionState {
    isCurrentProfessionalPlan: boolean;
    actionRefetchMyClient: () => void;
    actionShowLoading: () => void;
    actionHideLoading: () => void;
    actionInvoiceCreate: (payload: Invoice) => void;
    actionInvoicePay: (payload: boolean) => void;
    actionFetchCards: (clientId: number) => void;
}

export function useSubscriptionAction(): SubscriptionActionType {
    // contexts
    const context = useContext(SubscriptionContext);
    if (!context) {
        throw new Error(
            "useSubscriptionAction must be used within a SubscriptionProvider",
        );
    }
    const { state, dispatch } = context;

    const actionRefetchMyClient = () => {
        dispatch({
            type: SubscriptionActionEnum.REFETCH_MY_CLIENT,
        });
    };

    const actionShowLoading = () => {
        dispatch({
            type: SubscriptionActionEnum.SHOW_LOADING,
        });
    };

    const actionHideLoading = () => {
        dispatch({
            type: SubscriptionActionEnum.HIDE_LOADING,
        });
    };

    const actionInvoiceCreate = (payload: Invoice) => {
        dispatch({
            type: SubscriptionActionEnum.INVOICE_CREATE,
            payload,
        });
    };

    const actionInvoicePay = (payload: boolean) => {
        dispatch({
            type: SubscriptionActionEnum.INVOICE_PAY,
            payload,
        });
    };

    const actionFetchCards = (clientId: number) => {
        actionShowLoading();

        ClientApi.getCards<{
            data: {
                card: Card;
                id: string;
                billing_details: { name: string };
            }[];
        }>(clientId)
            .then(({ response, errorMessage }) => {
                if (errorMessage) {
                    errorToast(errorMessage);
                } else if (response !== null) {
                    const cards = response.data?.flatMap((x) => ({
                        ...x.card,
                        name: x.billing_details.name,
                        id: x.id,
                    })) as Card[];

                    ClientApi.getCustomer<{
                        invoice_settings: { default_payment_method: string };
                    }>(clientId).then(
                        ({ response: res, errorMessage: errMsg }) => {
                            if (errMsg) {
                                errorToast(errMsg);
                            } else if (res !== null) {
                                const defaultCardId =
                                    res.invoice_settings
                                        ?.default_payment_method;

                                const primaryCard =
                                    cards?.find(
                                        (x) => x.id === defaultCardId,
                                    ) || null;

                                const additionalCards = cards?.filter(
                                    (x) => x.id !== defaultCardId,
                                );

                                dispatch({
                                    type: SubscriptionActionEnum.FETCH_CARDS,
                                    payload: {
                                        cards: additionalCards,
                                        primaryCard,
                                    },
                                });
                            }
                        },
                    );
                }
            })
            .finally(() => actionHideLoading());
    };

    const isCurrentProfessionalPlan =
        !!state?.myClient &&
        state?.myClient.currentPlan === PLAN_PROFESSIONAL.name;

    return {
        ...state,
        isCurrentProfessionalPlan,
        actionRefetchMyClient,
        actionShowLoading,
        actionHideLoading,
        actionInvoiceCreate,
        actionInvoicePay,
        actionFetchCards,
    };
}
