import { FC, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { yupResolver } from "@hookform/resolvers/yup";
import { FormProvider, useForm } from "react-hook-form";

import {
    TKey,
    errorToast,
    setBackendViolations,
    setCustomViolation,
    successToast,
    validationMessage,
} from "../../../utils";
import { AuthApi } from "../../../apis";
import { useTranslation } from "../../../hooks";
import {
    AppButton,
    AppForm,
    AppFormActions,
    AppFormContent,
    AppFormGroup,
    AppFormInput,
    AppFormMessage,
    AppLink,
    AppLoader,
} from "../../../components";
import { schema } from "./schema";

interface ResetPasswordForm {
    plainPassword: string;
    confirmPassword: string;
}

interface ResetPasswordPayload {
    token: string;
    plainPassword: string;
}

export const ResetPasswordPage: FC = () => {
    // hooks
    const { t } = useTranslation();
    const navigate = useNavigate();
    const [searchParams] = useSearchParams();

    // state
    const [loading, setLoading] = useState(false);

    // form
    const methods = useForm<ResetPasswordForm>({
        resolver: yupResolver(schema(t)),
    });
    const { handleSubmit, formState, reset, setError } = methods;

    const onSubmitHandler = (formData: ResetPasswordForm) => {
        const token = searchParams.get("token");
        if (!token) {
            setCustomViolation(
                "custom",
                t(TKey.Common.Message.TokenMissing),
                setError,
                false,
            );
            return;
        }

        setLoading(true);

        AuthApi.resetPassword<unknown, ResetPasswordPayload>({
            token,
            plainPassword: formData.plainPassword,
        })
            .then(({ response, errorMessage, isInvalid, error }) => {
                if (isInvalid) {
                    setBackendViolations(error, setError);
                } else if (errorMessage) {
                    errorToast(errorMessage);
                } else if (response !== null) {
                    successToast(t("user.reset-password.form:toast.success"));
                    navigate("/auth/reset-password-confirmation");
                    reset();
                }
            })
            .finally(() => setLoading(false));
    };

    return (
        <FormProvider {...methods}>
            <AppForm onSubmit={handleSubmit(onSubmitHandler)}>
                {loading && <AppLoader isFullScreen />}

                <AppFormContent>
                    <AppFormGroup className="mb-2">
                        <AppFormMessage
                            errorMessage={validationMessage(
                                "custom",
                                formState,
                            )}
                        />
                    </AppFormGroup>

                    <AppFormInput
                        type="password"
                        id="plainPassword"
                        name="plainPassword"
                        label={t("user.reset-password.form:label.password")}
                        placeholder={t(
                            "user.reset-password.form:placeholder.password",
                        )}
                        showPasswordStrength
                        autoComplete="new-password"
                        block
                    />

                    <AppFormInput
                        inputClassName="mb-3"
                        type="password"
                        id="confirmPassword"
                        name="confirmPassword"
                        label={t(
                            "user.reset-password.form:label.confirmPassword",
                        )}
                        placeholder={t(
                            "user.reset-password.form:placeholder.confirmPassword",
                        )}
                        autoComplete="new-password"
                        block
                        onCopy={(e) => e.preventDefault()}
                        onPaste={(e) => e.preventDefault()}
                    />

                    <AppFormGroup auto className="ms-auto mb-4">
                        <AppLink to={"/auth/login"} size="sm">
                            {t("user.reset-password.form:link.login")}
                        </AppLink>
                    </AppFormGroup>
                </AppFormContent>

                <AppFormActions
                    withDefault={false}
                    position="center"
                    className="pt-2 px-5"
                >
                    <AppButton
                        type="submit"
                        isLoading={formState.isSubmitting || loading}
                        disabled={formState.isSubmitting || loading}
                        className="auth-button"
                    >
                        {t("user.reset-password.form:button.resetPassword")}
                    </AppButton>
                </AppFormActions>
            </AppForm>
        </FormProvider>
    );
};
