import { FC, Fragment, useEffect, useState } from "react";
import { FormProvider, useFieldArray, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useRecoilState } from "recoil";
import { Row } from "react-bootstrap";
import { useParams } from "react-router-dom";

import "./assets/scss/style.scss";

import {
    errorToast,
    setBackendViolations,
    successToast,
    validation,
    validationMessage,
} from "../../../utils";
import { schema } from "./schema";
import { atomActiveClient } from "../../../atoms";
import { useTranslation } from "../../../hooks";
import { Map, PMap } from "../../../models";
import { MapApi } from "../../../apis";
import {
    AppTabs,
    AppForm,
    AppFormCheckBox,
    AppFormContent,
    AppFormGroup,
    AppFormInput,
    AppFormMessage,
    AppFormModal,
    AppFormTextarea,
} from "../../../components";
import { ACTION_POST_STATUS } from "../../../config";

interface MapKanBanSettingProps {
    map: Map;
    showForm: boolean;
    onHide: () => void;
    onSettingChange?: () => void;
}

export const MapKanBanSetting: FC<MapKanBanSettingProps> = ({
    map,
    showForm,
    onHide,
    onSettingChange,
}) => {
    // hooks
    const { t } = useTranslation();
    const { id } = useParams();

    // atoms
    const [activeClient] = useRecoilState(atomActiveClient);

    // state
    const [loading, setLoading] = useState(false);
    const [activeTab, setActiveTab] = useState(
        ACTION_POST_STATUS.ACTIONPOST_STATUS_BACKLOG,
    );

    // form
    const methods = useForm({
        resolver: yupResolver(schema(t)),
    });
    const { handleSubmit, formState, reset, control, setError } = methods;

    const { fields } = useFieldArray({
        control,
        name: "kanbanSettings",
    });

    const onSubmitHandler = (formData: unknown) => {
        if (!activeClient || !id) {
            return;
        }

        const payload = formData as PMap;

        setLoading(true);

        MapApi.update<Map, PMap>(+id, payload)
            .then(({ response, errorMessage, isInvalid, error }) => {
                if (isInvalid) {
                    setBackendViolations(error, setError);
                } else if (errorMessage) {
                    errorToast(errorMessage);
                } else if (response !== null) {
                    successToast(
                        t("app.map.kanban.setting.form:update.toast.success"),
                    );
                    reset({
                        kanbanSettings: response.kanbanSettings,
                    });
                    onHide();

                    if (onSettingChange) {
                        onSettingChange();
                    }
                }
            })
            .finally(() => setLoading(false));
    };

    useEffect(() => {
        if (showForm) {
            setActiveTab(ACTION_POST_STATUS.ACTIONPOST_STATUS_BACKLOG);

            setLoading(true);

            MapApi.getSettingById<Map>(map.id)
                .then(({ errorMessage, response }) => {
                    if (errorMessage) {
                        errorToast(errorMessage);
                    } else if (response) {
                        reset({
                            kanbanSettings: response.kanbanSettings,
                        });
                    }
                })
                .finally(() => setLoading(false));
        }
    }, [showForm]);

    if (!map || !map?.kanbanSettings) {
        return <></>;
    }

    const renderTabs = () => {
        const isPrimaryBoardType = [
            ACTION_POST_STATUS.ACTIONPOST_STATUS_BACKLOG,
            ACTION_POST_STATUS.ACTIONPOST_STATUS_COMPLETED,
        ].includes(activeTab);

        return (
            <AppFormGroup block className="board-types">
                <div className="board-types--header mt-3">
                    <span className="label--300 ms-3">
                        {t(
                            "app.map.kanban.setting.form:label.kanbanSettings.name",
                        )}
                    </span>

                    {!isPrimaryBoardType && (
                        <div className="d-flex gap-5 flex">
                            <span className="label--300 me-3">
                                {t(
                                    "app.map.kanban.setting.form:label.kanbanSettings.limit",
                                )}
                            </span>

                            <span className="label--300 me-3">
                                {t(
                                    "app.map.kanban.setting.form:label.kanbanSettings.activate",
                                )}
                            </span>
                        </div>
                    )}
                </div>

                <div className="board-types--content gap-3 mt-3">
                    {fields.map((field, index) => {
                        if (field.type !== activeTab) {
                            return <></>;
                        }

                        return (
                            <Fragment key={field.id}>
                                <Row className="board-types--content--item">
                                    <AppFormInput
                                        id={`kanbanSettings[${index}].name`}
                                        name={`kanbanSettings[${index}].name`}
                                        block={isPrimaryBoardType ? 12 : 7}
                                    />

                                    {!isPrimaryBoardType && (
                                        <>
                                            <AppFormInput
                                                type="number"
                                                id={`kanbanSettings[${index}].limit`}
                                                name={`kanbanSettings[${index}].limit`}
                                                disabled={isPrimaryBoardType}
                                                block={3}
                                            />

                                            <AppFormCheckBox
                                                id={`kanbanSettings[${index}].isEnable`}
                                                name={`kanbanSettings[${index}].isEnable`}
                                                inputOptions={[
                                                    {
                                                        label: "",
                                                        value: "",
                                                        disabled:
                                                            isPrimaryBoardType,
                                                    },
                                                ]}
                                                block={2}
                                            />
                                        </>
                                    )}
                                </Row>

                                <AppFormTextarea
                                    id={`kanbanSettings[${index}].questions.question1`}
                                    name={`kanbanSettings[${index}].questions.question1`}
                                    label={t(
                                        "app.map.kanban.setting.form:label.questions.question1",
                                    )}
                                    required={false}
                                    block
                                    rows={5}
                                    maxCount={validation.description.max}
                                    isEditor
                                />

                                <AppFormTextarea
                                    id={`kanbanSettings[${index}].questions.question2`}
                                    name={`kanbanSettings[${index}].questions.question2`}
                                    label={t(
                                        "app.map.kanban.setting.form:label.questions.question2",
                                    )}
                                    required={false}
                                    block
                                    rows={5}
                                    maxCount={validation.description.max}
                                    isEditor
                                />

                                <AppFormTextarea
                                    id={`kanbanSettings[${index}].questions.question3`}
                                    name={`kanbanSettings[${index}].questions.question3`}
                                    label={t(
                                        "app.map.kanban.setting.form:label.questions.question3",
                                    )}
                                    required={false}
                                    block
                                    rows={5}
                                    maxCount={validation.description.max}
                                    isEditor
                                />

                                <AppFormTextarea
                                    id={`kanbanSettings[${index}].questions.question4`}
                                    name={`kanbanSettings[${index}].questions.question4`}
                                    label={t(
                                        "app.map.kanban.setting.form:label.questions.question4",
                                    )}
                                    required={false}
                                    block
                                    rows={5}
                                    maxCount={validation.description.max}
                                    isEditor
                                />
                            </Fragment>
                        );
                    })}

                    <AppFormMessage
                        errorMessage={validationMessage(
                            "kanbanSettings",
                            formState,
                        )}
                    />
                </div>
            </AppFormGroup>
        );
    };

    const renderForm = () => (
        <FormProvider {...methods}>
            <AppForm className="app-kanban-setting">
                <AppTabs
                    className="mb-4 flex-wrap"
                    items={map.kanbanSettings.map((x) => ({
                        title: x.type,
                        icon: "",
                        url: x.type,
                    }))}
                    onClick={(item) => {
                        if (item.url) {
                            setActiveTab(item.url);
                        }
                    }}
                    isActiveTab={(item) => item?.url === activeTab}
                />

                <AppFormContent>{renderTabs()}</AppFormContent>
            </AppForm>
        </FormProvider>
    );

    return (
        <AppFormModal
            show={showForm}
            icon="settings"
            title="app.map.kanban.setting.form:modal.title"
            isDrawer
            onSubmit={handleSubmit(onSubmitHandler)}
            onHide={() => onHide()}
            isLoading={formState.isSubmitting || loading}
            disabled={!activeClient}
            isEditMode
        >
            {renderForm()}
        </AppFormModal>
    );
};
