import i18next from "i18next";
import { initReactI18next } from "react-i18next";

// import all translation files
import translationEn from "../translations/en.json";
import translationEs from "../translations/es.json";
import translationFr from "../translations/fr.json";
import translationDe from "../translations/de.json";

// i18n.changeLanguage(locale);

export const initI18n = (lng = "en") => {
    i18next.use(initReactI18next).init({
        resources: {
            en: {
                translation: translationEn,
            },
            es: {
                translation: translationEs,
            },
            fr: {
                translation: translationFr,
            },
            de: {
                translation: translationDe,
            },
        },
        lng, //default language
        fallbackLng: "en",
        keySeparator: false, // means no nested json keys
        nsSeparator: "::", // avoid use of namespace (default :), otherwise it will break key after :
        interpolation: {
            escapeValue: false,
        },
        react: {
            useSuspense: true, // Enable Suspense mode
        },
    });
};
