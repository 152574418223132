import { BaseEntity } from "./BaseEntity";
import { Client } from "./Client";
import { WorkspacePermission } from "./WorkspacePermission";

export class Workspace extends BaseEntity {
    client: string | Client;

    name: string;

    workspacePermissions: WorkspacePermission[];

    ord: number;

    constructor(
        client: string | Client,
        { name = "", workspacePermissions = [], ord = 1 }: PWorkspace = {},
    ) {
        super();
        this.client = client;
        this.name = name;
        this.workspacePermissions = workspacePermissions;
        this.ord = ord;
    }

    toJSON(addExtraData = false) {
        return {
            ...super.toJSON(addExtraData),
            client: this.client,
            name: this.name,
            workspacePermissions: this.workspacePermissions,
        };
    }

    static createForm(client: string, formData: PWorkspace): PWorkspace {
        return new Workspace(client, formData).toJSON();
    }
}

export type PWorkspace = Partial<Workspace>;
