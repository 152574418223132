import { atom } from "recoil";

const KEY = "atom-map-portfolio-duration-height";

type AtomMapPortfolioDurationHeightType = {
    shortTermHeight: number;
    mediumTermHeight: number;
    longTermHeight: number;
};

export const atomMapPortfolioDurationHeight =
    atom<AtomMapPortfolioDurationHeightType>({
        key: KEY,
        default: {
            shortTermHeight: 0,
            mediumTermHeight: 0,
            longTermHeight: 0,
        },
    });
