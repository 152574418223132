import { ICellRendererParams } from "ag-grid-community";
import { Link } from "react-router-dom";

import "./app-grid-style.scss";

import { AppActionDropDown, AppSVGIcon } from "../../../../components";
import { TKey, copyToClipBoard } from "../../../../utils";
import { useTranslation } from "../../../../hooks";
import { AppMapShareGridColDefParams } from "./column-defs";

export const AppGridName = (params: ICellRendererParams) => {
    const { data } = params;
    return (
        <>
            {data.name}
            <Link
                to={"#"}
                onClick={() =>
                    copyToClipBoard(
                        `${window.location.origin}/map-view/${btoa(
                            `${data.id}|${data.map.id}|${data.name}|${data.map.name}`,
                        )}`,
                    )
                }
                style={{ display: "inline-block", marginLeft: "10px" }}
            >
                <AppSVGIcon icon="clone" size="xs" />
            </Link>
        </>
    );
};

export const AppGridCreator = (params: ICellRendererParams) => {
    const { value } = params;
    return <span>{`${value.firstName} ${value.lastName}`}</span>;
};

export const AppGridAction = (
    params: ICellRendererParams & AppMapShareGridColDefParams,
) => {
    // hooks
    const { t } = useTranslation();

    // params
    const { onPressDelete, data } = params;

    return (
        <AppActionDropDown id={data.id}>
            <li className="delete">
                <Link
                    to={"#"}
                    onClick={(e) => {
                        e.preventDefault();
                        onPressDelete(data.id);
                    }}
                >
                    <AppSVGIcon icon="delete" />

                    <span>{t(TKey.Common.Button.Delete)}</span>
                </Link>
            </li>
        </AppActionDropDown>
    );
};
