import * as yup from "yup";
import { TKey, validation } from "../../../utils";

export const schema = (t: (val: string) => string) =>
    yup.object().shape({
        name: yup
            .string()
            .required(t(TKey.Common.Message.Validation.RequiredField))
            .min(validation.title.min)
            .max(validation.title.max),
        workspacePermissions: yup
            .array()
            .of(
                yup.object().shape({
                    user: yup
                        .string()
                        .required(
                            t(TKey.Common.Message.Validation.RequiredField),
                        ),
                    permission: yup
                        .string()
                        .required(
                            t(TKey.Common.Message.Validation.RequiredField),
                        ),
                }),
            )
            .min(
                1,
                t(
                    "app.workspace.item:message.validation.atLeastOneOwnerPermission",
                ),
            ),
    });
