import { EntityAPI } from "./core";
import { FinalResponse, ROUTES, route } from "../config";

const {
    api_action_posts_get_collection: API_GET_COLLECTION,
    api_action_posts_post_collection: API_POST_COLLECTION,
    api_action_posts_get_item: API_GET_ITEM,
    api_action_posts_put_item: API_PUT_ITEM,
    api_action_posts_patch_item: API_PATCH_ITEM,
    api_action_posts_delete_item: API_DELETE_ITEM,
    api_action_posts_patch_change_order_item: API_PATCH_CHANGE_ORDER_ITEM,
    api_action_posts_clone: API_CLONE_ITEM,
    api_action_posts_get_pdf: API_GET_PDF,
    api_action_posts_archive_all: API_ARCHIVE_ALL,
    api_action_posts_pdf_all: API_GET_PDF_ALL,
    api_action_posts_pdf_all_kanban: API_GET_PDF_ALL_KANBAN,
    api_action_posts_dashboard_statastics: API_DASHBOARD_STATISTICS,
    api_action_posts_dashboard_statastics_by_at:
        API_DASHBOARD_STATISTICS_BY_ACTION_TASK,
    api_action_posts_dashboard_statastics_by_rp:
        API_DASHBOARD_STATISTICS_BY_RESPONSIBLE_PERSON,
} = ROUTES;

export abstract class ActionPostApi extends EntityAPI {
    protected static GET_COLLECTION = API_GET_COLLECTION;

    protected static POST_COLLECTION = API_POST_COLLECTION;

    protected static GET_ITEM = API_GET_ITEM;

    protected static PUT_ITEM = API_PUT_ITEM;

    protected static PATCH_ITEM = API_PATCH_ITEM;

    protected static DELETE_ITEM = API_DELETE_ITEM;

    public static async changeOrder<R, P>(
        id: number,
        payload: P,
    ): Promise<FinalResponse<R | null>> {
        return this.makePatch<R, P>(
            route(API_PATCH_CHANGE_ORDER_ITEM, { id }),
            JSON.stringify(payload),
        )
            .then(({ data }) => this.handleResponse<R>(data))
            .catch((e) => this.handleAllError(e));
    }

    public static async clone<R, P = null>(
        payload: P,
    ): Promise<FinalResponse<R | null>> {
        return this.makePost<R, P>(API_CLONE_ITEM, payload)
            .then(({ data }) => this.handleResponse<R>(data))
            .catch((e) => this.handleAllError(e));
    }

    public static async pdfDownload<R>(
        id: number,
        extraParams = {},
    ): Promise<FinalResponse<R | null>> {
        const path = route(API_GET_PDF, { id });

        return this.makeGet<R>(
            path,
            { ...extraParams },
            {
                responseType: "blob",
            },
        )
            .then(({ data }) => this.handleResponse<R>(data))
            .catch((e) => this.handleAllError(e));
    }

    public static async archiveAll<R, P = null>(
        payload: P,
    ): Promise<FinalResponse<R | null>> {
        return this.makePost<R, P>(API_ARCHIVE_ALL, payload)
            .then(({ data }) => this.handleResponse<R>(data))
            .catch((e) => this.handleAllError(e));
    }

    public static async pdfDownloadAll<R, P = null>(
        payload: P,
    ): Promise<FinalResponse<R | null>> {
        return this.makePost<R, P>(
            API_GET_PDF_ALL,
            payload,
            {},
            {
                responseType: "blob",
            },
        )
            .then(({ data }) => this.handleResponse<R>(data))
            .catch((e) => this.handleAllError(e));
    }

    public static async pdfDownloadAllKanban<R, P = null>(
        payload: P,
    ): Promise<FinalResponse<R | null>> {
        return this.makePost<R, P>(
            API_GET_PDF_ALL_KANBAN,
            payload,
            {},
            {
                responseType: "blob",
            },
        )
            .then(({ data }) => this.handleResponse<R>(data))
            .catch((e) => this.handleAllError(e));
    }

    public static async getDashboardStatistics<R, P = null>(
        payload: P,
    ): Promise<FinalResponse<R | null>> {
        return this.makePost<R, P>(API_DASHBOARD_STATISTICS, payload)
            .then(({ data }) => this.handleResponse<R>(data))
            .catch((e) => this.handleAllError(e));
    }

    public static async getDashboardStatisticsByActionTask<R, P = null>(
        payload: P,
    ): Promise<FinalResponse<R | null>> {
        return this.makePost<R, P>(
            API_DASHBOARD_STATISTICS_BY_ACTION_TASK,
            payload,
        )
            .then(({ data }) => this.handleResponse<R>(data))
            .catch((e) => this.handleAllError(e));
    }

    public static async getDashboardStatisticsByResponsiblePerson<R, P = null>(
        payload: P,
    ): Promise<FinalResponse<R | null>> {
        return this.makePost<R, P>(
            API_DASHBOARD_STATISTICS_BY_RESPONSIBLE_PERSON,
            payload,
        )
            .then(({ data }) => this.handleResponse<R>(data))
            .catch((e) => this.handleAllError(e));
    }
}
