import { ROUTES } from "../config";
import { EntityAPI } from "./core";

const {
    api_email_templates_get_collection: API_GET_COLLECTION,
    api_email_templates_get_item: API_GET_ITEM,
    api_email_templates_patch_item: API_PATCH_ITEM,
} = ROUTES;

export abstract class EmailTemplateApi extends EntityAPI {
    protected static GET_COLLECTION = API_GET_COLLECTION;

    // protected static POST_COLLECTION = API_POST_COLLECTION;

    protected static GET_ITEM = API_GET_ITEM;

    // protected static PUT_ITEM = API_PUT_ITEM;

    protected static PATCH_ITEM = API_PATCH_ITEM;

    // protected static DELETE_ITEM = API_DELETE_ITEM;
}
