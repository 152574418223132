import { BaseEntity } from "./BaseEntity";
import { Client } from "./Client";
import { Task } from "./Task";
import { ActionTask } from "./ActionTask";
import { User } from "./User";
import { Workspace } from "./Workspace";
import { ActionPostWeightLevel } from "./ActionPostWeightLevel";
import { ActionPostDuration } from "./ActionPostDuration";
import { ActionPostBoard } from "./ActionPostBoard";
import { staticZoomRatio } from "../../utils";

export class Map extends BaseEntity {
    client: string | Client;

    workspace: string | Workspace;

    name: string;

    subTitle: string;

    description: string;

    creator?: User;

    tasks?: Task[];

    actionTasks?: ActionTask[];

    actionPostWeightLevels: ActionPostWeightLevel[];

    actionPostDurations: ActionPostDuration[];

    kanbanSettings: ActionPostBoard[];

    zoomMap: number;

    zoomPortfolio: number;

    zoomKanban: number;

    isVoting: boolean;

    isVotingOpen: boolean;

    days?: number | null;

    budget?: number | null;

    expense?: number | null;

    constructor(
        client: string | Client,
        workspace: string | Workspace,
        {
            name = "",
            subTitle = "",
            description = "",
            actionPostWeightLevels = [],
            actionPostDurations = [],
            kanbanSettings = [],
            zoomMap = staticZoomRatio,
            zoomPortfolio = staticZoomRatio,
            zoomKanban = staticZoomRatio,
            isVoting = false,
            isVotingOpen = false,
            days = null,
            budget = null,
            expense = null,
        }: PMap = {},
    ) {
        super();
        this.client = client;
        this.workspace = workspace;
        this.name = name;
        this.subTitle = subTitle;
        this.description = description;
        this.actionPostWeightLevels = actionPostWeightLevels;
        this.actionPostDurations = actionPostDurations;
        this.kanbanSettings = kanbanSettings;
        this.zoomMap = zoomMap;
        this.zoomPortfolio = zoomPortfolio;
        this.zoomKanban = zoomKanban;
        this.isVoting = isVoting;
        this.isVotingOpen = isVotingOpen;
        this.days = days;
        this.budget = budget;
        this.expense = expense;
    }

    toJSON(addExtraData = false) {
        return {
            ...super.toJSON(addExtraData),
            client: this.client,
            workspace: this.workspace,
            name: this.name,
            subTitle: this.subTitle,
            description: this.description,
            actionPostWeightLevels: this.actionPostWeightLevels,
            actionPostDurations: this.actionPostDurations,
            kanbanSettings: this.kanbanSettings,
            zoomMap: this.zoomMap,
            zoomPortfolio: this.zoomPortfolio,
            zoomKanban: this.zoomKanban,
            isVoting: this.isVoting,
            isVotingOpen: this.isVotingOpen,
            days: this.days,
            budget: this.budget,
            expense: this.expense,
        };
    }

    static createForm(client: string, workspace: string, formData: PMap): PMap {
        return new Map(client, workspace, formData).toJSON();
    }
}

export type PMap = Partial<Map>;
