import { useContext } from "react";

import { GlobalContext, GlobalState } from "../contexts";

type GlobalStateType = GlobalState;

export function useGlobalState(): GlobalStateType {
    // contexts
    const context = useContext(GlobalContext);

    if (!context) {
        throw new Error("useGlobalData must be used within a GlobalProvider");
    }

    return context;
}
