import { TKey } from "../constants";
import { successToast } from "./notification.helper";

export const copyToClipBoard = async (textToCopy: string) => {
    try {
        await navigator.clipboard.writeText(textToCopy);
        successToast(TKey.Common.Message.CopiedToClipboard);
    } catch (err) {
        console.error("Failed to copy to clipboard.");
    }
};
