import { ICellRendererParams } from "ag-grid-community";
import { Link } from "react-router-dom";

import "./app-grid-style.scss";

import {
    AppActionDropDown,
    AppImage,
    AppSVGIcon,
} from "../../../../components";
import {
    ClientLogoFileInfo,
    PLAN_ENTERPRISE,
    PLAN_FREE,
    PLAN_PROFESSIONAL,
} from "../../../../config";
import { AppClientGridColDefParams } from "./column-defs";
import { TKey } from "../../../../utils";
import { useTranslation } from "../../../../hooks";

export const AppGridLogo = (params: ICellRendererParams) => {
    const { value } = params;

    if (!value) {
        return <></>;
    }

    return (
        <AppImage
            src={value}
            fileInfo={ClientLogoFileInfo}
            className="d-flex app-grid-logo"
            width="120px"
        />
    );
};

export const AppGridAction = (
    params: ICellRendererParams & AppClientGridColDefParams,
) => {
    // hooks
    const { t } = useTranslation();

    // params
    const {
        onPressEdit,
        onPressDelete,
        data,
        onPressChangePlan,
        onPressAutoRenew,
    } = params;

    return (
        <AppActionDropDown id={data.id}>
            {data?.currentPlan && (
                <>
                    {[PLAN_ENTERPRISE.name].includes(data.currentPlan) && (
                        <li className="change-to-free">
                            <Link
                                to={"#"}
                                onClick={(e) => {
                                    e.preventDefault();
                                    onPressChangePlan(data.id, PLAN_FREE.name);
                                }}
                            >
                                <AppSVGIcon icon="reply" />
                                {t("admin.client.list:label.changeToFree")}
                            </Link>
                        </li>
                    )}

                    {[PLAN_FREE.name].includes(data.currentPlan) && (
                        <li className="change-to-enterprise">
                            <Link
                                to={"#"}
                                onClick={(e) => {
                                    e.preventDefault();
                                    onPressChangePlan(
                                        data.id,
                                        PLAN_ENTERPRISE.name,
                                    );
                                }}
                            >
                                <AppSVGIcon icon="reply" />
                                {t(
                                    "admin.client.list:label.changeToEnterprise",
                                )}
                            </Link>
                        </li>
                    )}

                    {[PLAN_ENTERPRISE.name, PLAN_PROFESSIONAL.name].includes(
                        data.currentPlan,
                    ) && (
                        <li className="auto-renew">
                            <Link
                                to={"#"}
                                onClick={(e) => {
                                    e.preventDefault();
                                    onPressAutoRenew(data.id, data.isAutoRenew);
                                }}
                            >
                                <AppSVGIcon
                                    icon={
                                        data.isAutoRenew
                                            ? "renewal-disable"
                                            : "renewal-enable"
                                    }
                                />

                                {data.isAutoRenew
                                    ? t(
                                          "admin.subscription.overview:label.cancelRenewal",
                                      )
                                    : t(
                                          "admin.subscription.overview:label.activeRenewal",
                                      )}
                            </Link>
                        </li>
                    )}
                </>
            )}

            <li className="billing-history">
                <Link to={`/admin/clients/${data.id}/billing-history`}>
                    <AppSVGIcon icon="help" />
                    <span>
                        {t("admin.subscription.overview:label.billingHistory")}
                    </span>
                </Link>
            </li>

            {onPressEdit && (
                <li className="edit">
                    <Link
                        to={"#"}
                        onClick={(e) => {
                            e.preventDefault();
                            onPressEdit(data.id);
                        }}
                    >
                        <AppSVGIcon icon="edit" />

                        <span>{t(TKey.Common.Button.Edit)}</span>
                    </Link>
                </li>
            )}
            {onPressDelete && (
                <li className="delete">
                    <Link
                        to={"#"}
                        onClick={(e) => {
                            e.preventDefault();
                            onPressDelete(data.id);
                        }}
                    >
                        <AppSVGIcon icon="delete" />

                        <span>{t(TKey.Common.Button.Delete)}</span>
                    </Link>
                </li>
            )}
        </AppActionDropDown>
    );
};
