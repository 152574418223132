import { FC, useState } from "react";
import { yupResolver } from "@hookform/resolvers/yup";
import { FormProvider, useForm } from "react-hook-form";
import { useRecoilState, useResetRecoilState } from "recoil";

import "./assets/scss/style.scss";

import { useTranslation } from "../../../hooks";
import {
    AppButton,
    AppFlag,
    AppForm,
    AppFormContent,
    AppFormInput,
    AppFormModal,
    AppFormPhoneNumber,
    AppFormSelect,
    AppFormUploader,
    AppLoader,
} from "../../../components";
import {
    CropperViewEnum,
    ModalTypeEnum,
    TKey,
    errorToast,
    setBackendViolations,
    successToast,
    validation,
} from "../../../utils";
import { ClientLogoFileInfo, getCountryOptions } from "../../../config";
import { atomActiveClient, atomDrawer, atomDrawerType } from "../../../atoms";
import { Client, PClient } from "../../../models";
import { ClientApi } from "../../../apis";
import { schema } from "../../pages/ClientPage/schema";

interface SubscriptionCompanyInfoProps {
    clientInfo: Client;
}

export const SubscriptionCompanyInfo: FC<SubscriptionCompanyInfoProps> = ({
    clientInfo,
}) => {
    // hooks
    const { t } = useTranslation();

    // atoms
    const [activeClient, setActiveClient] = useRecoilState(atomActiveClient);
    const [drawer, setDrawer] = useRecoilState(
        atomDrawerType(ModalTypeEnum.CLIENT_ADD_EDIT),
    );
    const resetDrawer = useResetRecoilState(atomDrawer);

    // state
    const [editId, setEditId] = useState(0);
    const [isUploading, setIsUploading] = useState(false);
    const [loading, setLoading] = useState(false);

    // form
    const methods = useForm({
        resolver: yupResolver(schema(t, editId)),
    });
    const { handleSubmit, formState, reset, setError } = methods;

    const handleClientEdit = () => {
        if (!clientInfo) {
            return;
        }

        reset({
            name: clientInfo.name,
            address: clientInfo.address,
            postCode: clientInfo.postCode,
            city: clientInfo.city,
            country: clientInfo.country,
            phone: clientInfo.phone,
            imageName: clientInfo.imageName,
        });

        setEditId(clientInfo.id);
        setDrawer({ show: true, id: clientInfo.id });
    };

    const onSubmitHandler = (formData: PClient) => {
        setLoading(true);

        const { name, address, postCode, city, country, phone, imageName } =
            formData;

        const clientFormData = {
            name,
            address,
            postCode,
            city,
            country,
            phone,
            imageName,
        };

        ClientApi.createOrUpdate<Client, PClient>(editId, clientFormData)
            .then(({ response, errorMessage, isInvalid, error }) => {
                if (isInvalid) {
                    setBackendViolations(error, setError);
                } else if (errorMessage) {
                    errorToast(errorMessage);
                } else if (response !== null) {
                    if (editId) {
                        successToast(
                            t("admin.client.list:update.toast.success"),
                        );

                        if (activeClient && activeClient.id === editId) {
                            setActiveClient({
                                id: response.id,
                                imageName: response.imageName,
                            });
                        }
                    }

                    reset();
                    resetDrawer();
                    setEditId(0);
                }
            })
            .finally(() => setLoading(false));
    };

    const renderForm = () => (
        <FormProvider {...methods}>
            <AppForm className="client-page--form">
                {loading && <AppLoader isFullScreen />}

                <AppFormContent>
                    <AppFormUploader
                        id="imageName"
                        name="imageName"
                        label={t("admin.client.form:label.imageName")}
                        block
                        fileInfo={ClientLogoFileInfo}
                        cbFileUploading={setIsUploading}
                        withCropper={CropperViewEnum.Default}
                    />

                    <AppFormInput
                        id="name"
                        name="name"
                        label={t("admin.client.form:label.name")}
                        placeholder={t("admin.client.form:placeholder.name")}
                        block
                        maxCount={validation.title.max}
                    />

                    <AppFormInput
                        id="address"
                        name="address"
                        label={t("admin.client.form:label.address")}
                        placeholder={t("admin.client.form:placeholder.address")}
                        block
                        maxCount={validation.title.max}
                    />

                    <AppFormPhoneNumber
                        id="phone"
                        name="phone"
                        label={t("admin.client.form:label.phone")}
                        placeholder={t("admin.client.form:placeholder.phone")}
                        block
                    />

                    <AppFormSelect
                        id="country"
                        name="country"
                        label={t("admin.client.form:label.country")}
                        placeholder={t("admin.client.form:placeholder.country")}
                        block
                        options={getCountryOptions().map(
                            ({ label, value }) => ({
                                label: (
                                    <div className="d-flex gap-3 align-items-center">
                                        <AppFlag icon={value} />
                                        <span>{label}</span>
                                    </div>
                                ),
                                value,
                                search: label,
                            }),
                        )}
                    />

                    <AppFormInput
                        id="city"
                        name="city"
                        label={t("admin.client.form:label.city")}
                        placeholder={t("admin.client.form:placeholder.city")}
                        block
                    />

                    <AppFormInput
                        type="number"
                        id="postCode"
                        name="postCode"
                        label={t("admin.client.form:label.postCode")}
                        placeholder={t(
                            "admin.client.form:placeholder.postCode",
                        )}
                        block
                    />
                </AppFormContent>
            </AppForm>
        </FormProvider>
    );

    return (
        <>
            <AppFormModal
                show={drawer.show}
                icon="description"
                title="admin.client.form:modal.title"
                isDrawer
                onSubmit={handleSubmit(onSubmitHandler)}
                onHide={() => {
                    resetDrawer();
                    setEditId(0);
                }}
                isLoading={formState.isSubmitting || loading}
                disabled={isUploading}
                isEditMode={!!editId}
                disabledSumbit={!formState.isValid}
            >
                {renderForm()}
            </AppFormModal>

            <div className="subscription-company-info">
                <div className="content">
                    <span className="label">
                        {t("admin.client.form:label.name")}
                    </span>
                    <span className="value">{clientInfo.name || "-"}</span>
                </div>

                <div className="content">
                    <span className="label">
                        {t("admin.client.form:label.address")}
                    </span>
                    <span className="value">{clientInfo.address || "-"}</span>
                </div>

                <div className="content">
                    <span className="label">
                        {t("admin.client.form:label.region")}
                    </span>
                    <span className="value">{clientInfo.region || "-"}</span>
                </div>

                <div className="content">
                    <span className="label">
                        {t("admin.client.form:label.postCode")}
                    </span>
                    <span className="value">{clientInfo.postCode || "-"}</span>
                </div>

                <div className="content">
                    <span className="label">
                        {t("admin.client.form:label.city")}
                    </span>
                    <span className="value">{clientInfo.city || "-"}</span>
                </div>

                <div className="content">
                    <span className="label">
                        {t("admin.client.form:label.country")}
                    </span>
                    <span className="value">{clientInfo.country || "-"}</span>
                </div>

                <div className="content">
                    <span className="label">
                        {t("admin.client.form:label.phone")}
                    </span>
                    <span className="value">{clientInfo.phone || "-"}</span>
                </div>

                <AppButton
                    variant="secondary"
                    size="sm"
                    onClick={handleClientEdit}
                    className="edit-btn"
                >
                    {t(TKey.Common.Button.Edit)}
                </AppButton>
            </div>
        </>
    );
};
