import React, { FC, useEffect, useRef, useState } from "react";

import "./assets/scss/style.scss";

import { useTranslation } from "../../../hooks";
import { AppSVGIcon } from "../../AppSVGIcon";
import { TKey } from "../../../utils";

interface AppSearchBarProps {
    onSearchChange: (s: string) => void;
    inputClassName?: string;
}

export const AppSearchBar: FC<AppSearchBarProps> = ({
    onSearchChange,
    inputClassName = "",
}) => {
    // hooks
    const { t } = useTranslation();

    // ref
    const timeoutRef = useRef<NodeJS.Timeout | null>(null);

    // state
    const [search, setSearch] = useState("");

    useEffect(
        () => () => {
            if (timeoutRef.current) {
                clearTimeout(timeoutRef.current);
            }
        },
        [],
    );

    const handleOnChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const searchTerm = event.currentTarget.value;
        setSearch(searchTerm);

        if (timeoutRef.current) {
            clearTimeout(timeoutRef.current);
        }

        timeoutRef.current = setTimeout(() => {
            onSearchChange(searchTerm);
        }, 300);
    };

    return (
        <div className="app-search-bar">
            <AppSVGIcon icon="search" className="app-search-bar--icon" />

            <input
                type="search"
                placeholder={t(TKey.Common.Placeholder.Search)}
                onChange={handleOnChange}
                className={`form-control app-search-bar--input ${inputClassName}`}
                value={search}
            />
        </div>
    );
};
