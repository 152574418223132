import { FC, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useSetRecoilState } from "recoil";

import "./assets/scss/style.scss";

import { OnBoardClientAdmin } from "./OnBoardClientAdmin";
import { OnBoardClient } from "./OnBoardClient";
import { useAuthAction, useAuthState, useTranslation } from "../../../hooks";
import { OnboardClientAdminTabEnum, TKey } from "../../../utils";
import {
    AppButton,
    AppConfirmModal,
    AppLoader,
    AppProgressiveTabs,
} from "../../../components";

import { atomActiveClient } from "../../../atoms";
import { ROLES } from "../../../config";
import { OnBoardUser } from "./OnBoardUser";
import { SubscriptionProvider } from "../../../contexts";
import {
    SubscriptionPlanCards,
    SubscriptionReviewInfo,
} from "../../../Admin/components";
import { SubscriptionCompletePage } from "../../../Admin";

export const OnBoardPage: FC = () => {
    // hooks
    const { t } = useTranslation();
    const navigate = useNavigate();

    const { isOnboard, isAuthenticated, initialClient, userRole } =
        useAuthState();
    const { actionLogout, actionRefetchAuthUser } = useAuthAction();

    // atoms
    const setActiveClient = useSetRecoilState(atomActiveClient);

    // state
    const [activeTab, setActiveTab] = useState(OnboardClientAdminTabEnum.USER);
    const [showConfirm, setShowConfirm] = useState(false);

    // const [activeUserTab] = useState(OnboardUserTabEnum.USER);

    useEffect(() => {
        if (isOnboard) {
            if (isAuthenticated) {
                navigate("/", { replace: true });
            } else {
                navigate("/auth/login", { replace: true });
            }

            return;
        } else if (initialClient) {
            setActiveClient({
                id: initialClient.id,
                imageName: initialClient.imageName,
            });

            actionRefetchAuthUser();
        }
    }, []);

    if (isOnboard) {
        return <AppLoader isFullScreen />;
    }

    const renderClientAdminTabs = () => {
        switch (activeTab) {
            case OnboardClientAdminTabEnum.USER:
                return (
                    <OnBoardClientAdmin
                        onSubmit={() =>
                            setActiveTab(OnboardClientAdminTabEnum.CLIENT)
                        }
                    />
                );

            case OnboardClientAdminTabEnum.CLIENT:
                return (
                    <OnBoardClient
                        onBack={() =>
                            setActiveTab(OnboardClientAdminTabEnum.USER)
                        }
                        onSubmit={() =>
                            setActiveTab(OnboardClientAdminTabEnum.CHOOSE_PLAN)
                        }
                    />
                );

            case OnboardClientAdminTabEnum.CHOOSE_PLAN:
                return (
                    <div className="d-flex flex-column justify-content-between w-100">
                        <div className="card-content--form--plans p-3">
                            <SubscriptionPlanCards
                                onSubmit={() =>
                                    setActiveTab(
                                        OnboardClientAdminTabEnum.PAYMENT,
                                    )
                                }
                                isOnboarding
                            />
                        </div>

                        <div className="d-grid gap-3 justify-content-end d-md-flex justify-content-md-end">
                            <AppButton
                                onClick={() => setShowConfirm(true)}
                                iconLeft="close"
                                variant="secondary"
                            >
                                {t(TKey.Common.Button.Cancel)}
                            </AppButton>
                            <AppButton
                                onClick={() =>
                                    setActiveTab(
                                        OnboardClientAdminTabEnum.CLIENT,
                                    )
                                }
                                iconLeft="arrow-left"
                                variant="secondary"
                            >
                                {t("admin.subscription.checkout:button.back")}
                            </AppButton>
                            <AppButton
                                iconLeft="arrow-right"
                                variant="secondary"
                                disabled
                            >
                                {t(TKey.Common.Button.Next)}
                            </AppButton>
                        </div>
                    </div>
                );

            case OnboardClientAdminTabEnum.PAYMENT:
                return (
                    <div className="card-content--form--payment w-100 p-3">
                        <SubscriptionReviewInfo
                            onBack={() =>
                                setActiveTab(
                                    OnboardClientAdminTabEnum.CHOOSE_PLAN,
                                )
                            }
                            onSubmit={() =>
                                setActiveTab(OnboardClientAdminTabEnum.COMPLETE)
                            }
                            isOnboarding
                        />
                    </div>
                );

            case OnboardClientAdminTabEnum.COMPLETE:
                return (
                    <div className="card-content--form--payment w-100 p-3">
                        <SubscriptionCompletePage isOnboarding />
                    </div>
                );

            default:
                return <></>;
        }
    };

    const renderContent = () => {
        switch (userRole) {
            case ROLES.USER_ROLE_CLIENT_ADMIN:
                return (
                    <SubscriptionProvider>
                        <AppProgressiveTabs
                            items={OnboardClientAdminTabEnum}
                            activeTab={activeTab}
                        />

                        <div className="card-content--form mt-5">
                            {renderClientAdminTabs()}
                        </div>
                    </SubscriptionProvider>
                );

            case ROLES.USER_ROLE_USER:
                return (
                    <>
                        {/* <AppProgressiveTabs
                            items={OnboardUserTabEnum}
                            activeTab={activeUserTab}
                        /> */}

                        <div className="card-content--form mt-5">
                            <OnBoardUser />
                        </div>
                    </>
                );

            default:
                return <></>;
        }
    };

    return (
        <>
            <AppConfirmModal
                show={showConfirm}
                icon="warning"
                title="client.onboard.form:logout.confirm.modal.title"
                description="client.onboard.form:logout.confirm.modal.description"
                onHide={() => setShowConfirm(false)}
                nextAction={actionLogout}
            />
            <div className="onboard-page h-100">
                <div className="onboard-page--card h-100">
                    <div className="card-content">{renderContent()}</div>
                </div>
            </div>
        </>
    );
};
