import React, { FC, memo, useEffect, useState } from "react";

import "./assets/scss/style.scss";
import { useMapAction, useTranslation } from "../../../hooks";
import { AppLoader, AppSVGIcon, AppTooltip } from "../../../components";
import { ACTION_POST_DURATION, ACTION_POST_STATUS } from "../../../config";
import { errorToast, getPercentage, toPascalCase } from "../../../utils";
import { ActionPostApi } from "../../../apis";
import { ActionPost, StatisticsByTask } from "../../../models";
import { isBefore, parseISO } from "date-fns";

interface MapDashboardDocumentedProps extends StatisticsByTask {
    mapId: number;
}

export const MapDashboardDocumented: FC<MapDashboardDocumentedProps> = memo(
    ({
        mapId,
        totalPosts,
        totalShortPosts,
        totalMediumPosts,
        totalLongPosts,
    }) => {
        // hooks
        const { t } = useTranslation();

        // context
        const { map } = useMapAction();

        // state
        const [loading, setLoading] = useState(false);
        const [stats, setStats] = useState<{
            totalDocumented: number;
            totalShortDocumented: number;
            totalMediumDocumented: number;
            totalLongDocumented: number;
        }>({
            totalDocumented: 0,
            totalShortDocumented: 0,
            totalMediumDocumented: 0,
            totalLongDocumented: 0,
        });

        const getDurationType = (itemDate: Date, mapData: any) => {
            const [durationShort, durationMedium, durationLong] =
                mapData.actionPostDurations.map((d: any) => ({
                    ...d,
                    dueDate: parseISO(d.dueDate),
                }));

            if (isBefore(itemDate, durationShort.dueDate)) {
                return ACTION_POST_DURATION.ACTIONPOST_DURATION_SHORT.toLowerCase();
            } else if (isBefore(itemDate, durationMedium.dueDate)) {
                return ACTION_POST_DURATION.ACTIONPOST_DURATION_MEDIUM.toLowerCase();
            } else if (isBefore(itemDate, durationLong.dueDate)) {
                return ACTION_POST_DURATION.ACTIONPOST_DURATION_LONG.toLowerCase();
            } else {
                return ACTION_POST_DURATION.ACTIONPOST_DURATION_LONG.toLowerCase();
            }
        };

        const fetchStatistics = async () => {
            setLoading(true);

            ActionPostApi.find<ActionPost>(1, {
                "map.id": mapId,
                pagination: false,
                status: [
                    ACTION_POST_STATUS.ACTIONPOST_STATUS_BACKLOG,
                    ACTION_POST_STATUS.ACTIONPOST_STATUS_BOARD_1,
                    ACTION_POST_STATUS.ACTIONPOST_STATUS_INPROGRESS,
                    ACTION_POST_STATUS.ACTIONPOST_STATUS_BOARD_2,
                    ACTION_POST_STATUS.ACTIONPOST_STATUS_BOARD_3,
                    ACTION_POST_STATUS.ACTIONPOST_STATUS_BOARD_4,
                    ACTION_POST_STATUS.ACTIONPOST_STATUS_COMPLETED,
                ],
            })
                .then(({ response, errorMessage }) => {
                    if (errorMessage) {
                        errorToast(errorMessage);
                    } else if (response !== null) {
                        if (!map) {
                            return;
                        }

                        const { actionPostDurations = [] } = map;
                        const [durationShort, durationMedium, durationLong] =
                            actionPostDurations;

                        const formattedResponse = response.items.reduce(
                            (accumulator, item) => {
                                const { additionalInfo } = item;
                                const itemDate = parseISO(item.dueDate);
                                const durationType = getDurationType(
                                    itemDate,
                                    map,
                                );

                                const isDocumented =
                                    additionalInfo &&
                                    additionalInfo.goals &&
                                    additionalInfo.benefits &&
                                    additionalInfo.deliverables;

                                const isShortDocumented =
                                    !!isDocumented &&
                                    durationShort.name === durationType;

                                const isMediumDocumented =
                                    !!isDocumented &&
                                    durationMedium.name === durationType;

                                const isLongDocumented =
                                    !!isDocumented &&
                                    durationLong.name === durationType;

                                return {
                                    totalDocumented: isDocumented
                                        ? accumulator.totalDocumented + 1
                                        : accumulator.totalDocumented,
                                    totalShortDocumented: isShortDocumented
                                        ? accumulator.totalShortDocumented + 1
                                        : accumulator.totalShortDocumented,

                                    totalMediumDocumented: isMediumDocumented
                                        ? accumulator.totalMediumDocumented + 1
                                        : accumulator.totalMediumDocumented,

                                    totalLongDocumented: isLongDocumented
                                        ? accumulator.totalLongDocumented + 1
                                        : accumulator.totalLongDocumented,
                                };
                            },
                            {
                                totalDocumented: 0,
                                totalShortDocumented: 0,
                                totalMediumDocumented: 0,
                                totalLongDocumented: 0,
                            },
                        );

                        setStats(formattedResponse);
                    }
                })
                .finally(() => setLoading(false));
        };

        useEffect(() => {
            fetchStatistics();
        }, []);

        const renderPillSection = (
            type: string,
            count: number,
            total: number,
        ) => (
            <div className="item">
                <div className={`pill ${type.toLowerCase()}`}>
                    <span className="percentage">
                        {getPercentage(count, total, 1, false)}
                    </span>
                </div>
                <div className="title-section">
                    <span className="count">{count}</span>

                    <span>{toPascalCase(type)}</span>
                </div>
            </div>
        );

        return (
            <div className="documented-card">
                {loading && <AppLoader className="overlay-loader" />}

                <div className="documented-card--header mb-5">
                    <div className="d-flex">
                        <h3 className="title">
                            {t("app.map.dashboard:label.projectDocumented")}
                        </h3>
                        <AppTooltip
                            id={"Info-dashboard-documented"}
                            placement="top-start"
                            overlay={
                                <span className="paragraph--300">
                                    {t(
                                        "app.map.dashboard:tooltip.documented.title",
                                    )}
                                </span>
                            }
                        >
                            <AppSVGIcon
                                icon="help"
                                size="xs"
                                className="voting-help-icon"
                            />
                        </AppTooltip>
                    </div>

                    <div className="total-percentage">
                        <span className="percentage">
                            {getPercentage(
                                stats.totalDocumented,
                                +totalPosts,
                                0,
                                false,
                            )}
                        </span>
                    </div>
                </div>

                <div className="documented-card--info">
                    {renderPillSection(
                        ACTION_POST_DURATION.ACTIONPOST_DURATION_SHORT,
                        stats.totalShortDocumented,
                        +totalShortPosts,
                    )}

                    {renderPillSection(
                        ACTION_POST_DURATION.ACTIONPOST_DURATION_MEDIUM,
                        stats.totalMediumDocumented,
                        +totalMediumPosts,
                    )}

                    {renderPillSection(
                        ACTION_POST_DURATION.ACTIONPOST_DURATION_LONG,
                        stats.totalLongDocumented,
                        +totalLongPosts,
                    )}
                </div>
            </div>
        );
    },
);
