import React, { FC, PropsWithChildren } from "react";

import "./assets/scss/style.scss";

import { useTranslation } from "../../hooks";
import { AppButton } from "../AppButton";
import { AppSearchBar } from "../AppForm";
import { TKey } from "../../utils";

interface AppPageHeaderProps extends PropsWithChildren {
    title?: string;
    subTitle?: string;
    createLabel?: string;
    createButton?: boolean;
    onCreateBtnClick?: () => void;
    onSearchChange?: (s: string) => void;
    showToolbar?: boolean;
    isGrantedControl?: boolean;
    lock?: boolean;
    isSearchBar?: boolean;
}

export const AppPageHeader: FC<AppPageHeaderProps> = ({
    title,
    subTitle,
    createLabel = TKey.Common.Button.Create,
    createButton = true,
    onCreateBtnClick,
    onSearchChange = () => null,
    showToolbar,
    isGrantedControl = true,
    children,
    lock,
    isSearchBar = true,
}) => {
    // hooks
    const { t } = useTranslation();

    return (
        <div className="app-page-header gap-5">
            <div className="app-page-header--left gap-3">
                {title && (
                    <span className="headline--200 font-weight--semi-bold">
                        {t(title)}
                    </span>
                )}

                {subTitle && (
                    <span className="paragraph--200 font-weight--semi-bold">
                        {t(subTitle)}
                    </span>
                )}
            </div>

            <div className="app-page-header--center gap-3">
                <></>
            </div>

            {showToolbar && (
                <div className="app-page-header--right gap-3">
                    {isSearchBar && (
                        <AppSearchBar onSearchChange={onSearchChange} />
                    )}

                    {isGrantedControl && (
                        <>
                            {children}

                            <AppButton
                                iconLeft={lock ? "lock" : "plus"}
                                onClick={onCreateBtnClick}
                                disabled={lock}
                                hidden={!createButton}
                            >
                                {t(createLabel)}
                            </AppButton>
                        </>
                    )}
                </div>
            )}
        </div>
    );
};
