import { FC, PropsWithChildren } from "react";
import { Modal } from "react-bootstrap";

import "./assets/scss/style.scss";

const AppBaseModalBody: FC<PropsWithChildren> = ({ children }) => (
    <Modal.Body>{children}</Modal.Body>
);

export default AppBaseModalBody;
