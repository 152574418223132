import { FC } from "react";
import { useNavigate } from "react-router-dom";

import "./assets/scss/style.scss";

import { SubscriptionReviewInfo } from "../../components";

export const SubscriptionCheckoutPage: FC = () => {
    // hooks
    const navigate = useNavigate();

    return (
        <div className="subscription-checkout-page">
            <SubscriptionReviewInfo
                onBack={() => navigate(`/admin/subscription/pricing`)}
                onSubmit={() => navigate(`/admin/subscription/complete`)}
            />
        </div>
    );
};
