import { FC, PropsWithChildren } from "react";
import { Modal, ModalProps, Offcanvas, OffcanvasProps } from "react-bootstrap";

import "./assets/scss/style.scss";

export interface AppBaseModalProps
    extends ModalProps,
        Pick<OffcanvasProps, "scroll" | "placement">,
        PropsWithChildren {
    icon?: string;
    title?: string;
    closeButton?: boolean;
    isDrawer?: boolean;
}

const AppBaseModal: FC<AppBaseModalProps> = ({
    show,
    onHide,
    className = "",
    centered = true,
    scrollable = true,
    scroll = true,
    isDrawer,
    size,
    placement = "end",
    children,
}) => {
    if (isDrawer) {
        return (
            <Offcanvas
                show={show}
                onHide={onHide}
                className={`app-drawer app-drawer-with-shadow border-0 ${className}`}
                scroll={scroll}
                placement={placement}
                backdrop={false}
                keyboard={false}
            >
                {children}
            </Offcanvas>
        );
    }

    return (
        <Modal
            show={show}
            onHide={onHide}
            className={`app-base-modal ${className}`}
            centered={centered}
            scrollable={scrollable}
            size={size}
            backdrop="static"
            keyboard={false}
        >
            {children}
        </Modal>
    );
};

export default AppBaseModal;

// const AppBaseModal: FC<AppBaseModalProps> = ({

// const modalRef = useRef<HTMLDivElement>(null);
// const modalBackDropRef = useRef<HTMLDivElement>(null);

// const showHideProps = show
//     ? { "aria-modal": true, role: "dialog" }
//     : { "aria-hidden": true };

// useEffect(() => {
//     document.body.classList.toggle("modal-open", show);

//     if (show) {
//         modalRef.current?.classList.toggle("d-block", show);
//         modalBackDropRef.current?.classList.toggle("modal-backdrop", show);

//         setTimeout(() => {
//             modalRef.current?.classList.toggle("show", show);
//             modalBackDropRef.current?.classList.toggle("show", show);
//         }, 100);
//     } else {
//         modalRef.current?.classList.toggle("show", show);
//         modalBackDropRef.current?.classList.toggle("show", show);

//         setTimeout(() => {
//             modalRef.current?.classList.toggle("d-block", show);
//             modalBackDropRef.current?.classList.toggle(
//                 "modal-backdrop",
//                 show,
//             );
//         }, 100);
//     }
// }, [show]);

// <button
//     type="button"
//     className="btn btn-primary"
//     data-bs-toggle="modal"
//     data-bs-target="#staticBackdrop"
//     onClick={() => setShow(true)}
// >
//     Launch static backdrop modal
// </button>

//     return (
//         <>
//             {/* {createPortal(
//                 <div ref={modalBackDropRef} className="fade" />,
//                 document.body,
//             )} */}

//             <div
//                 // ref={modalRef}
//                 className="modal fade"
//                 // id="staticBackdrop"
//                 // data-bs-backdrop="static"
//                 // data-bs-keyboard="false"
//                 tabIndex={-1}
//                 // aria-labelledby="staticBackdropLabel"
//                 // {...showHideProps}
//             >
//                 <div className="modal-dialog modal-lg modal-dialog-centered modal-dialog-scrollable">
//                     <div className="modal-content">
//                         {isLoading && <AppLoader isFullScreen />}

//                         <div className="modal-header">
//                             <h1
//                                 // id="staticBackdropLabel"
//                                 className="modal-title fs-5"
//                             >
//                                 Modal title
//                             </h1>

//                             {/* {title && (
//                                 <span className="modal-title">{t(title)}</span>
//                             )} */}

//                             <button
//                                 className="btn-close"
//                                 // data-bs-dismiss="modal"
//                                 aria-label="Close"
//                                 onClick={onHide}
//                             />
//                         </div>

//                         <div className="modal-body">{children}</div>

//                         <div className="modal-footer">
//                             {/* <button
//                                 type="button"
//                                 className="btn btn-secondary"
//                                 // data-bs-dismiss="modal"
//                             >
//                                 Close
//                             </button> */}

//                             <AppFormActions
//                                 backAction={onHide}
//                                 isLoading={isLoading}
//                                 disabled={disabled}
//                             />
//                         </div>
//                     </div>
//                 </div>
//             </div>
//         </>
//     );
// };
