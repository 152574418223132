import { FC, memo, useMemo, useState } from "react";

import "./assets/scss/style.scss";

import { useTranslation } from "../../../hooks";
import { MapPieChart } from "../MapPieChart";
import {
    PDashboardStatistics,
    PrimitiveObject,
    StatisticsByActionTask,
} from "../../../models";
import { MapDurationTabs } from "../MapDurationTabs";
import { ActionPostApi, MapApi } from "../../../apis";
import { errorToast } from "../../../utils";
import { AppLoader } from "../../../components";

const themeColors = [
    "--map-dashboard-color-done",
    "--map-dashboard-color-short",
    "--map-dashboard-color-medium",
    "--map-dashboard-color-long",
    "--map-dashboard-color-theme-1",
    "--map-dashboard-color-theme-2",
    "--map-dashboard-color-theme-3",
    "--map-dashboard-color-theme-4",
    "--map-dashboard-color-theme-5",
    "--map-dashboard-color-theme-6",
];

interface MapDashboardThemeCardProps {
    mapId: number;
    data: StatisticsByActionTask[];
}

export const MapDashboardThemeCard: FC<MapDashboardThemeCardProps> = memo(
    ({ mapId, data }) => {
        // hooks
        const { t } = useTranslation();

        // state
        const [loading, setLoading] = useState(false);
        const [stats, setStats] = useState<StatisticsByActionTask[]>(data);
        const [selected, setSelected] = useState(-1);

        const fetchStatistics = async (duration: string) => {
            setLoading(true);

            ActionPostApi.getDashboardStatisticsByActionTask<
                PDashboardStatistics,
                PrimitiveObject
            >({
                map: MapApi.toResourceUrl(mapId),
                ...(duration ? { duration } : {}),
            })
                .then(({ response, errorMessage }) => {
                    if (errorMessage) {
                        errorToast(errorMessage);
                    } else if (
                        response !== null &&
                        response.byActionTask &&
                        response.byActionTask.length > 0
                    ) {
                        setStats(response.byActionTask);
                    }
                })
                .finally(() => setLoading(false));
        };

        const totalPosts = useMemo(
            () => stats.reduce((total, x) => total + +x.totalPosts, 0),
            [stats],
        );

        return (
            <div className="theme-card">
                {loading && <AppLoader className="overlay-loader" />}

                <div className="theme-card--header mb-5">
                    <h3 className="title">
                        {t("app.map.dashboard:label.projectByTheme")}
                    </h3>

                    <MapDurationTabs onChange={fetchStatistics} />
                </div>

                <div className="theme-card--info">
                    <div className="theme-card--info--chart">
                        <MapPieChart
                            total={totalPosts}
                            data={
                                stats.length === 0
                                    ? [
                                          ["Theme", "Total Tasks"],
                                          ["", 0],
                                      ]
                                    : [
                                          ["Theme", "Total Tasks"],
                                          ...stats.map((x) => [
                                              x.title,
                                              +x.totalPosts,
                                          ]),
                                      ]
                            }
                            options={{
                                colors: themeColors.map((x) =>
                                    getComputedStyle(
                                        document.documentElement,
                                    ).getPropertyValue(x),
                                ),
                            }}
                            onSelect={setSelected}
                        />
                    </div>

                    <div className="theme-card--info--details">
                        {stats.map((item, i) => (
                            <div className="detail-section" key={item.id}>
                                <div className="detail-section--header">
                                    <span
                                        className={`color ${
                                            selected === i ? "selected" : ""
                                        }`}
                                        style={{
                                            backgroundColor: getComputedStyle(
                                                document.documentElement,
                                            ).getPropertyValue(themeColors[i]),
                                            outlineColor: getComputedStyle(
                                                document.documentElement,
                                            ).getPropertyValue(themeColors[i]),
                                        }}
                                    />
                                    <span className="title">{item.title}</span>
                                </div>

                                <span
                                    className="detail-section--info"
                                    style={{
                                        color: getComputedStyle(
                                            document.documentElement,
                                        ).getPropertyValue(themeColors[i]),
                                    }}
                                >
                                    {item.totalPosts}
                                </span>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        );
    },
);
