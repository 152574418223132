import { FC, useEffect, useState } from "react";
import { yupResolver } from "@hookform/resolvers/yup";
import { FormProvider, useForm } from "react-hook-form";

import "./assets/scss/style.scss";

import {
    CropperViewEnum,
    TKey,
    errorToast,
    setBackendViolations,
    validation,
} from "../../../utils";
import {
    useAuthAction,
    useAuthState,
    useSubscriptionAction,
    useTranslation,
} from "../../../hooks";
import { ClientApi, UserApi } from "../../../apis";
import { Client, PUser, User } from "../../../models";
import {
    AppConfirmModal,
    AppFlag,
    AppForm,
    AppFormActions,
    AppFormCheckBox,
    AppFormContent,
    AppFormInput,
    AppFormPhoneNumber,
    AppFormSelect,
    AppFormUploader,
    AppLoader,
} from "../../../components";
import { ClientLogoFileInfo, getCountryOptions } from "../../../config";
import { schemaClient } from "./schema";
import { Col, Row } from "react-bootstrap";

interface OnBoardForm {
    // company info
    name: string;
    address: string;
    region?: string | null;
    postCode: string;
    city: string;
    country: string;
    phone?: string | null;
    imageName?: string;

    // billing info
    contactBill?: string | null;
    vatNo?: string | null;
    isSameAsCompany?: boolean;
    addressBill?: string | null;
    regionBill?: string | null;
    postCodeBill?: string | null;
    cityBill?: string | null;
    countryBill?: string | null;
}

interface OnBoardClientProps {
    onSubmit: () => void;
    onBack: () => void;
}

export const OnBoardClient: FC<OnBoardClientProps> = ({ onSubmit, onBack }) => {
    // hooks
    const { t } = useTranslation();
    const { userId } = useAuthState();
    const { actionLogout, actionRefetchAuthUser, actionOnBoardCompleted } =
        useAuthAction();

    // context
    const { isLoading, myClient, currentInvoice } = useSubscriptionAction();

    // state
    const [loading, setLoading] = useState(false);
    const [isUploading, setIsUploading] = useState(false);
    const [showConfirm, setShowConfirm] = useState(false);

    // form
    const methods = useForm<OnBoardForm>({
        resolver: yupResolver(schemaClient(t)),
    });
    const { handleSubmit, formState, reset, setError, watch } = methods;

    const isSameAsCompanyValue = watch("isSameAsCompany");

    useEffect(() => {
        if (myClient) {
            reset({
                // company info
                name: myClient.name,
                address: myClient.address,
                region: myClient.region,
                postCode: myClient.postCode,
                city: myClient.city,
                country: myClient.country || "CH",
                phone: myClient.phone,
                imageName: myClient.imageName,

                // billing info
                vatNo: myClient.vatNo,
                isSameAsCompany: myClient.isSameAsCompany || true,
                addressBill: myClient.addressBill,
                regionBill: myClient.regionBill,
                postCodeBill: myClient.postCodeBill,
                cityBill: myClient.cityBill,
                countryBill: myClient.countryBill,
            });
        }
    }, []);

    useEffect(() => {
        if (!isSameAsCompanyValue) {
            const billingForm = document.querySelector(".billing-form");
            billingForm?.scrollIntoView({
                behavior: "smooth",
                block: "center",
                inline: "center",
            });
        }
    }, [isSameAsCompanyValue]);

    const onSubmitHandler = (formData: OnBoardForm) => {
        const clientId = myClient?.id;

        if (!clientId) {
            return null;
        }

        setLoading(true);

        formData.contactBill = UserApi.toResourceUrl(userId);

        if (formData.isSameAsCompany) {
            formData.addressBill = formData.address;
            formData.regionBill = formData.region;
            formData.postCodeBill = formData.postCode;
            formData.cityBill = formData.city;
            formData.countryBill = formData.country;
        }

        ClientApi.updateProfile<Client, OnBoardForm>(clientId, formData)
            .then(({ response, errorMessage, isInvalid, error }) => {
                if (isInvalid) {
                    setBackendViolations(error, setError);
                } else if (errorMessage) {
                    errorToast(errorMessage);
                } else if (response !== null) {
                    reset();

                    UserApi.updateProfile<User, PUser>(userId, {
                        company: formData.name,
                        isOnboarded: !!currentInvoice,
                    }).then(({ response: res, errorMessage: errMsg }) => {
                        if (errMsg) {
                            errorToast(errMsg);
                        } else if (res !== null) {
                            actionRefetchAuthUser().then(() => {
                                // if client-admin created by SA & invoice already exists then skip onboarding for payment
                                if (currentInvoice) {
                                    actionOnBoardCompleted();
                                }

                                onSubmit();
                            });
                        }
                    });
                }
            })
            .finally(() => setLoading(false));
    };

    if (!myClient) {
        return <></>;
    }

    return (
        <>
            <AppConfirmModal
                show={showConfirm}
                icon="warning"
                title="client.onboard.form:logout.confirm.modal.title"
                description="client.onboard.form:logout.confirm.modal.description"
                onHide={() => setShowConfirm(false)}
                nextAction={actionLogout}
            />

            <FormProvider {...methods}>
                <AppForm
                    onSubmit={handleSubmit(onSubmitHandler)}
                    className="w-100"
                >
                    {(loading || isLoading) && <AppLoader isFullScreen />}

                    <AppFormContent className="card-content--form--fields onboarding-page--client">
                        <span className="headline--200 font-weight--bold">
                            {t("admin.subscription.overview:label.companyInfo")}
                        </span>

                        <AppFormInput
                            id="name"
                            name="name"
                            label={t("client.onboard.form:label.name")}
                            placeholder={t(
                                "client.onboard.form:placeholder.name",
                            )}
                            block={4}
                            maxCount={validation.title.max}
                        />

                        <AppFormInput
                            id="address"
                            name="address"
                            label={t("client.onboard.form:label.address")}
                            placeholder={t(
                                "client.onboard.form:placeholder.address",
                            )}
                            block={4}
                            maxCount={validation.title.max}
                        />

                        <AppFormInput
                            id="region"
                            name="region"
                            label={t("client.onboard.form:label.region")}
                            placeholder={t(
                                "client.onboard.form:placeholder.region",
                            )}
                            block={4}
                            maxCount={validation.title.max}
                            required={false}
                        />

                        <AppFormInput
                            type="number"
                            id="postCode"
                            name="postCode"
                            label={t("client.onboard.form:label.postCode")}
                            placeholder={t(
                                "client.onboard.form:placeholder.postCode",
                            )}
                            block={4}
                        />

                        <AppFormInput
                            id="city"
                            name="city"
                            label={t("client.onboard.form:label.city")}
                            placeholder={t(
                                "client.onboard.form:placeholder.city",
                            )}
                            block={4}
                            maxCount={validation.title.max}
                        />

                        <AppFormSelect
                            id="country"
                            name="country"
                            label={t("client.onboard.form:label.country")}
                            placeholder={t(
                                "client.onboard.form:placeholder.country",
                            )}
                            block={4}
                            options={getCountryOptions().map(
                                ({ label, value }) => ({
                                    label: (
                                        <div className="d-flex gap-3 align-items-center">
                                            <AppFlag icon={value} />
                                            <span>{label}</span>
                                        </div>
                                    ),
                                    value,
                                    search: label,
                                }),
                            )}
                            defaultValue="CHF"
                        />

                        <AppFormPhoneNumber
                            id="phone"
                            name="phone"
                            label={t("client.onboard.form:label.phone")}
                            placeholder={t(
                                "client.onboard.form:placeholder.phone",
                            )}
                            block={4}
                            required
                        />

                        <AppFormUploader
                            id="imageName"
                            name="imageName"
                            label={t("client.onboard.form:label.imageName")}
                            required={false}
                            block={4}
                            fileInfo={ClientLogoFileInfo}
                            cbFileUploading={setIsUploading}
                            withCropper={CropperViewEnum.Modal}
                            hasDownload={false}
                        />

                        <Col
                            xs="4"
                            sm="4"
                            md="4"
                            className=" d-flex gap-4 flex-column"
                        >
                            <span className="headline--200 font-weight--bold">
                                {t(
                                    "admin.subscription.overview:label.billingInfo",
                                )}
                            </span>

                            <AppFormInput
                                id="vatNo"
                                name="vatNo"
                                label={t("admin.client.form:label.vatNo")}
                                placeholder={t(
                                    "admin.client.form:placeholder.vatNo",
                                )}
                                required={false}
                                maxCount={validation.title.max}
                            />
                            <AppFormCheckBox
                                className="d-flex align-items-end"
                                id="isSameAsCompany"
                                name="isSameAsCompany"
                                inputOption={
                                    <span className="paragraph--200">
                                        {t(
                                            "admin.client.form:label.sameAsCompany",
                                        )}
                                    </span>
                                }
                                defaultChecked={true}
                            />
                        </Col>

                        <Row
                            className="mt-5 billing-form"
                            hidden={isSameAsCompanyValue}
                        >
                            <AppFormInput
                                id="addressBill"
                                name="addressBill"
                                label={t(
                                    "admin.client.form:label.billing.address",
                                )}
                                placeholder={t(
                                    "admin.client.form:placeholder.billing.address",
                                )}
                                required={false}
                                disabled={isSameAsCompanyValue}
                                block={4}
                                maxCount={validation.title.max}
                            />

                            <AppFormSelect
                                id="countryBill"
                                name="countryBill"
                                label={t(
                                    "admin.client.form:label.billing.country",
                                )}
                                placeholder={t(
                                    "admin.client.form:placeholder.billing.country",
                                )}
                                required={false}
                                disabled={isSameAsCompanyValue}
                                block={4}
                                options={getCountryOptions().map(
                                    ({ label, value }) => ({
                                        label: (
                                            <div className="d-flex gap-3 align-items-center">
                                                <AppFlag icon={value} />
                                                <span>{label}</span>
                                            </div>
                                        ),
                                        value,
                                        search: label,
                                    }),
                                )}
                            />

                            <AppFormInput
                                id="regionBill"
                                name="regionBill"
                                label={t(
                                    "admin.client.form:label.billing.region",
                                )}
                                placeholder={t(
                                    "admin.client.form:placeholder.billing.region",
                                )}
                                required={false}
                                disabled={isSameAsCompanyValue}
                                block={4}
                                maxCount={validation.title.max}
                            />

                            <AppFormInput
                                type="number"
                                id="postCodeBill"
                                name="postCodeBill"
                                label={t(
                                    "admin.client.form:label.billing.postCode",
                                )}
                                placeholder={t(
                                    "admin.client.form:placeholder.billing.postCode",
                                )}
                                required={false}
                                disabled={isSameAsCompanyValue}
                                block={4}
                            />

                            <AppFormInput
                                id="cityBill"
                                name="cityBill"
                                label={t(
                                    "admin.client.form:label.billing.city",
                                )}
                                placeholder={t(
                                    "admin.client.form:placeholder.billing.city",
                                )}
                                required={false}
                                disabled={isSameAsCompanyValue}
                                block={4}
                                maxCount={validation.title.max}
                            />
                        </Row>
                    </AppFormContent>

                    <AppFormActions
                        backAction={() => setShowConfirm(true)}
                        isLoading={
                            formState.isSubmitting || loading || isLoading
                        }
                        disabled={isUploading}
                        secondaryIconRight="close"
                        primaryIconRight="arrow-right"
                        primaryVariant="secondary"
                        createLabel={TKey.Common.Button.Next}
                        className="mt-3"
                        onBack={onBack}
                        backButton
                    />
                </AppForm>
            </FormProvider>
        </>
    );
};
