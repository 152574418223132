import { FC, useEffect, useRef, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useRecoilState, useResetRecoilState } from "recoil";
import { RowClickedEvent } from "ag-grid-community";

import "./assets/scss/style.scss";

import { useDateTime, useRoles, useTranslation } from "../../../hooks";
import { Client, OptionType, PClient, PUser, User } from "../../../models";
import {
    AppConfirmModal,
    AppFlag,
    AppForm,
    AppFormCheckBox,
    AppFormContent,
    AppFormDatePicker,
    AppFormInput,
    AppFormModal,
    AppFormPhoneNumber,
    AppFormSelect,
    AppFormUploader,
    AppGrid,
    AppLoader,
    AppPageHeader,
    AppTabs,
} from "../../../components";
import { ClientApi, UserApi } from "../../../apis";
import {
    ClientTabEnum,
    CropperViewEnum,
    ModalTypeEnum,
    errorToast,
    getUserFullName,
    setBackendViolations,
    setCustomViolation,
    successToast,
    validation,
} from "../../../utils";
import {
    ClientLogoFileInfo,
    PLAN_ENTERPRISE,
    ROLES,
    UserPosterFileInfo,
    cancelAllPrevRequest,
    getCountryOptions,
} from "../../../config";
import { schema } from "./schema";
import { appGridColDef } from "./app-grid";
import { Canceler } from "axios";
import { atomActiveClient, atomDrawer, atomDrawerType } from "../../../atoms";

export const ClientPage: FC = () => {
    // hooks
    const { t } = useTranslation();
    const { isSuperAdmin } = useRoles();
    const { toShortDate, toDbDateFormat } = useDateTime();

    // atoms
    const [activeClient, setActiveClient] = useRecoilState(atomActiveClient);
    const [drawer, setDrawer] = useRecoilState(
        atomDrawerType(ModalTypeEnum.CLIENT_ADD_EDIT),
    );
    const resetDrawer = useResetRecoilState(atomDrawer);

    // ref
    const cancelTokenSourcesRef = useRef<Canceler[]>([]);

    // state
    const [editId, setEditId] = useState(0);
    const [loading, setLoading] = useState(false);
    const [isUploading, setIsUploading] = useState(false);
    const [data, setData] = useState<Client[]>([]);
    const [showConfirm, setShowConfirm] = useState(0);
    const [emailExist, setEmailExist] = useState(false);
    const [activeTab, setActiveTab] = useState(ClientTabEnum.GENERAL);
    const [billingContactOptions, setBillingContactOptions] = useState<
        OptionType[]
    >([]);
    const [currentPlan, setCurrentPlan] = useState<string>();

    const getBillingContacts = () => {
        setLoading(true);

        UserApi.getForClient<User>(1, {
            pagination: false,
        })
            .then(({ response, errorMessage }) => {
                if (errorMessage) {
                    errorToast(errorMessage);
                } else if (response !== null) {
                    setBillingContactOptions(
                        response.items.map((item) => ({
                            label: getUserFullName(item),
                            value: UserApi.toResourceUrl(item.id),
                        })),
                    );
                }
            })
            .finally(() => setLoading(false));
    };

    useEffect(() => {
        getBillingContacts();
    }, []);

    // form
    const methods = useForm({
        resolver: yupResolver(schema(t, editId)),
    });
    const { handleSubmit, formState, reset, setError, trigger, watch } =
        methods;

    // local state
    const isPasswordStrong = watch("isPasswordStrong");
    const isSameAsCompanyValue = watch("isSameAsCompany");

    const fetchClients = (search = "") => {
        if (!activeClient) {
            return;
        }

        cancelAllPrevRequest(cancelTokenSourcesRef.current);
        setLoading(true);

        ClientApi.find<Client>(
            1,
            { pagination: false, ...(search ? { client_search: search } : {}) },
            (c) => {
                cancelTokenSourcesRef.current.push(c);
            },
        )
            .then(({ response, errorMessage }) => {
                if (errorMessage) {
                    errorToast(errorMessage);
                } else if (response !== null) {
                    setData(response.items);
                }
            })
            .finally(() => setLoading(false));
    };

    useEffect(() => {
        fetchClients();
    }, []);

    const onSubmitHandler = (
        formData: PClient &
            PUser & { plainPassword?: string; userImageName?: string },
    ) => {
        setLoading(true);

        if (formData.isSameAsCompany) {
            formData.addressBill = formData.address;
            formData.regionBill = formData.region;
            formData.postCodeBill = formData.postCode;
            formData.cityBill = formData.city;
            formData.countryBill = formData.country;
        }

        if (formData.planEndDate) {
            formData.planEndDate = toDbDateFormat(formData.planEndDate);
        }

        const {
            name,
            region,
            address,
            postCode,
            city,
            country,
            phone,
            imageName,
            email,
            firstName,
            lastName,
            plainPassword,
            userImageName,
            planEndDate,
            contactBill,
            vatNo,
            isSameAsCompany,
            addressBill,
            regionBill,
            postCodeBill,
            cityBill,
            countryBill,
        } = formData;

        const clientFormData = {
            name,
            region,
            address,
            postCode,
            city,
            country,
            phone,
            imageName,
            planEndDate,
            contactBill,
            vatNo,
            isSameAsCompany,
            addressBill,
            regionBill,
            postCodeBill,
            cityBill,
            countryBill,
            isActive: true,
        };

        ClientApi.createOrUpdate<Client, PClient>(editId, clientFormData)
            .then(({ response, errorMessage, isInvalid, error }) => {
                if (isInvalid) {
                    setBackendViolations(error, setError);
                } else if (errorMessage) {
                    errorToast(errorMessage);
                } else if (response !== null) {
                    if (editId) {
                        successToast(
                            t("admin.client.list:update.toast.success"),
                        );

                        if (activeClient && activeClient.id === editId) {
                            setActiveClient({
                                id: response.id,
                                imageName: response.imageName,
                            });
                        }
                    } else {
                        successToast(
                            t("admin.client.list:create.toast.success"),
                        );

                        const userFormData = {
                            email,
                            firstName,
                            lastName,
                            plainPassword,
                            imageName: userImageName,
                            role: ROLES.USER_ROLE_CLIENT_ADMIN,
                            company: name,
                            firstClient: ClientApi.toResourceUrl(response.id),
                            isOnboarded: true,
                            locale: "en",
                        };

                        UserApi.create<User, PUser>(userFormData);
                    }

                    reset();
                    resetDrawer();
                    setEditId(0);
                    fetchClients();
                }
            })
            .finally(() => setLoading(false));
    };

    const handleEdit = (id: number) => {
        ClientApi.findById<Client>(id).then(({ errorMessage, response }) => {
            if (errorMessage) {
                errorToast(errorMessage);
            } else if (response) {
                setCurrentPlan(response.currentPlan);

                reset({
                    name: response.name,
                    region: response.region,
                    address: response.address,
                    postCode: response.postCode,
                    city: response.city,
                    country: response.country,
                    phone: response.phone,
                    imageName: response.imageName,
                    isPasswordStrong: true,

                    planEndDate:
                        response.planEndDate || toDbDateFormat(new Date()),

                    // billing info
                    contactBill: response.contactBill
                        ? UserApi.toResourceUrl(
                              (response.contactBill as User).id,
                          )
                        : null,
                    vatNo: response.vatNo,
                    isSameAsCompany: response.isSameAsCompany,
                    addressBill: response.addressBill,
                    regionBill: response.regionBill,
                    postCodeBill: response.postCodeBill,
                    cityBill: response.cityBill,
                    countryBill: response.countryBill,
                });

                setEditId(id);
                setDrawer({ show: true, id: response.id });
            }
        });
    };

    const handleRowDoubleClick = (event: RowClickedEvent) => {
        handleEdit(event.data.id);
    };

    const handleDelete = (id: number) => {
        ClientApi.deleteById(id).then(({ errorMessage }) => {
            if (errorMessage) {
                errorToast(errorMessage);
            } else {
                successToast(t("admin.client.list:delete.toast.success"));
                setShowConfirm(0);
                fetchClients();
            }
        });
    };

    const triggerEmailExistError = () => {
        setCustomViolation(
            "email",
            t("user.register.form:emailExist.toast.error"),
            setError,
            false,
        );
    };

    const handleEmailExist = async (email: string) => {
        await trigger("email");

        if (email && !formState.errors.email?.message) {
            setEmailExist(false);

            UserApi.exist<User>({
                email,
            }).then(({ response, errorMessage }) => {
                if (errorMessage) {
                    errorToast(errorMessage);
                } else if (response !== null && response.items.length > 0) {
                    setEmailExist(true);
                    triggerEmailExistError();
                }
            });
        }
    };

    const handleChangePlan = (id: number, planToChange: string) => {
        ClientApi.changePlan(id, {
            currentPlan: planToChange,
        }).then(({ response, errorMessage }) => {
            if (errorMessage) {
                errorToast(errorMessage);
            } else if (response !== null) {
                successToast(t("admin.client.list:changePlan.toast.success"));

                fetchClients();
            }
        });
    };

    const handleAutoRenew = (id: number, isAutoRenew: boolean) => {
        ClientApi.autoRenewSubscription(id, {
            isAutoRenew: !isAutoRenew,
        }).then(({ response, errorMessage }) => {
            if (errorMessage) {
                errorToast(errorMessage);
            } else if (response !== null) {
                successToast(
                    isAutoRenew
                        ? t(
                              "admin.subscription.overview:autoRenew.cancel.toast.success",
                          )
                        : t(
                              "admin.subscription.overview:autoRenew.activate.toast.success",
                          ),
                );

                fetchClients();
            }
        });
    };

    useEffect(() => {
        setActiveTab(ClientTabEnum.GENERAL);
        trigger();
    }, [drawer.show]);

    useEffect(() => {
        const subscription = watch((_, { name }) => {
            if (name && formState.dirtyFields) {
                trigger(name);
            }
        });
        return () => subscription.unsubscribe();
    }, [watch, trigger, formState.dirtyFields]);

    const renderForm = () => (
        <FormProvider {...methods}>
            <AppForm className="client-page--form">
                {loading && <AppLoader isFullScreen />}

                <AppTabs
                    className="mb-4"
                    items={[
                        {
                            title: "admin.client.form:tab.general",
                            icon: "help",
                            url: ClientTabEnum.GENERAL,
                        },
                        {
                            title: "admin.client.form:tab.billing",
                            icon: "description",
                            url: ClientTabEnum.Billing,
                        },
                    ]}
                    onClick={(item) => {
                        if (item.url) {
                            setActiveTab(item.url as ClientTabEnum);
                        }
                    }}
                    isActiveTab={(item) => item?.url === activeTab}
                />

                <AppFormContent>
                    <div hidden={activeTab !== ClientTabEnum.GENERAL}>
                        <div className="d-flex flex-column gap-4">
                            <AppFormUploader
                                id="imageName"
                                name="imageName"
                                label={t("admin.client.form:label.imageName")}
                                block
                                fileInfo={ClientLogoFileInfo}
                                cbFileUploading={setIsUploading}
                                withCropper={CropperViewEnum.Modal}
                            />

                            <AppFormInput
                                id="name"
                                name="name"
                                label={t("admin.client.form:label.name")}
                                placeholder={t(
                                    "admin.client.form:placeholder.name",
                                )}
                                block
                                maxCount={validation.title.max}
                            />

                            <AppFormInput
                                id="region"
                                name="region"
                                label={t("admin.client.form:label.region")}
                                placeholder={t(
                                    "admin.client.form:placeholder.region",
                                )}
                                block
                                maxCount={validation.title.max}
                                required={false}
                            />

                            <AppFormInput
                                id="address"
                                name="address"
                                label={t("admin.client.form:label.address")}
                                placeholder={t(
                                    "admin.client.form:placeholder.address",
                                )}
                                block
                                maxCount={validation.title.max}
                            />

                            <AppFormPhoneNumber
                                id="phone"
                                name="phone"
                                label={t("admin.client.form:label.phone")}
                                placeholder={t(
                                    "admin.client.form:placeholder.phone",
                                )}
                                block
                            />

                            <AppFormSelect
                                id="country"
                                name="country"
                                label={t("admin.client.form:label.country")}
                                placeholder={t(
                                    "admin.client.form:placeholder.country",
                                )}
                                block
                                options={getCountryOptions().map(
                                    ({ label, value }) => ({
                                        label: (
                                            <div className="d-flex gap-3 align-items-center">
                                                <AppFlag icon={value} />
                                                <span>{label}</span>
                                            </div>
                                        ),
                                        value,
                                        search: label,
                                    }),
                                )}
                            />

                            <AppFormInput
                                id="city"
                                name="city"
                                label={t("admin.client.form:label.city")}
                                placeholder={t(
                                    "admin.client.form:placeholder.city",
                                )}
                                block
                            />

                            <AppFormInput
                                type="number"
                                id="postCode"
                                name="postCode"
                                label={t("admin.client.form:label.postCode")}
                                placeholder={t(
                                    "admin.client.form:placeholder.postCode",
                                )}
                                block
                            />

                            {/* START: for client user creation along with client create */}
                            {!editId && (
                                <>
                                    <div className="user-title-container">
                                        <span className="separator" />

                                        <span className="title pb-2">
                                            {t("admin.user.form:modal.title")}
                                        </span>
                                    </div>

                                    <AppFormInput
                                        type="email"
                                        id="email"
                                        name="email"
                                        label={t(
                                            "user.register.form:label.email",
                                        )}
                                        placeholder={t(
                                            "user.register.form:placeholder.email",
                                        )}
                                        autoComplete="email"
                                        block
                                        disabled={!!editId}
                                        onBlurCapture={(e) =>
                                            handleEmailExist(e.target.value)
                                        }
                                    />

                                    <AppFormUploader
                                        id="userImageName"
                                        name="userImageName"
                                        label={t(
                                            "admin.user.form:label.imageName",
                                        )}
                                        required={false}
                                        block
                                        fileInfo={UserPosterFileInfo}
                                        cbFileUploading={setIsUploading}
                                        withCropper={CropperViewEnum.Default}
                                    />

                                    <AppFormInput
                                        id="firstName"
                                        name="firstName"
                                        label={t(
                                            "admin.user.form:label.firstName",
                                        )}
                                        placeholder={t(
                                            "admin.user.form:placeholder.firstName",
                                        )}
                                        block
                                        maxCount={validation.title.max}
                                    />

                                    <AppFormInput
                                        id="lastName"
                                        name="lastName"
                                        label={t(
                                            "admin.user.form:label.lastName",
                                        )}
                                        placeholder={t(
                                            "admin.user.form:placeholder.lastName",
                                        )}
                                        block
                                        maxCount={validation.title.max}
                                    />

                                    <AppFormInput
                                        type="password"
                                        id="plainPassword"
                                        name="plainPassword"
                                        label={t(
                                            "user.register.form:label.password",
                                        )}
                                        placeholder={t(
                                            "user.register.form:placeholder.password",
                                        )}
                                        showPasswordStrength
                                        autoComplete="new-password"
                                        block
                                    />

                                    <AppFormInput
                                        type="password"
                                        id="confirmPassword"
                                        name="confirmPassword"
                                        label={t(
                                            "user.register.form:label.confirmPassword",
                                        )}
                                        placeholder={t(
                                            "user.register.form:placeholder.confirmPassword",
                                        )}
                                        autoComplete="new-password"
                                        block
                                        onCopy={(e) => e.preventDefault()}
                                        onPaste={(e) => e.preventDefault()}
                                    />
                                </>
                            )}
                            {/* END: for client user creation along with client create */}

                            {/* START: add end date for Enterprice plan */}
                            {!!editId && (
                                <AppFormDatePicker
                                    disabled={
                                        currentPlan !== PLAN_ENTERPRISE.name
                                    }
                                    className="subscription-billing-page__date-picker"
                                    id="planEndDate"
                                    name="planEndDate"
                                    label={t(
                                        "admin.client.form:label.planEndDate",
                                    )}
                                    placeholder={t(
                                        "admin.client.form:placeholder.planEndDate",
                                    )}
                                    required={false}
                                    block
                                />
                            )}
                            {/* END: add end date for Enterprice plan */}
                        </div>
                    </div>

                    <div hidden={activeTab !== ClientTabEnum.Billing}>
                        <div className="d-flex flex-column gap-4">
                            <AppFormSelect
                                id="contactBill"
                                name="contactBill"
                                label={t(
                                    "admin.client.form:label.billing.contact",
                                )}
                                placeholder={t(
                                    "admin.client.form:placeholder.billing.contact",
                                )}
                                required={false}
                                block
                                options={billingContactOptions}
                                isClearable
                            />

                            <AppFormInput
                                id="vatNo"
                                name="vatNo"
                                label={t("admin.client.form:label.vatNo")}
                                placeholder={t(
                                    "admin.client.form:placeholder.vatNo",
                                )}
                                required={false}
                                block
                                maxCount={validation.title.max}
                            />

                            <AppFormCheckBox
                                className="d-flex align-items-end"
                                id="isSameAsCompany"
                                name="isSameAsCompany"
                                inputOption={
                                    <span className="paragraph--200">
                                        {t(
                                            "admin.client.form:label.sameAsCompany",
                                        )}
                                    </span>
                                }
                                block
                                inputClassName="mt-0"
                            />

                            <AppFormSelect
                                id="countryBill"
                                name="countryBill"
                                label={t(
                                    "admin.client.form:label.billing.country",
                                )}
                                placeholder={t(
                                    "admin.client.form:placeholder.billing.country",
                                )}
                                required={false}
                                disabled={isSameAsCompanyValue}
                                block
                                options={getCountryOptions().map(
                                    ({ label, value }) => ({
                                        label: (
                                            <div className="d-flex gap-3 align-items-center">
                                                <AppFlag icon={value} />
                                                <span>{label}</span>
                                            </div>
                                        ),
                                        value,
                                        search: label,
                                    }),
                                )}
                            />
                            <AppFormInput
                                id="addressBill"
                                name="addressBill"
                                label={t(
                                    "admin.client.form:label.billing.address",
                                )}
                                placeholder={t(
                                    "admin.client.form:placeholder.billing.address",
                                )}
                                required={false}
                                disabled={isSameAsCompanyValue}
                                block
                                maxCount={validation.title.max}
                            />
                            <AppFormInput
                                id="regionBill"
                                name="regionBill"
                                label={t(
                                    "admin.client.form:label.billing.region",
                                )}
                                placeholder={t(
                                    "admin.client.form:placeholder.billing.region",
                                )}
                                required={false}
                                disabled={isSameAsCompanyValue}
                                block
                                maxCount={validation.title.max}
                            />
                            <AppFormInput
                                id="cityBill"
                                name="cityBill"
                                label={t(
                                    "admin.client.form:label.billing.city",
                                )}
                                placeholder={t(
                                    "admin.client.form:placeholder.billing.city",
                                )}
                                required={false}
                                disabled={isSameAsCompanyValue}
                                block
                                maxCount={validation.title.max}
                            />
                            <AppFormInput
                                type="number"
                                id="postCodeBill"
                                name="postCodeBill"
                                label={t(
                                    "admin.client.form:label.billing.postCode",
                                )}
                                placeholder={t(
                                    "admin.client.form:placeholder.billing.postCode",
                                )}
                                required={false}
                                disabled={isSameAsCompanyValue}
                                block
                            />
                        </div>
                    </div>
                </AppFormContent>
            </AppForm>
        </FormProvider>
    );

    return (
        <div className="client-page">
            {loading && <AppLoader isFullScreen />}

            <AppPageHeader
                title="admin.client.list:header.title"
                onCreateBtnClick={() => {
                    reset({
                        name: undefined,
                        region: undefined,
                        address: undefined,
                        postCode: undefined,
                        city: undefined,
                        country: undefined,
                        phone: undefined,
                        imageName: undefined,

                        // billing info
                        contactBill: undefined,
                        vatNo: undefined,
                        isSameAsCompany: true,
                        addressBill: undefined,
                        regionBill: undefined,
                        postCodeBill: undefined,
                        cityBill: undefined,
                        countryBill: undefined,
                    });
                    setDrawer({ show: true, id: 0 });
                }}
                onSearchChange={fetchClients}
                showToolbar
                isGrantedControl={isSuperAdmin && !!activeClient}
            />

            <AppFormModal
                show={drawer.show}
                icon="description"
                title="admin.client.form:modal.title"
                isDrawer
                onSubmit={handleSubmit(onSubmitHandler)}
                onHide={() => {
                    resetDrawer();
                    setEditId(0);
                }}
                isLoading={formState.isSubmitting || loading}
                disabled={isUploading}
                disabledSumbit={
                    !isPasswordStrong || emailExist || !formState.isValid
                }
                isEditMode={!!editId}
            >
                {renderForm()}
            </AppFormModal>

            <AppConfirmModal
                show={!!showConfirm}
                icon="warning"
                title="admin.client.item:delete.confirm.modal.title"
                description="admin.client.item:delete.confirm.modal.description"
                onHide={() => setShowConfirm(0)}
                nextAction={() => handleDelete(showConfirm)}
            />

            <AppGrid
                dynamicHeight
                columnDefs={appGridColDef({
                    onPressEdit: handleEdit,
                    onPressDelete: setShowConfirm,
                    onPressChangePlan: handleChangePlan,
                    onPressAutoRenew: handleAutoRenew,
                    toShortDate,
                })}
                rowData={data}
                gridOptions={{
                    suppressRowClickSelection: true,
                    onRowDoubleClicked: handleRowDoubleClick,
                }}
                isLoading={loading}
            />
        </div>
    );
};
