import React, { FC, useState } from "react";
import Cropper from "react-cropper";

// eslint-disable-next-line import/no-extraneous-dependencies
import "cropperjs/dist/cropper.css";
import "./assets/scss/style.scss";

import { AppFile, FileTypeInfo } from "../../models";
import { TKey, changeAspectRatioColon, getFileFromBlob } from "../../utils";
import { useTranslation } from "../../hooks";
import { AppSVGIcon } from "../AppSVGIcon";
import { AppFormActions } from "../AppForm";
import { AppButton } from "../AppButton";
import {
    AppBaseModal,
    AppBaseModalBody,
    AppBaseModalFooter,
    AppBaseModalHeader,
    AppBaseModalProps,
} from "../AppBaseModal";
import { AppZoomSlider } from "../AppZoomSlider";

interface AppCropperProps extends AppBaseModalProps {
    file: AppFile;
    fileInfo: FileTypeInfo;
    onSubmit?: (croppedFile: File) => void;
    isModal?: boolean;
}

export const AppCropper: FC<AppCropperProps> = ({
    show,
    onHide,
    className = "",
    file,
    fileInfo,
    onSubmit,
    isModal,
}) => {
    // hooks
    const { t } = useTranslation();

    // state
    const [cropper, setCropper] = useState<Cropper>();
    const [zoom, setZoom] = useState(0);
    const [range] = useState({
        min: 0,
        max: 5,
        step: 0.1,
    });

    const handleRotate = (val: number) => {
        cropper?.rotate(val);
    };

    const handleZoomOut = () => {
        if (zoom > range.min) {
            setZoom((prev) =>
                Math.max(parseFloat((prev - range.step).toFixed(2)), range.min),
            );
            cropper?.zoom(-range.step);
        }
    };

    const handleZoomIn = () => {
        if (zoom < range.max) {
            setZoom((prev) =>
                Math.min(parseFloat((prev + range.step).toFixed(2)), range.max),
            );
            cropper?.zoom(range.step);
        }
    };

    const handleSliderChange = (value: number) => {
        setZoom(value);
        cropper?.zoomTo(value);
    };

    const handleReset = () => {
        cropper?.reset();
        setZoom(range.min);
    };

    const onCrop = () => {
        cropper?.getCroppedCanvas().toBlob(
            async (blob) => {
                if (blob) {
                    const croppedFile = getFileFromBlob(blob, file);

                    if (onSubmit) {
                        onSubmit(croppedFile);
                    }
                }
            },
            file.type,
            1,
        );
    };

    const renderView = () => (
        <Cropper
            onInitialized={setCropper}
            src={file?.preview}
            style={{ height: isModal ? 510 : "100%", width: "100%" }} // TODO:: height to dynamic
            initialAspectRatio={16 / 9}
            aspectRatio={changeAspectRatioColon(fileInfo.ratio)}
            minCropBoxWidth={fileInfo.width ? +fileInfo.width : undefined}
            minCropBoxHeight={fileInfo.height ? +fileInfo.height : undefined}
            cropBoxResizable={!!changeAspectRatioColon(fileInfo.ratio)} // resize enable if we have ratio available
            viewMode={2}
            dragMode="crop"
            guides={false}
            movable={false}
            scalable={false}
            autoCropArea={0.1}
            // autoCropArea={1}
        />
    );

    const renderAction = () => (
        <AppFormActions withDefault={false} className="flex-md-wrap">
            <AppButton
                variant="secondary"
                onClick={() => handleRotate(-90)}
                className="btn-square"
            >
                <AppSVGIcon icon="tilt-left" size="xxl" />
            </AppButton>

            <AppButton
                variant="secondary"
                onClick={() => handleRotate(90)}
                className="btn-square"
            >
                <AppSVGIcon icon="tilt-right" size="xxl" />
            </AppButton>

            <AppZoomSlider
                value={zoom}
                min={range.min}
                max={range.max}
                step={range.step}
                onZoomIn={handleZoomIn}
                onZoomOut={handleZoomOut}
                onChange={handleSliderChange}
            />

            <AppButton
                variant="secondary"
                onClick={handleReset}
                iconRight="refresh"
            >
                {t(TKey.Common.Button.Reset)}
            </AppButton>

            <AppButton variant="secondary" onClick={onHide} iconRight="close">
                {t(TKey.Common.Button.Cancel)}
            </AppButton>

            <AppButton
                onClick={onCrop}
                disabled={!file?.preview}
                iconRight={isModal ? "arrow-right" : undefined}
            >
                {isModal
                    ? t(TKey.Common.Button.Next)
                    : t(TKey.Common.Button.Done)}
            </AppButton>
        </AppFormActions>
    );

    if (!isModal && show) {
        return (
            <div className={`app-cropper mb-4 ${className}`}>
                <div className="app-cropper--content">{renderView()}</div>

                <div className="app-cropper--action mt-2">{renderAction()}</div>
            </div>
        );
    }

    return (
        <AppBaseModal
            show={show}
            onHide={onHide}
            className={`app-cropper-modal ${className}`}
            size="lg"
        >
            <AppBaseModalHeader
                title={TKey.Common.Label.Crop}
                onHide={onHide}
            />

            <AppBaseModalBody>{renderView()}</AppBaseModalBody>

            <AppBaseModalFooter>{renderAction()}</AppBaseModalFooter>
        </AppBaseModal>
    );
};
