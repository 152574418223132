import { FC } from "react";
import { useController, useFormContext } from "react-hook-form";

import { AppFormInputWrapper } from "./AppFormInputWrapper";
import { AppDatePickerProps } from "./AppDatePicker/AppDatePicker";
import { AppDatePicker } from "./AppDatePicker";
import { useDateTime } from "../../hooks";

export const AppFormDatePicker: FC<AppDatePickerProps> = ({ ...props }) => {
    // hooks
    const { toShortDate } = useDateTime();
    const { control } = useFormContext();
    const { field } = useController({
        name: props.name,
        control,
    });

    const selectProps = {
        name: props.name,
        placeholder: props.placeholder,
        disabled: props.disabled,
        ref: field.ref,
        defaultValue: field.value,
        handleOnChange: props.handleOnChange,
        isInline: props.isInline,
    };

    const renderDatePicker = () => (
        <div className="input-group">
            <AppDatePicker
                {...selectProps}
                className={`w-100 ${props.className}`}
                inputClassName={`form-control ${props.inputClassName}`}
                defaultValue={toShortDate(field.value)}
                handleOnChange={(date) => {
                    if (props.handleOnChange) {
                        props.handleOnChange(date);
                    }

                    field.onChange(date.toISOString());
                }}
            />
        </div>
    );

    return (
        <AppFormInputWrapper {...props}>
            {renderDatePicker()}
        </AppFormInputWrapper>
    );
};
