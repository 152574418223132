import { memo } from "react";
import { ColDef } from "ag-grid-community";

import { AppGridAction } from "./cell-renderer";
import { AppGridColDefParams } from "../../../../models";

export const appGridColDef = ({
    onPressEdit,
}: AppGridColDefParams): ColDef[] => [
    { field: "name", sort: "asc" },
    { field: "subject" },
    {
        sortable: false,
        cellRenderer: memo(AppGridAction),
        cellRendererParams: {
            onPressEdit,
        },
        maxWidth: 50,
        cellClass: "center-column",
    },
];
