import { useContext } from "react";
import { onUserLogin, onUserLogout } from "../Socket";
import { AuthContext } from "../../contexts";
import { APP_TOKEN_KEY } from "../../utils";
import { useRecoilState } from "recoil";
import { atomActiveClient } from "../../atoms";

type UserSocketEventsType = {
    emitLogin: () => void;
    emitLogout: () => void;
};

export function useUserSocketEvents(): UserSocketEventsType {
    const { state } = useContext(AuthContext);
    const { user } = state;
    const [activeClient] = useRecoilState(atomActiveClient);

    const emitLogin = (): void => {
        if (user && user.id && activeClient) {
            // eslint-disable-next-line no-console
            console.debug("emitLogin", activeClient?.id);

            onUserLogin({
                token: localStorage.getItem(APP_TOKEN_KEY),
                userId: user.id,
                clientId: activeClient?.id,
            });
        }
    };

    const emitLogout = (): void => {
        // eslint-disable-next-line no-console
        console.debug("emitLogout");

        onUserLogout();
    };

    return {
        emitLogin,
        emitLogout,
    };
}
