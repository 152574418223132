import { ICellRendererParams } from "ag-grid-community";
import { Link } from "react-router-dom";

import "./app-grid-style.scss";

import { AppActionDropDown, AppSVGIcon } from "../../../../components";
import { AppKanBanArchiveGridColDefParams } from "./column-defs";
import { TKey } from "../../../../utils";
import { useTranslation, useWorkspacePermission } from "../../../../hooks";

export const AppGridAction = (
    params: ICellRendererParams & AppKanBanArchiveGridColDefParams,
) => {
    // hooks
    const { t } = useTranslation();
    const { isWrite } = useWorkspacePermission();

    // params
    const { onPressDelete, onPressMove, data } = params;

    return (
        <AppActionDropDown id={data.id} isGrantedControl={isWrite}>
            <li className="move-to-kanban">
                <Link
                    to={"#"}
                    onClick={(e) => {
                        e.preventDefault();
                        onPressMove(data.id);
                    }}
                >
                    <AppSVGIcon icon="reply" />
                    {t("app.map.kanban.archive.item:label.moveToKanBan")}
                </Link>
            </li>

            {onPressDelete && (
                <li className="delete">
                    <Link
                        to={"#"}
                        onClick={(e) => {
                            e.preventDefault();
                            onPressDelete(data.id);
                        }}
                    >
                        <AppSVGIcon icon="delete" />

                        <span>{t(TKey.Common.Button.Delete)}</span>
                    </Link>
                </li>
            )}
        </AppActionDropDown>
    );
};
