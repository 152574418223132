import { EntityAPI } from "./core";
import { FinalResponse, ROUTES, route } from "../config";

const {
    api_invoices_get_collection: API_GET_COLLECTION,
    api_invoices_post_collection: API_POST_COLLECTION,
    // api_invoices_get_item: API_GET_ITEM,
    // api_invoices_patch_item: API_PATCH_ITEM,
    api_invoices_delete_item: API_DELETE_ITEM,
    api_invoices_create: API_POST_CREATE,
    api_invoices_pay: API_POST_PAY,
    api_invoices_notify_enterprise: API_POST_NOTIFY_ENTERPRISE,
    get_invoices_pdf: API_GET_PDF,
    api_invoices_patch_change_status_item: API_PATCH_CHANGE_STATUS,
} = ROUTES;

export abstract class InvoiceApi extends EntityAPI {
    protected static GET_COLLECTION = API_GET_COLLECTION;

    protected static POST_COLLECTION = API_POST_COLLECTION;

    // protected static GET_ITEM = API_GET_ITEM;

    // protected static PUT_ITEM = API_PUT_ITEM;

    // protected static PATCH_ITEM = API_PATCH_ITEM;

    protected static DELETE_ITEM = API_DELETE_ITEM;

    public static async createPlan<R, P = null>(
        payload: P,
    ): Promise<FinalResponse<R | null>> {
        return this.makePost<R, P>(API_POST_CREATE, payload)
            .then(({ data }) => this.handleResponse<R>(data))
            .catch((e) => this.handleAllError(e));
    }

    public static async pay<R, P = null>(
        payload: P,
    ): Promise<FinalResponse<R | null>> {
        return this.makePost<R, P>(API_POST_PAY, payload)
            .then(({ data }) => this.handleResponse<R>(data))
            .catch((e) => this.handleAllError(e));
    }

    public static async notifyEnterprise<R, P = null>(): Promise<
        FinalResponse<R | null>
    > {
        return this.makePost<R, P>(API_POST_NOTIFY_ENTERPRISE, {})
            .then(({ data }) => this.handleResponse<R>(data))
            .catch((e) => this.handleAllError(e));
    }

    public static async pdfDownload<R>(
        id: number,
        extraParams = {},
    ): Promise<FinalResponse<R | null>> {
        const path = route(API_GET_PDF, { id });

        return this.makeGet<R>(
            path,
            { ...extraParams },
            {
                responseType: "blob",
            },
        )
            .then(({ data }) => this.handleResponse<R>(data))
            .catch((e) => this.handleAllError(e));
    }

    public static async changeStatus<R, P>(
        id: number,
        payload: P,
    ): Promise<FinalResponse<R | null>> {
        return this.makePatch<R, P>(
            route(API_PATCH_CHANGE_STATUS, { id }),
            JSON.stringify(payload),
        )
            .then(({ data }) => this.handleResponse<R>(data))
            .catch((e) => this.handleAllError(e));
    }
}
