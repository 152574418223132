import { lazy } from "react";

export const AppBaseModal = lazy(() => import("./AppBaseModal"));
export const AppBaseModalHeader = lazy(() => import("./AppBaseModalHeader"));
export const AppBaseModalBody = lazy(() => import("./AppBaseModalBody"));
export const AppBaseModalFooter = lazy(() => import("./AppBaseModalFooter"));

export * from "./AppBaseModal";
export * from "./AppBaseModalHeader";
export * from "./AppBaseModalBody";
export * from "./AppBaseModalFooter";
