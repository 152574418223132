import { SimpleObject } from "../../../models";

export class ErrorResponse implements IErrorResponse {
    title = "Something went wrong!";

    description = "Unable to process your request, please try again!";

    violations: SimpleObject<string> = {};

    constructor(
        title: string | null = null,
        description: string | null = null,
    ) {
        if (title) {
            this.title = title;
        }
        if (description) {
            this.description = description;
        }
    }
}

export interface IErrorResponse {
    violations: SimpleObject<string>;
    title: string;
    description: string;
}
