import { FinalResponse, ROUTES } from "../config";
import { EntityAPI } from "./core";

const {
    api_login_check: API_LOGIN_CHECK,
    api_security_auth_map_view: API_AUTH_MAP_VIEW,
    api_security_register: API_REGISTER,
    api_reset_password_requests_request_collection: API_FORGOT_PASSWORD,
    api_reset_password_requests_reset_collection: API_RESET_PASSWORD,
    api_users_get_me_collection: API_ME,
} = ROUTES;

export abstract class AuthApi extends EntityAPI {
    public static async login<R, P = null>(
        payload: P,
    ): Promise<FinalResponse<R | null>> {
        return this.makePost<R, P>(API_LOGIN_CHECK, payload)
            .then(({ data }) => this.handleResponse<R>(data))
            .catch((e) => this.handleAllError(e));
    }

    public static async authMapView<R, P = null>(
        payload: P,
    ): Promise<FinalResponse<R | null>> {
        return this.makePost<R, P>(API_AUTH_MAP_VIEW, payload)
            .then(({ data }) => this.handleResponse<R>(data))
            .catch((e) => this.handleAllError(e));
    }

    public static async register<R, P = null>(
        payload: P,
    ): Promise<FinalResponse<R | null>> {
        return this.makePost<R, P>(API_REGISTER, payload)
            .then(({ data }) => this.handleResponse<R>(data))
            .catch((e) => this.handleAllError(e));
    }

    public static async forgotPassword<R, P = null>(
        payload: P,
    ): Promise<FinalResponse<R | null>> {
        return this.makePost<R, P>(API_FORGOT_PASSWORD, payload)
            .then(({ data }) => this.handleResponse<R>(data))
            .catch((e) => this.handleAllError(e));
    }

    public static async resetPassword<R, P = null>(
        payload: P,
    ): Promise<FinalResponse<R | null>> {
        return this.makePost<R, P>(API_RESET_PASSWORD, payload)
            .then(({ data }) => this.handleResponse<R>(data))
            .catch((e) => this.handleAllError(e));
    }

    public static async me<R>(): Promise<FinalResponse<R | null>> {
        return this.makeGet<R>(API_ME)
            .then(({ data }) => this.handleResponse<R>(data))
            .catch((e) => this.handleAllError(e));
    }
}
