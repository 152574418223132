import * as yup from "yup";
import { TKey, validation } from "../../../utils";

export const schema = (t: (val: string) => string, isEditMode: number) =>
    yup.object().shape({
        email: yup
            .string()
            .email()
            .required(t(TKey.Common.Message.Validation.RequiredField)),
        role: yup.string(),
        plainPassword: yup
            .string()
            .min(8)
            .matches(
                /[0-9]/,
                t(TKey.Common.Message.Validation.RequiredOneNumber),
            )
            .matches(
                /[_\W]/,
                t(TKey.Common.Message.Validation.RequiredSpecialCharacter),
            )
            .max(32)
            .when([], {
                is: () => !isEditMode,
                then: (sc) =>
                    sc.required(
                        t(TKey.Common.Message.Validation.RequiredField),
                    ),
                otherwise: (sc) => sc.optional(),
            }),
        confirmPassword: yup
            .string()
            .oneOf(
                [yup.ref("plainPassword")],
                t(TKey.Common.Message.Validation.RequiredPasswordMatch),
            )
            .when([], {
                is: () => !isEditMode,
                then: (sc) =>
                    sc.required(
                        t(TKey.Common.Message.Validation.RequiredField),
                    ),
                otherwise: (sc) => sc.optional(),
            }),
        firstName: yup
            .string()
            .required(t(TKey.Common.Message.Validation.RequiredField))
            .max(validation.title.max),
        lastName: yup
            .string()
            .required(t(TKey.Common.Message.Validation.RequiredField))
            .max(validation.title.max),
        imageName: yup
            .string()
            .transform((value) => (value?.length ? value : "")),
        company: yup
            .string()
            .required(t(TKey.Common.Message.Validation.RequiredField))
            .max(validation.title.max),
        locale: yup
            .string()
            .required(t(TKey.Common.Message.Validation.RequiredField)),
        isPasswordStrong: yup.boolean(),
        isOnboarded: yup.boolean(),
    });
