import * as yup from "yup";
import { TKey } from "../../../utils";

export const schema = (t: (val: string) => string) =>
    yup.object().shape({
        email: yup
            .string()
            .email()
            .required(t(TKey.Common.Message.Validation.RequiredField)),
        plainPassword: yup
            .string()
            .required(t(TKey.Common.Message.Validation.RequiredField))
            .min(8)
            .matches(
                /[0-9]/,
                t(TKey.Common.Message.Validation.RequiredOneNumber),
            )
            .matches(
                /[_\W]/,
                t(TKey.Common.Message.Validation.RequiredSpecialCharacter),
            )
            .max(32),
        confirmPassword: yup
            .string()
            .oneOf(
                [yup.ref("plainPassword")],
                t(TKey.Common.Message.Validation.RequiredPasswordMatch),
            )
            .required(t(TKey.Common.Message.Validation.RequiredField)),
        agree: yup
            .boolean()
            .oneOf(
                [true],
                t("user.register.form:validation.required.termsAndCondition"),
            )
            .required(t(TKey.Common.Message.Validation.RequiredField)),
        isPasswordStrong: yup.boolean(),
    });
