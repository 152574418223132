import { FC, useEffect, useState } from "react";
import { useRecoilState, useResetRecoilState } from "recoil";
import { FormProvider, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";

import "./assets/scss/style.scss";

import {
    AppButton,
    AppFlag,
    AppForm,
    AppFormContent,
    AppFormInput,
    AppFormModal,
    AppFormPhoneNumber,
    AppFormSelect,
    AppFormUploader,
    AppLink,
    AppLoader,
} from "../../../components";
import { useSubscriptionAction, useTranslation } from "../../../hooks";
import {
    CropperViewEnum,
    ModalTypeEnum,
    TKey,
    errorToast,
    setBackendViolations,
    successToast,
    validation,
} from "../../../utils";
import { atomActiveClient, atomDrawer, atomDrawerType } from "../../../atoms";
import { ClientApi } from "../../../apis";
import { schema } from "./schema";
import { Client, PClient } from "../../../models";
import { ClientLogoFileInfo, getCountryOptions } from "../../../config";

interface SubscriptionClientModalProps {
    clientId: number;
    isPrimary?: boolean;
}

export const SubscriptionClientModal: FC<SubscriptionClientModalProps> = ({
    clientId,
    isPrimary = true,
}) => {
    // hooks
    const { t } = useTranslation();

    // atoms
    const [activeClient, setActiveClient] = useRecoilState(atomActiveClient);
    const [drawer, setDrawer] = useRecoilState(
        atomDrawerType(ModalTypeEnum.CLIENT_ADD_EDIT),
    );
    const resetDrawer = useResetRecoilState(atomDrawer);

    // context
    const { actionRefetchMyClient } = useSubscriptionAction();

    // state
    const [isUploading, setIsUploading] = useState(false);
    const [loading, setLoading] = useState(false);

    // form
    const methods = useForm({
        resolver: yupResolver(schema(t)),
    });
    const { handleSubmit, formState, reset, setError, watch, trigger } =
        methods;

    const handleClientEdit = () => {
        ClientApi.getMyClient<Client>().then(({ errorMessage, response }) => {
            if (errorMessage) {
                errorToast(errorMessage);
            } else if (response) {
                reset({
                    name: response.name,
                    address: response.address,
                    region: response.region,
                    postCode: response.postCode,
                    city: response.city,
                    country: response.country,
                    phone: response.phone,
                    imageName: response.imageName,
                });

                setDrawer({ show: true, id: response.id });
            }
        });
    };

    const onSubmitHandler = (formData: PClient) => {
        setLoading(true);

        ClientApi.updateProfile<Client, PClient>(clientId, formData)
            .then(({ response, errorMessage, isInvalid, error }) => {
                if (isInvalid) {
                    setBackendViolations(error, setError);
                } else if (errorMessage) {
                    errorToast(errorMessage);
                } else if (response !== null) {
                    successToast(t("admin.client.list:update.toast.success"));
                    actionRefetchMyClient();

                    if (activeClient && activeClient.id === clientId) {
                        setActiveClient({
                            id: response.id,
                            imageName: response.imageName,
                        });
                    }

                    reset();
                    resetDrawer();
                }
            })
            .finally(() => setLoading(false));
    };

    useEffect(() => {
        const subscription = watch((_, { name }) => {
            if (name && formState.touchedFields[name]) {
                trigger(name);
            }
        });
        return () => subscription.unsubscribe();
    }, [watch, trigger, formState.touchedFields]);

    const renderForm = () => (
        <FormProvider {...methods}>
            <AppForm className="client-page--form">
                {loading && <AppLoader isFullScreen />}

                <AppFormContent>
                    <AppFormInput
                        id="name"
                        name="name"
                        label={t("admin.client.form:label.name")}
                        placeholder={t("admin.client.form:placeholder.name")}
                        md={12}
                        lg={6}
                        maxCount={validation.title.max}
                    />

                    <AppFormInput
                        id="region"
                        name="region"
                        label={t("admin.client.form:label.region")}
                        placeholder={t("admin.client.form:placeholder.region")}
                        md={12}
                        lg={6}
                        maxCount={validation.title.max}
                    />

                    <AppFormInput
                        id="address"
                        name="address"
                        label={t("admin.client.form:label.address")}
                        placeholder={t("admin.client.form:placeholder.address")}
                        md={12}
                        lg={6}
                        maxCount={validation.title.max}
                    />

                    <AppFormPhoneNumber
                        id="phone"
                        name="phone"
                        label={t("admin.client.form:label.phone")}
                        placeholder={t("admin.client.form:placeholder.phone")}
                        md={12}
                        lg={6}
                    />

                    <AppFormSelect
                        id="country"
                        name="country"
                        label={t("admin.client.form:label.country")}
                        placeholder={t("admin.client.form:placeholder.country")}
                        md={12}
                        lg={6}
                        options={getCountryOptions().map(
                            ({ label, value }) => ({
                                label: (
                                    <div className="d-flex gap-3 align-items-center">
                                        <AppFlag icon={value} />
                                        <span>{label}</span>
                                    </div>
                                ),
                                value,
                                search: label,
                            }),
                        )}
                    />

                    <AppFormInput
                        id="city"
                        name="city"
                        label={t("admin.client.form:label.city")}
                        placeholder={t("admin.client.form:placeholder.city")}
                        md={12}
                        lg={6}
                    />

                    <AppFormUploader
                        id="imageName"
                        name="imageName"
                        label={t("admin.client.form:label.imageName")}
                        md={12}
                        lg={6}
                        fileInfo={ClientLogoFileInfo}
                        cbFileUploading={setIsUploading}
                        withCropper={CropperViewEnum.Default}
                    />

                    <AppFormInput
                        type="number"
                        id="postCode"
                        name="postCode"
                        label={t("admin.client.form:label.postCode")}
                        placeholder={t(
                            "admin.client.form:placeholder.postCode",
                        )}
                        md={12}
                        lg={6}
                    />
                </AppFormContent>
            </AppForm>
        </FormProvider>
    );

    return (
        <div className="subscription-client-modal">
            {isPrimary ? (
                <AppLink to={"#"} size="sm" onClick={() => handleClientEdit()}>
                    {t("admin.subscription.overview:label.change")}
                </AppLink>
            ) : (
                <AppButton
                    variant="secondary"
                    size="sm"
                    onClick={handleClientEdit}
                >
                    {t(TKey.Common.Button.Edit)}
                </AppButton>
            )}

            <AppFormModal
                show={drawer.show}
                icon="description"
                title="admin.client.form:modal.title"
                // className="app-drawer-stick-header"
                // isDrawer
                size="xl"
                onSubmit={handleSubmit(onSubmitHandler)}
                onHide={() => resetDrawer()}
                isLoading={formState.isSubmitting || loading}
                disabled={isUploading}
                isEditMode
                disabledSumbit={!formState.isValid}
            >
                {renderForm()}
            </AppFormModal>
        </div>
    );
};
