import { FC, useState } from "react";
import { useNavigate } from "react-router-dom";
import { RowClickedEvent } from "ag-grid-community";

import "./assets/scss/style.scss";

import { Map } from "../../../models";
import { AppConfirmModal, AppGrid } from "../../../components";
import { MapApi } from "../../../apis";
import { errorToast, successToast } from "../../../utils";
import {
    WorkspacePermissionType,
    useDateTime,
    useRoles,
    useTranslation,
} from "../../../hooks";
import { appGridColDef } from "./app-grid";

interface MapListProps extends WorkspacePermissionType {
    data: Map[];
    loading: boolean;
    onEdit: (map: Map) => void;
    onDelete: () => void;
    className?: string;
}

export const MapList: FC<MapListProps> = ({
    data,
    loading,
    onEdit,
    onDelete,
    className = "",
    isOwner,
    isWrite,
    isRead,
}) => {
    // hooks
    const { t } = useTranslation();
    const { toShortDate } = useDateTime();
    const navigate = useNavigate();
    const { isGrantedClientAdmin } = useRoles();

    // state
    const [showConfirm, setShowConfirm] = useState(0);
    const [showCloneConfirm, setShowCloneConfirm] = useState(0);

    const handleEdit = (id: number) => {
        MapApi.findById<Map>(id).then(({ errorMessage, response }) => {
            if (errorMessage) {
                errorToast(errorMessage);
            } else if (response) {
                onEdit(response);
            }
        });
    };

    const handleDelete = (id: number) => {
        MapApi.deleteById(id).then(({ errorMessage }) => {
            if (errorMessage) {
                errorToast(errorMessage);
            } else {
                successToast(t("app.map.list:delete.toast.success"));
                setShowConfirm(0);
                onDelete();
            }
        });
    };

    const handleClone = (id: number) => {
        MapApi.cloneMap({
            cloneId: id,
        }).then(({ errorMessage }) => {
            if (errorMessage) {
                errorToast(errorMessage);
            } else {
                successToast(t("app.map.list:clone.toast.success"));
                setShowCloneConfirm(0);
            }
        });
    };

    const handleRowDoubleClick = (params: RowClickedEvent) => {
        navigate(`/map/${params.data.id}/view`);
    };

    return (
        <div className={`map-list ${className}`}>
            <AppConfirmModal
                show={!!showConfirm}
                icon="warning"
                title="app.map.item:delete.confirm.modal.title"
                description="app.map.item:delete.confirm.modal.description"
                onHide={() => setShowConfirm(0)}
                nextAction={() => handleDelete(showConfirm)}
            />

            <AppConfirmModal
                show={!!showCloneConfirm}
                icon="warning"
                title="app.map.item:clone.confirm.modal.title"
                description="app.map.item:clone.confirm.modal.description"
                onHide={() => setShowCloneConfirm(0)}
                nextAction={() => handleClone(showCloneConfirm)}
            />

            <AppGrid
                dynamicHeight
                columnDefs={appGridColDef({
                    onPressEdit: handleEdit,
                    onPressDelete: setShowConfirm,
                    onPressClone: setShowCloneConfirm,
                    toShortDate,
                    isOwner,
                    isWrite,
                    isRead,
                    isGrantedClientAdmin,
                })}
                rowData={data}
                gridOptions={{
                    suppressRowClickSelection: true,
                    onRowDoubleClicked: handleRowDoubleClick,
                }}
                isLoading={loading}
            />
        </div>
    );
};
