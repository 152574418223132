import { io } from "socket.io-client";
import { SOCKET_HOST } from "../config";
import { PTaskPost, PUser } from "../models";

export const Socket = io(SOCKET_HOST, {
    transports: ["websocket"],
});

export const EVENTS = {
    CONNECT: "connect",
    DISCONNECT: "disconnect",
    USER_LOGIN: "on-user-login",
    USER_LOGOUT: "on-user-logout",

    MAP_JOIN: "map-join",
    MAP_LEAVE: "map-leave",
    MAP_VOTING_STATUS: "map-voting-status",
    ON_MAP_VOTING_STATUS: "on-map-voting-status",
    MAP_VOTING_OPEN_STATUS: "map-voting-open-status",
    ON_MAP_VOTING_OPEN_STATUS: "on-map-voting-open-status",
    MAP_CREATE_TASK_POST: "map-create-task-post",
    ON_MAP_CREATE_TASK_POST: "on-map-create-task-post",
    MAP_UPDATE_TASK_POST: "map-update-task-post",
    ON_MAP_UPDATE_TASK_POST: "on-map-update-task-post",
    ON_MAP_UPDATE_TASK_POST_BACKEND: "on-map-update-task-post-backend",
    MAP_DELETE_TASK_POST: "map-delete-task-post",
    ON_MAP_DELETE_TASK_POST: "on-map-delete-task-post",
    MAP_ORDER_TASK_POST: "map-order-task-post",
    ON_MAP_ORDER_TASK_POST: "on-map-order-task-post",
    MAP_LOCK_TASK_POST: "map-lock-task-post",
    ON_MAP_LOCK_TASK_POST: "on-map-lock-task-post",
    MAP_CREATE_SUB_TASK: "map-create-sub-task",
    ON_MAP_CREATE_SUB_TASK: "on-map-create-sub-task",
    MAP_UPDATE_SUB_TASK: "map-update-sub-task",
    ON_MAP_UPDATE_SUB_TASK: "on-map-update-sub-task",
    MAP_DELETE_SUB_TASK: "map-delete-sub-task",
    ON_MAP_DELETE_SUB_TASK: "on-map-delete-sub-task",
    MAP_ORDER_SUB_TASK: "map-order-sub-task",
    ON_MAP_ORDER_SUB_TASK: "on-map-order-sub-task",
    MAP_LOCK_SUB_TASK: "map-lock-sub-task",
    ON_MAP_LOCK_SUB_TASK: "on-map-lock-sub-task",
    MAP_CREATE_TASK: "map-create-task",
    ON_MAP_CREATE_TASK: "on-map-create-task",
    MAP_UPDATE_TASK: "map-update-task",
    ON_MAP_UPDATE_TASK: "on-map-update-task",
    MAP_DELETE_TASK: "map-delete-task",
    ON_MAP_DELETE_TASK: "on-map-delete-task",
    MAP_ORDER_TASK: "map-order-task",
    ON_MAP_ORDER_TASK: "on-map-order-task",
    MAP_LOCK_TASK: "map-lock-task",
    ON_MAP_LOCK_TASK: "on-map-lock-task",
    MAP_CREATE_ACTION_TASK: "map-create-action-task",
    ON_MAP_CREATE_ACTION_TASK: "on-map-create-action-task",
    MAP_UPDATE_ACTION_TASK: "map-update-action-task",
    ON_MAP_UPDATE_ACTION_TASK: "on-map-update-action-task",
    MAP_DELETE_ACTION_TASK: "map-delete-action-task",
    ON_MAP_DELETE_ACTION_TASK: "on-map-delete-action-task",
    MAP_ORDER_ACTION_TASK: "map-order-action-task",
    ON_MAP_ORDER_ACTION_TASK: "on-map-order-action-task",
    MAP_LOCK_ACTION_TASK: "map-lock-action-task",
    ON_MAP_LOCK_ACTION_TASK: "on-map-lock-action-task",

    JOIN_COMMAND_CENTER: "join-command-center",
    NEW_COMMAND: "new-command",
    ON_NEW_COMMAND: "on-new-command",
    LEAVE_COMMAND_CENTER: "leave-command-center",
    BACKEND_COMMAND: "backend-command",

    ONLINE: "online",
    ONLINE_LIST: "online-list",
    OFFLINE: "offline",
};

export type OnJoinMapPayload = {
    mapId: string;
};
export type OnLeaveMapPayload = OnJoinMapPayload;
export type OnMapCreateTaskPostPayload = {
    mapId: string;
    user: PUser;
    taskId: number;
    subTaskId: number;
    taskPost: PTaskPost;
};
export type OnMapUpdateTaskPostPayload = {
    mapId: string;
    user: PUser;
    taskId: number;
    subTaskId: number;
    taskPostId: number;
};
export type OnMapDeleteTaskPostPayload = {
    mapId: string;
    user: PUser;
    taskId: number;
    subTaskId: number;
    taskPostId: number;
};

type OnUserLoginPayload = {
    token: string | null;
    userId: number | null;
    clientId: number | null;
};

export const onUserLogin = ({
    token,
    userId,
    clientId,
}: OnUserLoginPayload): void => {
    Socket.emit(EVENTS.USER_LOGIN, {
        token,
        userId,
        clientId,
    });
};

export const onUserLogout = (): void => {
    Socket.emit(EVENTS.USER_LOGOUT, {
        token: null,
        userId: null,
    });
};

export const emitter = <T>(type: string, data: T): void => {
    Socket.emit(type, data);
};
