import React, { FC, useEffect, useState } from "react";
import { useRecoilState, useResetRecoilState } from "recoil";
import { Link, useLocation, useNavigate } from "react-router-dom";

import "./assets/scss/style.scss";

import {
    useDownloadFile,
    useTranslation,
    useWorkspacePermission,
} from "../../../hooks";
import {
    ActionPost,
    Map,
    PActionPost,
    PMap,
    PUserZoom,
    PrimitiveObject,
    UserZoom,
} from "../../../models";
import {
    MapPageEnum,
    MapPaperSizeEnum,
    ModalTypeEnum,
    TKey,
    errorToast,
    staticZoomRatio,
    successToast,
    zoomToFixed,
} from "../../../utils";
import {
    atomDrawer,
    atomDrawerType,
    atomMapDetailFilter,
    atomMapPortfolioFilter,
    atomZoomLevel,
} from "../../../atoms";
import { MapPortfolioSetting } from "../MapPortfolioSetting";
import { MapKanBanSetting } from "../MapKanBanSetting";
import {
    AppActionDropDown,
    AppButton,
    AppConfirmModal,
    AppDisplayEditorContent,
    AppLoader,
    AppSVGIcon,
    AppSearchBar,
    AppSwitch,
    AppTooltip,
    AppZoomSlider,
} from "../../../components";
import { ActionPostApi, MapApi, UserZoomApi } from "../../../apis";
import { EVENTS, emitter } from "../../../Socket/Socket";

interface MapPageHeaderProps {
    map: Map;
    pageType: MapPageEnum;
    userZoom?: UserZoom;
    showToolbar?: boolean;
    onFilterBtnClick?: () => void;
    onChange?: (sortParams?: PrimitiveObject) => void;
    sorting?: string;
    setSorting?: (val: string) => void;
}

export const MapPageHeader: FC<MapPageHeaderProps> = ({
    map,
    pageType,
    userZoom,
    showToolbar,
    onFilterBtnClick,
    onChange,
    setSorting,
}) => {
    // hooks
    const { t } = useTranslation();
    const navigate = useNavigate();
    const location = useLocation();
    const { isOwner, isWrite } = useWorkspacePermission();
    const { downloadLink } = useDownloadFile();

    // context
    // const { tasks } = useMapAction();

    // atoms
    const [zoom, setZoom] = useRecoilState(atomZoomLevel);
    const [appliedMapFilter] = useRecoilState(atomMapDetailFilter);
    const [appliedPortfolioFilter, setAppliedPortfolioFilter] = useRecoilState(
        atomMapPortfolioFilter,
    );
    const [drawerPortfolio, setDrawerPortfolio] = useRecoilState(
        atomDrawerType(ModalTypeEnum.PORTFOLIO_SETTING),
    );
    const [drawerKanban, setDrawerKanban] = useRecoilState(
        atomDrawerType(ModalTypeEnum.KANBAN_SETTING),
    );
    const resetDrawer = useResetRecoilState(atomDrawer);

    // state
    const [loading, setLoading] = useState(false);
    const [range] = useState({
        min: 0.3,
        max: 1.7,
        step: 0.1,
    });
    const [showPrintLines, setShowPrintLines] = useState(false);
    const [selectedPaperSize, setSelectedPaperSize] = useState(
        MapPaperSizeEnum.A3,
    );
    const [paperWidthHeight] = useState({
        A3: {
            width: 1191,
            height: 842,
        },
    });

    const selectedPaperWidth = paperWidthHeight[selectedPaperSize].width;
    const selectedPaperHeight = paperWidthHeight[selectedPaperSize].height;

    // const [maxTaskPosts] = useState(
    //     tasks
    //         .flatMap((x) => x.subTasks || [])
    //         .reduce((accumulator, x, i) => {
    //             const taskPostsLength = x.taskPosts?.length || 0;

    //             if (i === 0 || taskPostsLength > accumulator) {
    //                 return taskPostsLength;
    //             }

    //             return accumulator;
    //         }, 0),
    // );
    // const [maxItemsPerPaperSize] = useState<{
    //     [key: number]: {
    //         A2: number;
    //         A3: number;
    //         A4: number;
    //     };
    // }>({
    //     0.5: {
    //         A2: 20,
    //         A3: 13,
    //         A4: 8,
    //     },
    //     0.6: {
    //         A2: 17,
    //         A3: 11,
    //         A4: 6,
    //     },
    //     0.7: {
    //         A2: 14,
    //         A3: 9,
    //         A4: 5,
    //     },
    //     0.8: {
    //         A2: 12,
    //         A3: 7,
    //         A4: 4,
    //     },
    //     0.9: {
    //         A2: 10,
    //         A3: 6,
    //         A4: 3,
    //     },
    //     1: {
    //         A2: 9,
    //         A3: 5,
    //         A4: 2,
    //     },
    //     1.1: {
    //         A2: 8,
    //         A3: 4,
    //         A4: 2,
    //     },
    //     1.2: {
    //         A2: 7,
    //         A3: 4,
    //         A4: 1,
    //     },
    //     1.3: {
    //         A2: 6,
    //         A3: 3,
    //         A4: 1,
    //     },
    //     1.4: {
    //         A2: 5,
    //         A3: 3,
    //         A4: 1,
    //     },
    //     1.5: {
    //         A2: 5,
    //         A3: 2,
    //         A4: 0,
    //     },
    // });
    // const [printCutConfirm, setShowPrintCutConfirm] =
    //     useState<MapPaperSizeEnum>();
    const [resetVotingConfirm, setShowResetVotingConfirm] = useState(false);
    const [resetVotingColorConfirm, setShowResetVotingColorConfirm] =
        useState(false);
    const [transferVotingColorConfirm, setShowTransferVotingColorConfirm] =
        useState(false);

    // local state
    const { id, name, subTitle, description, isVoting, isVotingOpen } = map;

    const { zoomMap, zoomPortfolio, zoomKanban } = userZoom || {
        zoomMap: staticZoomRatio,
        zoomPortfolio: staticZoomRatio,
        zoomKanban: staticZoomRatio,
    };

    const [headerCollapsed, setHeaderCollapsed] = useState(() =>
        JSON.parse(localStorage.getItem("headerCollapsed") || "false"),
    );
    useEffect(() => {
        localStorage.setItem(
            "headerCollapsed",
            JSON.stringify(headerCollapsed),
        );
    }, [headerCollapsed]);
    useEffect(() => {
        setZoom({
            zoomMap: zoomMap / staticZoomRatio,
            zoomPortfolio: zoomPortfolio / staticZoomRatio,
            zoomKanban: zoomKanban / staticZoomRatio,
        });
    }, [userZoom]);

    const getZoom = () => {
        switch (pageType) {
            case MapPageEnum.MAP_PORTFOLIO:
                return {
                    key: "zoomPortfolio",
                    value: zoom.zoomPortfolio,
                };

            case MapPageEnum.MAP_KANBAN:
                return {
                    key: "zoomKanban",
                    value: zoom.zoomKanban,
                };

            case MapPageEnum.MAP_VIEW:
            default:
                return {
                    key: "zoomMap",
                    value: zoom.zoomMap,
                };
        }
    };

    const handleUpdateZoomLevel = (value: number) => {
        const updatedValue = parseFloat(value.toFixed(zoomToFixed));

        setZoom((prev) => ({
            ...prev,
            [getZoom().key]: updatedValue,
        }));

        if (!isOwner) {
            return;
        }

        const storeZoomValue = Math.round(updatedValue * staticZoomRatio);

        UserZoomApi.create<PUserZoom, PUserZoom>({
            map: MapApi.toResourceUrl(map.id),
            [getZoom().key]: storeZoomValue,
        }).then(({ response, errorMessage }) => {
            if (errorMessage) {
                errorToast(errorMessage);
            } else if (response !== null) {
                //
            }
        });
    };

    const handleZoomOut = () => {
        if (getZoom().value > range.min) {
            const selectedValue = Math.max(
                getZoom().value - range.step,
                range.min,
            );

            handleUpdateZoomLevel(selectedValue);
        }
    };

    const handleZoomIn = () => {
        if (getZoom().value < range.max) {
            const selectedValue = Math.min(
                getZoom().value + range.step,
                range.max,
            );

            handleUpdateZoomLevel(selectedValue);
        }
    };

    const handleSliderChange = (value: number) => {
        handleUpdateZoomLevel(value);
    };

    const handleZoomForPrinting = () => {
        let height = 0;
        let width = 0;

        // * total height calculation * //
        // app-main-container

        const mapDetailHeader = document.getElementsByClassName(
            "app-map-page-header",
        );
        const mapDetailContainer = document.getElementsByClassName(
            "map-detail-page--container",
        );

        if (mapDetailHeader?.length && mapDetailContainer?.length) {
            height =
                mapDetailHeader[0].clientHeight +
                mapDetailContainer[0].clientHeight;
        }

        // * total width calculation * //
        // action-task-container
        // OR
        // task-container--item + sub-task-container--item + task-post-container

        let width1 = 0;
        let width2 = 0;

        const mapTaskItem = document.getElementsByClassName(
            "task-container--item",
        );
        const mapSubTaskItem = document.getElementsByClassName(
            "sub-task-container--item",
        );
        const mapTaskPostContainer = document.getElementsByClassName(
            "task-post-container",
        );
        if (
            mapTaskItem?.length &&
            mapSubTaskItem?.length &&
            mapTaskPostContainer?.length
        ) {
            let mapTaskPostContainerWidth = 0;

            for (let index = 0; index < mapTaskPostContainer.length; index++) {
                // get the height of task post container (with max numbers of cards)
                mapTaskPostContainerWidth = Math.max(
                    mapTaskPostContainerWidth,
                    mapTaskPostContainer[index].clientWidth,
                );
            }

            width1 =
                mapTaskItem[0].clientWidth +
                mapSubTaskItem[0].clientWidth +
                mapTaskPostContainerWidth;
        }

        const mapActionTaskContainer = document.getElementsByClassName(
            "action-task-container",
        );
        if (mapActionTaskContainer?.length) {
            width2 = mapActionTaskContainer[0].clientWidth;
        }

        // get max width from width1 & width2
        width = Math.max(width1, width2);

        // * zoom level calculation * //
        // expected zoom level for selected paper size (for height) => here height calc bit different as on zoom level height changes for div
        const expectedHeightBasedZoomLevel =
            selectedPaperHeight / (height + 150);

        // expected zoom level for selected paper size (for width)
        const expectedWidthBasedZoomLevel = selectedPaperWidth / (width + 100); // 100 extra buffer value

        const expectedZoomLevel = Math.min(
            expectedHeightBasedZoomLevel,
            expectedWidthBasedZoomLevel,
        );

        handleUpdateZoomLevel(expectedZoomLevel);
    };

    const handleMapVotingSetting = (key: "isVoting" | "isVotingOpen") => {
        setLoading(true);

        let payload = { [key]: !map[key] };

        if (key === "isVoting") {
            payload = {
                ...payload,
                isVotingOpen: false,
            };
        }

        MapApi.update<Map, PMap>(+id, payload)
            .then(({ errorMessage }) => {
                if (errorMessage) {
                    errorToast(errorMessage);
                } else {
                    successToast(
                        t(
                            "app.map.portfolio.setting.form:update.toast.success",
                        ),
                    );

                    if (onChange) {
                        onChange();
                    }

                    if (key === "isVoting") {
                        emitter(EVENTS.MAP_VOTING_STATUS, {
                            mapId: `${id}`,
                            isOn: payload.isVoting,
                        });
                    }
                    emitter(EVENTS.MAP_VOTING_OPEN_STATUS, {
                        mapId: `${id}`,
                        isOn: payload.isVotingOpen,
                    });
                }
            })
            .finally(() => setLoading(false));
    };

    const handleResetVoting = () => {
        setLoading(true);

        MapApi.resetVoting<Map, PMap>(+id)
            .then(({ errorMessage }) => {
                if (errorMessage) {
                    errorToast(errorMessage);
                } else {
                    successToast(
                        t(
                            "app.map.portfolio.setting.form:update.toast.success",
                        ),
                    );

                    setShowResetVotingConfirm(false);

                    if (onChange) {
                        onChange();
                    }
                }
            })
            .finally(() => setLoading(false));
    };

    const handleResetVotingAndColor = () => {
        setLoading(true);

        MapApi.resetVotingAndColor<Map, PMap>(+id)
            .then(({ errorMessage }) => {
                if (errorMessage) {
                    errorToast(errorMessage);
                } else {
                    successToast(
                        t(
                            "app.map.portfolio.setting.form:update.toast.success",
                        ),
                    );

                    setShowResetVotingColorConfirm(false);

                    if (onChange) {
                        onChange();
                    }
                }
            })
            .finally(() => setLoading(false));
    };

    const handleTransferVotingColor = () => {
        setLoading(true);

        MapApi.transferVotingColor<Map, PMap>(+id)
            .then(({ errorMessage }) => {
                if (errorMessage) {
                    errorToast(errorMessage);
                } else {
                    successToast(
                        t(
                            "app.map.portfolio.setting.form:update.toast.success",
                        ),
                    );

                    setShowTransferVotingColorConfirm(false);

                    if (onChange) {
                        onChange();
                    }
                }
            })
            .finally(() => setLoading(false));
    };

    const handleArchiveAll = () => {
        ActionPostApi.archiveAll<ActionPost, PActionPost>({
            map: MapApi.toResourceUrl(id),
        }).then(({ errorMessage, response }) => {
            if (errorMessage) {
                errorToast(errorMessage);
            } else if (response !== null) {
                if (onChange) {
                    onChange();
                }
            }
        });
    };

    const handlePrintAll = (paperSize = MapPaperSizeEnum.A3, zoomLevel = 1) => {
        if (pageType === MapPageEnum.MAP_PORTFOLIO) {
            ActionPostApi.pdfDownloadAll<ActionPost, PActionPost>({
                map: MapApi.toResourceUrl(id),
            }).then(({ errorMessage, response }) => {
                if (errorMessage) {
                    errorToast(errorMessage);
                } else if (response !== null) {
                    downloadLink({
                        name: `portfolio_${id}.pdf`,
                        type: "file/pdf",
                        file: response,
                    });
                }
            });
        } else if (pageType === MapPageEnum.MAP_KANBAN) {
            ActionPostApi.pdfDownloadAllKanban<ActionPost, PActionPost>({
                map: MapApi.toResourceUrl(id),
            }).then(({ errorMessage, response }) => {
                if (errorMessage) {
                    errorToast(errorMessage);
                } else if (response !== null) {
                    downloadLink({
                        name: `kanban_${id}.pdf`,
                        type: "file/pdf",
                        file: response,
                    });
                }
            });
        } else if (pageType === MapPageEnum.MAP_VIEW) {
            const updatedValue = parseFloat(zoomLevel.toFixed(zoomToFixed));
            const storeZoomValue = Math.round(updatedValue * staticZoomRatio);
            MapApi.pdfDownload<Map, PrimitiveObject>({
                cloneId: id,
                paperSize,
                zoomMap: storeZoomValue,
            }).then(({ errorMessage, response }) => {
                if (errorMessage) {
                    errorToast(errorMessage);
                } else if (response !== null) {
                    downloadLink({
                        name: `map_${id}_${paperSize}.pdf`,
                        type: "file/pdf",
                        file: response,
                    });
                    // setShowPrintCutConfirm(undefined);
                }
            });
        }
    };

    const handleMapDetailPrint = (paperSize: MapPaperSizeEnum) => {
        const zoomLevel = getZoom().value;
        handleZoomForPrinting();

        // if (maxTaskPosts > maxItemsPerPaperSize[zoom.zoomMap][paperSize]) {
        //     setShowPrintCutConfirm(paperSize);
        //     return;
        // }

        handlePrintAll(paperSize, zoomLevel);
        handleUpdateZoomLevel(zoomLevel);
    };

    const handleSearch = (value: string) => {
        ActionPostApi.find<ActionPost>(1, {
            "map.id": id,
            "tags.id": appliedPortfolioFilter.tags,
            "responsible.id": appliedPortfolioFilter.responsible,
            ...(value ? { action_post_search: value } : {}),
            pagination: false,
        }).then(({ response, errorMessage }) => {
            if (errorMessage) {
                errorToast(errorMessage);
            } else if (response !== null) {
                setAppliedPortfolioFilter((prev) => ({
                    ...prev,
                    text: value,
                    filteredActionPostIds: response.items.map((x) => x.id),
                }));
            }
        });
    };

    useEffect(() => {
        if (showPrintLines) {
            let height = 0;
            let width = 0;

            // * total height calculation * //
            // app-main-container

            const mapDetailHeader = document.getElementsByClassName(
                "app-map-page-header",
            );
            const mapDetailContainer = document.getElementsByClassName(
                "map-detail-page--container",
            );

            if (mapDetailHeader?.length && mapDetailContainer?.length) {
                height =
                    mapDetailHeader[0].clientHeight +
                    mapDetailContainer[0].clientHeight;
            }

            // * total width calculation * //
            // action-task-container
            // OR
            // task-container--item + sub-task-container--item + task-post-container

            let width1 = 0;
            let width2 = 0;

            const mapTaskItem = document.getElementsByClassName(
                "task-container--item",
            );
            const mapSubTaskItem = document.getElementsByClassName(
                "sub-task-container--item",
            );
            const mapTaskPostContainer = document.getElementsByClassName(
                "task-post-container",
            );
            if (
                mapTaskItem?.length &&
                mapSubTaskItem?.length &&
                mapTaskPostContainer?.length
            ) {
                let mapTaskPostContainerWidth = 0;

                for (
                    let index = 0;
                    index < mapTaskPostContainer.length;
                    index++
                ) {
                    // get the height of task post container (with max numbers of cards)
                    mapTaskPostContainerWidth = Math.max(
                        mapTaskPostContainerWidth,
                        mapTaskPostContainer[index].clientWidth,
                    );
                }

                width1 =
                    mapTaskItem[0].clientWidth +
                    mapSubTaskItem[0].clientWidth +
                    mapTaskPostContainerWidth;
            }

            const mapActionTaskContainer = document.getElementsByClassName(
                "action-task-container",
            );
            if (mapActionTaskContainer?.length) {
                width2 = mapActionTaskContainer[0].clientWidth;
            }

            // get max width from width1 & width2
            width = Math.max(width1, width2);

            // * zoom level calculation * //
            // expected zoom level for selected paper size (for height) => here height calc bit different as on zoom level height changes for div
            const expectedHeightBasedZoomLevel =
                selectedPaperHeight / (height + 150);

            // expected zoom level for selected paper size (for width)
            const expectedWidthBasedZoomLevel =
                selectedPaperWidth / (width + 100); // 100 extra buffer value

            const expectedZoomLevel = Math.min(
                expectedHeightBasedZoomLevel,
                expectedWidthBasedZoomLevel,
            );

            handleUpdateZoomLevel(expectedZoomLevel);
        } else {
            // default zoom to 1 level (optional)
            // handleUpdateZoomLevel(1);

            // reset to A4 size
            setSelectedPaperSize(MapPaperSizeEnum.A3);
        }
    }, [selectedPaperSize, showPrintLines]);

    const getBadgeNumber = () => {
        let count = 0;

        if (pageType === MapPageEnum.MAP_VIEW) {
            if (appliedMapFilter?.colors?.length) {
                count += 1;
            }
            if (appliedMapFilter?.tags?.length) {
                count += 1;
            }
        }

        if (
            [MapPageEnum.MAP_PORTFOLIO, MapPageEnum.MAP_KANBAN].includes(
                pageType,
            )
        ) {
            if (appliedPortfolioFilter?.responsible?.length) {
                count += 1;
            }

            if (appliedPortfolioFilter?.tags?.length) {
                count += 1;
            }

            if (appliedPortfolioFilter?.text?.length) {
                count += 1;
            }
        }

        return count;
    };

    const renderActionMenu = () => (
        <>
            {pageType === MapPageEnum.MAP_PORTFOLIO && (
                <MapPortfolioSetting
                    map={map}
                    showForm={drawerPortfolio.show}
                    onHide={resetDrawer}
                    onSettingChange={onChange}
                />
            )}

            {pageType === MapPageEnum.MAP_KANBAN && (
                <MapKanBanSetting
                    map={map}
                    showForm={drawerKanban.show}
                    onHide={resetDrawer}
                    onSettingChange={onChange}
                />
            )}

            <AppActionDropDown
                btnClassName="btn-square"
                isEllipsis={false}
                icon="ellipsis"
                isGrantedControl={!showPrintLines}
            >
                {[MapPageEnum.MAP_PORTFOLIO, MapPageEnum.MAP_KANBAN].includes(
                    pageType,
                ) &&
                    isOwner && (
                        <li className="setting">
                            <Link
                                to={"#"}
                                onClick={(e) => {
                                    e.preventDefault();

                                    if (
                                        pageType === MapPageEnum.MAP_PORTFOLIO
                                    ) {
                                        setDrawerPortfolio({
                                            show: true,
                                            id: 0,
                                        });
                                    }

                                    if (pageType === MapPageEnum.MAP_KANBAN) {
                                        setDrawerKanban({
                                            show: true,
                                            id: 0,
                                        });
                                    }
                                }}
                            >
                                <AppSVGIcon icon="settings" />
                                <span> {t(TKey.Common.Button.Setting)}</span>
                            </Link>
                        </li>
                    )}

                {[MapPageEnum.MAP_VIEW].includes(pageType) && (
                    <>
                        {/*  TODO: Create Save As Function then uncomment this
                        {isOwner && ( 
                            <li className="save-as">
                                <Link
                                    to={"#"}
                                    onClick={(e) => {
                                        e.preventDefault();
                                    }}
                                >
                                    <AppSVGIcon icon="save" />
                                    <span> {t(TKey.Common.Button.SaveAs)}</span>
                                </Link>
                            </li>
                        )} */}
                        {isWrite && (
                            <>
                                <li className="share">
                                    <Link to={`/map/${map.id}/share`}>
                                        <AppSVGIcon icon="share" />
                                        <span>
                                            {t(TKey.Common.Button.Share)}
                                        </span>
                                    </Link>
                                </li>
                                <li className="voting-setting">
                                    <Link
                                        to={"#"}
                                        onClick={(e) => {
                                            e.preventDefault();
                                            handleMapVotingSetting("isVoting");
                                        }}
                                    >
                                        <AppSVGIcon
                                            icon={
                                                isVoting ? "block" : "vote-on"
                                            }
                                        />
                                        <span>
                                            {isVoting
                                                ? t(
                                                      "app.map.view:button.votingSettingOff",
                                                  )
                                                : t(
                                                      "app.map.view:button.votingSettingOn",
                                                  )}
                                        </span>
                                        <AppTooltip
                                            id={"Info-active-voting"}
                                            placement="top-start"
                                            overlay={
                                                <span className="paragraph--300">
                                                    {isVoting
                                                        ? t(
                                                              "app.map.view:tooltip.voting.deactivate",
                                                          )
                                                        : t(
                                                              "app.map.view:tooltip.voting.activate",
                                                          )}
                                                </span>
                                            }
                                        >
                                            <AppSVGIcon
                                                icon="help"
                                                size="xs"
                                                className="voting-help-icon"
                                            />
                                        </AppTooltip>
                                    </Link>
                                </li>

                                {isVoting && (
                                    <>
                                        <li className="reset-voting">
                                            <Link
                                                to={"#"}
                                                onClick={(e) => {
                                                    e.preventDefault();
                                                    setShowResetVotingConfirm(
                                                        true,
                                                    );
                                                }}
                                            >
                                                <AppSVGIcon icon="voting" />
                                                <span>
                                                    {t(
                                                        "app.map.view:button.resetVoting",
                                                    )}
                                                </span>
                                                <AppTooltip
                                                    id={"Info-reset-voting"}
                                                    placement="top-start"
                                                    overlay={
                                                        <span className="paragraph--300">
                                                            {t(
                                                                "app.map.view:tooltip.voting.resetVoting",
                                                            )}
                                                        </span>
                                                    }
                                                >
                                                    <AppSVGIcon
                                                        icon="help"
                                                        size="xs"
                                                        className="voting-help-icon"
                                                    />
                                                </AppTooltip>
                                            </Link>
                                        </li>

                                        <li className="reset-voting-and-color">
                                            <Link
                                                to={"#"}
                                                onClick={(e) => {
                                                    e.preventDefault();
                                                    setShowResetVotingColorConfirm(
                                                        true,
                                                    );
                                                }}
                                            >
                                                <AppSVGIcon icon="refresh" />
                                                <span>
                                                    {t(
                                                        "app.map.view:button.resetVotingAndColor",
                                                    )}
                                                </span>
                                                <AppTooltip
                                                    id={
                                                        "Info-reset-voting-and-color"
                                                    }
                                                    placement="top-start"
                                                    overlay={
                                                        <span className="paragraph--300">
                                                            {t(
                                                                "app.map.view:tooltip.voting.resetVotingAndColor",
                                                            )}
                                                        </span>
                                                    }
                                                >
                                                    <AppSVGIcon
                                                        icon="help"
                                                        size="xs"
                                                        className="voting-help-icon"
                                                    />
                                                </AppTooltip>
                                            </Link>
                                        </li>

                                        <li className="transfer-voting-and-color">
                                            <Link
                                                to={"#"}
                                                onClick={(e) => {
                                                    e.preventDefault();
                                                    setShowTransferVotingColorConfirm(
                                                        true,
                                                    );
                                                }}
                                            >
                                                <AppSVGIcon icon="arrow_range" />
                                                <span>
                                                    {t(
                                                        "app.map.view:button.transferVotingColor",
                                                    )}
                                                </span>
                                                <AppTooltip
                                                    id={
                                                        "Info-transfer-voting-color"
                                                    }
                                                    placement="top-start"
                                                    overlay={
                                                        <span className="paragraph--300">
                                                            {t(
                                                                "app.map.view:tooltip.voting.transferVotingColor",
                                                            )}
                                                        </span>
                                                    }
                                                >
                                                    <AppSVGIcon
                                                        icon="help"
                                                        size="xs"
                                                        className="voting-help-icon"
                                                    />
                                                </AppTooltip>
                                            </Link>
                                        </li>
                                    </>
                                )}
                            </>
                        )}
                    </>
                )}

                {[MapPageEnum.MAP_KANBAN].includes(pageType) && (
                    <>
                        <li className="archive-list">
                            <Link
                                to={"#"}
                                onClick={(e) => {
                                    e.preventDefault();
                                    navigate(`${location.pathname}/archive`);
                                }}
                            >
                                <AppSVGIcon icon="voting" />
                                {t("app.map.kanban.setting:label.archiveBoard")}
                            </Link>
                        </li>

                        <li className="archive-all">
                            <Link
                                to={"#"}
                                onClick={(e) => {
                                    e.preventDefault();
                                    handleArchiveAll();
                                }}
                            >
                                <AppSVGIcon icon="exit" />
                                {t("app.map.kanban.setting:label.archiveAll")}
                            </Link>
                        </li>

                        <li className="print">
                            <Link
                                to={"#"}
                                onClick={(e) => {
                                    e.preventDefault();
                                    handlePrintAll();
                                }}
                            >
                                <AppSVGIcon icon="print" />
                                <span>{t(TKey.Common.Button.Print)}</span>
                            </Link>
                        </li>
                    </>
                )}

                {[MapPageEnum.MAP_PORTFOLIO].includes(pageType) && (
                    <li className="print">
                        <Link
                            to={"#"}
                            onClick={(e) => {
                                e.preventDefault();
                                handlePrintAll();
                            }}
                        >
                            <AppSVGIcon icon="print" />
                            <span>{t(TKey.Common.Button.Print)}</span>
                        </Link>
                    </li>
                )}

                {[MapPageEnum.MAP_VIEW].includes(pageType) && (
                    <>
                        <li className="print">
                            <Link
                                to={"#"}
                                onClick={(e) => {
                                    e.preventDefault();
                                    handleMapDetailPrint(selectedPaperSize);
                                }}
                            >
                                <AppSVGIcon icon="print" />
                                <span>{t(TKey.Common.Button.Print)}</span>
                            </Link>
                        </li>
                    </>
                )}
            </AppActionDropDown>
        </>
    );

    const renderSortingMenu = () =>
        [MapPageEnum.MAP_PORTFOLIO, MapPageEnum.MAP_KANBAN].includes(
            pageType,
        ) &&
        onChange && (
            <AppActionDropDown
                isEllipsis={false}
                iconLeft="sort"
                text={`${t(TKey.Common.Button.Sort)}`}
            >
                <li className="manual">
                    <Link
                        to={"#"}
                        onClick={(e) => {
                            e.preventDefault();
                            onChange({
                                "order[ord]": "asc",
                            });
                            setSorting?.("app.map.view:button.manually");
                        }}
                    >
                        <AppSVGIcon icon="manually" />
                        {t("app.map.view:button.manually")}
                    </Link>
                </li>

                <li className="priority">
                    <Link
                        to={"#"}
                        onClick={(e) => {
                            e.preventDefault();
                            onChange({
                                "order[totalLevel]": "desc",
                            });
                            setSorting?.("app.map.view:button.priority");
                        }}
                    >
                        <AppSVGIcon icon="priority" />
                        {t("app.map.view:button.priority")}
                    </Link>
                </li>

                <li className="due-date">
                    <Link
                        to={"#"}
                        onClick={(e) => {
                            e.preventDefault();
                            onChange({
                                "order[dueDate]": "asc",
                            });
                            setSorting?.("app.map.view:button.dueDate");
                        }}
                    >
                        <AppSVGIcon icon="due-date" />
                        {t("app.map.view:button.dueDate")}
                    </Link>
                </li>
            </AppActionDropDown>
        );

    const renderZoomSlider = () => (
        <AppZoomSlider
            value={getZoom().value}
            min={range.min}
            max={range.max}
            step={range.step}
            onZoomIn={handleZoomIn}
            onZoomOut={handleZoomOut}
            onChange={handleSliderChange}
        />
    );

    const renderPrintLines = () => {
        if (!showPrintLines) {
            return <></>;
        }

        return (
            <div className="print-area">
                <div
                    className="print-area--a4"
                    style={{
                        width: selectedPaperWidth,
                        height: selectedPaperHeight,
                    }}
                >
                    <div className="print-area--a4--bg" />

                    <div className="print-area--a4--content">
                        <AppButton
                            variant="primary"
                            iconLeft="print"
                            size="sm"
                            onClick={() =>
                                handleMapDetailPrint(selectedPaperSize)
                            }
                        >
                            {t(TKey.Common.Button.Print)} ({selectedPaperSize})
                        </AppButton>

                        <AppButton
                            variant="secondary"
                            iconLeft="close"
                            size="sm"
                            onClick={() => setShowPrintLines(false)}
                        >
                            {t(TKey.Common.Button.Cancel)}
                        </AppButton>
                    </div>
                </div>
            </div>
        );
    };

    return (
        <div className="app-map-page-header gap-5">
            {loading && <AppLoader isFullScreen />}

            {renderPrintLines()}

            <AppConfirmModal
                show={resetVotingConfirm}
                icon="warning"
                title="app.map.view:resetVoting.confirm.modal.title"
                description="app.map.view:resetVoting.confirm.modal.description"
                onHide={() => setShowResetVotingConfirm(false)}
                nextAction={handleResetVoting}
            />

            <AppConfirmModal
                show={resetVotingColorConfirm}
                icon="warning"
                title="app.map.view:resetVotingColor.confirm.modal.title"
                description="app.map.view:resetVotingColor.confirm.modal.description"
                onHide={() => setShowResetVotingColorConfirm(false)}
                nextAction={handleResetVotingAndColor}
            />

            <AppConfirmModal
                show={transferVotingColorConfirm}
                icon="warning"
                title="app.map.view:transferVotingColor.confirm.modal.title"
                description="app.map.view:transferVotingColor.confirm.modal.description"
                onHide={() => setShowTransferVotingColorConfirm(false)}
                nextAction={handleTransferVotingColor}
            />

            {/* <AppConfirmModal
                show={!!printCutConfirm}
                icon="warning"
                title="app.map.view:printCut.confirm.modal.title"
                description="app.map.view:printCut.confirm.modal.description"
                onHide={() => setShowPrintCutConfirm(undefined)}
                nextAction={() => handlePrintAll(printCutConfirm)}
            /> */}

            <div className="app-map-page-header--left gap-3">
                <div className="app-map-page-header--left--header">
                    <div className="title">
                        <AppTooltip
                            id={name}
                            placement="bottom-end"
                            overlay={
                                <span className="paragraph--400">{name}</span>
                            }
                        >
                            <span className="paragraph--100 font-weight--bold">
                                {name}
                            </span>
                        </AppTooltip>
                    </div>

                    <AppButton
                        variant="secondary"
                        className="btn-square btn-sm"
                        onClick={() => setHeaderCollapsed(!headerCollapsed)}
                        disabled={loading}
                    >
                        <AppSVGIcon
                            icon={
                                headerCollapsed
                                    ? "chevron-down"
                                    : "chevron-right"
                            }
                        />
                    </AppButton>
                </div>
                <div
                    hidden={!headerCollapsed}
                    className="app-map-page-header--left--content gap-3"
                >
                    <AppTooltip
                        id={name}
                        placement="bottom-end"
                        overlay={
                            <div className="map-tooltip">
                                <span className="sub-title">{subTitle}</span>

                                <AppDisplayEditorContent
                                    className="description"
                                    content={description}
                                />
                            </div>
                        }
                    >
                        <div className="map-tooltip">
                            <span className="sub-title">{subTitle}</span>

                            <AppDisplayEditorContent
                                className="description"
                                content={description}
                            />
                        </div>
                    </AppTooltip>
                </div>
            </div>

            {![MapPageEnum.MAP_DASHBOARD].includes(pageType) && (
                <div className="app-map-page-header--center gap-2 mt-3">
                    {[MapPageEnum.MAP_VIEW].includes(pageType) &&
                        isVoting &&
                        isWrite &&
                        !showPrintLines && (
                            <>
                                <AppSwitch
                                    id="isVotingOpen"
                                    name="isVotingOpen"
                                    checked={isVotingOpen}
                                    label={t("app.map.view:label.voting")}
                                    onChange={() =>
                                        handleMapVotingSetting("isVotingOpen")
                                    }
                                />
                                <AppTooltip
                                    id={"Info-isVoting"}
                                    placement="top-start"
                                    overlay={
                                        <span className="paragraph--300">
                                            {t(
                                                "app.map.view:tooltip.voting.info",
                                            )}
                                        </span>
                                    }
                                >
                                    <AppSVGIcon
                                        icon="help"
                                        size="xs"
                                        className="voting-help-icon"
                                    />
                                </AppTooltip>
                            </>
                        )}

                    {[
                        MapPageEnum.MAP_VIEW,
                        MapPageEnum.MAP_PORTFOLIO,
                        MapPageEnum.MAP_KANBAN,
                    ].includes(pageType) &&
                        !showPrintLines &&
                        renderZoomSlider()}
                </div>
            )}

            {showToolbar && (
                <div className="app-map-page-header--right gap-3">
                    {[
                        MapPageEnum.MAP_PORTFOLIO,
                        MapPageEnum.MAP_KANBAN,
                    ].includes(pageType) && (
                        <AppSearchBar onSearchChange={handleSearch} />
                    )}

                    {renderSortingMenu()}

                    {[
                        MapPageEnum.MAP_VIEW,
                        MapPageEnum.MAP_PORTFOLIO,
                        MapPageEnum.MAP_KANBAN,
                    ].includes(pageType) &&
                        !showPrintLines && (
                            <AppButton
                                variant="secondary"
                                iconLeft="filter"
                                onClick={onFilterBtnClick}
                                badge={getBadgeNumber()}
                            >
                                {t(TKey.Common.Button.Filter)}
                            </AppButton>
                        )}

                    {renderActionMenu()}
                </div>
            )}
        </div>
    );
};
