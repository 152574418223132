import { FC } from "react";

import "./assets/scss/style.scss";

import { useTranslation } from "../../hooks";
import { AppContentModal, AppContentModalProps } from "../AppContentModal";

interface AppConfirmModalProps extends AppContentModalProps {
    description?: string;
}

export const AppConfirmModal: FC<AppConfirmModalProps> = ({
    description,
    className = "",
    ...props
}) => {
    // hooks
    const { t } = useTranslation();

    return (
        <AppContentModal
            {...props}
            className={`app-confirm-modal ${className}`}
        >
            {description && (
                <span className="description">{t(description)}</span>
            )}
        </AppContentModal>
    );
};
