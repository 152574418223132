import React, { FC, PropsWithChildren } from "react";
import { useNavigate } from "react-router-dom";

import { useTranslation } from "../../hooks";
import { AppButton, AppButtonProps } from "../AppButton";
import { TKey } from "../../utils";

type VariantType = AppButtonProps["variant"];

export interface AppFormActionsProps extends PropsWithChildren {
    isLoading?: boolean;
    isEditMode?: boolean;
    disabled?: boolean;
    disabledSumbit?: boolean;
    cancelLabel?: string;
    createLabel?: string;
    updateLabel?: string;
    className?: string;
    secondaryIconLeft?: string;
    secondaryIconRight?: string;
    primaryIconLeft?: string;
    primaryIconRight?: string;
    secondaryVariant?: VariantType;
    primaryVariant?: VariantType;
    backLink?: string;
    backAction?: () => void;
    nextAction?: () => void;
    hideSecondary?: boolean;
    withDefault?: boolean;
    position?: "start" | "center" | "end";
    backButton?: boolean;
    onBack?: () => void;
}

export const AppFormActions: FC<AppFormActionsProps> = ({
    isLoading,
    isEditMode,
    disabled,
    disabledSumbit,
    cancelLabel = TKey.Common.Button.Cancel,
    createLabel = TKey.Common.Button.Create,
    updateLabel = TKey.Common.Button.Update,
    secondaryIconLeft = "",
    secondaryIconRight = "",
    primaryIconLeft = "",
    primaryIconRight = "",
    secondaryVariant = "secondary",
    primaryVariant = "primary",
    className = "",
    backLink,
    backAction,
    nextAction,
    withDefault = true,
    hideSecondary,
    position = "end",
    children,
    backButton = false,
    onBack = () => {},
}): JSX.Element => {
    const { t } = useTranslation();
    const navigate = useNavigate();

    const btnDisabled = isLoading || disabled;
    const disableSubmit = isLoading || disabledSumbit;
    const positionClass =
        position !== "center" ? `d-md-flex justify-content-md-${position}` : "";

    return (
        <div
            className={`d-grid gap-3 justify-content-${position} ${positionClass} ${className}`}
        >
            {children}

            {withDefault && (
                <>
                    {!hideSecondary && (
                        <AppButton
                            variant={secondaryVariant}
                            onClick={() => {
                                if (backAction) {
                                    backAction();
                                } else if (backLink) {
                                    navigate(backLink);
                                } else {
                                    navigate(-1);
                                }
                            }}
                            disabled={btnDisabled}
                            iconLeft={secondaryIconLeft}
                            iconRight={secondaryIconRight}
                        >
                            {t(cancelLabel)}
                        </AppButton>
                    )}

                    {backButton && (
                        <AppButton
                            onClick={() => onBack()}
                            iconLeft="arrow-left"
                            variant="secondary"
                        >
                            {t("admin.subscription.checkout:button.back")}
                        </AppButton>
                    )}

                    <AppButton
                        variant={primaryVariant}
                        {...(nextAction
                            ? { onClick: nextAction }
                            : { type: "submit" })}
                        isLoading={isLoading}
                        disabled={btnDisabled || disableSubmit}
                        iconLeft={primaryIconLeft}
                        iconRight={primaryIconRight}
                    >
                        {isEditMode ? t(updateLabel) : t(createLabel)}
                    </AppButton>
                </>
            )}
        </div>
    );
};

// TODO:: use default withDefault=true everywhere in auth pages
