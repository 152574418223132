import { checkAndParseResponse } from "../helpers";
import { ListResponse } from "../models";

export const onFindAllResponse = <T>(
    data: unknown,
    acceptHydra: boolean,
): ListResponse<T> => {
    const listResponse = new ListResponse<T>();
    const parsedData = checkAndParseResponse(data);

    const itemsKey = acceptHydra ? "hydra:member" : "items";
    const totalItemsKey = acceptHydra ? "hydra:totalItems" : "totalItems";

    if (parsedData[itemsKey]) {
        listResponse.items = parsedData[itemsKey];
    }

    if (parsedData[totalItemsKey]) {
        listResponse.totalItems = parsedData[totalItemsKey];
    }

    return listResponse;
};
