import { FC, useEffect, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import { DropResult } from "react-beautiful-dnd";
import { useRecoilState, useResetRecoilState } from "recoil";

import "./assets/scss/style.scss";

import { ActionPostApi, MapApi, UserZoomApi } from "../../../apis";
import {
    ActionPost,
    ActionPostBoard,
    Map,
    PActionPost,
    PMap,
    PrimitiveObject,
    UserZoom,
} from "../../../models";
import {
    MapItemEnum,
    MapPageEnum,
    ModalTypeEnum,
    TKey,
    errorToast,
    isString,
    reorder,
} from "../../../utils";
import {
    AppAlertBar,
    AppCheckBox,
    AppContentModal,
    AppDisplayEditorContent,
    AppLoader,
} from "../../../components";
import { atomDrawer, atomDrawerType, atomZoomLevel } from "../../../atoms";
import { MapKanBanBoards } from "./MapKanBanBoards";
import { useAuthState, useMapAction, useTranslation } from "../../../hooks";
import { MapPageHeader, MapPortfolioFilter } from "../../components";
import { ACTION_POST_STATUS } from "../../../config";

export const MapKanBanPage: FC = () => {
    // hooks
    const { t } = useTranslation();
    const { id } = useParams();

    // context
    const {
        map,
        actionPosts,
        actionSetMap,
        actionSetMapKanBan,
        actionReSetMap,
        actionChangeActionPostOrder,
        actionUpdateActionPost,
        fetchResponsibleUsers,
    } = useMapAction();

    // ref
    const zoomRef = useRef<HTMLDivElement>(null);

    // atoms
    const [zoom] = useRecoilState(atomZoomLevel);
    const [drawer, setDrawer] = useRecoilState(
        atomDrawerType(ModalTypeEnum.PORTFOLIO_FILTER),
    );
    const resetDrawer = useResetRecoilState(atomDrawer);

    // state
    const { user: loggedinUser } = useAuthState();
    const [loading, setLoading] = useState(false);
    const [boards, setBoards] = useState<ActionPostBoard[]>([]);
    const [showContent, setShowContent] = useState<{
        questions: {
            key: string;
            value: string;
        }[];
        actionPostId: number;
        destinationBoardType: string;
        isAgreed: boolean;
    }>();
    const [sorting, setSorting] = useState("app.map.view:button.manually");
    const [userZoom, setUserZoom] = useState<UserZoom>();

    const fetchKanBan = async (
        params = {
            "order[ord]": "asc",
        } as PrimitiveObject,
    ) => {
        actionReSetMap();

        if (!id) {
            return;
        }

        setLoading(true);

        UserZoomApi.find(1, {
            "user.id": loggedinUser.id,
            "map.id": id,
        }).then(({ errorMessage, response }) => {
            if (!errorMessage && response && response?.totalItems > 0) {
                setUserZoom(response?.items[0] as UserZoom);
            }
        });

        MapApi.findById<Map>(+id).then(({ errorMessage, response }) => {
            if (errorMessage) {
                errorToast(errorMessage);
            } else if (response) {
                actionSetMap(response);
                fetchResponsibleUsers(response.workspace as string);

                if (response?.kanbanSettings?.length) {
                    setBoards(response.kanbanSettings);
                }

                const { actionPostDurations = [] } = response;
                const [durationShort] = actionPostDurations;

                ActionPostApi.find<ActionPost>(1, {
                    ...params,
                    "map.id": id,
                    pagination: false,
                    status: [
                        ACTION_POST_STATUS.ACTIONPOST_STATUS_BACKLOG,
                        ACTION_POST_STATUS.ACTIONPOST_STATUS_BOARD_1,
                        ACTION_POST_STATUS.ACTIONPOST_STATUS_INPROGRESS,
                        ACTION_POST_STATUS.ACTIONPOST_STATUS_BOARD_2,
                        ACTION_POST_STATUS.ACTIONPOST_STATUS_BOARD_3,
                        ACTION_POST_STATUS.ACTIONPOST_STATUS_BOARD_4,
                        ACTION_POST_STATUS.ACTIONPOST_STATUS_COMPLETED,
                    ],
                    "dueDate[before]": durationShort.dueDate,
                })
                    .then(({ response: res, errorMessage: errMsg }) => {
                        if (errMsg) {
                            errorToast(errMsg);
                        } else if (res !== null) {
                            actionSetMapKanBan(res.items);
                        }
                    })
                    .finally(() => setLoading(false));
            }
        });
    };

    useEffect(() => {
        fetchKanBan();
    }, [id]);

    const handleChangeOrderActionPost = (itemId: number, newOrd: number) => {
        ActionPostApi.changeOrder<ActionPost, PrimitiveObject>(itemId, {
            newOrd,
        }).then(({ response, errorMessage }) => {
            if (errorMessage) {
                errorToast(errorMessage);
            } else if (response !== null) {
                //
            }
        });
    };

    const handleEdit = (actionPostId: number, item: PActionPost) => {
        setLoading(true);

        ActionPostApi.update<ActionPost, PActionPost>(actionPostId, item)
            .then(({ response, errorMessage }) => {
                if (errorMessage) {
                    errorToast(errorMessage);
                } else if (response !== null) {
                    actionUpdateActionPost(response.id, response);
                }
            })
            .finally(() => setLoading(false));
    };

    const handleDragEnd = (result: DropResult) => {
        const { source, destination, draggableId, type } = result;

        if (!destination) return;

        if (
            destination.index === source.index &&
            destination.droppableId === source.droppableId
        ) {
            return;
        }

        const isDifferentParent =
            destination.droppableId !== source.droppableId;

        if (type === MapItemEnum.ACTION_TASK) {
            if (!map) {
                return;
            }

            if (
                destination.index === 0 ||
                destination.index === boards.length - 1
            ) {
                return;
            }

            setLoading(true);

            const updatedBoards = reorder<ActionPostBoard>(
                boards,
                source.index,
                destination.index,
            );

            MapApi.update<Map, PMap>(+map.id, {
                kanbanSettings: updatedBoards,
            })
                .then(({ response, errorMessage }) => {
                    if (errorMessage) {
                        errorToast(errorMessage);
                    } else if (response !== null) {
                        setBoards(response.kanbanSettings);
                    }
                })
                .finally(() => setLoading(false));
        } else if (type === MapItemEnum.ACTION_POST) {
            const [, , , actionPostId] = draggableId.split("-");

            if (isDifferentParent) {
                const [, sourceBoardType] = source.droppableId.split("-");
                const [, destinationBoardType] =
                    destination.droppableId.split("-"); // don't change

                // prevent going to same board (ex. from backlog to backlog)
                if (sourceBoardType === destinationBoardType) {
                    return;
                }

                const selectedBoard = boards.find(
                    (x) => x.type === destinationBoardType,
                );

                if (!selectedBoard) {
                    return;
                }

                const boardActionPosts = actionPosts.filter(
                    (x) => x.status === destinationBoardType,
                );

                // check whether move has limit restriction
                if (
                    !(selectedBoard.limit > boardActionPosts.length) &&
                    [
                        ACTION_POST_STATUS.ACTIONPOST_STATUS_INPROGRESS,
                        ACTION_POST_STATUS.ACTIONPOST_STATUS_BOARD_1,
                        ACTION_POST_STATUS.ACTIONPOST_STATUS_BOARD_2,
                        ACTION_POST_STATUS.ACTIONPOST_STATUS_BOARD_3,
                        ACTION_POST_STATUS.ACTIONPOST_STATUS_BOARD_4,
                    ].includes(destinationBoardType)
                ) {
                    errorToast(
                        `${t(
                            "app.map.kanban:move.error.message.limitExceed",
                        )} ${selectedBoard.name}`,
                    );
                    return;
                }

                // check whether move has some questions
                const questions = Object.entries(selectedBoard.questions)
                    .filter(
                        ([, value]) =>
                            value && isString(value) && value.length > 0,
                    )
                    .map(([key, value]) => ({
                        key,
                        value: value as string,
                    }));

                if (questions.length) {
                    setShowContent({
                        questions,
                        actionPostId: +actionPostId,
                        destinationBoardType,
                        isAgreed: false,
                    });
                    return;
                }

                handleEdit(+actionPostId, {
                    status: destinationBoardType,
                });
                return;
            }

            handleChangeOrderActionPost(+actionPostId, destination.index + 1);
            actionChangeActionPostOrder(source.index, destination.index);
        }
    };

    if (loading) {
        return <AppLoader isFullScreen />;
    }

    if (!map) {
        return <></>;
    }

    const renderQuestionModal = () => (
        <AppContentModal
            show={!!showContent?.questions.length}
            title="app.map.kanban:move.confirm.modal.title"
            onHide={() => setShowContent(undefined)}
            nextAction={() => {
                setShowContent(undefined);

                if (showContent) {
                    handleEdit(showContent.actionPostId, {
                        status: showContent.destinationBoardType,
                    });
                }
            }}
            disabled={!showContent?.isAgreed}
        >
            <div>
                <ul className="mb-4">
                    {showContent?.questions.map((question) => (
                        <li className="mb-2" key={question.key}>
                            <AppDisplayEditorContent
                                className="paragraph--300"
                                content={question.value}
                            />
                        </li>
                    ))}
                </ul>

                <AppCheckBox
                    id="isAgreed"
                    name="isAgreed"
                    label={t(
                        "app.map.kanban:move.confirm.modal.checkbox.title",
                    )}
                    value=""
                    onChange={(e) => {
                        setShowContent((prev) =>
                            prev
                                ? {
                                      ...prev,
                                      isAgreed: e.target.checked,
                                  }
                                : prev,
                        );
                    }}
                />
            </div>
        </AppContentModal>
    );

    return (
        <div className="map-kanban-page">
            <MapPortfolioFilter
                loading={loading}
                showForm={drawer.show}
                onHide={resetDrawer}
            />

            <MapPageHeader
                map={map}
                userZoom={userZoom}
                pageType={MapPageEnum.MAP_KANBAN}
                showToolbar
                onFilterBtnClick={() => setDrawer({ show: true, id: 0 })}
                onChange={fetchKanBan}
                sorting={sorting}
                setSorting={setSorting}
            />

            {renderQuestionModal()}

            {!actionPosts.length && !loading && (
                <AppAlertBar
                    size="lg"
                    variant="danger"
                    title={t(TKey.Common.Message.NoData)}
                    className="mt-1"
                />
            )}

            <div
                className="map-kanban-page--container"
                ref={zoomRef}
                style={{
                    zoom: zoom.zoomKanban,
                    // transform: `scale(${zoom.zoomKanban})`,
                    // transformOrigin: "top left",
                }}
                hidden={!actionPosts.length}
            >
                <MapKanBanBoards
                    onDragEnd={handleDragEnd}
                    onSettingChange={fetchKanBan}
                    boards={boards}
                />
            </div>
        </div>
    );
};
