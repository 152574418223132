import { FC, useState } from "react";
import { useNavigate } from "react-router-dom";
import { yupResolver } from "@hookform/resolvers/yup";
import { FormProvider, useForm } from "react-hook-form";

import { errorToast, setBackendViolations, successToast } from "../../../utils";
import { AuthApi } from "../../../apis";
import { useTranslation } from "../../../hooks";
import {
    AppButton,
    AppForm,
    AppFormActions,
    AppFormContent,
    AppFormGroup,
    AppFormInput,
    AppLink,
    AppLoader,
} from "../../../components";
import { schema } from "./schema";

interface ForgotPasswordForm {
    email: string;
}

export const ForgotPasswordPage: FC = () => {
    // hooks
    const { t } = useTranslation();
    const navigate = useNavigate();

    // state
    const [loading, setLoading] = useState(false);

    // form
    const methods = useForm<ForgotPasswordForm>({
        resolver: yupResolver(schema(t)),
    });
    const { handleSubmit, formState, reset, setError } = methods;

    const onSubmitHandler = (formData: ForgotPasswordForm) => {
        setLoading(true);

        AuthApi.forgotPassword<unknown, ForgotPasswordForm>(formData)
            .then(({ response, errorMessage, isInvalid, error }) => {
                if (isInvalid) {
                    setBackendViolations(error, setError);
                } else if (errorMessage) {
                    errorToast(errorMessage);
                } else if (response !== null) {
                    successToast(
                        t(
                            "user.forgot-password.form:verify-email.toast.success",
                        ),
                    );
                    navigate("/auth/forgot-password-confirmation");
                    reset();
                }
            })
            .finally(() => setLoading(false));
    };

    return (
        <FormProvider {...methods}>
            <AppForm onSubmit={handleSubmit(onSubmitHandler)}>
                {loading && <AppLoader isFullScreen />}

                <AppFormContent>
                    <AppFormInput
                        type="email"
                        id="email"
                        name="email"
                        placeholder={t(
                            "user.forgot-password.form:placeholder.email",
                        )}
                        autoComplete="email"
                        block
                    />

                    <AppFormGroup auto className="ms-auto mb-4">
                        <AppLink to={"/auth/login"} size="sm">
                            {t("user.forgot-password.form:link.login")}
                        </AppLink>
                    </AppFormGroup>
                </AppFormContent>

                <AppFormActions
                    withDefault={false}
                    position="center"
                    className="pt-2 px-5"
                >
                    <AppButton
                        type="submit"
                        isLoading={formState.isSubmitting || loading}
                        disabled={formState.isSubmitting || loading}
                        className="auth-button"
                    >
                        {t(
                            "user.forgot-password.form:button.sendVerificationLink",
                        )}
                    </AppButton>
                </AppFormActions>
            </AppForm>
        </FormProvider>
    );
};
