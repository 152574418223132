import { getRandomId, toPascalCase } from "../../utils";
import { PrimitiveObject } from "../custom";

export class ActionPostBoard {
    id: number;

    name: string;

    type: string;

    limit: number;

    isEnable: boolean;

    questions: PrimitiveObject;

    ord: number;

    constructor(
        type: string,
        {
            id = getRandomId(),
            name,
            limit = 10,
            isEnable = true,
            questions = {},
            ord = 1,
        }: PActionPostBoard = {},
    ) {
        this.id = id;
        this.name = name || toPascalCase(type);
        this.type = type;
        this.limit = limit;
        this.isEnable = isEnable;
        this.questions = questions;
        this.ord = ord;
    }

    toJSON() {
        return {
            id: this.id,
            name: this.name,
            type: this.type,
            limit: this.limit,
            isEnable: this.isEnable,
            questions: this.questions,
            ord: this.ord,
        };
    }

    static createForm(
        type: string,
        formData?: PActionPostBoard,
    ): PActionPostBoard {
        return new ActionPostBoard(type, formData).toJSON();
    }
}

export type PActionPostBoard = Partial<ActionPostBoard>;
