import { memo } from "react";
import { ColDef } from "ag-grid-community";

import { AppGridAction } from "./cell-renderer";
import { AppGridColDefParams } from "../../../../models";
import { getTotalLevel, getUserFullName } from "../../../../utils";

export interface AppKanBanArchiveGridColDefParams extends AppGridColDefParams {
    onPressMove: (id: number) => void;
    toShortDate: (date: number | Date | string) => string;
}

export const appGridColDef = ({
    onPressDelete,
    onPressMove,
    toShortDate,
}: AppKanBanArchiveGridColDefParams): ColDef[] => [
    { field: "title" },
    {
        field: "responsible",
        valueGetter: ({ data }) => getUserFullName(data?.responsible),
    },
    {
        field: "totalLevel",
        valueGetter: ({ data }) => getTotalLevel(data.totalLevel),
    },
    {
        field: "dueDate",
        valueGetter: ({ data }) => toShortDate(data.dueDate),
    },
    {
        sortable: false,
        cellRenderer: memo(AppGridAction),
        cellRendererParams: {
            onPressDelete,
            onPressMove,
        },
        maxWidth: 50,
        cellClass: "center-column",
    },
];
