import { ACTION_POST_DURATION, ACTION_POST_STATUS } from "../config";
import {
    ActionPostBoard,
    ActionPostDuration,
    ActionPostWeightLevel,
    PrimitiveObject,
} from "../models";
import { PortfolioSettingTabEnum } from "../utils";
import { useDateTime } from "./useDateTime";

type MapSettingType = {
    getDefaultActionPostWeightLevels: () => ActionPostWeightLevel[];
    getDefaultActionPostDurations: () => ActionPostDuration[];
    getDefaultActionPostQuestions: () => PrimitiveObject;
    getDefaultKanBanSettings: () => ActionPostBoard[];
};

export function useMapSetting(): MapSettingType {
    const { futureDate, toDbDateFormat } = useDateTime();

    const getDefaultActionPostWeightLevels = () =>
        [
            ActionPostWeightLevel.createForm({
                name: `${PortfolioSettingTabEnum.PRIORITIES} 1`,
                per: 25,
                ord: 1,
            }),
            ActionPostWeightLevel.createForm({
                name: `${PortfolioSettingTabEnum.PRIORITIES} 2`,
                per: 25,
                ord: 2,
            }),
            ActionPostWeightLevel.createForm({
                name: `${PortfolioSettingTabEnum.PRIORITIES} 3`,
                per: 25,
                ord: 3,
            }),
            ActionPostWeightLevel.createForm({
                name: `${PortfolioSettingTabEnum.PRIORITIES} 4`,
                per: 25,
                ord: 4,
            }),
            ActionPostWeightLevel.createForm({
                name: "",
                per: 0,
                ord: 5,
            }),
        ] as ActionPostWeightLevel[];

    const getDefaultActionPostDurations = () =>
        [
            ActionPostDuration.createForm(
                ACTION_POST_DURATION.ACTIONPOST_DURATION_SHORT,
                toDbDateFormat(futureDate(new Date(), 90)),
            ),
            ActionPostDuration.createForm(
                ACTION_POST_DURATION.ACTIONPOST_DURATION_MEDIUM,
                toDbDateFormat(futureDate(new Date(), 180)),
            ),
            ActionPostDuration.createForm(
                ACTION_POST_DURATION.ACTIONPOST_DURATION_LONG,
                toDbDateFormat(futureDate(new Date(), 360)),
            ),
        ] as ActionPostDuration[];

    const getDefaultActionPostQuestions = () => ({
        question1: "",
        question2: "",
        question3: "",
        question4: "",
    });

    const getDefaultKanBanSettings = () =>
        [
            ActionPostBoard.createForm(
                ACTION_POST_STATUS.ACTIONPOST_STATUS_BACKLOG,
                {
                    questions: getDefaultActionPostQuestions(),
                    ord: 1,
                },
            ),
            ActionPostBoard.createForm(
                ACTION_POST_STATUS.ACTIONPOST_STATUS_BOARD_1,
                {
                    questions: getDefaultActionPostQuestions(),
                    ord: 2,
                    name: "TODO",
                },
            ),
            ActionPostBoard.createForm(
                ACTION_POST_STATUS.ACTIONPOST_STATUS_INPROGRESS,
                {
                    questions: getDefaultActionPostQuestions(),
                    ord: 3,
                },
            ),
            ActionPostBoard.createForm(
                ACTION_POST_STATUS.ACTIONPOST_STATUS_BOARD_2,
                {
                    questions: getDefaultActionPostQuestions(),
                    isEnable: false,
                    ord: 4,
                },
            ),
            ActionPostBoard.createForm(
                ACTION_POST_STATUS.ACTIONPOST_STATUS_BOARD_3,
                {
                    questions: getDefaultActionPostQuestions(),
                    isEnable: false,
                    ord: 5,
                },
            ),
            ActionPostBoard.createForm(
                ACTION_POST_STATUS.ACTIONPOST_STATUS_BOARD_4,
                {
                    questions: getDefaultActionPostQuestions(),
                    isEnable: false,
                    ord: 6,
                },
            ),
            ActionPostBoard.createForm(
                ACTION_POST_STATUS.ACTIONPOST_STATUS_COMPLETED,
                {
                    questions: getDefaultActionPostQuestions(),
                    ord: 7,
                },
            ),
        ] as ActionPostBoard[];

    return {
        getDefaultActionPostWeightLevels,
        getDefaultActionPostDurations,
        getDefaultActionPostQuestions,
        getDefaultKanBanSettings,
    };
}
