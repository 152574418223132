import { DefaultValue, WrappedValue } from "recoil";

interface LocalStorageEffectParams<T> {
    setSelf: (
        param:
            | T
            | DefaultValue
            | Promise<T | DefaultValue>
            | WrappedValue<T>
            | ((param: T | DefaultValue) => T | DefaultValue | WrappedValue<T>),
    ) => void;
    onSet: (
        param: (
            newValue: T,
            oldValue: T | DefaultValue,
            isReset: boolean,
        ) => void,
    ) => void;
}

export const localStorageEffect =
    <T>(key: string) =>
    ({ setSelf, onSet }: LocalStorageEffectParams<T>) => {
        const savedValue = localStorage.getItem(key);
        if (savedValue !== null) {
            try {
                setSelf(JSON.parse(savedValue));
            } catch (error) {
                localStorage.removeItem(key);
            }
        }

        onSet((newValue, _, isReset) =>
            isReset
                ? localStorage.removeItem(key)
                : localStorage.setItem(key, JSON.stringify(newValue)),
        );
    };
