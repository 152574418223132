import i18next from "i18next";
import { useTranslation as UseTrans } from "react-i18next";

import { APP_TRANS_DEBUG_KEY } from "../utils";

type TranslationType = {
    t: (translationKey: string) => string;
    changeLanguage: (locale: string) => void;
};

export const useTranslation = (): TranslationType => {
    const { t } = UseTrans();

    const debugModeOn = localStorage.getItem(APP_TRANS_DEBUG_KEY);

    const translate = (translationKey: string) =>
        debugModeOn === "true" ? translationKey : t(translationKey);

    const changeLanguage = (locale: string) => i18next.changeLanguage(locale);

    return { t: translate, changeLanguage };
};
