import { FC } from "react";
import { useController, useFormContext } from "react-hook-form";

import {
    AppFormInputWrapper,
    AppFormInputWrapperProps,
} from "./AppFormInputWrapper";
import { AppSelect, AppSelectCreatable } from "./AppSelect";
import { AppSelectProps } from "./AppSelect/AppSelect";

interface AppFormSelectProps extends AppFormInputWrapperProps, AppSelectProps {
    isCreatable?: boolean;
}

export const AppFormSelect: FC<AppFormSelectProps> = ({
    isCreatable,
    ...props
}) => {
    // hooks
    const { control } = useFormContext();
    const { field } = useController({
        name: props.name,
        control,
    });

    const selectProps = {
        name: props.name,
        placeholder: props.placeholder,
        disabled: props.disabled,
        ref: field.ref,
        defaultValue: field.value,
        selectedOptions: field.value,
        options: props.options,
        loadInitialOptions: props.loadInitialOptions,
        fetchOptions: props.fetchOptions,
        handleOnChange: props.handleOnChange,
        isMulti: props.isMulti,
        isClearable: props.isClearable,
        customFilter: props.customFilter,
        inputClassName: props.inputClassName,
        isLoading: props.isLoading,
    };

    return (
        <AppFormInputWrapper {...props}>
            {isCreatable ? (
                <AppSelectCreatable
                    {...selectProps}
                    handleOnChange={(selected) => {
                        const { selectedItems } = selected;

                        if (props.handleOnChange) {
                            props.handleOnChange(selected);
                        }

                        field.onChange(selectedItems);
                    }}
                />
            ) : (
                <AppSelect
                    {...selectProps}
                    handleOnChange={(selected) => {
                        const { selectedValues } = selected;

                        if (props.handleOnChange) {
                            props.handleOnChange(selected);
                        }

                        if (props.isMulti) {
                            field.onChange(selectedValues);
                        } else {
                            field.onChange(selectedValues[0] ?? null);
                        }
                    }}
                />
            )}
        </AppFormInputWrapper>
    );
};
