import React, { FC } from "react";
import { FormCheck, FormCheckProps } from "react-bootstrap";

import "./assets/scss/style.scss";

import { AppSVGIcon } from "../../../components";
import { useTranslation } from "../../../hooks";

export const MapVoteCheckBox: FC<FormCheckProps> = ({
    className = "",
    color,
    title,
    label,
    ...props
}): JSX.Element => {
    // hooks
    const { t } = useTranslation();

    const inputStyle = {
        borderColor: props.value as string,
        backgroundColor: props.value as string,
    };

    return (
        <FormCheck
            {...props}
            type="checkbox"
            className={`map-vote-checkbox ${className} mx-4`}
        >
            <FormCheck.Input
                type="checkbox"
                isValid
                style={inputStyle}
                onChange={props.onChange}
                checked={props.checked}
                value={props.value}
                disabled={props.disabled}
            />

            <FormCheck.Label
                htmlFor={props.id}
                className="map-vote-checkbox--label"
            >
                <div className="label-container">
                    <AppSVGIcon icon={label as string} size="lg" />
                    <span className="title">{t(title as string)}</span>
                </div>
            </FormCheck.Label>
        </FormCheck>
    );
};
