import { FC, useEffect, useRef, useState } from "react";
import { FormProvider, useFieldArray, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useRecoilState, useResetRecoilState } from "recoil";
import { DropResult, DraggableProvided } from "react-beautiful-dnd";

import "./assets/scss/style.scss";

import { atomActiveClient, atomDrawer, atomDrawerType } from "../../../atoms";
import { useAuthState, useRoles, useTranslation } from "../../../hooks";
import {
    getUserFullName,
    errorToast,
    reorder,
    setBackendViolations,
    successToast,
    validationMessage,
    setCustomViolation,
    validation,
    ModalTypeEnum,
} from "../../../utils";
import {
    AnyType,
    OptionType,
    PWorkspace,
    PrimitiveObject,
    User,
    Workspace,
    WorkspacePermission,
} from "../../../models";
import { ClientApi, UserApi, WorkspaceApi } from "../../../apis";
import {
    AppButton,
    AppDraggableList,
    AppForm,
    AppFormCheckBox,
    AppFormContent,
    AppFormGroup,
    AppFormInput,
    AppFormLabel,
    AppFormMessage,
    AppFormModal,
    AppLoader,
    AppPageHeader,
    AppSVGIcon,
    AppSelect,
} from "../../../components";
import { schema } from "./schema";
import { PERMISSION, cancelAllPrevRequest } from "../../../config";
import { Canceler } from "axios";
import { WorkspaceItem } from "../../components";
import { UserInviteModal } from "../../../Admin/components";

export const WorkspacePage: FC = () => {
    // hooks
    const { t } = useTranslation();
    const { isSuperAdmin, isGrantedClientAdmin } = useRoles();
    const { user } = useAuthState();

    // ref
    const cancelTokenSourcesRef = useRef<Canceler[]>([]);
    const selectRef = useRef(null);

    // atoms
    const [activeClient] = useRecoilState(atomActiveClient);
    const [drawer, setDrawer] = useRecoilState(
        atomDrawerType(ModalTypeEnum.WORKSPACE_ADD_EDIT),
    );
    const resetDrawer = useResetRecoilState(atomDrawer);

    // state
    const [editId, setEditId] = useState(0);
    const [loading, setLoading] = useState(false);
    const [data, setData] = useState<Workspace[]>([]);
    const [userOptions, setUserOptions] = useState<OptionType[]>([]);
    const [selectedUser, setSelectedUser] = useState<string>();
    const [showInviteModal, setShowInviteModal] = useState(0);

    // form
    const methods = useForm({
        resolver: yupResolver(schema(t)),
    });
    const {
        handleSubmit,
        formState,
        reset,
        setError,
        control,
        trigger,
        watch,
    } = methods;
    const { append, remove, fields } = useFieldArray({
        control,
        name: "workspacePermissions",
    });

    // local state
    const workspacePermissionsValue = watch("workspacePermissions");
    const workspaceUsersId =
        workspacePermissionsValue?.map((x) => x.user) || [];

    const fetchWorkspaces = (search = "") => {
        if (!activeClient) {
            return;
        }

        cancelAllPrevRequest(cancelTokenSourcesRef.current);
        setLoading(true);

        WorkspaceApi.find<Workspace>(
            1,
            {
                pagination: false,
                "client.id": activeClient.id,
                "order[ord]": "asc",
                ...(search ? { name: search } : {}),
            },
            (c) => {
                cancelTokenSourcesRef.current.push(c);
            },
        )
            .then(({ response, errorMessage }) => {
                if (errorMessage) {
                    errorToast(errorMessage);
                } else if (response !== null) {
                    setData(response.items);
                }
            })
            .finally(() => setLoading(false));
    };

    useEffect(() => {
        fetchWorkspaces();
        resetDrawer();
    }, [activeClient?.id]);

    const onSubmitHandler = (formData: unknown) => {
        if (!activeClient) {
            return;
        }

        const payload = editId
            ? (formData as PWorkspace)
            : Workspace.createForm(
                  ClientApi.toResourceUrl(activeClient.id),
                  formData as PWorkspace,
              );

        if (
            !payload.workspacePermissions?.some(
                (x) => x.permission === PERMISSION.PERMISSION_OWNER,
            )
        ) {
            setCustomViolation(
                "workspacePermissions",
                t(
                    "app.workspace.item:message.validation.atLeastOneOwnerPermission",
                ),
                setError,
                false,
            );
            return;
        }

        setLoading(true);

        WorkspaceApi.createOrUpdate<Workspace, PWorkspace>(editId, payload)
            .then(({ response, errorMessage, isInvalid, error }) => {
                if (isInvalid) {
                    setBackendViolations(error, setError);
                } else if (errorMessage) {
                    errorToast(errorMessage);
                } else if (response !== null) {
                    if (editId) {
                        successToast(
                            t("app.workspace.list:update.toast.success"),
                        );
                    } else {
                        successToast(
                            t("app.workspace.list:create.toast.success"),
                        );
                    }
                    reset();
                    resetDrawer();
                    setEditId(0);
                    fetchWorkspaces();
                }
            })
            .finally(() => setLoading(false));
    };

    const handleChangeOrder = (itemId: number, newOrd: number) => {
        WorkspaceApi.changeOrder<Workspace, PrimitiveObject>(itemId, {
            newOrd,
        }).then(({ response, errorMessage }) => {
            if (errorMessage) {
                errorToast(errorMessage);
            } else if (response !== null) {
                successToast(t("app.workspace.list:changeOrder.info.message"));
            }
        });
    };

    const handleDragEnd = (result: DropResult) => {
        const { source, destination, draggableId } = result;

        if (!destination) return;

        if (destination.index === source.index) return;

        const [, workspaceId] = draggableId.split("-");

        handleChangeOrder(+workspaceId, destination.index + 1);
        setData(reorder<Workspace>(data, source.index, destination.index));
    };

    const fetchUsers = () => {
        if (!activeClient) {
            return;
        }

        setLoading(true);

        UserApi.getForWorkspace<User>(1, {
            pagination: false,
        })
            .then(({ response, errorMessage }) => {
                if (errorMessage) {
                    errorToast(errorMessage);
                } else if (response !== null) {
                    setUserOptions(
                        response.items
                            // .filter((x) => x.id !== user.id)
                            .map((item) => ({
                                label: getUserFullName(item) || item.email,
                                value: UserApi.toResourceUrl(item.id),
                            })),
                    );
                }
            })
            .finally(() => setLoading(false));
    };

    useEffect(() => {
        if (drawer.show) {
            fetchUsers();

            if (!editId && user && !isSuperAdmin) {
                append(
                    WorkspacePermission.createForm(
                        UserApi.toResourceUrl(user.id),
                        PERMISSION.PERMISSION_OWNER,
                    ),
                );
            }
        }
    }, [drawer.show]);

    useEffect(() => {
        const subscription = watch((_, { name }) => {
            if (name && formState.dirtyFields) {
                trigger(name);
            }
        });
        return () => subscription.unsubscribe();
    }, [watch, trigger, formState.dirtyFields]);

    const addUser = () => {
        if (selectedUser && !workspaceUsersId.includes(selectedUser)) {
            append(WorkspacePermission.createForm(selectedUser));
        }

        setSelectedUser(undefined);
        (selectRef?.current as AnyType)?.clearValue();
    };

    const renderUsersWithPermission = () => (
        <div className="user-permission">
            {fields.map((field, index) => {
                const userFullName = userOptions.find(
                    (x) => x.value === field.user,
                )?.label;

                const isCreatorUser =
                    field.user === UserApi.toResourceUrl(user.id);

                return (
                    <div key={field.id} className="user-permission--item">
                        <div className="user-permission--item--content">
                            <span>{userFullName}</span>
                        </div>

                        <div className="user-permission--item--action">
                            <AppFormCheckBox
                                type="radio"
                                id={`workspacePermissions[${index}].permission`}
                                name={`workspacePermissions[${index}].permission`}
                                inputOptions={[
                                    {
                                        label: PERMISSION.PERMISSION_OWNER,
                                        value: PERMISSION.PERMISSION_OWNER,
                                    },
                                    {
                                        label: PERMISSION.PERMISSION_READ,
                                        value: PERMISSION.PERMISSION_READ,
                                        disabled: isCreatorUser,
                                    },
                                    {
                                        label: PERMISSION.PERMISSION_WRITE,
                                        value: PERMISSION.PERMISSION_WRITE,
                                        disabled: isCreatorUser,
                                    },
                                ]}
                                auto
                                isInline
                            />

                            <AppButton
                                variant="secondary"
                                className="btn-square btn-sm"
                                onClick={() => remove(index)}
                                disabled={isCreatorUser}
                            >
                                <AppSVGIcon icon="delete" />
                            </AppButton>
                        </div>
                    </div>
                );
            })}
        </div>
    );

    const renderForm = () => (
        <FormProvider {...methods}>
            <AppForm className="workspace-page--form">
                <AppFormContent>
                    <AppFormInput
                        id="name"
                        name="name"
                        label={t("app.workspace.form:label.name")}
                        placeholder={t("app.workspace.form:placeholder.name")}
                        block
                        maxCount={validation.title.max}
                    />

                    <AppFormGroup block>
                        <AppFormLabel
                            controlId="workspacePermissions"
                            label={t("app.workspace.form:label.permission")}
                            required
                        />

                        <div className="d-flex justify-content-between gap-3">
                            <AppSelect
                                ref={selectRef}
                                id="user"
                                name="user"
                                placeholder={t(
                                    "app.workspace.form:placeholder.user",
                                )}
                                options={userOptions.filter(
                                    (x) =>
                                        !workspaceUsersId.includes(
                                            x.value as string,
                                        ),
                                )}
                                handleOnChange={({ selectedValues }) => {
                                    const [selectedOption] = selectedValues;

                                    if (selectedOption) {
                                        setSelectedUser(
                                            selectedOption as string,
                                        );
                                    }
                                }}
                                inputClassName="flex-grow-1"
                                isClearable
                            />

                            <AppButton
                                variant="secondary"
                                className="btn-square mt-auto"
                                onClick={() => addUser()}
                                disabled={!selectedUser}
                            >
                                <AppSVGIcon icon="plus" />
                            </AppButton>
                        </div>
                    </AppFormGroup>

                    <AppFormGroup className="my-4" block>
                        {renderUsersWithPermission()}

                        <AppFormMessage
                            errorMessage={validationMessage(
                                "workspacePermissions",
                                formState,
                            )}
                        />
                    </AppFormGroup>
                </AppFormContent>
            </AppForm>
        </FormProvider>
    );

    const renderWorkspaceItem = (
        workspace: Workspace,
        provided: DraggableProvided,
    ) => (
        <WorkspaceItem
            workspace={workspace}
            provided={provided}
            onEdit={(response) => {
                reset({
                    name: response.name,
                    workspacePermissions: response.workspacePermissions.map(
                        (x) => ({
                            user: x.user,
                            permission: x.permission,
                        }),
                    ),
                });
                setEditId(response.id);
                setTimeout(
                    () => setDrawer({ show: true, id: response.id }),
                    100,
                );
            }}
            onDelete={fetchWorkspaces}
            onInvite={setShowInviteModal}
        />
    );

    const renderView = () => (
        <AppDraggableList<Workspace>
            droppableId="workspace"
            data={data}
            onDragEnd={handleDragEnd}
            renderItem={renderWorkspaceItem}
            renderWrapper={(children, providedMain) => (
                <div
                    className="workspace-page--items"
                    ref={providedMain.innerRef}
                    {...providedMain.droppableProps}
                >
                    {children}
                </div>
            )}
        />
    );

    return (
        <div className="workspace-page">
            {loading && <AppLoader isFullScreen />}

            <AppPageHeader
                title="app.workspace.list:header.title"
                onCreateBtnClick={() => {
                    reset({
                        name: undefined,
                        workspacePermissions: [],
                    });
                    setDrawer({ show: true, id: 0 });
                }}
                onSearchChange={fetchWorkspaces}
                showToolbar={!!activeClient}
                isGrantedControl={isGrantedClientAdmin}
            />

            <AppFormModal
                show={drawer.show}
                icon="description"
                title="app.workspace.form:modal.title"
                isDrawer
                onSubmit={handleSubmit(onSubmitHandler)}
                onHide={() => {
                    resetDrawer();
                    setEditId(0);
                }}
                isLoading={formState.isSubmitting || loading}
                // disabled={!formState.isValid || !activeClient} // TODO:: to convert button disable all place later
                disabled={!activeClient}
                isEditMode={!!editId}
                disabledSumbit={!formState.isValid}
            >
                {renderForm()}
            </AppFormModal>

            <UserInviteModal
                showForm={showInviteModal > 0}
                onHide={() => setShowInviteModal(0)}
                inviteWorkspace={showInviteModal}
            />

            {renderView()}
        </div>
    );
};
