import { PrimitiveObject } from "../custom";
import { ActionPost } from "./ActionPost";
import { BaseEntity } from "./BaseEntity";
import { Client } from "./Client";
import { Map } from "./Map";
import { User } from "./User";

export class ActionTask extends BaseEntity {
    client: string | Client;

    map: string | Map;

    user: string | User | null;

    title: string;

    description: string;

    additionalInfo: PrimitiveObject;

    documents: Document[];

    ord: number;

    actionPosts?: ActionPost[];

    constructor(
        client: string | Client,
        map: string | Map,
        {
            user = null,
            title = "",
            description = "",
            additionalInfo = {},
            documents = [],
            ord = 1,
        }: PActionTask = {},
    ) {
        super();
        this.client = client;
        this.map = map;
        this.user = user;
        this.title = title;
        this.description = description;
        this.additionalInfo = additionalInfo;
        this.documents = documents;
        this.ord = ord;
    }

    toJSON(addExtraData = false) {
        return {
            ...super.toJSON(addExtraData),
            client: this.client,
            map: this.map,
            user: this.user,
            title: this.title,
            description: this.description,
            additionalInfo: this.additionalInfo,
            documents: this.documents,
        };
    }

    static createForm(
        client: string,
        map: string,
        formData: PActionTask,
    ): PActionTask {
        return new ActionTask(client, map, formData).toJSON();
    }
}

export type PActionTask = Partial<ActionTask>;
