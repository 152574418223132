import { SimpleObject } from "../custom";
import { BaseEntity } from "./BaseEntity";
import { Client } from "./Client";
import { Map } from "./Map";
import { SubTask } from "./SubTask";
import { Tag } from "./Tag";

export class TaskPost extends BaseEntity {
    client: string | Client;

    map: string | Map;

    subTask: string | SubTask;

    title: string;

    description: string;

    documents: Document[];

    tags: Tag[];

    ord: number;

    color: string;

    colorVote: string;

    colorOverride: string | null;

    colorChange: string;

    voteDetails: SimpleObject<number>;

    isTag?: boolean | null;

    constructor(
        client: string | Client,
        map: string | Map,
        subTask: string | SubTask,
        {
            title = "",
            description = "",
            documents = [],
            tags = [],
            ord = 1,
            color = "",
            colorVote = "",
            colorOverride = "",
            colorChange = "",
            voteDetails = {},
            isTag = false,
        }: PTaskPost = {},
    ) {
        super();
        this.client = client;
        this.map = map;
        this.subTask = subTask;
        this.title = title;
        this.description = description;
        this.documents = documents;
        this.tags = tags;
        this.ord = ord;
        this.color = color;
        this.colorVote = colorVote;
        this.colorChange = colorChange;
        this.colorOverride = colorOverride;
        this.voteDetails = voteDetails;
        this.isTag = isTag;
    }

    toJSON(addExtraData = false) {
        return {
            ...super.toJSON(addExtraData),
            client: this.client,
            map: this.map,
            subTask: this.subTask,
            title: this.title,
            description: this.description,
            documents: this.documents,
            tags: this.tags,
            color: this.color,
            colorVote: this.colorVote,
            colorOverride: this.colorOverride,
            colorChange: this.colorChange,
            isTag: this.isTag,
        };
    }

    static createForm(
        client: string,
        map: string,
        subTask: string,
        formData: PTaskPost,
    ): PTaskPost {
        return new TaskPost(client, map, subTask, formData).toJSON();
    }
}

export type PTaskPost = Partial<TaskPost>;
