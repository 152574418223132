import { FC, ReactNode } from "react";
import { AppFormLayoutProps } from "./AppForm";
import { useGridHelper } from "../../hooks";

interface AppFormGroupProps extends AppFormLayoutProps {
    className?: string;
    children?: ReactNode;
}

export const AppFormGroup: FC<AppFormGroupProps> = ({
    children,
    className = "",
    ...props
}) => {
    const { getColumnClasses } = useGridHelper();
    const colClasses = getColumnClasses(props);

    return <div className={`${colClasses} ${className}`}>{children}</div>;
};
