import { FC, PropsWithChildren } from "react";

interface AppFormContentProps extends PropsWithChildren {
    className?: string;
}

export const AppFormContent: FC<AppFormContentProps> = ({
    children,
    className = "",
}) => <div className={`row g-4 gx-5 ${className}`}>{children}</div>;
