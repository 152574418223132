import { API_HOST, ENV, STORAGE } from "../config";
import { FileTypeInfo } from "../models";

const { STORAGE_S3 } = STORAGE;
const { APP_STORAGE, APP_S3_NAME, APP_S3_REGION, APP_S3_ENDPOINT } = ENV;

type BuildAssetPathType = {
    getBasePath: () => string;
    buildFilePath: (fileName?: string, placeHolder?: string) => string;
};

export function useBuildAssetPath(
    fileInfo: FileTypeInfo,
    isThumbnail?: boolean,
): BuildAssetPathType {
    const getBasePath = () => {
        let { path } = fileInfo;
        const { thumbnail } = fileInfo;

        if (isThumbnail && thumbnail?.path) {
            path = thumbnail.path;
        }

        let basePath = `${API_HOST}/uploads`;

        if (APP_STORAGE === STORAGE_S3) {
            if (APP_S3_ENDPOINT) {
                basePath = `${APP_S3_ENDPOINT}/${APP_S3_NAME}`;
            } else {
                basePath = `https://${APP_S3_NAME}.s3.${APP_S3_REGION}.amazonaws.com`;
            }
        }

        if (path) {
            basePath = `${basePath}/${path}`;
        }

        return basePath;
    };

    const buildFilePath = (fileName?: string, placeHolder?: string) => {
        if (!fileName) {
            return placeHolder || "";
        }

        return `${getBasePath()}/${fileName}`;
    };

    return { getBasePath, buildFilePath };
}
