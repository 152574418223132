import { ACTION_POST_SIZE, ACTION_POST_STATUS } from "../../config";
import { PrimitiveObject } from "../custom";
import { ActionTask } from "./ActionTask";
import { BaseEntity } from "./BaseEntity";
import { Client } from "./Client";
import { Map } from "./Map";
import { Tag } from "./Tag";
import { User } from "./User";

export class ActionPost extends BaseEntity {
    client: string | Client;

    map: string | Map;

    actionTask: string | ActionTask;

    title: string;

    description: string;

    dueDate: string;

    size: string;

    status: string;

    weightLevelValues: PrimitiveObject[];

    tags: Tag[];

    documents: Document[];

    isTag: boolean;

    responsible: string | User | null;

    linkActionPost: string | ActionPost | null;

    fromLinkActionPosts: string[] | ActionPost[];

    additionalInfo: PrimitiveObject;

    progressLevel: string | null;

    ord: number;

    totalLevel?: string;

    durationType?: string;

    constructor(
        client: string | Client,
        map: string | Map,
        actionTask: string | ActionTask,
        {
            title = "",
            description = "",
            dueDate = "",
            size = ACTION_POST_SIZE.ACTIONPOST_SIZE_MEDIUM,
            status = ACTION_POST_STATUS.ACTIONPOST_STATUS_BACKLOG,
            weightLevelValues = [],
            tags = [],
            isTag = false,
            documents = [],
            responsible = null,
            linkActionPost = null,
            fromLinkActionPosts = [],
            additionalInfo = {},
            progressLevel = null,
            ord = 1,
        }: PActionPost = {},
    ) {
        super();
        this.client = client;
        this.map = map;
        this.actionTask = actionTask;
        this.title = title;
        this.description = description;
        this.dueDate = dueDate;
        this.size = size;
        this.status = status;
        this.weightLevelValues = weightLevelValues;
        this.tags = tags;
        this.isTag = isTag;
        this.documents = documents;
        this.responsible = responsible;
        this.linkActionPost = linkActionPost;
        this.additionalInfo = additionalInfo;
        this.progressLevel = progressLevel;
        this.fromLinkActionPosts = fromLinkActionPosts;
        this.ord = ord;
    }

    toJSON(addExtraData = false) {
        return {
            ...super.toJSON(addExtraData),
            client: this.client,
            map: this.map,
            actionTask: this.actionTask,
            title: this.title,
            description: this.description,
            dueDate: this.dueDate,
            size: this.size,
            weightLevelValues: this.weightLevelValues,
            tags: this.tags,
            isTag: this.isTag,
            documents: this.documents,
            responsible: this.responsible,
            additionalInfo: this.additionalInfo,
        };
    }

    static createForm(
        client: string,
        map: string,
        actionTask: string,
        formData: PActionPost,
    ): PActionPost {
        return new ActionPost(client, map, actionTask, formData).toJSON();
    }
}

export type PActionPost = Partial<ActionPost>;
