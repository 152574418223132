import { FC, Fragment } from "react";

import "./assets/scss/style.scss";

import { AppSVGIcon } from "../AppSVGIcon";
import { AppTabItem } from "../../models";
import { useTranslation } from "../../hooks";

interface AppTabsProps {
    items: AppTabItem[];
    className?: string;
    onClick: (item: AppTabItem) => void;
    isActiveTab: (item: AppTabItem) => boolean;
}

export const AppTabs: FC<AppTabsProps> = ({
    items,
    className = "",
    onClick,
    isActiveTab,
}) => {
    // hooks
    const { t } = useTranslation();

    return (
        <div className={`app-tabs ${className}`}>
            {items.map((item) => (
                <Fragment key={item.title}>
                    <div
                        className={`app-tabs--tab ${
                            isActiveTab(item) ? "active" : ""
                        }`}
                        onClick={() => onClick(item)}
                    >
                        {item.icon && (
                            <AppSVGIcon icon={item.icon} className="tab-icon" />
                        )}

                        <span className="tab-text">{t(item.title)}</span>
                    </div>

                    {/* <div className="app-tabs--separator" /> */}
                </Fragment>
            ))}
        </div>
    );
};
