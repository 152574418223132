import { FC, useEffect, useState } from "react";
import { useRecoilState, useResetRecoilState } from "recoil";
import { useNavigate, useParams } from "react-router-dom";
import { FormProvider, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";

import "./assets/scss/style.scss";

import { Invoice } from "../../../models";
import { ClientApi, InvoiceApi } from "../../../apis";
import {
    ModalTypeEnum,
    TKey,
    errorToast,
    setBackendViolations,
    successToast,
} from "../../../utils";
import { atomActiveClient, atomDrawer, atomDrawerType } from "../../../atoms";
import {
    AppConfirmModal,
    AppForm,
    AppFormCheckBox,
    AppFormContent,
    AppFormDatePicker,
    AppFormInput,
    AppFormModal,
    AppGrid,
    AppLoader,
    AppPageHeader,
} from "../../../components";
import { appGridColDef } from "./app-grid";
import {
    useDateTime,
    useDownloadFile,
    useRoles,
    useTranslation,
} from "../../../hooks";
import { schema } from "./schema";

interface InvoiceForm {
    noOfMonths: number;
    noOfUsers: number;
    startDate: string;
}

export const SubscriptionBillingPage: FC = () => {
    // hooks
    const { t } = useTranslation();
    const { downloadLink } = useDownloadFile();
    const { id } = useParams();
    const { isSuperAdmin } = useRoles();
    const navigate = useNavigate();
    const { toDbDateFormat } = useDateTime();

    // atoms
    const [activeClient] = useRecoilState(atomActiveClient);
    const [drawer, setDrawer] = useRecoilState(
        atomDrawerType(ModalTypeEnum.INVOICE_ADD),
    );
    const resetDrawer = useResetRecoilState(atomDrawer);

    // state
    const [loading, setLoading] = useState(false);
    const [data, setData] = useState<Invoice[]>([]);
    const [disabled, setDisabled] = useState(true);
    const [currentInvoice, setCurrentInvoice] = useState<Invoice>();
    const [showConfirm, setShowConfirm] = useState(0);

    // form
    const methods = useForm({
        resolver: yupResolver(schema(t)),
    });
    const {
        handleSubmit,
        formState,
        trigger,
        watch,
        reset,
        setError,
        setValue,
    } = methods;

    const [noOfMonths, noOfUsers] = watch(["noOfMonths", "noOfUsers"]);

    useEffect(() => {
        if (currentInvoice) {
            setValue("noOfMonths", currentInvoice.noOfMonths);
        }
        if (noOfUsers) {
            trigger(["noOfMonths", "noOfUsers"]);
        }
        setDisabled(!formState.isDirty || !formState.isValid);
    }, [
        formState.isDirty,
        formState.isValid,
        noOfMonths,
        noOfUsers,
        currentInvoice,
    ]);

    useEffect(() => {
        if (id && !isSuperAdmin) {
            navigate(-1);
        }
    }, [id]);

    const fetchInvoices = () => {
        const clientId = isSuperAdmin ? id : activeClient?.id;

        if (!clientId) {
            return;
        }

        setLoading(true);

        InvoiceApi.find<Invoice>(1, {
            pagination: false,
            "client.id": clientId,
            "order[id]": "desc",
            // status: ["PAID", "FAILED"],
            // "exists[parent]": false,
        })
            .then(({ response, errorMessage }) => {
                if (errorMessage) {
                    errorToast(errorMessage);
                } else if (response !== null) {
                    setData(response.items);
                }
            })
            .finally(() => setLoading(false));
    };

    const fetchCurrentInvoice = () => {
        const clientId = isSuperAdmin ? id : activeClient?.id;

        if (!clientId) {
            return;
        }

        setLoading(true);

        ClientApi.getCurrentInvoice<Invoice>(Number(clientId))
            .then(({ response, errorMessage }) => {
                if (errorMessage) {
                    errorToast(errorMessage);
                } else if (response !== null) {
                    setCurrentInvoice(response);
                    if (response) {
                        setValue("noOfMonths", response.noOfMonths);
                    }
                }
            })
            .finally(() => setLoading(false));
    };

    useEffect(() => {
        fetchCurrentInvoice();
        fetchInvoices();
    }, []);

    const onSubmitHandler = (formData: InvoiceForm) => {
        if (!id) {
            return;
        }

        setLoading(true);

        if (formData.startDate) {
            formData.startDate = toDbDateFormat(formData.startDate);
        }

        InvoiceApi.create<Invoice, unknown>({
            client: ClientApi.toResourceUrl(+id),
            ...formData,
        })
            .then(({ response, errorMessage, isInvalid, error }) => {
                if (isInvalid) {
                    setBackendViolations(error, setError);
                } else if (errorMessage) {
                    errorToast(errorMessage);
                } else if (response !== null) {
                    reset();
                    resetDrawer();
                    fetchCurrentInvoice();
                    fetchInvoices();
                }
            })
            .finally(() => setLoading(false));
    };

    const handleDownload = (invoiceId: number) => {
        InvoiceApi.pdfDownload<Invoice>(invoiceId).then(
            ({ errorMessage, response }) => {
                if (errorMessage) {
                    errorToast(errorMessage);
                } else if (response !== null) {
                    downloadLink({
                        name: `${t(
                            "admin.subscription.billing:column.id",
                        )}_#${invoiceId}.pdf`,
                        type: "file/pdf",
                        file: response,
                    });
                }
            },
        );
    };

    const handleChangeStatus = (invoiceId: number, status: string) => {
        InvoiceApi.changeStatus(invoiceId, {
            status: status === "CREATED" ? "PAID" : "CREATED",
        }).then(({ response, errorMessage }) => {
            if (errorMessage) {
                errorToast(errorMessage);
            } else if (response !== null) {
                successToast(
                    t("admin.subscription.billing:changeStatus.toast.success"),
                );
                fetchCurrentInvoice();
                fetchInvoices();
            }
        });
    };

    const handleDelete = (invoiceId: number) => {
        InvoiceApi.deleteById(invoiceId).then(({ errorMessage }) => {
            if (errorMessage) {
                errorToast(errorMessage);
            } else {
                successToast(
                    t("admin.subscription.billing:delete.toast.success"),
                );
                setShowConfirm(0);
                fetchCurrentInvoice();
                fetchInvoices();
            }
        });
    };

    const renderForm = () => (
        <FormProvider {...methods}>
            <AppForm>
                <AppFormContent>
                    <div style={{ display: currentInvoice ? "none" : "block" }}>
                        <AppFormCheckBox
                            type="radio"
                            id="noOfMonths"
                            name="noOfMonths"
                            label={t("admin.invoice.form:label.noOfMonths")}
                            inputOptions={[
                                {
                                    label: "Quarterly",
                                    value: 3,
                                },
                                {
                                    label: "Annual",
                                    value: 12,
                                },
                            ]}
                            auto
                            isInline
                        />
                    </div>

                    <AppFormInput
                        type="number"
                        id="noOfUsers"
                        name="noOfUsers"
                        label={
                            currentInvoice
                                ? t("admin.invoice.form:label.addUsers")
                                : t("admin.invoice.form:label.noOfUsers")
                        }
                        placeholder={t(
                            "admin.invoice.form:placeholder.noOfUsers",
                        )}
                        block
                    />

                    <AppFormDatePicker
                        id="startDate"
                        name="startDate"
                        className="subscription-billing-page__date-picker"
                        label={t("admin.invoice.form:label.startDate")}
                        placeholder={t(
                            "admin.invoice.form:placeholder.startDate",
                        )}
                        block
                    />
                </AppFormContent>
            </AppForm>
        </FormProvider>
    );

    return (
        <div className="subscription-billing-page">
            {loading && <AppLoader isFullScreen />}

            <AppPageHeader
                title="admin.subscription.billing:header.title"
                onCreateBtnClick={() => {
                    reset({
                        noOfUsers: undefined,
                        startDate: toDbDateFormat(new Date()),
                    });
                    setDrawer({ show: true, id: 0 });
                }}
                createLabel={
                    currentInvoice
                        ? TKey.Common.Button.AddUser
                        : TKey.Common.Button.Create
                }
                showToolbar
                isSearchBar={false}
                isGrantedControl={isSuperAdmin}
            />

            <AppFormModal
                show={drawer.show}
                icon="description"
                title="admin.invoice.form:modal.title"
                isDrawer
                onSubmit={handleSubmit(onSubmitHandler)}
                onHide={() => {
                    reset();
                    resetDrawer();
                }}
                isLoading={formState.isSubmitting || loading}
                disabled={disabled}
            >
                {renderForm()}
            </AppFormModal>

            <AppConfirmModal
                show={!!showConfirm}
                icon="warning"
                title="admin.invoice.item:delete.confirm.modal.title"
                description="admin.invoice.item:delete.confirm.modal.description"
                onHide={() => setShowConfirm(0)}
                nextAction={() => handleDelete(showConfirm)}
            />

            <AppGrid
                columnDefs={appGridColDef({
                    isSuperAdmin,
                    onPressDownload: handleDownload,
                    onChangeStatus: handleChangeStatus,
                    onPressDelete: setShowConfirm,
                })}
                rowData={data}
                isLoading={loading}
            />
        </div>
    );
};
