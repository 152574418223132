import { FC, useEffect, useState } from "react";
import { Controller, useFormContext } from "react-hook-form";
import { Editor } from "@tinymce/tinymce-react";

import { AppFormLayoutProps } from "./AppForm";
import {
    AppFormInputCommonProps,
    AppFormInputWrapper,
} from "./AppFormInputWrapper";
import { TINY_MCE_API_KEY } from "../../config";
import "../../../src/assets/scss/_tinymce_custom.scss";

interface AppFormTextareaProps
    extends AppFormLayoutProps,
        AppFormInputCommonProps,
        React.DetailedHTMLProps<
            React.TextareaHTMLAttributes<HTMLTextAreaElement>,
            HTMLTextAreaElement
        > {
    name: string;
    isEditor?: boolean;
    withCode?: boolean;
}

export const AppFormTextarea: FC<AppFormTextareaProps> = ({
    id,
    name,
    inputClassName = "",
    isEditor,
    withCode,
    ...props
}) => {
    // hooks
    const { register } = useFormContext();

    // state
    const [plugins, setPlugins] = useState("autoresize autolink link");
    const [toolbar, setToolbar] = useState(
        "bold italic underline strikethrough link",
    );

    useEffect(() => {
        const htmlCodeEditor = "code"; // advcode premium feature

        if (withCode) {
            setPlugins((prev) => prev + " " + htmlCodeEditor);
            setToolbar((prev) => prev + " " + htmlCodeEditor);
        }
    }, [withCode]);

    const controlId = id || name;

    const renderTextArea = () => (
        <div className="app-form-input">
            {isEditor ? (
                <Controller
                    {...props}
                    {...register(name)}
                    name={controlId}
                    render={({ field }) => (
                        <Editor
                            apiKey={TINY_MCE_API_KEY}
                            value={field.value}
                            onEditorChange={(content) =>
                                field.onChange(content)
                            }
                            init={{
                                menubar: false,
                                toolbar_location: "bottom",
                                content_css: "tinymce_custom.scss",
                                statusbar: false,
                                branding: false,
                                min_height: 150,
                                plugins,
                                toolbar,
                                content_style: `@import url('https://fonts.googleapis.com/css2?family=Titillium+Web:wght@300;600;700&display=swap'); body { font-family: Titillium Web, sans-serif; color: #1d2433; }`,
                            }}
                        />
                    )}
                />
            ) : (
                <textarea
                    {...props}
                    {...register(name)}
                    id={controlId}
                    className={`form-control ${inputClassName}`}
                />
            )}
        </div>
    );

    return (
        <AppFormInputWrapper
            id={id}
            name={name}
            label={props.label}
            toolTipLabel={props.toolTipLabel}
            required={props.required}
            xs={props.xs}
            sm={props.sm}
            md={props.md}
            lg={props.lg}
            xl={props.xl}
            xxl={props.xxl}
            block={props.block}
            auto={props.auto}
            maxCount={props.maxCount}
        >
            {renderTextArea()}
        </AppFormInputWrapper>
    );
};
