import { FC, useEffect, useState } from "react";
import { useRecoilState, useResetRecoilState } from "recoil";
import { useParams } from "react-router-dom";

import "./assets/scss/style.scss";

import { MapShare } from "../../../models";
import { ClientApi, MapShareApi } from "../../../apis";
import {
    ModalTypeEnum,
    TKey,
    errorToast,
    setBackendViolations,
    successToast,
} from "../../../utils";
import { atomActiveClient, atomDrawer, atomDrawerType } from "../../../atoms";
import {
    AppConfirmModal,
    AppForm,
    AppFormContent,
    AppFormInput,
    AppFormModal,
    AppFormTextarea,
    AppGrid,
    AppLoader,
    AppPageHeader,
} from "../../../components";
import { appGridColDef } from "./app-grid";
import { useDateTime, useTranslation } from "../../../hooks";
import { FormProvider, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { schema } from "./schema";

interface MapShareForm {
    name: string;
    pwd?: string | null;
    map?: string | null;
    invitedUsers?: string | string[] | null;
}

export const MapSharePage: FC = () => {
    // hooks
    const { t } = useTranslation();
    const { id } = useParams();
    const { toShortDate } = useDateTime();

    // atoms
    const [activeClient] = useRecoilState(atomActiveClient);
    const [drawer, setDrawer] = useRecoilState(
        atomDrawerType(ModalTypeEnum.INVOICE_ADD),
    );
    const resetDrawer = useResetRecoilState(atomDrawer);

    // state
    const [loading, setLoading] = useState(false);
    const [data, setData] = useState<MapShare[]>([]);
    const [showConfirm, setShowConfirm] = useState(0);

    const methods = useForm({
        resolver: yupResolver(schema(t)),
    });
    const { handleSubmit, formState, reset, setError } = methods;

    const fetchListData = () => {
        setLoading(true);

        MapShareApi.find<MapShare>(1, {
            pagination: false,
            "client.id": activeClient?.id,
            "map.id": id,
            "order[id]": "desc",
        })
            .then(({ response, errorMessage }) => {
                if (errorMessage) {
                    errorToast(errorMessage);
                } else if (response !== null) {
                    setData(response.items);
                }
            })
            .finally(() => setLoading(false));
    };

    const onSubmitHandler = (formData: MapShareForm) => {
        if (!id) {
            return;
        }
        setLoading(true);

        formData.map = `/api/maps/${id}`;
        if (formData.invitedUsers) {
            formData.invitedUsers = (formData.invitedUsers as string).split(
                ",",
            );
        } else {
            formData.invitedUsers = [];
        }

        MapShareApi.create<MapShare, unknown>({
            client: ClientApi.toResourceUrl(Number(activeClient?.id)),
            ...formData,
        })
            .then(({ response, errorMessage, isInvalid, error }) => {
                if (isInvalid) {
                    setBackendViolations(error, setError);
                } else if (errorMessage) {
                    errorToast(errorMessage);
                } else if (response !== null) {
                    reset();
                    resetDrawer();
                    fetchListData();
                }
            })
            .finally(() => setLoading(false));
    };

    useEffect(() => {
        fetchListData();
    }, []);

    const handleDelete = (mapShareId: number) => {
        MapShareApi.deleteById(mapShareId).then(({ errorMessage }) => {
            if (errorMessage) {
                errorToast(errorMessage);
            } else {
                successToast(t("admin.mapShare:delete.toast.success"));
                setShowConfirm(0);
                fetchListData();
            }
        });
    };

    const renderForm = () => (
        <FormProvider {...methods}>
            <AppForm>
                <AppFormContent>
                    <AppFormInput
                        id="name"
                        name="name"
                        label={t("admin.mapShare.form:label.name")}
                        placeholder={t("admin.mapShare.form:placeholder.name")}
                        block
                    />
                    <AppFormInput
                        type="password"
                        id="pwd"
                        name="pwd"
                        label={t("admin.mapShare.form:label.password")}
                        placeholder={t(
                            "admin.mapShare.form:placeholder.password",
                        )}
                        showPasswordStrength
                        required={false}
                        block
                    />
                    <AppFormTextarea
                        id="invitedUsers"
                        name="invitedUsers"
                        label={t("admin.mapShare.form:label.invitedUsers")}
                        placeholder={t(
                            "admin.mapShare.form:placeholder.invitedUsers",
                        )}
                        required={false}
                        rows={6}
                        block
                    />
                </AppFormContent>
            </AppForm>
        </FormProvider>
    );

    return (
        <div className="map-share-page">
            {loading && <AppLoader isFullScreen />}

            <AppPageHeader
                title="admin.mapShare:header.title"
                onCreateBtnClick={() => {
                    reset({
                        name: "",
                        pwd: "",
                        invitedUsers: "",
                    });
                    setDrawer({ show: true, id: 0 });
                }}
                createLabel={TKey.Common.Button.Create}
                showToolbar
                isSearchBar={false}
            />

            <AppConfirmModal
                show={!!showConfirm}
                icon="warning"
                title="admin.mapShare.item:delete.confirm.modal.title"
                description="admin.mapShare.item:delete.confirm.modal.description"
                onHide={() => setShowConfirm(0)}
                nextAction={() => handleDelete(showConfirm)}
            />

            <AppFormModal
                show={drawer.show}
                icon="description"
                title="admin.mapShare.form:modal.title"
                isDrawer
                onSubmit={handleSubmit(onSubmitHandler)}
                onHide={() => {
                    reset();
                    resetDrawer();
                }}
                isLoading={formState.isSubmitting || loading}
                disabled={!formState.isValid}
            >
                {renderForm()}
            </AppFormModal>

            <AppGrid
                columnDefs={appGridColDef({
                    toShortDate,
                    onPressDelete: setShowConfirm,
                })}
                rowData={data}
                isLoading={loading}
            />
        </div>
    );
};
