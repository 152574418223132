import React, { FC, useEffect, useRef } from "react";

import "./assets/scss/style.scss";

interface AppProgressBarProps {
    progress: number;
    rate?: string;
    enable?: boolean;
    withNumber?: boolean;
    className?: string;
    size?: "lg";
}

export const AppProgressBar: FC<AppProgressBarProps> = ({
    progress,
    rate,
    enable = true,
    withNumber = true,
    className = "",
    size,
}): JSX.Element => {
    const progressBarRef = useRef<HTMLDivElement>(null);

    const getFillColor = (): string => {
        if (progress >= 50) {
            return "var(--app-success-color)";
        } else if (progress >= 25) {
            return "var(--app-warning-color)";
        } else {
            return "var(--app-danger-color)";
        }
    };

    useEffect(() => {
        if (progressBarRef.current) {
            progressBarRef.current.style.width = `${progress}%`;
            progressBarRef.current.style.backgroundColor = getFillColor();
        }
    }, [progress]);

    if (!enable) {
        return <></>;
    }

    const sizeClass = size ? `app-progress-bar--${size}` : "";

    return (
        <div className={`app-progress-bar ${sizeClass} ${className}`}>
            <div className="app-progress-bar--progress">
                <div
                    ref={progressBarRef}
                    className="app-progress-bar--progress--fill"
                />
            </div>

            <div className="app-progress-bar--details mt-2">
                {withNumber && (
                    <span className="percentage">
                        {`${progress.toFixed(0)}%`}
                    </span>
                )}

                {rate && <span className="speed-rate">{rate}</span>}
            </div>
        </div>
    );
};
