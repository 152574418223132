import { FC, useEffect, useState } from "react";
import { useRecoilState, useResetRecoilState } from "recoil";
import { Link, useMatch, useNavigate } from "react-router-dom";

import "./assets/scss/style.scss";

import {
    useAuthAction,
    useAuthState,
    useRoles,
    useTranslation,
} from "../../hooks";
import { AppSVGIcon } from "../AppSVGIcon";
import { AppSelect } from "../AppForm";
import { atomActiveClient, atomDrawer, atomDrawerType } from "../../atoms";
import { Client, OptionType } from "../../models";
import { AppAvatar } from "../AppAvatar";
import { AppImage } from "../AppImage";
import { ClientLogoFileInfo } from "../../config";
import { ModalTypeEnum, TKey, emailToSupport } from "../../utils";
import { AppTabs } from "../AppTabs";
import { AppUserProfile } from "../AppUserProfile";

export const AppHeader: FC = () => {
    // hooks
    const { t } = useTranslation();
    const navigate = useNavigate();
    const { user, clients, allClients } = useAuthState();
    const { actionLogout, actionRefetchAuthUser } = useAuthAction();
    const { isSuperAdmin, isGrantedClientAdmin, isClientAdmin } = useRoles();
    const matchMap = useMatch("/map/:id/*");
    const matchSubscription = useMatch("/admin/subscription/*");

    // atoms
    const [activeClient, setActiveClient] = useRecoilState(atomActiveClient);
    const [drawer, setDrawer] = useRecoilState(
        atomDrawerType(ModalTypeEnum.USER_PROFILE),
    );
    const resetDrawer = useResetRecoilState(atomDrawer);

    // state
    const [clientList, setClientList] = useState<Client[]>([]);
    const [clientOptions, setClientOptions] = useState<OptionType[]>([]);

    const handleSelectedClient = (selected?: Client) => {
        if (selected) {
            setActiveClient({
                id: selected.id,
                imageName: selected.imageName,
            });
            return;
        }

        setActiveClient(selected);
    };

    const setInitialClients = (data: Client[]) => {
        if (!data.length) {
            return;
        }

        setClientList(data);
        setClientOptions(
            data.map((item) => ({
                label: item.name,
                value: item.id,
            })),
        );

        if (data.length === 1) {
            const [firstClient] = data;
            handleSelectedClient(firstClient);
        } else if (activeClient) {
            const isExist = data.find((x) => x.id === activeClient.id);

            if (!isExist) {
                handleSelectedClient(undefined);
            }
        }
    };

    useEffect(() => {
        if (isSuperAdmin) {
            setInitialClients(allClients);
        } else {
            setInitialClients(clients);
        }
    }, [allClients.length]);

    useEffect(() => {
        if (activeClient && !isSuperAdmin) {
            actionRefetchAuthUser();
        }
    }, [activeClient?.id]);

    return (
        <header className="app-header">
            <AppUserProfile showForm={drawer.show} onHide={resetDrawer} />

            <div className="app-header--main">
                <Link className="header-logo" to={"/"}>
                    {activeClient?.imageName ? (
                        <AppImage
                            src={activeClient.imageName}
                            fileInfo={ClientLogoFileInfo}
                        />
                    ) : (
                        <AppSVGIcon icon="logo" type="image" />
                    )}
                </Link>

                <div className="header-separator" />

                <div className="header-search">
                    <AppSelect
                        id="client"
                        name="client"
                        placeholder={t("header:search.placeholder.client")}
                        defaultValue={activeClient?.id}
                        options={clientOptions}
                        handleOnChange={({ selectedValues }) => {
                            const [clientId] = selectedValues;
                            handleSelectedClient(
                                clientList.find((x) => x.id === clientId),
                            );
                            navigate("/");
                        }}
                    />
                </div>
            </div>

            <div className="app-header--center">
                {matchMap?.params?.id && (
                    <AppTabs
                        items={[
                            {
                                title: "header:navigation.dashboard",
                                icon: "dashboard",
                                url: `/map/${matchMap.params.id}/dashboard`,
                            },
                            {
                                title: "header:navigation.map",
                                icon: "map",
                                url: `/map/${matchMap.params.id}/view`,
                            },
                            {
                                title: "header:navigation.portfolio",
                                icon: "portfolio",
                                url: `/map/${matchMap.params.id}/portfolio`,
                            },
                            {
                                title: "header:navigation.kanban",
                                icon: "kanban",
                                url: `/map/${matchMap.params.id}/kanban`,
                            },
                        ]}
                        onClick={(item) => {
                            if (item.url) {
                                navigate(item.url);
                            }
                        }}
                        isActiveTab={(item) => item?.url === matchMap.pathname}
                    />
                )}

                {matchSubscription?.params && isClientAdmin && (
                    <AppTabs
                        items={[
                            {
                                title: "header:navigation.subscription.overview",
                                icon: "dashboard",
                                url: `${matchSubscription.pathnameBase}/overview`,
                            },
                            {
                                title: "header:navigation.subscription.members",
                                icon: "map",
                                url: `${matchSubscription.pathnameBase}/members`,
                            },
                            {
                                title: "header:navigation.subscription.billing",
                                icon: "portfolio",
                                url: `${matchSubscription.pathnameBase}/billing-history`,
                            },
                            {
                                title: "header:navigation.subscription.pricing",
                                icon: "kanban",
                                url: `${matchSubscription.pathnameBase}/pricing`,
                            },
                        ]}
                        onClick={(item) => {
                            if (item.url) {
                                navigate(item.url);
                            }
                        }}
                        isActiveTab={(item) =>
                            item?.url === matchSubscription.pathname
                        }
                    />
                )}
            </div>

            <div className="app-header--action">
                {/* // TODO:: action items component if needed */}
                <div>
                    <Link to={emailToSupport} target="_blank">
                        <AppSVGIcon icon="help" size="xl" />
                    </Link>
                </div>
                <div className="app-header--action--profile">
                    <div className="header-profile position-relative">
                        <AppAvatar user={user} />

                        <div className="header-menu-action position-absolute">
                            <div className="menu-label my-2 d-flex">
                                <AppAvatar user={user} withDetail />
                            </div>

                            <hr />

                            <div
                                className="menu-label my-2 gap-2 cursor-pointer"
                                onClick={() => setDrawer({ show: true, id: 0 })}
                            >
                                <AppSVGIcon icon="user" />

                                <span className="title">
                                    {t("header:label.profile")}
                                </span>
                            </div>

                            {isGrantedClientAdmin && (
                                <>
                                    <div className="menu-label my-2 gap-2">
                                        <AppSVGIcon icon="settings" />

                                        <span className="title">
                                            {t("header:label.administration")}
                                        </span>
                                    </div>

                                    <div className="ms-4">
                                        {isSuperAdmin && (
                                            <div
                                                className="menu-item my-3 gap-2"
                                                onClick={() => {
                                                    navigate("/admin/clients");
                                                }}
                                            >
                                                <AppSVGIcon icon="user" />

                                                <span className="title">
                                                    {t(
                                                        "header:administration.button.clients",
                                                    )}
                                                </span>
                                            </div>
                                        )}

                                        <div
                                            className="menu-item my-3 gap-2"
                                            onClick={() => {
                                                navigate("/admin/users");
                                            }}
                                        >
                                            <AppSVGIcon icon="user-group" />

                                            <span className="title">
                                                {t(
                                                    "header:administration.button.users",
                                                )}
                                            </span>
                                        </div>

                                        <div
                                            className="menu-item my-3 gap-2"
                                            onClick={() => {
                                                navigate(
                                                    "/admin/email-templates",
                                                );
                                            }}
                                        >
                                            <AppSVGIcon icon="description" />

                                            <span className="title">
                                                {t(
                                                    "header:administration.button.emailTemplates",
                                                )}
                                            </span>
                                        </div>

                                        {isClientAdmin && (
                                            <div
                                                className="menu-item my-3 gap-2"
                                                onClick={() => {
                                                    navigate(
                                                        "/admin/subscription/overview",
                                                    );
                                                }}
                                            >
                                                <AppSVGIcon icon="kanban" />

                                                <span className="title">
                                                    {t(
                                                        "header:administration.button.subscriptions",
                                                    )}
                                                </span>
                                            </div>
                                        )}
                                    </div>

                                    <hr />
                                </>
                            )}

                            <div
                                className="menu-item my-1 gap-2"
                                onClick={actionLogout}
                            >
                                <AppSVGIcon icon="exit" />

                                <span className="title">
                                    {t(TKey.Common.Button.Logout)}
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </header>
    );
};
