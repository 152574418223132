import { atom } from "recoil";
import { Client } from "../models";
import { localStorageEffect } from "../utils";

const KEY = "atom-active-client";

export const atomActiveClient = atom<
    Pick<Client, "id" | "imageName"> | undefined
>({
    key: KEY,
    default: undefined,
    effects: [localStorageEffect(btoa(KEY))],
});
