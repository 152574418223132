import { useContext, useEffect } from "react";
import { useNavigate } from "react-router-dom";

import { AuthContext } from "../contexts";
import { Client, User } from "../models";
import { APP_TOKEN_KEY } from "../utils";
import { ROLES } from "../config";
import { UserApi } from "../apis";

type AuthStateType = {
    isAuthenticated: boolean;
    token: string;
    isOnboard: boolean;
    user: User;
    userId: number;
    userResourceId: string;
    userRole: string;
    initialClient: Client;
    clients: Client[];
    allClients: Client[];
};

export function useAuthState(): AuthStateType {
    // hooks
    const navigate = useNavigate();

    // contexts
    const { state } = useContext(AuthContext);

    // state
    const { user, isAuthenticated, allClients } = state;
    const token = localStorage.getItem(APP_TOKEN_KEY);

    if (token === null || !user || !user.id) {
        throw new Error("useAuthState is not defined or initialized.");
    }

    const { id: userId, roles, clients, isOnboarded } = user;

    const [userRole = ""] = roles;
    const [initialClient] = clients;

    const isOnboard = userRole === ROLES.USER_ROLE_SUPER_ADMIN || isOnboarded;

    // TODO:: to move to hoc
    // redirect to onboard screen if user is authenticated and not onboard
    useEffect(() => {
        if (isAuthenticated && !isOnboard) {
            navigate("/onboarding");
        }
    }, [user?.id]);

    return {
        isAuthenticated,
        token,
        isOnboard,
        user,
        userId,
        userResourceId: UserApi.toResourceUrl(userId),
        userRole,
        initialClient,
        clients,
        allClients,
    };
}
