import { useContext } from "react";
import { useNavigate } from "react-router-dom";

import { AuthContext } from "../contexts";
import { Client, User } from "../models";
import {
    APP_TOKEN_KEY,
    APP_USER_KEY,
    APP_USER_LOCALE_KEY,
    AuthActionEnum,
    errorToast,
} from "../utils";
import { AuthApi, ClientApi } from "../apis";
import { useTranslation } from "./useTranslation";

type AuthActionType = {
    isAuthenticated: boolean;
    actionSetAuthToken: (token: string) => void;
    actionSetAuthUser: (user: User) => void;
    actionLogin: (payload: User) => void;
    actionRegister: (payload: User) => void;
    actionOnBoardInit: (payload: User) => void;
    actionOnBoardCompleted: () => void;
    actionLogout: () => void;
    actionRefetchAuthUser: () => Promise<void>;
    actionFetchAllClients: () => Promise<void>;
};

export function useAuthAction(): AuthActionType {
    // hooks
    const navigate = useNavigate();
    // const resetAtomActiveClient = useResetRecoilState(atomActiveClient);
    const { changeLanguage } = useTranslation();

    // contexts
    const { state, dispatch } = useContext(AuthContext);

    // state
    const { isAuthenticated } = state;

    const actionSetAuthToken = (token: string) => {
        localStorage.setItem(APP_TOKEN_KEY, token);
    };

    const actionSetAuthUser = (user: User) => {
        localStorage.setItem(APP_USER_LOCALE_KEY, user?.locale || "en");
        localStorage.setItem(APP_USER_KEY, JSON.stringify(user));
    };

    const actionLogin = (payload: User) => {
        actionSetAuthUser(payload);

        dispatch({
            type: AuthActionEnum.LOGIN,
            payload,
        });
    };

    const actionRegister = (payload: User) => {
        if (!payload.id) {
            return;
        }

        dispatch({
            type: AuthActionEnum.REGISTER,
            payload,
        });
    };

    const actionOnBoardInit = (payload: User) => {
        actionSetAuthUser(payload);

        dispatch({
            type: AuthActionEnum.ONBOARD_INITIATED,
            payload,
        });
    };

    const actionOnBoardCompleted = () => {
        dispatch({
            type: AuthActionEnum.ONBOARD_COMPLETED,
        });
        navigate("/");
    };

    const actionLogout = () => {
        // reset atoms
        // resetAtomActiveClient();

        dispatch({ type: AuthActionEnum.LOGOUT });
        navigate("/auth/login");
    };

    const actionRefetchAuthUser = async () =>
        AuthApi.me<User>().then(({ response, errorMessage }) => {
            if (errorMessage) {
                errorToast(errorMessage);
            } else if (response !== null) {
                actionSetAuthUser(response);

                changeLanguage(response?.locale || "en");

                dispatch({
                    type: AuthActionEnum.REFETCH_AUTH_USER,
                    payload: response,
                });
            }
        });

    const actionFetchAllClients = async () =>
        ClientApi.find<Client>(1, { pagination: false }).then(
            ({ response }) => {
                if (response !== null) {
                    dispatch({
                        type: AuthActionEnum.FETCH_ALL_CLIENTS,
                        payload: response.items,
                    });
                }
            },
        );

    return {
        isAuthenticated,
        actionSetAuthToken,
        actionSetAuthUser,
        actionLogin,
        actionRegister,
        actionOnBoardInit,
        actionOnBoardCompleted,
        actionLogout,
        actionRefetchAuthUser,
        actionFetchAllClients,
    };
}
