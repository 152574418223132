import React, { FC, PropsWithChildren } from "react";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { Placement } from "react-bootstrap/esm/types";

import "./assets/scss/style.scss";

export interface AppTooltipProps extends PropsWithChildren {
    id: string;
    overlay: JSX.Element;
    placement?: Placement;
    className?: string;
}

export const AppTooltip: FC<AppTooltipProps> = ({
    id,
    overlay,
    placement = "top-start",
    className = "",
    children,
}) => (
    <OverlayTrigger
        placement={placement}
        flip={!placement.includes("auto")}
        overlay={(props) => (
            <Tooltip {...props} id={`tooltip-${id}`} className={className}>
                {overlay}
            </Tooltip>
        )}
    >
        {(props) => <div {...props}>{children}</div>}
    </OverlayTrigger>
);
