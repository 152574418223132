import * as yup from "yup";
import { PhoneNumberUtil } from "google-libphonenumber";
import { TKey, validation } from "../../../utils";

const phoneUtil = PhoneNumberUtil.getInstance();

export const schema = (t: (val: string) => string, isEditMode: number) =>
    yup.object().shape({
        name: yup
            .string()
            .required(t(TKey.Common.Message.Validation.RequiredField))
            .max(validation.title.max),
        region: yup.string().max(validation.title.max),
        address: yup
            .string()
            .required(t(TKey.Common.Message.Validation.RequiredField))
            .max(validation.title.max),
        postCode: yup
            .string()
            .required(t(TKey.Common.Message.Validation.RequiredField)),
        city: yup
            .string()
            .required(t(TKey.Common.Message.Validation.RequiredField)),
        country: yup
            .string()
            .required(t(TKey.Common.Message.Validation.RequiredField)),
        phone: yup
            .string()
            .required(t(TKey.Common.Message.Validation.RequiredField))
            .test(
                "is-valid-phone",
                t(TKey.Common.Message.Validation.InvalidPhoneNumber),
                (value) => {
                    try {
                        return phoneUtil.isValidNumber(
                            phoneUtil.parseAndKeepRawInput(value),
                        );
                    } catch (error) {
                        return false;
                    }
                },
            ),
        imageName: yup
            .string()
            .transform((value) => (value?.length ? value : undefined))
            .required(t(TKey.Common.Message.Validation.RequiredField)),

        // for client user creation along with client create
        email: yup
            .string()
            .email()
            .when([], {
                is: () => !isEditMode,
                then: (sc) =>
                    sc.required(
                        t(TKey.Common.Message.Validation.RequiredField),
                    ),
                otherwise: (sc) => sc.optional(),
            }),
        firstName: yup
            .string()
            .when([], {
                is: () => !isEditMode,
                then: (sc) =>
                    sc.required(
                        t(TKey.Common.Message.Validation.RequiredField),
                    ),
                otherwise: (sc) => sc.optional(),
            })
            .max(validation.title.max),
        lastName: yup
            .string()
            .when([], {
                is: () => !isEditMode,
                then: (sc) =>
                    sc.required(
                        t(TKey.Common.Message.Validation.RequiredField),
                    ),
                otherwise: (sc) => sc.optional(),
            })
            .max(validation.title.max),
        plainPassword: yup
            .string()
            .min(8)
            .matches(
                /[0-9]/,
                t(TKey.Common.Message.Validation.RequiredOneNumber),
            )
            .matches(
                /[_\W]/,
                t(TKey.Common.Message.Validation.RequiredSpecialCharacter),
            )
            .max(32)
            .when([], {
                is: () => !isEditMode,
                then: (sc) =>
                    sc.required(
                        t(TKey.Common.Message.Validation.RequiredField),
                    ),
                otherwise: (sc) => sc.optional(),
            }),
        confirmPassword: yup
            .string()
            .oneOf(
                [yup.ref("plainPassword")],
                t(TKey.Common.Message.Validation.RequiredPasswordMatch),
            )
            .when([], {
                is: () => !isEditMode,
                then: (sc) =>
                    sc.required(
                        t(TKey.Common.Message.Validation.RequiredField),
                    ),
                otherwise: (sc) => sc.optional(),
            }),
        userImageName: yup
            .string()
            .transform((value) => (value?.length ? value : undefined)),
        isPasswordStrong: yup.boolean(),

        planEndDate: yup.string().nullable(),

        // billing info
        contactBill: yup.string().nullable().max(validation.title.max),
        vatNo: yup.string().nullable().max(validation.title.max),

        isSameAsCompany: yup.boolean(),

        addressBill: yup.string().nullable().max(validation.title.max),
        regionBill: yup.string().nullable().max(validation.title.max),
        postCodeBill: yup.string().nullable(),
        cityBill: yup.string().nullable().max(validation.title.max),
        countryBill: yup.string().nullable(),
    });
