import { Fragment, FC } from "react";

import "./assets/scss/style.scss";

import { AppSVGIcon } from "../AppSVGIcon";
import { OnboardClientAdminTabEnum, OnboardUserTabEnum } from "../../utils";

interface AppProgressiveTabsProps {
    items: typeof OnboardClientAdminTabEnum | typeof OnboardUserTabEnum;
    activeTab: OnboardClientAdminTabEnum | OnboardUserTabEnum;
}

export const AppProgressiveTabs: FC<AppProgressiveTabsProps> = ({
    items,
    activeTab,
}) => {
    const tabValues = Object.values(items);
    const activeTabIndex = tabValues.indexOf(activeTab);

    return (
        <div className="app-progressive-tabs mb-5">
            {tabValues.map((item, i) => (
                <Fragment key={item}>
                    <div className="onboard-tab">
                        <div
                            className={`onboard-tab--icon ${
                                activeTabIndex > i ? "completed" : ""
                            } ${activeTabIndex === i ? "active" : ""}`}
                        >
                            <AppSVGIcon
                                icon="check"
                                className="tab-icon-svg"
                                size="xxl"
                            />
                        </div>
                        <span className="onboard-tab--name">{item}</span>
                    </div>

                    <div
                        className={`tab-separator ${
                            activeTabIndex > i ? "completed" : ""
                        }`}
                    />
                </Fragment>
            ))}
        </div>
    );
};
