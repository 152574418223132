import React, { FC } from "react";

import "./assets/scss/style.scss";

import { User } from "../../models";
import { useBuildAssetPath } from "../../hooks";
import { UserPosterFileInfo } from "../../config";
import { getUserFullName } from "../../utils";
import { AppTooltip } from "../AppTooltip";

interface AppAvatarProps {
    user: User;
    status?: boolean;
    withDetail?: boolean;
}

export const AppAvatar: FC<AppAvatarProps> = ({ user, status, withDetail }) => {
    // hooks
    const { buildFilePath } = useBuildAssetPath(UserPosterFileInfo);

    // constants
    const { imageName, firstName, lastName } = user;
    const filePath = buildFilePath(imageName);

    const initials = `${firstName ? firstName.charAt(0) : ""}${
        lastName ? lastName?.charAt(0) : ""
    }`;
    const isOnline = true;

    return (
        <div className="app-avatar gap-3">
            <div className="app-avatar--img-container">
                {filePath ? (
                    <img
                        src={filePath}
                        className="app-avatar--img-container--img img-fluid"
                        alt={firstName}
                    />
                ) : (
                    <span className="app-avatar--img-container--initials">
                        {initials}
                    </span>
                )}

                {status && (
                    <i
                        className={`app-avatar--img-container--status ${
                            isOnline ? "online" : "offline"
                        }`}
                    />
                )}
            </div>

            {withDetail && (
                <div className="app-avatar--detail">
                    <AppTooltip
                        id={user.id.toString()}
                        placement="bottom-end"
                        overlay={<span>{getUserFullName(user)}</span>}
                    >
                        <span className="full-name">
                            {getUserFullName(user)}
                        </span>
                    </AppTooltip>

                    {/* // TODO:: maybe need later on */}
                    {/* <AppTooltip
                        id={user.id.toString() + getUserCompany(user)}
                        placement="bottom-end"
                        overlay={<span>{getUserCompany(user)}</span>}
                    >
                        <span className="company-name">
                            {getUserCompany(user)}
                        </span>
                    </AppTooltip> */}
                </div>
            )}
        </div>
    );
};
