import { ICellRendererParams } from "ag-grid-community";
import { Link } from "react-router-dom";

import "./app-grid-style.scss";

import {
    AppActionDropDown,
    AppSVGIcon,
    AppTooltip,
} from "../../../../components";
import { TKey } from "../../../../utils";
import { useDateTime, useTranslation } from "../../../../hooks";
import { AppInvoiceGridColDefParams } from "./column-defs";

export const AppGridInvoiceId = (params: ICellRendererParams) => {
    const { value, data } = params;
    const { t } = useTranslation();

    return (
        <div className="d-flex align-items-center gap-3">
            <span>{t("admin.subscription.billing:column.id")}</span>
            <span>{`#${value}`}</span>
            {data?.parent && (
                <span>
                    {" "}
                    (
                    {t(
                        "admin.subscription.billing:column.id.addUserForInvoice",
                    )}{" "}
                    {`#${data?.parent?.id}`})
                </span>
            )}
        </div>
    );
};

export const AppGridDate = (params: ICellRendererParams) => {
    const { data } = params;
    const { toShortDate } = useDateTime();

    return (
        <AppTooltip
            id={data.id}
            overlay={
                <span>
                    {toShortDate(data.startDate)} - {toShortDate(data.endDate)}
                </span>
            }
        >
            <span className="app-grid-tooltip">
                {toShortDate(data.startDate)} - {toShortDate(data.endDate)}
            </span>
        </AppTooltip>
    );
};

export const AppGridStatus = (params: ICellRendererParams) => {
    const { value } = params;
    const { t } = useTranslation();

    const isPaid = value === "PAID";

    return (
        <div className={`app-grid-status ${isPaid ? "paid" : "failed"}`}>
            <div
                className={`app-grid-status__icon ${
                    isPaid ? "paid" : "failed"
                }`}
            />
            <span className="label--200">
                {isPaid
                    ? t("admin.subscription.billing:label.paid")
                    : t("admin.user.list:column.status.pending")}
            </span>
        </div>
    );
};

export const AppGridAmount = (params: ICellRendererParams) => {
    const { value } = params;
    const { t } = useTranslation();

    return (
        <span>
            {value} {t(TKey.Common.Label.Currency)}
        </span>
    );
};

export const AppGridManualInvoice = (params: ICellRendererParams) => {
    const { value } = params;
    const { t } = useTranslation();

    return (
        <span>
            {value
                ? t("admin.subscription.billing:column.creator.client")
                : t("admin.subscription.billing:column.creator.sa")}
        </span>
    );
};

export const AppGridAction = (
    params: ICellRendererParams & AppInvoiceGridColDefParams,
) => {
    // hooks
    const { t } = useTranslation();

    // params
    const { onPressDownload, onChangeStatus, onPressDelete, data } = params;

    return (
        <AppActionDropDown id={data.id}>
            <li className="edit">
                <Link
                    to={"#"}
                    onClick={(e) => {
                        e.preventDefault();
                        onPressDownload(data.id);
                    }}
                >
                    <AppSVGIcon icon="download" />

                    <span>{t(TKey.Common.Button.Download)}</span>
                </Link>
            </li>
            <li className="change-status">
                <Link
                    to={"#"}
                    onClick={(e) => {
                        e.preventDefault();
                        onChangeStatus(data.id, data.status);
                    }}
                >
                    <AppSVGIcon icon="tag" />

                    <span>
                        {data.status === "CREATED"
                            ? t(TKey.Common.Button.MarkAsPaid)
                            : t(TKey.Common.Button.MarkAsPending)}
                    </span>
                </Link>
            </li>
            <li className="delete">
                <Link
                    to={"#"}
                    onClick={(e) => {
                        e.preventDefault();
                        onPressDelete(data.id);
                    }}
                >
                    <AppSVGIcon icon="delete" />

                    <span>{t(TKey.Common.Button.Delete)}</span>
                </Link>
            </li>
        </AppActionDropDown>
    );
};
