import { FC, useEffect, useMemo, useState } from "react";
import { yupResolver } from "@hookform/resolvers/yup";
import { FormProvider, useForm } from "react-hook-form";
import { useRecoilState, useResetRecoilState } from "recoil";

import "./assets/scss/style.scss";

import { useTranslation } from "../../../hooks";
import {
    AppButton,
    AppFlag,
    AppForm,
    AppFormCheckBox,
    AppFormContent,
    AppFormInput,
    AppFormModal,
    AppFormSelect,
    AppLoader,
} from "../../../components";
import {
    ModalTypeEnum,
    TKey,
    errorToast,
    getUserFullName,
    setBackendViolations,
    successToast,
    validation,
} from "../../../utils";
import { getCountryOptions } from "../../../config";
import { atomDrawer, atomDrawerType } from "../../../atoms";
import { Client, OptionType, PClient, User } from "../../../models";
import { ClientApi, UserApi } from "../../../apis";
import { schema } from "./schema";

interface SubscriptionBillingInfoProps {
    clientInfo: Client;
    refetchClientInfo: () => void;
}

export const SubscriptionBillingInfo: FC<SubscriptionBillingInfoProps> = ({
    clientInfo,
    refetchClientInfo,
}) => {
    // hooks
    const { t } = useTranslation();

    // atoms
    const [drawer, setDrawer] = useRecoilState(
        atomDrawerType(ModalTypeEnum.CLIENT_BILLING),
    );
    const resetDrawer = useResetRecoilState(atomDrawer);

    // state
    const [editId, setEditId] = useState(0);
    const [loading, setLoading] = useState(false);
    const [billingContactOptions, setBillingContactOptions] = useState<
        OptionType[]
    >([]);

    // memo
    const billingCountryInfo = useMemo(
        () =>
            getCountryOptions().find(
                ({ value }) => value === clientInfo.countryBill,
            ),
        [clientInfo.countryBill],
    );

    const getBillingContacts = () => {
        setLoading(true);

        UserApi.getForClient<User>(1, {
            pagination: false,
        })
            .then(({ response, errorMessage }) => {
                if (errorMessage) {
                    errorToast(errorMessage);
                } else if (response !== null) {
                    setBillingContactOptions(
                        response.items.map((item) => ({
                            label: getUserFullName(item),
                            value: UserApi.toResourceUrl(item.id),
                        })),
                    );
                }
            })
            .finally(() => setLoading(false));
    };

    useEffect(() => {
        getBillingContacts();
    }, []);

    // form
    const methods = useForm({
        resolver: yupResolver(schema()),
    });
    const { handleSubmit, formState, reset, setError, watch, trigger } =
        methods;

    const isSameAsCompanyValue = watch("isSameAsCompany");

    const handleClientEdit = () => {
        if (!clientInfo) {
            return;
        }

        reset({
            contactBill: clientInfo.contactBill
                ? UserApi.toResourceUrl((clientInfo.contactBill as User).id)
                : null,
            vatNo: clientInfo.vatNo,
            isSameAsCompany: clientInfo.isSameAsCompany,
            addressBill: clientInfo.addressBill,
            regionBill: clientInfo.regionBill,
            postCodeBill: clientInfo.postCodeBill,
            cityBill: clientInfo.cityBill,
            countryBill: clientInfo.countryBill,
        });

        setEditId(clientInfo.id);
        setDrawer({ show: true, id: clientInfo.id });
    };

    const onSubmitHandler = (formData: PClient) => {
        setLoading(true);

        if (formData.isSameAsCompany) {
            formData.addressBill = clientInfo.address;
            formData.regionBill = clientInfo.region;
            formData.postCodeBill = clientInfo.postCode;
            formData.cityBill = clientInfo.city;
            formData.countryBill = clientInfo.country;
        }

        ClientApi.updateProfile<Client, PClient>(clientInfo.id, formData)
            .then(({ response, errorMessage, isInvalid, error }) => {
                if (isInvalid) {
                    setBackendViolations(error, setError);
                } else if (errorMessage) {
                    errorToast(errorMessage);
                } else if (response !== null) {
                    successToast(t("admin.client.list:update.toast.success"));
                    refetchClientInfo();

                    reset();
                    resetDrawer();
                    setEditId(0);
                }
            })
            .finally(() => setLoading(false));
    };

    useEffect(() => {
        const subscription = watch((_, { name }) => {
            if (name && formState.touchedFields[name]) {
                trigger(name);
            }
        });
        return () => subscription.unsubscribe();
    }, [watch, trigger, formState.touchedFields]);

    const renderForm = () => (
        <FormProvider {...methods}>
            <AppForm className="subscription-billing-info__form">
                {loading && <AppLoader isFullScreen />}

                <AppFormContent>
                    <AppFormSelect
                        id="contactBill"
                        name="contactBill"
                        label={t("admin.client.form:label.billing.contact")}
                        placeholder={t(
                            "admin.client.form:placeholder.billing.contact",
                        )}
                        required={false}
                        md={12}
                        lg={6}
                        options={billingContactOptions}
                        isClearable
                    />

                    <AppFormInput
                        id="vatNo"
                        name="vatNo"
                        label={t("admin.client.form:label.vatNo")}
                        placeholder={t("admin.client.form:placeholder.vatNo")}
                        required={false}
                        md={12}
                        lg={6}
                        maxCount={validation.title.max}
                    />

                    <AppFormCheckBox
                        className="d-flex align-items-end"
                        id="isSameAsCompany"
                        name="isSameAsCompany"
                        inputOption={
                            <span className="address-check">
                                {t("admin.client.form:label.sameAsCompany")}
                            </span>
                        }
                        md={12}
                        lg={6}
                    />

                    <AppFormSelect
                        id="countryBill"
                        name="countryBill"
                        label={t("admin.client.form:label.billing.country")}
                        placeholder={t(
                            "admin.client.form:placeholder.billing.country",
                        )}
                        required={false}
                        disabled={isSameAsCompanyValue}
                        md={12}
                        lg={6}
                        options={getCountryOptions().map(
                            ({ label, value }) => ({
                                label: (
                                    <div className="d-flex gap-3 align-items-center">
                                        <AppFlag icon={value} />
                                        <span>{label}</span>
                                    </div>
                                ),
                                value,
                                search: label,
                            }),
                        )}
                    />
                    <AppFormInput
                        id="addressBill"
                        name="addressBill"
                        label={t("admin.client.form:label.billing.address")}
                        placeholder={t(
                            "admin.client.form:placeholder.billing.address",
                        )}
                        required={false}
                        disabled={isSameAsCompanyValue}
                        md={12}
                        lg={6}
                        maxCount={validation.title.max}
                    />
                    <AppFormInput
                        id="regionBill"
                        name="regionBill"
                        label={t("admin.client.form:label.billing.region")}
                        placeholder={t(
                            "admin.client.form:placeholder.billing.region",
                        )}
                        required={false}
                        disabled={isSameAsCompanyValue}
                        md={12}
                        lg={6}
                        maxCount={validation.title.max}
                    />
                    <AppFormInput
                        id="cityBill"
                        name="cityBill"
                        label={t("admin.client.form:label.billing.city")}
                        placeholder={t(
                            "admin.client.form:placeholder.billing.city",
                        )}
                        required={false}
                        disabled={isSameAsCompanyValue}
                        md={12}
                        lg={6}
                        maxCount={validation.title.max}
                    />
                    <AppFormInput
                        type="number"
                        id="postCodeBill"
                        name="postCodeBill"
                        label={t("admin.client.form:label.billing.postCode")}
                        placeholder={t(
                            "admin.client.form:placeholder.billing.postCode",
                        )}
                        required={false}
                        disabled={isSameAsCompanyValue}
                        md={12}
                        lg={6}
                    />
                </AppFormContent>
            </AppForm>
        </FormProvider>
    );

    return (
        <>
            <AppFormModal
                show={drawer.show}
                icon="description"
                title="admin.client.form:modal.title"
                // className="app-drawer-stick-header"
                // isDrawer
                size="xl"
                onSubmit={handleSubmit(onSubmitHandler)}
                onHide={() => {
                    resetDrawer();
                    setEditId(0);
                }}
                isLoading={formState.isSubmitting || loading}
                isEditMode={!!editId}
                disabledSumbit={!formState.isValid}
            >
                {renderForm()}
            </AppFormModal>

            <div className="subscription-billing-info">
                <div className="content">
                    <span className="label">
                        {t("admin.client.form:label.billing.contact")}
                    </span>
                    <span className="value">
                        {(clientInfo.contactBill as User)?.firstName || "-"}
                    </span>
                </div>

                <div className="content">
                    <span className="label">
                        {t("admin.client.form:label.vatNo")}
                    </span>
                    <span className="value">{clientInfo.vatNo || "-"}</span>
                </div>

                <div className="content">
                    <span className="label">
                        {t("admin.client.form:label.billing.address")}
                    </span>
                    <span className="value">
                        {clientInfo.addressBill || "-"}
                    </span>
                </div>

                <div className="content">
                    <span className="label">
                        {t("admin.client.form:label.billing.region")}
                    </span>
                    <span className="value">
                        {clientInfo.regionBill || "-"}
                    </span>
                </div>

                <div className="content">
                    <span className="label">
                        {t("admin.client.form:label.billing.postCode")}
                    </span>
                    <span className="value">
                        {clientInfo.postCodeBill || "-"}
                    </span>
                </div>

                <div className="content">
                    <span className="label">
                        {t("admin.client.form:label.billing.city")}
                    </span>
                    <span className="value">{clientInfo.cityBill || "-"}</span>
                </div>

                <div className="content">
                    <span className="label">
                        {t("admin.client.form:label.billing.country")}
                    </span>
                    {billingCountryInfo ? (
                        <div className="d-flex gap-3 align-items-center value">
                            <AppFlag icon={billingCountryInfo.value} />
                            <span>{billingCountryInfo.label}</span>
                        </div>
                    ) : (
                        "-"
                    )}
                </div>

                <AppButton
                    variant="secondary"
                    size="sm"
                    onClick={handleClientEdit}
                    className="edit-btn"
                >
                    {t(TKey.Common.Button.Edit)}
                </AppButton>
            </div>
        </>
    );
};
