import { FC, useEffect, useMemo, useState } from "react";

import "./assets/scss/style.scss";

import {
    AppAlertBar,
    AppButton,
    AppConfirmModal,
    AppLoader,
    AppSVGIcon,
} from "../../../components";
import {
    useAuthAction,
    useAuthState,
    useDateTime,
    useSubscriptionAction,
    useTranslation,
} from "../../../hooks";
import { TKey, errorToast, successToast, toPascalCase } from "../../../utils";
import { SubscriptionCardModal } from "../SubscriptionCardModal";
import {
    PLAN_FREE,
    PLAN_PROFESSIONAL,
    getCountryOptions,
} from "../../../config";
import { InvoiceApi, UserApi } from "../../../apis";
import { PUser, User } from "../../../models";
import { SubscriptionClientModal } from "../SubscriptionClientModal";

interface SubscriptionReviewInfoProps {
    onBack: () => void;
    onSubmit: () => void;
    isOnboarding?: boolean;
}

export const SubscriptionReviewInfo: FC<SubscriptionReviewInfoProps> = ({
    onBack,
    onSubmit,
    isOnboarding = false,
}) => {
    // hooks
    const { t } = useTranslation();
    const { toShortDate } = useDateTime();
    const { userId } = useAuthState();

    // context
    const {
        isLoading,
        invoice,
        myClient,
        actionFetchCards,
        primaryCard,
        actionShowLoading,
        actionHideLoading,
        actionInvoicePay,
        actionRefetchMyClient,
    } = useSubscriptionAction();
    const { actionRefetchAuthUser, actionOnBoardCompleted } = useAuthAction();

    // state
    const [showConfirm, setShowConfirm] = useState(false);

    useEffect(() => {
        if (myClient?.id) {
            actionFetchCards(myClient.id);
        }
    }, [myClient]);

    useEffect(() => {
        if (isOnboarding) {
            actionRefetchMyClient();
        }
    }, []);

    // memo
    const countryInfo = useMemo(
        () =>
            getCountryOptions().find(
                ({ value }) => value === myClient?.country,
            ),
        [myClient?.country],
    );
    const billingCountryInfo = useMemo(
        () =>
            getCountryOptions().find(
                ({ value }) => value === myClient?.countryBill,
            ),
        [myClient?.countryBill],
    );

    const handleCompletePurchase = () => {
        if (!invoice) {
            return;
        }

        actionShowLoading();

        InvoiceApi.pay<{ result: { status: string } }, { invoiceId: number }>({
            invoiceId: invoice.id,
        })
            .then(({ response, errorMessage }) => {
                if (errorMessage) {
                    errorToast(errorMessage);
                } else if (response !== null) {
                    successToast(
                        t("admin.subscription.checkout:create.toast.success"),
                    );

                    const isPaySuccess =
                        invoice.name === PLAN_PROFESSIONAL.name
                            ? !!response.result?.status &&
                              response.result.status === "succeeded"
                            : !!response.result;

                    actionInvoicePay(isPaySuccess);
                    onSubmit();

                    if (isOnboarding && isPaySuccess) {
                        UserApi.updateProfile<User, PUser>(userId, {
                            isOnboarded: true,
                        }).then(({ response: res, errorMessage: errMsg }) => {
                            if (errMsg) {
                                errorToast(errMsg);
                            } else if (res !== null) {
                                actionRefetchAuthUser().then(() => {
                                    actionOnBoardCompleted();
                                });
                            }
                        });
                    }
                }
            })
            .finally(() => {
                setShowConfirm(false);
                actionHideLoading();
            });
    };

    useEffect(() => {
        if (!invoice || !myClient) {
            onBack();
        }
    }, [invoice, myClient]);

    if (!invoice || !myClient) {
        return <></>;
    }

    // local state
    const duration = t(
        invoice.noOfMonths === 12
            ? TKey.Common.Label.DurationYear
            : TKey.Common.Label.DurationQuarter,
    );

    const totalMonthly = +invoice.unitPrice * invoice.noOfUsers;
    const total = +invoice.unitPrice * invoice.noOfUsers * invoice.noOfMonths;

    return (
        <>
            {isLoading && <AppLoader isFullScreen />}

            <AppConfirmModal
                show={!!showConfirm}
                icon="warning"
                title="admin.subscription.checkout:purchase.confirm.modal.title"
                description="admin.subscription.checkout:purchase.confirm.modal.description"
                onHide={() => setShowConfirm(false)}
                nextAction={() => handleCompletePurchase()}
            />

            <div className="subscription-review-info">
                <div className="subscription-review-info__review">
                    <span className="headline--100 font-weight--bold">
                        {t("admin.subscription.checkout:label.reviewOrder")}
                    </span>

                    <div className="highlight mb-4 mt-4">
                        {t("admin.subscription.checkout:label.highlight1")}
                    </div>

                    <div className="d-flex flex-column mb-4 px-4 gap-2">
                        <span className="headline--300 font-weight--bold mb-2">
                            {t(
                                "admin.subscription.overview:label.billingCycle",
                            )}
                        </span>
                        <span className="paragraph--200 font-weight--bold">
                            {t("admin.subscription.checkout:label.billed")}{" "}
                            {duration}ly
                        </span>
                    </div>

                    <div className="d-flex flex-column mb-4 px-4 gap-2">
                        <span className="headline--300 font-weight--bold">
                            {t(
                                "admin.subscription.checkout:label.commitmentTerm",
                            )}
                        </span>
                        <span className="paragraph--200 font-weight--bold">
                            {invoice.noOfMonths}{" "}
                            {t(TKey.Common.Label.DurationMonth)}{" "}
                            {t("admin.subscription.checkout:label.commitment")}
                        </span>
                        <span className="paragraph--200 font-weight--bold">
                            {toShortDate(invoice.startDate)} -{" "}
                            {toShortDate(invoice.endDate)}
                        </span>
                    </div>

                    <div className="highlight mb-4">
                        {t("admin.subscription.checkout:label.highlight2")}
                    </div>

                    <div className="mb-4 px-4">
                        <span className="headline--300 font-weight--bold separator-line pb-3">
                            {invoice.name}
                        </span>
                    </div>

                    <div className="d-flex align-items-center mb-4 px-4 pt-3">
                        <span className="headline--300 font-weight--bold">
                            {invoice.noOfUsers}{" "}
                            {t("admin.subscription.overview:label.users")}
                        </span>

                        <span className="paragraph--200 font-weight--light ms-5">
                            {invoice.unitPrice} {t(TKey.Common.Label.Currency)}{" "}
                            / {t("admin.subscription.checkout:label.perUser")}
                        </span>

                        <span className="headline--300 font-weight--bold ms-auto">
                            {totalMonthly} {t(TKey.Common.Label.Currency)}
                        </span>
                    </div>

                    <div className="highlight mb-4">
                        {t("admin.subscription.checkout:label.highlight3")}
                    </div>

                    <div className="d-flex align-items-center mb-5 px-4">
                        <span className="headline--300 font-weight--bold">
                            {t("admin.subscription.checkout:label.total")}
                        </span>

                        <span className="headline--200 font-weight--semi-bold ms-auto">
                            {totalMonthly} {t(TKey.Common.Label.Currency)}/
                            {t(TKey.Common.Label.DurationMonth)}
                        </span>
                    </div>

                    <div className="d-flex align-items-center mb-4">
                        <span className="headline--200 font-weight--bold">
                            {t("admin.subscription.checkout:label.billed")}{" "}
                            {t(duration)}ly
                        </span>

                        <span className="headline--300 font-weight--bold ms-auto">
                            {total} {t(TKey.Common.Label.Currency)}/{duration}
                        </span>
                    </div>

                    <div className="d-flex align-items-center mb-4">
                        <span className="headline--200 font-weight--bold">
                            {t(
                                "admin.subscription.checkout:label.proratedAdjustment",
                            )}
                        </span>

                        <span className="headline--300 font-weight--bold ms-auto">
                            {(+invoice.adjustment).toFixed(2)}{" "}
                            {t(TKey.Common.Label.Currency)}
                        </span>
                    </div>

                    <div className="d-flex align-items-center mb-4 separator-line pb-4">
                        <span className="headline--200 font-weight--bold">
                            {t("admin.subscription.checkout:label.VAT")}
                        </span>

                        <span className="headline--300 font-weight--bold ms-auto">
                            {(+invoice.tax).toFixed(2)}{" "}
                            {t(TKey.Common.Label.Currency)}
                        </span>
                    </div>

                    <div className="d-flex align-items-center mb-5">
                        <div className="d-flex">
                            <span className="headline--200 font-weight--bold">
                                {t(
                                    "admin.subscription.checkout:label.orderTotal",
                                )}
                            </span>

                            <span className="headline--200 font-weight--light ms-2">
                                {t("admin.subscription.checkout:label.inclTax")}
                            </span>
                        </div>

                        <span className="headline--100 font-weight--bold ms-auto">
                            {(+invoice.totalAmount).toFixed(2)}{" "}
                            {t(TKey.Common.Label.Currency)}
                        </span>
                    </div>
                </div>

                <div className="subscription-review-info__sidebar">
                    <div className="d-flex mb-2">
                        <span className="headline--200 font-weight--bold">
                            {t(
                                "admin.subscription.checkout:label.companyAddress",
                            )}
                        </span>

                        <div className="ms-auto">
                            <SubscriptionClientModal clientId={myClient.id} />
                        </div>
                    </div>

                    <div className="mb-3">
                        <span className="paragraph--100 font-weight--bold">
                            {t("admin.subscription.checkout:description.tax")}
                        </span>
                    </div>

                    <div className="d-flex flex-column mb-3">
                        <span className="headline--300 font-weight--bold mb-2">
                            {t("admin.subscription.checkout:label.companyName")}
                        </span>
                        <span className="paragraph--200 font-weight--bold">
                            {myClient.name}
                        </span>
                    </div>

                    <div className="d-flex flex-column mb-4">
                        <span className="headline--300 font-weight--bold mb-2">
                            {t("admin.subscription.checkout:label.address")}
                        </span>
                        <span className="paragraph--200 font-weight--bold">
                            {myClient.address} {myClient.region} {myClient.city}{" "}
                            {countryInfo?.label}, {myClient.postCode}
                        </span>
                    </div>

                    <div className="separator-line mb-4" />

                    <div className="d-flex mb-3">
                        <span className="headline--200 font-weight--bold">
                            {t(
                                "admin.subscription.checkout:label.paymentDetails",
                            )}
                        </span>

                        <div className="ms-auto">
                            <SubscriptionCardModal clientId={myClient.id} />
                        </div>
                    </div>

                    <div className="d-flex align-items-center">
                        {primaryCard ? (
                            <>
                                <AppSVGIcon
                                    icon={`card-${primaryCard.brand}`}
                                    type="image"
                                    size="xxl"
                                />

                                <div className="d-flex flex-column ms-2">
                                    <span className="paragraph--200 font-weight--semi-bold">
                                        {toPascalCase(primaryCard.brand)}{" "}
                                        <p className="font-weight--light d-inline">
                                            {t(
                                                "admin.subscription.overview:card.label.ending",
                                            )}
                                        </p>{" "}
                                        {primaryCard.last4}
                                    </span>
                                    {/* <span className="paragraph--200 font-weight--semi-bold">
                                        {primaryCard.exp_month}/
                                        {primaryCard.exp_year}
                                    </span> */}
                                    <span className="paragraph--200 font-weight--semi-bold">
                                        {primaryCard.name}
                                    </span>
                                </div>
                            </>
                        ) : (
                            <AppAlertBar
                                variant="warning"
                                description={""}
                                title={t(
                                    "admin.subscription.overview:label.noCard",
                                )}
                            />
                        )}
                    </div>

                    <div className="d-flex flex-column mb-4 mt-3">
                        <span className="headline--300 font-weight--bold mb-2">
                            {t(
                                "admin.subscription.checkout:label.billingAddress",
                            )}
                        </span>
                        <span className="paragraph--200 font-weight--bold">
                            {myClient.addressBill} {myClient.regionBill}{" "}
                            {myClient.cityBill} {billingCountryInfo?.label},{" "}
                            {myClient.postCodeBill}
                        </span>
                    </div>

                    <div className="d-flex justify-content-end mt-auto">
                        <AppButton
                            onClick={() => onBack()}
                            iconLeft="arrow-left"
                            variant="secondary"
                        >
                            {t("admin.subscription.checkout:button.back")}
                        </AppButton>

                        <AppButton
                            onClick={() => setShowConfirm(true)}
                            disabled={!invoice}
                            className="ms-3"
                        >
                            {invoice.name === PLAN_FREE.name
                                ? t(
                                      "admin.subscription.checkout:button.getStarted",
                                  )
                                : t(
                                      "admin.subscription.checkout:button.completePurchase",
                                  )}
                        </AppButton>
                    </div>
                </div>
            </div>
        </>
    );
};
