import { FC, useState } from "react";
import { useRecoilState } from "recoil";
import { yupResolver } from "@hookform/resolvers/yup";
import { FormProvider, useForm } from "react-hook-form";
import { Link, useNavigate } from "react-router-dom";

import "./assets/scss/style.scss";

import { TKey, errorToast, setBackendViolations } from "../../../utils";
import { useTranslation } from "../../../hooks";
import { AuthApi } from "../../../apis";
import {
    AppButton,
    AppForm,
    AppFormActions,
    AppFormCheckBox,
    AppFormContent,
    AppFormInput,
    AppLink,
    AppLoader,
} from "../../../components";
import { schema } from "./schema";

import { registeredState } from "../../../Auth/atoms/registeredState";

interface RegisterForm {
    email: string;
    plainPassword: string;
    confirmPassword: string;
    agree: boolean;
    isPasswordStrong?: boolean;
}

export const RegisterPage: FC = () => {
    // hooks
    const { t } = useTranslation();
    // const { actionRegister } = useAuthAction();

    const navigate = useNavigate();
    // state
    const [loading, setLoading] = useState(false);

    // atoms
    const [, setRegisterState] = useRecoilState(registeredState);

    // form
    const methods = useForm<RegisterForm>({
        resolver: yupResolver(schema(t)),
    });
    const { handleSubmit, formState, reset, setError, watch } = methods;

    // local state
    const agreeValue = watch("agree");
    const isPasswordStrong = watch("isPasswordStrong");

    const onSubmitHandler = (formData: RegisterForm) => {
        setLoading(true);

        AuthApi.register<{ isAlreadyExists: boolean }, Partial<RegisterForm>>({
            email: formData.email,
            plainPassword: formData.plainPassword,
        })
            .then(({ response, errorMessage, isInvalid, error }) => {
                if (isInvalid) {
                    setBackendViolations(error, setError);
                } else if (errorMessage) {
                    errorToast(errorMessage);
                } else if (response !== null) {
                    if (response.isAlreadyExists) {
                        errorToast(
                            t(
                                "user.register.form:alreadyRegistered.toast.error",
                            ),
                        );
                    } else {
                        setRegisterState(true);
                        navigate("/auth/login");
                    }

                    // actionRegister(response.user);
                    reset();
                }
            })
            .finally(() => setLoading(false));
    };

    return (
        <FormProvider {...methods}>
            <AppForm
                onSubmit={handleSubmit(onSubmitHandler)}
                className="register-page"
            >
                {loading && <AppLoader isFullScreen />}

                <AppFormContent>
                    <AppFormInput
                        type="email"
                        id="email"
                        name="email"
                        label={t("user.register.form:label.email")}
                        placeholder={t("user.register.form:placeholder.email")}
                        autoComplete="email"
                        block
                    />

                    <AppFormInput
                        type="password"
                        id="plainPassword"
                        name="plainPassword"
                        label={t("user.register.form:label.password")}
                        placeholder={t(
                            "user.register.form:placeholder.password",
                        )}
                        showPasswordStrength
                        autoComplete="new-password"
                        block
                    />

                    <AppFormInput
                        inputClassName="mb-3"
                        type="password"
                        id="confirmPassword"
                        name="confirmPassword"
                        label={t("user.register.form:label.confirmPassword")}
                        placeholder={t(
                            "user.register.form:placeholder.confirmPassword",
                        )}
                        autoComplete="new-password"
                        block
                        onCopy={(e) => e.preventDefault()}
                        onPaste={(e) => e.preventDefault()}
                    />

                    <AppFormCheckBox
                        id="agree"
                        name="agree"
                        inputOption={
                            <span className="terms">
                                {`${t("user.register.form:label.agreeTo")}`}{" "}
                                <Link
                                    className="active-link"
                                    to="https://www.dropbox.com/scl/fi/11tjv0771n3mox0h6qr9u/Privacy-Policy-AGB-s-english.pdf?rlkey=t29r5mmhiw8nnazl3pknu2z73&st=c076gyrj&dl=0"
                                    target="_blank"
                                    rel="noreferrer"
                                >
                                    {t(
                                        "user.register.form:label.termsAndPolicy",
                                    )}
                                </Link>
                            </span>
                        }
                        block
                    />
                </AppFormContent>

                <AppFormActions
                    withDefault={false}
                    position="center"
                    className="pt-2 px-5"
                >
                    <AppButton
                        type="submit"
                        isLoading={formState.isSubmitting || loading}
                        disabled={
                            formState.isSubmitting ||
                            loading ||
                            !agreeValue ||
                            !isPasswordStrong
                        }
                        className="auth-button"
                    >
                        {t("user.register.form:button.register")}
                    </AppButton>

                    <AppLink
                        isBtn
                        to={"/auth/login"}
                        variant="secondary"
                        disabled={formState.isSubmitting || loading}
                        className="auth-button"
                    >
                        {t(TKey.Common.Button.Login)}
                    </AppLink>
                </AppFormActions>
            </AppForm>
        </FormProvider>
    );
};
