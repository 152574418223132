import { FormState, UseFormSetError } from "react-hook-form";
import { AnyType } from "../../models";
import { ErrorType, UnprocessableEntityErrorResponse } from "../../config";

export const validationMessage = (
    fieldName: string,
    formState: FormState<AnyType>,
): string => {
    const { errors } = formState;

    return (errors?.[fieldName]?.message as string) || "";
};

export const setBackendViolations = (
    error: ErrorType,
    setError: UseFormSetError<AnyType>,
) => {
    if (error instanceof UnprocessableEntityErrorResponse) {
        const { violations } = error;

        Object.entries(violations).map(([key, value]) => {
            setError(
                key as never,
                {
                    type: "backend",
                    message: value,
                },
                { shouldFocus: true },
            );
        });
    }
};

export const setCustomViolation = (
    propertyName: string,
    message: string,
    setError: UseFormSetError<AnyType>,
    shouldFocus = true,
) => {
    setError(
        propertyName,
        {
            type: "custom",
            message,
        },
        { shouldFocus },
    );
};
