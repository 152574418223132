import { FC, useEffect, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useRecoilState, useResetRecoilState } from "recoil";
import { RowClickedEvent } from "ag-grid-community";

import "./assets/scss/style.scss";

import { useTranslation } from "../../../hooks";
import { EmailTemplate } from "../../../models";
import {
    AppForm,
    AppFormContent,
    AppFormGroup,
    AppFormInput,
    AppFormModal,
    AppFormTextarea,
    AppGrid,
    AppLoader,
    AppPageHeader,
} from "../../../components";
import { EmailTemplateApi } from "../../../apis";
import {
    ModalTypeEnum,
    copyToClipBoard,
    errorToast,
    setBackendViolations,
    successToast,
    validation,
} from "../../../utils";
import { appGridColDef } from "./app-grid";
import { schema } from "./schema";
import { getEmailTemplatePlaceholders } from "../../../config";
import { atomActiveClient, atomDrawer, atomDrawerType } from "../../../atoms";

interface EmailTemplateForm {
    name: string;
    subject: string;
    content: string;
}

export const EmailTemplatePage: FC = () => {
    // hooks
    const { t } = useTranslation();

    // atoms
    const [activeClient] = useRecoilState(atomActiveClient);
    const [drawer, setDrawer] = useRecoilState(
        atomDrawerType(ModalTypeEnum.EMAIL_ADD_EDIT),
    );
    const resetDrawer = useResetRecoilState(atomDrawer);

    // state
    const [editId, setEditId] = useState(0);
    const [loading, setLoading] = useState(false);
    const [data, setData] = useState<EmailTemplate[]>([]);
    const [availablePlaceHolders, setAvailablePlaceHolders] = useState<
        string[]
    >([]);

    // form
    const methods = useForm<EmailTemplateForm>({
        resolver: yupResolver(schema(t)),
    });
    const { handleSubmit, formState, reset, setError, watch, trigger } =
        methods;

    const fetchTemplates = (search = "") => {
        if (!activeClient) {
            return;
        }

        setLoading(true);

        EmailTemplateApi.find<EmailTemplate>(1, {
            pagination: false,
            ...(search ? { email_template_search: search } : {}),
        })
            .then(({ response, errorMessage }) => {
                if (errorMessage) {
                    errorToast(errorMessage);
                } else if (response !== null) {
                    setData(response.items);
                }
            })
            .finally(() => setLoading(false));
    };

    const onSubmitHandler = (formData: EmailTemplateForm) => {
        setLoading(true);

        EmailTemplateApi.update<EmailTemplate, EmailTemplateForm>(
            editId,
            formData,
        )
            .then(({ response, errorMessage, isInvalid, error }) => {
                if (isInvalid) {
                    setBackendViolations(error, setError);
                } else if (errorMessage) {
                    errorToast(errorMessage);
                } else if (response !== null) {
                    successToast(
                        t("admin.emailTemplate.list:update.toast.success"),
                    );
                    reset();
                    resetDrawer();
                    setEditId(0);
                    fetchTemplates();
                }
            })
            .finally(() => setLoading(false));
    };

    useEffect(() => {
        fetchTemplates();
    }, []);

    const handleEdit = (id: number) => {
        EmailTemplateApi.findById<EmailTemplate>(id).then(
            ({ errorMessage, response }) => {
                if (errorMessage) {
                    errorToast(errorMessage);
                } else if (response) {
                    setAvailablePlaceHolders(
                        getEmailTemplatePlaceholders(response.etKey),
                    );

                    reset({
                        name: response.name,
                        subject: response.subject,
                        content: response.content,
                    });

                    setEditId(id);
                    setDrawer({ show: true, id: response.id });
                }
            },
        );
    };

    const handleRowDoubleClick = (event: RowClickedEvent) => {
        handleEdit(event.data.id);
    };

    useEffect(() => {
        const subscription = watch((_, { name }) => {
            if (name && formState.dirtyFields) {
                trigger(name);
            }
        });
        return () => subscription.unsubscribe();
    }, [watch, trigger, formState.dirtyFields]);

    const renderForm = () => (
        <FormProvider {...methods}>
            <AppForm className="email-template-page--form">
                {loading && <AppLoader isFullScreen />}

                <AppFormContent>
                    <AppFormInput
                        id="name"
                        name="name"
                        label={t("admin.emailTemplate.form:label.name")}
                        placeholder={t(
                            "admin.emailTemplate.form:placeholder.name",
                        )}
                        block
                        maxCount={validation.title.max}
                    />
                    <AppFormTextarea
                        id="subject"
                        name="subject"
                        label={t("admin.emailTemplate.form:label.subject")}
                        placeholder={t(
                            "admin.emailTemplate.form:placeholder.subject",
                        )}
                        block
                        rows={3}
                        maxCount={validation.title.max}
                    />

                    <AppFormTextarea
                        id="content"
                        name="content"
                        label={t("admin.emailTemplate.form:label.content")}
                        placeholder={t(
                            "admin.emailTemplate.form:placeholder.content",
                        )}
                        block
                        rows={15}
                        isEditor
                        withCode
                    />

                    <AppFormGroup block className="placeholders-container">
                        <span className="paragraph--100 font-weight--semi-bold">
                            {t("admin.emailTemplate.form:label.placeHolders")}
                        </span>

                        <div className="placeholders-container--content gap-3 mt-3">
                            {availablePlaceHolders.map((placeHolder) => (
                                <div
                                    key={placeHolder}
                                    className="placeholders-container--content--item"
                                    onClick={() => copyToClipBoard(placeHolder)}
                                >
                                    <span className="paragraph--200">
                                        {placeHolder}
                                    </span>
                                </div>
                            ))}
                        </div>
                    </AppFormGroup>
                </AppFormContent>
            </AppForm>
        </FormProvider>
    );

    return (
        <div className="email-template-page">
            {loading && <AppLoader isFullScreen />}

            <AppPageHeader
                title="admin.emailTemplate.list:header.title"
                // subTitle="admin.emailTemplate.list:header.subTitle"
                onSearchChange={fetchTemplates}
                showToolbar
                isGrantedControl={false}
            />

            <AppFormModal
                show={drawer.show}
                icon="description"
                title="admin.emailTemplate.form:modal.title"
                isDrawer
                onSubmit={handleSubmit(onSubmitHandler)}
                onHide={() => {
                    resetDrawer();
                    setEditId(0);
                }}
                isLoading={formState.isSubmitting || loading}
                isEditMode={!!editId}
                disabledSumbit={!formState.isValid}
            >
                {renderForm()}
            </AppFormModal>

            <AppGrid
                columnDefs={appGridColDef({
                    onPressEdit: handleEdit,
                })}
                gridOptions={{ onRowDoubleClicked: handleRowDoubleClick }}
                rowData={data}
                isLoading={loading}
            />
        </div>
    );
};
