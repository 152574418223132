import { ICellRendererParams } from "ag-grid-community";
import { Link } from "react-router-dom";

import "./app-grid-style.scss";

import { AppActionDropDown, AppSVGIcon } from "../../../../components";
import { AppMapGridColDefParams } from "./column-defs";
import { TKey } from "../../../../utils";
import { useRoles, useTranslation } from "../../../../hooks";

export const AppGridAction = (
    params: ICellRendererParams & AppMapGridColDefParams,
) => {
    // hooks
    const { t } = useTranslation();
    const { isSuperAdmin, isClientAdmin } = useRoles();

    // params
    const { onPressEdit, onPressDelete, onPressClone, data, isOwner, isWrite } =
        params;

    return (
        <AppActionDropDown id={data.id}>
            <li className="view">
                <Link to={`/map/${data.id}/view`}>
                    <AppSVGIcon icon="eye" />
                    <span>{t(TKey.Common.Button.View)}</span>
                </Link>
            </li>

            <li className="share">
                <Link to={`/map/${data.id}/share`}>
                    <AppSVGIcon icon="share" />
                    <span>{t(TKey.Common.Button.Share)}</span>
                </Link>
            </li>

            {onPressEdit && isWrite && (
                <li className="edit">
                    <Link
                        to={"#"}
                        onClick={(e) => {
                            e.preventDefault();
                            e.stopPropagation();
                            onPressEdit(data.id);
                        }}
                    >
                        <AppSVGIcon icon="edit" />

                        <span>{t(TKey.Common.Button.Edit)}</span>
                    </Link>
                </li>
            )}

            {isOwner && (
                <li className="tag">
                    <Link to={`/map/${data.id}/tags/taskPost`}>
                        <AppSVGIcon icon="tag" />

                        <span>{t(TKey.Common.Button.Tag)}</span>
                    </Link>
                </li>
            )}

            {onPressClone && (isSuperAdmin || isClientAdmin) && (
                <li className="clone">
                    <Link
                        to={"#"}
                        onClick={(e) => {
                            e.preventDefault();
                            e.stopPropagation();
                            onPressClone(data.id);
                        }}
                    >
                        <AppSVGIcon icon="clone" />

                        <span>{t(TKey.Common.Button.Clone)}</span>
                    </Link>
                </li>
            )}

            {onPressDelete && isOwner && (
                <li className="delete">
                    <Link
                        to={"#"}
                        onClick={(e) => {
                            e.preventDefault();
                            e.stopPropagation();
                            onPressDelete(data.id);
                        }}
                    >
                        <AppSVGIcon icon="delete" />

                        <span>{t(TKey.Common.Button.Delete)}</span>
                    </Link>
                </li>
            )}
        </AppActionDropDown>
    );
};
