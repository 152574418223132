import { FC, useState } from "react";
import { DraggableProvided, DropResult } from "react-beautiful-dnd";

import {
    AppDraggableList,
    AppInlineInput,
    AppLoader,
    AppSVGIcon,
} from "../../../components";
import {
    useMapAction,
    useTranslation,
    useWorkspacePermission,
} from "../../../hooks";
import {
    MapItemEnum,
    MapPageEnum,
    errorToast,
    successToast,
    validation,
} from "../../../utils";
import { MapPortfolioActionPosts } from "../MapPortfolioPage/MapPortfolioActionPosts";
import { ActionPostBoard, Map, PMap } from "../../../models";
import { MapApi } from "../../../apis";
// import { atomMapPortfolioFilter } from "../../../atoms";
import { ACTION_POST_DURATION, ACTION_POST_STATUS } from "../../../config";

interface MapDetailActionTasksProps {
    boards: ActionPostBoard[];
    onDragEnd: (result: DropResult) => void;
    onSettingChange: () => void;
}

export const MapKanBanBoards: FC<MapDetailActionTasksProps> = ({
    boards,
    onDragEnd,
    onSettingChange,
}) => {
    // hooks
    const { t } = useTranslation();
    const { isWrite } = useWorkspacePermission();

    // context
    const { map, actionPosts } = useMapAction();

    // atoms
    // const [appliedFilter] = useRecoilState(atomMapPortfolioFilter);

    // state
    const [loading, setLoading] = useState(false);
    const [showInput, setShowInput] = useState(0);

    const handleEdit = (id: number, name: string) => {
        if (!map) {
            return;
        }

        setLoading(true);

        const payload = boards.map((x) => {
            if (x.id === id) {
                return { ...x, name } as ActionPostBoard;
            }

            return x;
        });

        MapApi.update<Map, PMap>(+map.id, { kanbanSettings: payload })
            .then(({ response, errorMessage }) => {
                if (errorMessage) {
                    errorToast(errorMessage);
                } else if (response !== null) {
                    successToast(
                        t("app.map.kanban.setting.form:update.toast.success"),
                    );

                    onSettingChange();
                }
            })
            .finally(() => setLoading(false));
    };

    if (!map) {
        return <></>;
    }

    const renderItem = (item: ActionPostBoard, provided: DraggableProvided) => {
        const boardActionPosts = actionPosts.filter(
            (x) => x.status === item.type,
        );

        // if (Object.values(appliedFilter)?.some((x) => !!x?.length)) {
        //     boardActionPosts = boardActionPosts.filter((x) =>
        //         appliedFilter.filteredActionPostIds.includes(x.id),
        //     );
        // }

        return (
            <div
                className="board-wrapper"
                ref={provided.innerRef}
                {...provided.draggableProps}
            >
                <div className={`board-container ${item.type.toLowerCase()}`}>
                    <div className="board-container--item">
                        <div className="board-container--item--header">
                            <AppSVGIcon
                                icon="drag-n-drop"
                                {...provided.dragHandleProps}
                                hidden={
                                    !(
                                        isWrite &&
                                        [
                                            ACTION_POST_STATUS.ACTIONPOST_STATUS_INPROGRESS,
                                            ACTION_POST_STATUS.ACTIONPOST_STATUS_BOARD_1,
                                            ACTION_POST_STATUS.ACTIONPOST_STATUS_BOARD_2,
                                            ACTION_POST_STATUS.ACTIONPOST_STATUS_BOARD_3,
                                            ACTION_POST_STATUS.ACTIONPOST_STATUS_BOARD_4,
                                        ].includes(item.type)
                                    )
                                }
                            />
                        </div>

                        <div
                            className="board-container--item--content"
                            onDoubleClick={() =>
                                isWrite && setShowInput(item.id)
                            }
                        >
                            <AppInlineInput
                                size="lg"
                                show={showInput === item.id && isWrite}
                                initialText={item.name}
                                onHide={() => setShowInput(0)}
                                onUpdate={(text) => handleEdit(item.id, text)}
                                maxLength={validation.kanbanTask.title.max}
                            >
                                <span className="title">{item.name}</span>
                            </AppInlineInput>
                        </div>

                        <div className="board-container--item--action">
                            <div className="total-cards">
                                <span className="title">
                                    {boardActionPosts.length}
                                </span>
                            </div>
                        </div>
                    </div>

                    <MapPortfolioActionPosts
                        actionPosts={boardActionPosts}
                        type={ACTION_POST_DURATION.ACTIONPOST_DURATION_SHORT}
                        pageType={MapPageEnum.MAP_KANBAN}
                        boardType={item.type}
                        onDragEnd={onDragEnd}
                    />
                </div>

                <div className="board-separator" />
            </div>
        );
    };

    return (
        <>
            {loading && <AppLoader isFullScreen />}

            <AppDraggableList<ActionPostBoard>
                droppableId="board"
                direction="horizontal"
                type={MapItemEnum.ACTION_TASK}
                data={boards.filter((x) => x.isEnable)}
                onDragEnd={onDragEnd}
                renderItem={renderItem}
                renderWrapper={(children, providedMain, snapshot) => (
                    <div
                        className={`map-kanban-page--container--boards ${
                            snapshot.isDraggingOver
                                ? "draggable-container--dropable"
                                : ""
                        }`}
                        ref={providedMain.innerRef}
                        {...providedMain.droppableProps}
                    >
                        {children}
                    </div>
                )}
            />
        </>
    );
};
