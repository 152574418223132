import * as yup from "yup";
import { TKey, validation } from "../../../utils";

export const schemaActionPost = (t: (val: string) => string) =>
    yup.object().shape({
        title: yup
            .string()
            .required(t(TKey.Common.Message.Validation.RequiredField))
            .max(validation.portfolioAction.title.max),
        description: yup.string().max(validation.description.max),
        responsible: yup.string().nullable(),
        dueDate: yup
            .string()
            .required(t(TKey.Common.Message.Validation.RequiredField)),
        tags: yup.array().of(yup.object()),
        weightLevelValues: yup.array().of(
            yup.object().shape({
                per: yup.number().transform((value) => value || 0),
            }),
        ),
        documents: yup.array().of(
            yup.object().shape({
                name: yup
                    .string()
                    .required(t(TKey.Common.Message.Validation.RequiredField)),
                fileName: yup
                    .string()
                    .required(t(TKey.Common.Message.Validation.RequiredField)),
            }),
        ),
        additionalInfo: yup.object().shape({
            goals: yup.string().max(validation.description.max),
            benefits: yup.string().max(validation.description.max),
            deliverables: yup.string().max(validation.description.max),
            workPackage: yup.string().max(validation.description.max),
            outOfScope: yup.string().max(validation.descriptionSecondary.max),
            interface: yup.string().max(validation.descriptionSecondary.max),
            personDaysInternal: yup
                .number()
                .transform((value) => (Number.isNaN(value) ? null : value))
                .nullable(),
            personDaysExternal: yup
                .number()
                .transform((value) => (Number.isNaN(value) ? null : value))
                .nullable(),
            cashOut: yup
                .number()
                .transform((value) => (Number.isNaN(value) ? null : value))
                .nullable(),
        }),
    });
