export const TKey = {
    Common: {
        Label: {
            Loading: "common:label.loading",
            Crop: "common:label.crop",
            Currency: "common:label.currency",
            DurationMonth: "common:label.duration.month",
            DurationQuarter: "common:label.duration.quarter",
            DurationYear: "common:label.duration.year",
        },
        Button: {
            Create: "common:button.create",
            Creating: "common:button.creating",
            Update: "common:button.update",
            Updating: "common:button.updating",
            Save: "common:button.save",
            Saving: "common:button.saving",
            Cancel: "common:button.cancel",
            Edit: "common:button.edit",
            Delete: "common:button.delete",
            Next: "common:button.next",
            Done: "common:button.done",
            Reset: "common:button.reset",
            Login: "common:button.login",
            Register: "common:button.register",
            Logout: "common:button.logout",
            TryAgain: "common:button.tryAgain",
            Confirm: "common:button.confirm",
            Yes: "common:button.yes",
            No: "common:button.no",
            Add: "common:button.add",
            View: "common:button.view",
            Filter: "common:button.filter",
            Sort: "common:button.sort",
            Setting: "common:button.setting",
            SaveAs: "common:button.saveAs",
            Print: "common:button.print",
            Size: "common:button.size",
            Clone: "common:button.clone",
            Tag: "common:button.tag",
            Download: "common:button.download",
            Invite: "common:button.invite",
            ReInvite: "common:button.reInvite",
            AddUser: "common:button.addUser",
            MarkAsPaid: "common:button.markAsPaid",
            MarkAsPending: "common:button.markAsPending",
            Close: "common:button.close",
            Share: "common:button.share",
            Request: "common:button.request",
        },
        Placeholder: {
            Search: "common:placeholder.search",
            PhoneNumber: "common:placeholder.phoneNumber",
        },
        Message: {
            SomethingWentWrong: "common:message.somethingWentWrong",
            PageNotFoundTitle: "common:message.pageNotFound.title",
            PageNotFoundDescription: "common:message.pageNotFound.description",
            CopiedToClipboard: "common:message.copiedToClipboard",
            TokenMissing: "common:message.tokenMissing",
            NoData: "common:message.noData",

            Validation: {
                RequiredField: "common:message.validation.required.field",
                RequiredOneNumber:
                    "common:message.validation.required.oneNumber",
                RequiredSpecialCharacter:
                    "common:message.validation.required.specialCharacter",
                RequiredPasswordMatch:
                    "common:message.validation.required.passwordMatch",
                InvalidPhoneNumber:
                    "common:message.validation.invalid.phoneNumber",
                MoreThanOneUser: "common:message.validation.moreThanOneUser",
            },
        },
    },
};
