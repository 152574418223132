import * as yup from "yup";
import { TKey } from "../../../utils";

export const schema = (t: (val: string) => string) =>
    yup.object().shape({
        name: yup
            .string()
            .required(t(TKey.Common.Message.Validation.RequiredField)),
        pwd: yup.string().nullable(),
        invitedUsers: yup.string().nullable(),
    });
