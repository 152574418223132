import { FC, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Col, Row } from "react-bootstrap";

import "./assets/scss/style.scss";

import { useSubscriptionAction, useTranslation } from "../../../hooks";
import { AppButton, AppLoader } from "../../../components";

import {
    SubscriptionPaymentInfo,
    SubscriptionChartInfo,
    SubscriptionCompanyInfo,
    SubscriptionBillingInfo,
} from "../../components";
import { TKey, errorToast } from "../../../utils";
import { ClientApi } from "../../../apis";

export interface ReportData {
    mapCount: number;
    userCount: number;
    workspaceCount: number;
}

export const SubscriptionOverviewPage: FC = () => {
    // hooks
    const { t } = useTranslation();
    const navigate = useNavigate();

    // context
    const {
        isLoading,
        myClient,
        actionRefetchMyClient,
        isCurrentProfessionalPlan,
        actionFetchCards,
        primaryCard,
        currentInvoice,
    } = useSubscriptionAction();

    // state
    const [report, setReport] = useState<ReportData>({
        mapCount: 0,
        userCount: 0,
        workspaceCount: 0,
    });

    useEffect(() => {
        if (myClient?.id) {
            actionFetchCards(myClient.id);

            ClientApi.getStatForSubscription<ReportData>(myClient.id).then(
                ({ response: res, errorMessage: errMsg }) => {
                    if (errMsg) {
                        errorToast(errMsg);
                    } else if (res !== null) {
                        setReport(res);
                    }
                },
            );
        }
    }, [myClient]);

    if (!myClient) {
        return <></>;
    }

    const renderHeader = () => (
        <>
            <div className="subscription-overview-page__box subscription-overview-page__box--primary">
                <span className="subscription-overview-page__title">
                    {myClient.currentPlan}{" "}
                    {isCurrentProfessionalPlan ? (
                        `(${myClient.allowUsers} ${t(
                            "admin.subscription.overview:label.users",
                        )})`
                    ) : (
                        <></>
                    )}
                </span>
                <AppButton
                    variant="secondary"
                    size="sm"
                    onClick={() => navigate(`/admin/subscription/pricing`)}
                >
                    {t("admin.subscription.overview:label.upgrade")}
                </AppButton>
            </div>

            <div className="subscription-overview-page__box subscription-overview-page__box--primary">
                <span className="subscription-overview-page__title">
                    {t("admin.subscription.overview:label.billingHistory")}
                </span>
                <AppButton
                    variant="secondary"
                    size="sm"
                    onClick={() =>
                        navigate(`/admin/subscription/billing-history`)
                    }
                >
                    <span>{t(TKey.Common.Button.View)}</span>
                </AppButton>
            </div>
        </>
    );

    const renderContent = () => (
        <Row>
            <Col xl={4} className="mb-4">
                <div className="subscription-overview-page__box subscription-overview-page__box--secondary">
                    <span className="subscription-overview-page__title">
                        {isCurrentProfessionalPlan
                            ? t("admin.subscription.overview:label.nextPayment")
                            : t(
                                  "admin.subscription.overview:label.noNextPayment",
                              )}
                    </span>

                    <SubscriptionPaymentInfo
                        clientId={myClient.id}
                        isCurrentProfessionalPlan={isCurrentProfessionalPlan}
                        primaryCard={primaryCard}
                        currentInvoice={currentInvoice}
                        isAutoRenew={myClient.isAutoRenew}
                        refetchClientInfo={actionRefetchMyClient}
                    />
                </div>
            </Col>

            <Col xl={8} className="mb-4">
                <div className="subscription-overview-page__box subscription-overview-page__box--secondary">
                    <span className="subscription-overview-page__title">
                        {t("admin.subscription.overview:label.usageReport")}
                    </span>

                    <SubscriptionChartInfo report={report} />
                </div>
            </Col>

            <Col lg={6} className="mb-4">
                <div className="subscription-overview-page__box subscription-overview-page__box--secondary">
                    <span className="subscription-overview-page__title">
                        {t("admin.subscription.overview:label.companyInfo")}
                    </span>

                    <SubscriptionCompanyInfo
                        clientInfo={myClient}
                        // refetchClientInfo={actionRefetchMyClient}
                    />
                </div>
            </Col>

            <Col lg={6} className="mb-4">
                <div className="subscription-overview-page__box subscription-overview-page__box--secondary">
                    <span className="subscription-overview-page__title">
                        {t("admin.subscription.overview:label.billingInfo")}
                    </span>

                    <SubscriptionBillingInfo
                        clientInfo={myClient}
                        refetchClientInfo={actionRefetchMyClient}
                    />
                </div>
            </Col>
        </Row>
    );

    return (
        <div className="subscription-overview-page">
            {isLoading && <AppLoader isFullScreen />}

            <div className="subscription-overview-page__header">
                {renderHeader()}
            </div>

            <div className="subscription-overview-page__content">
                {renderContent()}
            </div>
        </div>
    );
};
