import { FC } from "react";

import "./assets/scss/style.scss";
import { AppTooltip } from "../AppTooltip";

interface AppDisplayEditorContentProps {
    content?: string | null;
    className?: string;
}

export const AppDisplayEditorContent: FC<AppDisplayEditorContentProps> = ({
    content = "",
    className = "",
}) => {
    if (!content) {
        return <></>;
    }

    const stripHtmlTags = (html: string) => {
        const div = document.createElement("div");
        div.innerHTML = html;
        return div.textContent || div.innerText || "";
    };

    return (
        <AppTooltip
            id={content}
            overlay={
                <span className="paragraph--400">{stripHtmlTags(content)}</span>
            }
        >
            <span
                className={`app-display-editor-content ${className}`}
                dangerouslySetInnerHTML={{
                    __html: content,
                }}
            />
        </AppTooltip>
    );
};
