import ROUTES from "./routes.json";
import CONSTANTS from "./constants.json";
import { FileTypeInfo } from "../models";
import { PortfolioSizeEnum } from "../utils";

export { ROUTES, CONSTANTS };

// upload constant
const {
    Upload,
    User,
    ENV,
    EmailTemplate,
    WorkspacePermission,
    Document,
    ActionPost,
    TaskPost,
    Map,
    Client,
} = CONSTANTS;

const { FILETYPEINFO: FILETYPE_INFO, STORAGE } = Upload;
const { COUNTRY, USER: ROLES } = User;
const { ETKEY: ET_KEY, ETKEYINFO: ET_KEY_INFO } = EmailTemplate;
const { PERMISSION } = WorkspacePermission;
const { TYPE: DOCUMENT_TYPE } = Document;
const { ACTIONPOST: ACTION_POST_STATUS } = ActionPost;
const { ACTIONPOST: ACTION_POST_SIZE } = ActionPost;
const { ACTIONPOST: ACTION_POST_DURATION } = Map;
const { TASKPOST: TASK_POST_COLOR } = TaskPost;
const {
    PLAN,
    PLANDETAIL: {
        PLANDETAIL_FREE: PLAN_FREE,
        PLANDETAIL_PROFESSIONAL: PLAN_PROFESSIONAL,
        PLANDETAIL_ENTERPRISE: PLAN_ENTERPRISE,
    },
} = Client;

const {
    FILETYPEINFO_USER_POSTER,
    FILETYPEINFO_CLIENT_LOGO,
    FILETYPEINFO_DOCUMENT,
} = FILETYPE_INFO;
const UserPosterFileInfo = FILETYPEINFO_USER_POSTER as FileTypeInfo;
const ClientLogoFileInfo = FILETYPEINFO_CLIENT_LOGO as FileTypeInfo;
const DocumentFileInfo = FILETYPEINFO_DOCUMENT as FileTypeInfo;

const getAllRoleOptions = () =>
    Object.values(ROLES).map((value) => {
        const [, ...rest] = value.split("_");

        return {
            label: rest.join(" "),
            value,
        };
    });

const getSelectRoleOptions = (isAll = false) =>
    getAllRoleOptions().filter(
        ({ value }) =>
            isAll ||
            ![
                ROLES.USER_ROLE_SUPER_ADMIN,
                ROLES.USER_ROLE_CLIENT_ADMIN,
            ].includes(value),
    );

const getCountryOptions = () =>
    Object.entries(COUNTRY).map(([key, value]) => ({
        label: value,
        value: key,
    }));

const getEmailTemplatePlaceholders = (etKey: string) =>
    Object.entries(ET_KEY_INFO).reduce(
        (placeHolders: string[], [key, value]) => {
            if (key === `ETKEYINFO_${etKey}`) {
                const selectedValues = Object.values(value);
                return [...placeHolders, ...selectedValues];
            }
            return placeHolders;
        },
        [],
    );

const getVotingColors = (includeNoOpinion = true) => {
    const colorOptions = [
        {
            value: TASK_POST_COLOR.TASKPOST_COLOR_ONE,
            color: "#23966b",
            icon: "thumb-up",
            title: "app.map.view:label.vote.checkboxes.ok",
        },
        {
            value: TASK_POST_COLOR.TASKPOST_COLOR_TWO,
            color: "#f6ec00",
            icon: "flashlight",
            title: "app.map.view:label.vote.checkboxes.watch",
        },
        {
            value: TASK_POST_COLOR.TASKPOST_COLOR_THREE,
            color: "#ffaa01",
            icon: "construction",
            title: "app.map.view:label.vote.checkboxes.track",
        },
        {
            value: TASK_POST_COLOR.TASKPOST_COLOR_FOUR,
            color: "#ff4013",
            icon: "bomb",
            title: "app.map.view:label.vote.checkboxes.danger",
        },
        {
            value: TASK_POST_COLOR.TASKPOST_COLOR_FIVE,
            color: "#6b728050",
            icon: "hand",
            title: "app.map.view:label.vote.checkboxes.hand",
        },
    ];
    if (!includeNoOpinion) {
        delete colorOptions[4];
    }
    return colorOptions;
};

const getActionPostsSize = () => [
    {
        key: PortfolioSizeEnum.SMALL,
        value: ACTION_POST_STATUS.ACTIONPOST_SIZE_SMALL,
        label: ACTION_POST_STATUS.ACTIONPOST_SIZE_SMALL,
    },

    {
        key: PortfolioSizeEnum.MEDIUM,
        value: ACTION_POST_STATUS.ACTIONPOST_SIZE_MEDIUM,
        label: ACTION_POST_STATUS.ACTIONPOST_SIZE_MEDIUM,
    },

    {
        key: PortfolioSizeEnum.LARGE,
        value: ACTION_POST_STATUS.ACTIONPOST_SIZE_LARGE,
        label: ACTION_POST_STATUS.ACTIONPOST_SIZE_LARGE,
    },

    {
        key: PortfolioSizeEnum.EXTRA_LARGE,
        value: ACTION_POST_STATUS.ACTIONPOST_SIZE_XLARGE,
        label: ACTION_POST_STATUS.ACTIONPOST_SIZE_XLARGE,
    },
];

export {
    COUNTRY,
    ROLES,
    STORAGE,
    ENV,
    ET_KEY,
    ET_KEY_INFO,
    PERMISSION,
    DOCUMENT_TYPE,
    ACTION_POST_STATUS,
    ACTION_POST_SIZE,
    ACTION_POST_DURATION,
    TASK_POST_COLOR,
    PLAN,
    PLAN_FREE,
    PLAN_PROFESSIONAL,
    PLAN_ENTERPRISE,
    UserPosterFileInfo,
    ClientLogoFileInfo,
    DocumentFileInfo,
    getAllRoleOptions,
    getSelectRoleOptions,
    getCountryOptions,
    getEmailTemplatePlaceholders,
    getVotingColors,
    getActionPostsSize,
};
