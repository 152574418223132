import React, { FC, Suspense, lazy, useEffect, useRef, useState } from "react";
import { Routes, Route, Navigate, Outlet } from "react-router-dom";

import { AppLoader, AppNotFound } from "./components";
import {
    LoginPage,
    RegisterPage,
    AutoLoginPage,
    ForgotPasswordPage,
    ForgotPasswordConfirmationPage,
    ResetPasswordPage,
    ResetPasswordConfirmationPage,
} from "./Auth";
import {
    // HomePage,
    OnBoardPage,
    WorkspacePage,
    MapDetailPage,
    MapDashboardPage,
    MapPortfolioPage,
    MapKanBanPage,
    MapKanBanArchivePage,
    TagPage,
    MapDetailViewPage,
    MapSharePage,
} from "./App/pages";
import {
    ClientPage,
    EmailTemplatePage,
    SubscriptionBillingPage,
    SubscriptionCheckoutPage,
    SubscriptionCompletePage,
    // SubscriptionMemberPage,
    SubscriptionOverviewPage,
    SubscriptionPricingPage,
    UserPage,
} from "./Admin";
import { useAuthAction, useGlobalState, useTranslation } from "./hooks";
import { MapProvider, SubscriptionProvider } from "./contexts";
import { EVENTS, Socket } from "./Socket/Socket";
import { useUserSocketEvents } from "./Socket/hooks/useUserSocketEvents";
import { useRecoilState } from "recoil";
import { atomActiveClient } from "./atoms";
import { APP_USER_LOCALE_KEY } from "./utils";

const AdminLayout = lazy(() => import("./layouts/AdminLayout/AdminLayout"));
const AppLayout = lazy(() => import("./layouts/AppLayout/AppLayout"));
const AuthLayout = lazy(() => import("./layouts/AuthLayout/AuthLayout"));
const CommonLayout = lazy(() => import("./layouts/CommonLayout/CommonLayout"));

const App: FC = () => {
    // hooks
    const { isLoading } = useGlobalState();
    const { isAuthenticated, actionRefetchAuthUser, actionFetchAllClients } =
        useAuthAction();
    const { changeLanguage } = useTranslation();

    useEffect(() => {
        if (isAuthenticated) {
            actionRefetchAuthUser();
            actionFetchAllClients();
        }
    }, [isAuthenticated]);

    const [activeClient] = useRecoilState(atomActiveClient);
    const { emitLogin, emitLogout } = useUserSocketEvents();
    const { CONNECT, DISCONNECT } = EVENTS;
    const connectRef = useRef(0);

    useEffect(() => {
        changeLanguage(localStorage.getItem(APP_USER_LOCALE_KEY) || "en");

        // Fired upon a connection error.
        Socket.io.on("error", (error) => {
            // eslint-disable-next-line no-console
            console.debug("socket error", error?.cause, error?.message);
        });

        // Fired upon a successful reconnection.
        Socket.io.on("reconnect", (attempt) => {
            // eslint-disable-next-line no-console
            console.debug("reconnect success", attempt);

            connectRef.current = 0;
        });

        // Fired upon an attempt to reconnect.
        Socket.io.on("reconnect_attempt", (attempt) => {
            // eslint-disable-next-line no-console
            console.debug("reconnect attempt", attempt);

            connectRef.current = attempt;
        });

        // Fired upon a reconnection attempt error.
        Socket.io.on("reconnect_error", (error) => {
            // eslint-disable-next-line no-console
            console.debug(
                "reconnect attempt error",
                connectRef.current,
                error?.cause,
                error?.message,
            );

            if (connectRef.current === 3) {
                // eslint-disable-next-line no-console
                console.debug("API error log socket");
            }
        });

        // Fired when couldn't reconnect within reconnectionAttempts.
        Socket.io.on("reconnect_failed", () => {
            // eslint-disable-next-line no-console
            console.debug("reconnect failed");
        });

        // Fired when socket is closed.
        Socket.io.on("close", () => {
            // tryReconnect()
            // eslint-disable-next-line no-console
            console.debug("socket close");
        });

        Socket.on("connect_error", () => {
            // eslint-disable-next-line no-console
            console.debug("socket connect error");
        });

        Socket.on(CONNECT, () => {
            // eslint-disable-next-line no-console
            console.debug("socket connected");
            emitLogin();
        });

        Socket.on(DISCONNECT, () => {
            // eslint-disable-next-line no-console
            console.debug("socket disconnected");
            emitLogout();
        });

        return () => {
            Socket.off(CONNECT);
            Socket.off(DISCONNECT);
        };
    }, []);

    const [isFirstTime, setIsFirstTime] = useState(true);
    useEffect(() => {
        if (!isFirstTime) {
            emitLogout();
            emitLogin();
        }
        setIsFirstTime(false);
    }, [activeClient?.id]);

    if (isLoading) {
        return <AppLoader isFullScreen />;
    }

    return (
        <Routes>
            {isAuthenticated ? (
                <>
                    <Route path="*" element={<AppNotFound />} />

                    {/* Admin Routes */}
                    <Route
                        path="/admin"
                        element={
                            <Suspense fallback={<AppLoader isFullScreen />}>
                                <AdminLayout />
                            </Suspense>
                        }
                    >
                        <Route path="clients" element={<ClientPage />} />
                        <Route
                            path="clients/:id/billing-history"
                            element={<SubscriptionBillingPage />}
                        />
                        <Route path="users" element={<UserPage />} />
                        <Route
                            path="email-templates"
                            element={<EmailTemplatePage />}
                        />
                        <Route
                            path="subscription"
                            element={
                                <Suspense fallback={<AppLoader isFullScreen />}>
                                    <SubscriptionProvider>
                                        <Outlet />
                                    </SubscriptionProvider>
                                </Suspense>
                            }
                        >
                            <Route
                                path="overview"
                                element={<SubscriptionOverviewPage />}
                            />
                            <Route path="members" element={<UserPage />} />
                            <Route
                                path="billing-history"
                                element={<SubscriptionBillingPage />}
                            />
                            <Route
                                path="pricing"
                                element={<SubscriptionPricingPage />}
                            />
                            <Route
                                path="checkout"
                                element={<SubscriptionCheckoutPage />}
                            />

                            <Route
                                path="complete"
                                element={<SubscriptionCompletePage />}
                            />
                        </Route>
                    </Route>

                    {/* App Routes */}
                    <Route
                        path="/"
                        element={
                            <Suspense fallback={<AppLoader isFullScreen />}>
                                <AppLayout />
                            </Suspense>
                        }
                    >
                        <Route index element={<WorkspacePage />} />

                        <Route
                            path="map/:id"
                            element={
                                <Suspense fallback={<AppLoader isFullScreen />}>
                                    <MapProvider>
                                        <Outlet />
                                    </MapProvider>
                                </Suspense>
                            }
                        >
                            <Route path="view" element={<MapDetailPage />} />
                            <Route
                                path="dashboard"
                                element={<MapDashboardPage />}
                            />
                            <Route
                                path="portfolio"
                                element={<MapPortfolioPage />}
                            />
                            <Route path="kanban" element={<MapKanBanPage />} />
                            <Route
                                path="kanban/archive"
                                element={<MapKanBanArchivePage />}
                            />
                            <Route path="tags/:type" element={<TagPage />} />
                            <Route path="share" element={<MapSharePage />} />
                        </Route>

                        {/* <Route path="home" element={<HomePage />} /> */}
                    </Route>

                    {/* Common Routes */}
                    <Route
                        path="/"
                        element={
                            <Suspense fallback={<AppLoader isFullScreen />}>
                                <CommonLayout />
                            </Suspense>
                        }
                    >
                        <Route path="onboarding" element={<OnBoardPage />} />
                    </Route>
                </>
            ) : (
                <>
                    <Route
                        path="*"
                        element={<Navigate to="/auth/login" replace />}
                    />

                    {/* Auth Routes */}
                    <Route
                        path="/auth"
                        element={
                            <Suspense fallback={<AppLoader isFullScreen />}>
                                <AuthLayout />
                            </Suspense>
                        }
                    >
                        <Route path="login" element={<LoginPage />} />
                        <Route path="register" element={<RegisterPage />} />
                        <Route
                            path="forgot-password"
                            element={<ForgotPasswordPage />}
                        />
                        <Route
                            path="forgot-password-confirmation"
                            element={<ForgotPasswordConfirmationPage />}
                        />
                        <Route
                            path="reset-password"
                            element={<ResetPasswordPage />}
                        />
                        <Route
                            path="reset-password-confirmation"
                            element={<ResetPasswordConfirmationPage />}
                        />
                        <Route
                            path={"auto-login/:token"}
                            element={<AutoLoginPage />}
                        />
                    </Route>
                </>
            )}
            <Route
                path="/map-view/:key"
                element={
                    <Suspense fallback={<AppLoader isFullScreen />}>
                        <MapDetailViewPage />
                    </Suspense>
                }
            />
        </Routes>
    );
};

export default App;
