import { FC, memo, useState } from "react";

import "./assets/scss/style.scss";

import { useTranslation } from "../../../hooks";
import { MapPieChart } from "../MapPieChart";
import { StatisticsByTask } from "../../../models";
import { getPercentage } from "../../../utils";
import { ACTION_POST_DURATION } from "../../../config";

const {
    ACTIONPOST_DURATION_SHORT,
    ACTIONPOST_DURATION_MEDIUM,
    ACTIONPOST_DURATION_LONG,
} = ACTION_POST_DURATION;

// interface MapDashboardProgressCardProps extends StatisticsByTask {
// }

export const MapDashboardProgressCard: FC<StatisticsByTask> = memo(
    ({
        totalPosts,
        totalCompletedPosts,
        totalShortPosts,
        totalMediumPosts,
        totalLongPosts,
    }) => {
        // hooks
        const { t } = useTranslation();

        // state
        const [selected, setSelected] = useState(-1);
        const [data] = useState([
            {
                key: "done",
                label: t("app.map.dashboard:label.project.done"),
                value: +totalCompletedPosts,
            },
            {
                key: ACTIONPOST_DURATION_SHORT.toLowerCase(),
                label: t("app.map.dashboard:label.project.short"),
                value: +totalShortPosts,
            },
            {
                key: ACTIONPOST_DURATION_MEDIUM.toLowerCase(),
                label: t("app.map.dashboard:label.project.medium"),
                value: +totalMediumPosts,
            },
            {
                key: ACTIONPOST_DURATION_LONG.toLowerCase(),
                label: t("app.map.dashboard:label.project.long"),
                value: +totalLongPosts,
            },
        ]);

        return (
            <div className="progress-card h-100">
                <div className="progress-card--header mb-5">
                    <h3 className="title">
                        {t("app.map.dashboard:label.project")}
                    </h3>
                </div>

                <div className="progress-card--info">
                    <div className="progress-card--info--chart">
                        <MapPieChart
                            total={totalPosts}
                            data={[
                                ["Task Type", "Total Tasks"],
                                ...data.map((x) => [x.label, x.value]),
                            ]}
                            onSelect={setSelected}
                        />
                    </div>

                    <div className="progress-card--info--details">
                        {data.map((item, i) => {
                            const percentage = getPercentage(
                                +item.value,
                                +totalPosts,
                                1,
                                false,
                            );

                            return (
                                <div
                                    className={`detail-section ${item.key}`}
                                    key={item.key}
                                >
                                    <div className="detail-section--header">
                                        <span
                                            className={`color ${item.key} ${
                                                selected === i ? "selected" : ""
                                            }`}
                                        />
                                        <span className="title">
                                            {item.label}
                                        </span>
                                    </div>
                                    <span
                                        className={`detail-section--info ${item.key}`}
                                    >
                                        {`${item.value} / ${percentage}`}
                                    </span>
                                </div>
                            );
                        })}
                    </div>
                </div>
            </div>
        );
    },
);
