import { FC } from "react";
import { useNavigate } from "react-router-dom";

import "./assets/scss/style.scss";

import { useTranslation } from "../../../hooks";
import { MapPieChart } from "../../../App/components";
import { AppButton } from "../../../components";
import { ReportData } from "../../pages";

interface SubscriptionChartInfoProps {
    allowUsers?: number;
    report: ReportData;
}

export const SubscriptionChartInfo: FC<SubscriptionChartInfoProps> = ({
    report,
}) => {
    // hooks
    const { t } = useTranslation();
    const navigate = useNavigate();

    return (
        <div className="subscription-chart-info">
            <MapPieChart
                total={report.userCount}
                data={[
                    ["Users", "Total Users"],
                    [
                        t("admin.subscription.overview:label.users"),
                        report.userCount,
                    ],
                ]}
            >
                <span className="chart-title">
                    {t("admin.subscription.overview:label.users")}
                </span>

                <AppButton
                    className="chart-btn"
                    size="sm"
                    onClick={() => navigate(`/admin/subscription/pricing`)}
                >
                    {t("admin.subscription.overview:label.addSeats")}
                </AppButton>
            </MapPieChart>

            {report.workspaceCount > 0 && (
                <MapPieChart
                    total={report.workspaceCount}
                    data={[
                        ["Workspaces", "Total Workspaces"],
                        [
                            t("admin.subscription.overview:label.workspaces"),
                            report.workspaceCount,
                        ],
                    ]}
                >
                    <span className="chart-title">
                        {t("admin.subscription.overview:label.workspaces")}
                    </span>

                    {/* <AppButton
                    className="chart-btn"
                    size="sm"
                    onClick={() => navigate(`/admin/subscription/pricing`)}
                >
                    {t("admin.subscription.overview:label.upgrade")}
                </AppButton> */}
                </MapPieChart>
            )}

            {report.mapCount > 0 && (
                <MapPieChart
                    total={report.mapCount}
                    data={[
                        ["Maps", "Total Maps"],
                        [
                            t("admin.subscription.overview:label.maps"),
                            report.mapCount,
                        ],
                    ]}
                >
                    <span className="chart-title">
                        {t("admin.subscription.overview:label.maps")}
                    </span>

                    {/* <AppButton
                    className="chart-btn"
                    size="sm"
                    onClick={() => navigate(`/admin/subscription/pricing`)}
                >
                    {t("admin.subscription.overview:label.upgrade")}
                </AppButton> */}
                </MapPieChart>
            )}
        </div>
    );
};
