import { memo } from "react";
import { ColDef } from "ag-grid-community";

import { AppGridAction, AppGridOnBoarded } from "./cell-renderer";
import { AppGridColDefParams } from "../../../../models";
import { getAllRoleOptions } from "../../../../config";
import { getUserFullName } from "../../../../utils";
import { AppGridToolTip } from "../../../../App/components/MapList/app-grid/AppGridToolTip";

export interface AppUserGridColDefParams extends AppGridColDefParams {
    onPressReInvite: (email: string) => void;
}

export const appGridColDef = ({
    onPressEdit,
    onPressDelete,
    onPressReInvite,
}: AppUserGridColDefParams): ColDef[] => [
    {
        headerName: "admin.user.list:column.name",
        valueGetter: ({ data }) => getUserFullName(data),
        sort: "asc",
        cellRenderer: memo(AppGridToolTip),
    },
    { field: "email", cellRenderer: memo(AppGridToolTip) },
    { field: "company", cellRenderer: memo(AppGridToolTip) },
    {
        field: "roles",
        valueFormatter: ({ value }) => {
            const [userRole] = value;

            const isExist = getAllRoleOptions().find(
                (x) => x.value === userRole,
            );

            return isExist?.label || "";
        },
    },
    {
        headerName: "admin.user.list:column.status",
        field: "isOnboarded",
        valueGetter: ({ data }) =>
            data.isOnboarded ? "isOnboarded" : "notOnboarded",
        cellRenderer: memo(AppGridOnBoarded),
        cellClass: "d-flex",
    },
    {
        sortable: false,
        cellRenderer: memo(AppGridAction),
        cellRendererParams: {
            onPressEdit,
            onPressDelete,
            onPressReInvite,
        },
        maxWidth: 50,
        cellClass: "center-column",
    },
];
