import { Canceler } from "axios";
import { FinalResponse, ROUTES } from "../config";
import { EntityAPI } from "./core";
import { humanFileSize } from "../utils";

const {
    api_uploads_post_collection: API_POST_COLLECTION,
    api_uploads_get_item: API_GET_ITEM,
} = ROUTES;

export abstract class UploadApi extends EntityAPI {
    protected static GET_ITEM = API_GET_ITEM;

    public static async createResource<R, P>(
        payload: P,
        cancelToken?: (c: Canceler) => void,
        setProgress?: (progress: number) => void,
        setRate?: (rate: string) => void,
    ): Promise<FinalResponse<R | null>> {
        const source = this.createCancelTokenSource();

        if (cancelToken) {
            cancelToken(source.cancel);
        }

        return this.makePostFormData<R, P>(
            API_POST_COLLECTION,
            payload,
            {},
            {
                cancelToken: source.token,
                onUploadProgress: (progressEvent) => {
                    if (setProgress) {
                        const progress = progressEvent?.progress
                            ? progressEvent.progress * 100
                            : 0;

                        setProgress(progress);
                    }
                    if (setRate) {
                        const rate = progressEvent?.rate
                            ? `${humanFileSize(progressEvent.rate)} / s`
                            : "";
                        setRate(rate);
                    }
                },
            },
        )
            .then(({ data }) => this.handleResponse<R>(data))
            .catch((e) => this.handleAllError(e));
    }
}
