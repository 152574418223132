import React, { FC, PropsWithChildren, createContext, useReducer } from "react";

import { Client, User } from "../models";
import { APP_TOKEN_KEY, APP_USER_KEY, AuthActionEnum } from "../utils";

type AuthAction =
    | { type: AuthActionEnum.LOGIN; payload: User }
    | { type: AuthActionEnum.REGISTER; payload: User }
    | { type: AuthActionEnum.ONBOARD_INITIATED; payload: User }
    | { type: AuthActionEnum.ONBOARD_COMPLETED }
    | { type: AuthActionEnum.REFETCH_AUTH_USER; payload: User }
    | { type: AuthActionEnum.FETCH_ALL_CLIENTS; payload: Client[] }
    | { type: AuthActionEnum.LOGOUT };

interface AuthState {
    isAuthenticated: boolean;
    user: User | null;
    allClients: Client[];
}

const token = localStorage.getItem(APP_TOKEN_KEY);
const userRes = localStorage.getItem(APP_USER_KEY);

const initialState: AuthState = {
    isAuthenticated: !!token,
    user: userRes ? JSON.parse(userRes) : null,
    allClients: [],
};

export const AuthContext = createContext<{
    state: AuthState;
    dispatch: React.Dispatch<AuthAction>;
}>({
    state: initialState,
    dispatch: () => null,
});

function reducer(state: AuthState, action: AuthAction): AuthState {
    switch (action.type) {
        case AuthActionEnum.LOGIN:
            return {
                ...state,
                isAuthenticated: true,
                user: action.payload,
            };

        case AuthActionEnum.REGISTER:
            return {
                ...state,
                // user: action.payload,
            };

        case AuthActionEnum.ONBOARD_INITIATED:
            return {
                ...state,
                user: action.payload,
            };

        case AuthActionEnum.ONBOARD_COMPLETED:
            return {
                ...state,
            };

        case AuthActionEnum.REFETCH_AUTH_USER:
            return {
                ...state,
                user: action.payload,
            };

        case AuthActionEnum.FETCH_ALL_CLIENTS:
            return {
                ...state,
                allClients: action.payload,
            };

        case AuthActionEnum.LOGOUT:
            localStorage.removeItem(APP_TOKEN_KEY);
            localStorage.removeItem(APP_USER_KEY);
            return {
                ...state,
                isAuthenticated: false,
                user: null,
            };

        default:
            return state;
    }
}

export const AuthProvider: FC<PropsWithChildren> = ({
    children,
}: PropsWithChildren): JSX.Element => {
    const [state, dispatch] = useReducer(reducer, initialState);

    // eslint-disable-next-line no-console
    console.debug(state, "AuthProvider state");

    return (
        <AuthContext.Provider value={{ state, dispatch }}>
            {children}
        </AuthContext.Provider>
    );
};
