import { FC } from "react";
import { useFormContext } from "react-hook-form";

import {
    AppFormInputWrapper,
    AppFormInputWrapperProps,
} from "./AppFormInputWrapper";
import { OptionType } from "../../models";

interface AppFormCheckBoxProps extends AppFormInputWrapperProps {
    inputOption?: JSX.Element | string;
    inputOptions?: OptionType[];
    isInline?: boolean;
    className?: string;
}

export const AppFormCheckBox: FC<AppFormCheckBoxProps> = ({
    id,
    name,
    inputClassName = "",
    className = "",
    isInline = false,
    inputOption,
    inputOptions = [],
    type = "checkbox",
    ...props
}) => {
    // hooks
    const { register } = useFormContext();

    const controlId = id || name;

    const renderInput = () => {
        if (inputOption) {
            return (
                <div className={`form-check pt-0 ${className}`}>
                    <input
                        {...props}
                        {...register(name)}
                        id={controlId}
                        className={`form-check-input ${inputClassName} mt-1 me-3`}
                        type={type}
                    />

                    <label className="form-check-label" htmlFor={controlId}>
                        {inputOption}
                    </label>
                </div>
            );
        }

        return inputOptions.map((option, i) => {
            const groupControlId = `${controlId}-${i}`;

            return (
                <div
                    key={option.value}
                    className={`form-check ${
                        isInline ? "form-check-inline" : ""
                    }`}
                >
                    <input
                        {...props}
                        {...register(name)}
                        id={groupControlId}
                        className={`form-check-input me-2 ${inputClassName}`}
                        type={type}
                        value={option.value} // for different option value
                        disabled={props.disabled || option.disabled}
                    />

                    <label
                        className="form-check-label mt-1"
                        htmlFor={groupControlId}
                    >
                        {option.label}
                    </label>
                </div>
            );
        });
    };

    return (
        <AppFormInputWrapper id={id} name={name} {...props}>
            {renderInput()}
        </AppFormInputWrapper>
    );
};
