export enum ModalTypeEnum {
    WORKSPACE,
    MAP,
    TASK_POST,
    ACTION_TASK,
    ACTION_POST,
    PORTFOLIO_SETTING,
    KANBAN_SETTING,
    MAP_FILTER,
    PORTFOLIO_FILTER,
    USER_PROFILE,
    CLIENT_ADD_EDIT,
    USER_ADD_EDIT,
    EMAIL_ADD_EDIT,
    WORKSPACE_ADD_EDIT,
    MAP_ADD_EDIT,
    TAG_ADD_EDIT,
    CLIENT_BILLING,
    CARD_ADD,
    INVOICE_ADD,
    MAPSHARE_ADD,
}
