import { AppFormLayoutProps } from "../components";
import { isNumber, isString } from "../utils";

type GridHelperType = {
    getColumnClasses: (value: AppFormLayoutProps) => string;
};

export function useGridHelper(): GridHelperType {
    const getColumnClasses = ({
        xs,
        sm,
        md,
        lg,
        xl,
        xxl,
        block,
        auto,
    }: AppFormLayoutProps): string => {
        let classesName = ``;

        if (xs) {
            classesName += `col-xs-${xs} `;
        }

        if (sm) {
            classesName += `col-sm-${sm} `;
        }

        if (md) {
            classesName += `col-md-${md} `;
        }

        if (lg) {
            classesName += `col-lg-${lg} `;
        }

        if (xl) {
            classesName += `col-xl-${xl} `;
        }

        if (xxl) {
            classesName += `col-xxl-${xxl} `;
        }

        if (block) {
            if (isString(block) || isNumber(block)) {
                classesName += `col-${block}`;
            } else {
                classesName += `col-12`;
            }
        }

        if (auto) {
            classesName += `col-auto`;
        }

        if (!classesName) {
            classesName = "col";
        }

        return classesName;
    };

    return {
        getColumnClasses,
    };
}
