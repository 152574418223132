export class ActionPostDuration {
    type: string;

    name: string;

    dueDate: string;

    isEnable: boolean;

    constructor(
        type: string,
        dueDate: string,
        {
            name = type.toLowerCase(),
            isEnable = true,
        }: PActionPostDuration = {},
    ) {
        this.type = type;
        this.name = name;
        this.dueDate = dueDate;
        this.isEnable = isEnable;
    }

    toJSON() {
        return {
            type: this.type,
            name: this.name,
            dueDate: this.dueDate,
            isEnable: this.isEnable,
        };
    }

    static createForm(type: string, dueDate: string): PActionPostDuration {
        return new ActionPostDuration(type, dueDate).toJSON();
    }
}

export type PActionPostDuration = Partial<ActionPostDuration>;
