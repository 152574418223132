import { atom, selectorFamily } from "recoil";
import { ModalTypeEnum } from "../utils";

const KEY = "atom-drawer";

type AtomDrawerType = {
    type?: ModalTypeEnum;
    id: number;
};

export const atomDrawer = atom<AtomDrawerType>({
    key: KEY,
    default: {
        type: undefined,
        id: 0,
    },
});

export const atomDrawerType = selectorFamily({
    key: "atom-drawer-type",
    get:
        (type: ModalTypeEnum) =>
        ({ get }) => {
            const drawer = get(atomDrawer);

            return {
                show: type === drawer.type,
                id: drawer.id,
            };
        },
    set:
        (type: ModalTypeEnum) =>
        ({ set }, newValue) =>
            set(atomDrawer, (prevState) => ({
                ...prevState,
                ...newValue,
                type,
            })),
});
