import { FC, useEffect, useState } from "react";
import { DraggableProvided } from "react-beautiful-dnd";
import { FormProvider, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useRecoilState, useResetRecoilState } from "recoil";
import { Col, Row } from "react-bootstrap";

import {
    AppButton,
    AppConfirmModal,
    AppDisplayEditorContent,
    AppDraggableList,
    AppForm,
    AppFormContent,
    AppFormGroup,
    AppFormInput,
    AppFormLabel,
    AppFormModal,
    AppFormSelect,
    AppFormTextarea,
    AppFormUploader,
    AppInlineInput,
    AppLoader,
    AppSVGIcon,
    AppTooltip,
} from "../../../components";
import {
    OptionType,
    PTaskPost,
    PUser,
    PVote,
    SimpleObject,
    SubTask,
    Tag,
    TaskPost,
    Vote,
} from "../../../models";
import {
    MapItemEnum,
    ModalTypeEnum,
    errorToast,
    setBackendViolations,
    successToast,
    validation,
} from "../../../utils";
import {
    ClientApi,
    MapApi,
    SubTaskApi,
    TaskPostApi,
    TaskPostTagApi,
    VoteApi,
} from "../../../apis";
import {
    useAuthState,
    useMapAction,
    useRoles,
    useTranslation,
    useWorkspacePermission,
} from "../../../hooks";
import { schemaTaskPost } from "./schema";
import {
    atomActiveClient,
    atomDrawer,
    atomDrawerType,
    atomMapDetailFilter,
} from "../../../atoms";
import {
    DOCUMENT_TYPE,
    DocumentFileInfo,
    TASK_POST_COLOR,
    getVotingColors,
} from "../../../config";
import { MapVoteCheckBox, MapVoteColorSelector } from "../../components";
import { EVENTS, Socket } from "../../../Socket/Socket";

interface MapDetailTaskPostsProps {
    taskId: number;
    subTask: SubTask;
}

export const MapDetailTaskPosts: FC<MapDetailTaskPostsProps> = ({
    taskId,
    subTask,
}) => {
    // hooks
    const { t } = useTranslation();
    const { isWrite, isRead, isOwner } = useWorkspacePermission();
    const { isSuperAdmin, isClientAdmin } = useRoles();
    const { userId } = useAuthState();

    // context
    const {
        map,
        actionCreateTaskPost,
        actionUpdateTaskPost,
        actionDeleteTaskPost,
        fetchTaskPostTags,
        taskPostTagsOptions,
    } = useMapAction();

    // atoms
    const [activeClient] = useRecoilState(atomActiveClient);
    const [appliedFilter] = useRecoilState(atomMapDetailFilter);
    const [drawer, setDrawer] = useRecoilState(
        atomDrawerType(ModalTypeEnum.TASK_POST),
    );
    const resetDrawer = useResetRecoilState(atomDrawer);

    // state
    const [editId, setEditId] = useState(0);
    const [loading, setLoading] = useState(false);
    const [showConfirm, setShowConfirm] = useState(0);
    const [isUploading, setIsUploading] = useState(false);
    const [showInput, setShowInput] = useState(0);
    const [voteDetails, setVoteDetails] = useState<SimpleObject<number> | null>(
        null,
    );
    const [colorOverride, setColorOverride] = useState<string | null>(null);
    const [selectedColorVote, setSelectedColorVote] = useState("");
    const [lockTaskPostId, setLockTaskPostId] = useState(0);
    const [lockDisplayName, setLockDisplayName] = useState("");

    // form
    const methods = useForm({
        resolver: yupResolver(schemaTaskPost(t)),
    });
    const {
        handleSubmit,
        formState,
        reset,
        setError,
        watch,
        setValue,
        trigger,
    } = methods;

    // local state
    const colorValue = watch("color");
    const titleValue = watch("title");

    const onSubmitHandler = (formData: unknown) => {
        if (!activeClient) {
            return;
        }

        const payload = formData as PTaskPost;

        payload.tags = (payload.tags as unknown as OptionType[])?.map((x) =>
            x?.id
                ? x.value
                : {
                      name: x.label,
                      map: map ? MapApi.toResourceUrl(map?.id) : null,
                      client: ClientApi.toResourceUrl(activeClient.id),
                  },
        ) as unknown as Tag[];

        payload.documents = payload.documents?.map((x) => ({
            ...x,
            client: ClientApi.toResourceUrl(activeClient.id),
            type: DOCUMENT_TYPE.TYPE_TASKPOST,
        }));

        payload.colorOverride = colorOverride;

        setLoading(true);

        TaskPostApi.createOrUpdate<TaskPost, PTaskPost>(editId, payload)
            .then(({ response, errorMessage, isInvalid, error }) => {
                if (isInvalid) {
                    setBackendViolations(error, setError);
                } else if (errorMessage) {
                    errorToast(errorMessage);
                } else if (response !== null) {
                    successToast(t("app.taskPost.list:update.toast.success"));
                    actionUpdateTaskPost(
                        taskId,
                        subTask.id,
                        response.id,
                        response,
                    );
                    reset();
                    resetDrawer();
                    setEditId(0);
                }
            })
            .finally(() => setLoading(false));
    };

    const handleCreate = () => {
        if (!activeClient || !map) {
            return;
        }

        const payload = TaskPost.createForm(
            ClientApi.toResourceUrl(activeClient.id),
            MapApi.toResourceUrl(map.id),
            SubTaskApi.toResourceUrl(subTask.id),
            { title: " " },
        );

        TaskPostApi.create<TaskPost, PTaskPost>(payload).then(
            ({ response, errorMessage }) => {
                if (errorMessage) {
                    errorToast(errorMessage);
                } else if (response !== null) {
                    actionCreateTaskPost(taskId, subTask.id, response);
                    setShowInput(response.id);
                }
            },
        );
    };

    const onEdit = (id: number) => {
        if (!(lockTaskPostId !== id && isWrite)) {
            return;
        }

        fetchTaskPostTags({ "client.id": activeClient?.id, "map.id": map?.id });

        TaskPostApi.findById<TaskPost>(id).then(
            ({ errorMessage, response }) => {
                if (errorMessage) {
                    errorToast(errorMessage);
                } else if (response) {
                    reset({
                        title: response.title,
                        description: response.description,
                        tags: response.tags.map((x) => ({
                            label: x.name,
                            value: TaskPostTagApi.toResourceUrl(x.id),
                            id: x.id,
                        })),
                        documents: response.documents as never,
                        color: response.color,
                    });

                    setEditId(response.id);
                    setDrawer({ show: true, id: response.id });
                    setVoteDetails(response.voteDetails);
                    setColorOverride(response.colorOverride);
                }
            },
        );
    };

    const handleEdit = (id: number, payload: PTaskPost) => {
        TaskPostApi.update<TaskPost, PTaskPost>(id, payload).then(
            ({ response, errorMessage }) => {
                if (errorMessage) {
                    errorToast(errorMessage);
                } else if (response !== null) {
                    actionUpdateTaskPost(
                        taskId,
                        subTask.id,
                        response.id,
                        response,
                    );
                }
            },
        );
    };

    const handleDelete = (id: number) => {
        TaskPostApi.deleteById(id).then(({ errorMessage }) => {
            if (errorMessage) {
                errorToast(errorMessage);
            } else {
                setShowConfirm(0);
                actionDeleteTaskPost(taskId, subTask.id, id);
            }
        });
    };

    const getVotePercentage = (colorVoteValue: string) => {
        let percentage = 0;

        if (voteDetails && Object.keys(voteDetails)?.length > 0) {
            const totalVote = Object.values(voteDetails).reduce(
                (total, x) => total + x,
                0,
            );

            if (voteDetails[colorVoteValue]) {
                percentage =
                    ((voteDetails[colorVoteValue] as number) * 100) / totalVote;
            }
        }

        return `${(percentage ?? 0).toFixed(0)}%`;
    };

    const getVotePercentageWithCount = (colorVoteValue: string) => {
        const count = voteDetails?.[colorVoteValue] || 0;
        const percentage = getVotePercentage(colorVoteValue);

        return `${count} (${percentage})`;
    };

    const handleOnVotingClick = (id: number) => {
        if (!(map?.isVoting && map?.isVotingOpen)) {
            return;
        }

        setLoading(true);

        VoteApi.find<Vote>(1, {
            "creator.id": userId,
            "taskPost.id": id,
        })
            .then(({ response, errorMessage }) => {
                if (errorMessage) {
                    errorToast(errorMessage);
                } else if (response !== null) {
                    if (response.items.length > 0) {
                        const [item] = response.items;
                        setSelectedColorVote(item.colorVote);
                    } else {
                        setSelectedColorVote("");
                    }
                }
            })
            .finally(() => setLoading(false));
    };

    const handleOnVotingChange = (item: TaskPost, selectedValue: string) => {
        if (map?.isVoting && map?.isVotingOpen) {
            if (!activeClient || !map) {
                return;
            }

            const payload = Vote.createForm(
                ClientApi.toResourceUrl(activeClient.id),
                MapApi.toResourceUrl(map.id),
                TaskPostApi.toResourceUrl(item.id),
                {
                    colorVote:
                        selectedColorVote !== selectedValue
                            ? selectedValue
                            : "",
                },
            );

            VoteApi.create<Vote, PVote>(payload).then(
                ({ response, errorMessage }) => {
                    if (errorMessage) {
                        errorToast(errorMessage);
                    } else if (response !== null) {
                        //
                    }
                },
            );
        } else {
            handleEdit(item.id, {
                color: item.color === selectedValue ? "" : selectedValue,
            });
        }
    };

    useEffect(() => {
        const subscription = watch((_, { name }) => {
            trigger(name);
        });
        return () => subscription.unsubscribe();
    }, [watch, trigger, formState.dirtyFields]);

    const renderForm = () => (
        <FormProvider {...methods}>
            <AppForm className="task-post-form">
                <AppFormContent>
                    <AppFormInput
                        id="title"
                        name="title"
                        label={t("app.taskPost.form:label.title")}
                        placeholder={t("app.taskPost.form:placeholder.title")}
                        block
                        maxCount={validation.title.max}
                    />

                    <AppFormTextarea
                        id="description"
                        name="description"
                        label={t("app.taskPost.form:label.description")}
                        placeholder={t(
                            "app.taskPost.form:placeholder.description",
                        )}
                        required={false}
                        block
                        rows={5}
                        maxCount={validation.description.max}
                        isEditor
                    />

                    <AppFormUploader
                        id="documents"
                        name="documents"
                        label={t("app.taskPost.form:label.documents")}
                        required={false}
                        block
                        fileInfo={DocumentFileInfo}
                        cbFileUploading={setIsUploading}
                        maxFiles={10}
                    />

                    <AppFormSelect
                        id="tags"
                        name="tags"
                        label={t("app.taskPost.form:label.tags")}
                        placeholder={t("app.taskPost.form:placeholder.tags")}
                        required={false}
                        block
                        options={taskPostTagsOptions}
                        isCreatable
                    />

                    <AppFormGroup block className="vote-color">
                        <AppFormLabel
                            controlId="color"
                            label={t("app.taskPost.form:label.color")}
                        />

                        <Row className="vote-color--container py-3 mx-0">
                            {map?.isVoting
                                ? getVotingColors(map?.isVoting).map((x) => (
                                      <Col
                                          xs={12}
                                          key={x.value}
                                          className="my-3 d-flex gap-2 align-items-center justify-content-between"
                                      >
                                          <div className="vote-color--container--bar">
                                              <AppSVGIcon
                                                  icon={x.icon}
                                                  size="xl"
                                              />

                                              <div
                                                  className="vote-color--indicator"
                                                  style={{
                                                      backgroundColor: x.color,
                                                  }}
                                              />

                                              <div className="vote-progress">
                                                  <div
                                                      className="vote-progress-bar"
                                                      style={{
                                                          width: getVotePercentage(
                                                              x.value,
                                                          ),
                                                          backgroundColor:
                                                              x.color,
                                                      }}
                                                  />
                                              </div>
                                          </div>
                                          {!map?.isVotingOpen && (
                                              <div className="vote-progress--override">
                                                  {x.value !==
                                                      TASK_POST_COLOR.TASKPOST_COLOR_FIVE && (
                                                      <>
                                                          <input
                                                              className="vote-progress--override--radio"
                                                              disabled={
                                                                  !isOwner &&
                                                                  !isSuperAdmin &&
                                                                  !isClientAdmin
                                                              }
                                                              id={`color-${x.value}`}
                                                              type="radio"
                                                              name="color"
                                                              value={x.value}
                                                              checked={
                                                                  colorOverride ===
                                                                  x.value
                                                              }
                                                              onClick={() =>
                                                                  setColorOverride(
                                                                      colorOverride ===
                                                                          x.value
                                                                          ? ""
                                                                          : x.value,
                                                                  )
                                                              }
                                                          />

                                                          <AppTooltip
                                                              id={
                                                                  "Info-overrideColor"
                                                              }
                                                              placement="bottom-end"
                                                              overlay={
                                                                  <span className="paragraph--300">
                                                                      {t(
                                                                          "app.taskPost.form:tooltip.overrideColor.radio",
                                                                      )}
                                                                  </span>
                                                              }
                                                          >
                                                              {colorOverride ===
                                                                  x.value && (
                                                                  <AppSVGIcon
                                                                      icon="help"
                                                                      size="sm"
                                                                  />
                                                              )}
                                                          </AppTooltip>
                                                      </>
                                                  )}
                                              </div>
                                          )}
                                          <span className="percentage">
                                              {getVotePercentageWithCount(
                                                  x.value,
                                              )}
                                          </span>
                                      </Col>
                                  ))
                                : getVotingColors(map?.isVoting).map((x) => (
                                      <Col
                                          xs={6}
                                          key={x.value}
                                          className="my-2"
                                      >
                                          <MapVoteCheckBox
                                              id={`color-${x.value}`}
                                              name="color"
                                              checked={colorValue === x.value}
                                              label={x.icon}
                                              value={x.color}
                                              title={x.title}
                                              onChange={() =>
                                                  setValue(
                                                      "color",
                                                      colorValue === x.value
                                                          ? ""
                                                          : x.value,
                                                  )
                                              }
                                          />
                                      </Col>
                                  ))}
                        </Row>
                    </AppFormGroup>
                </AppFormContent>
            </AppForm>
        </FormProvider>
    );

    const renderItem = (item: TaskPost, provided: DraggableProvided) => {
        // const selectedColor = map?.isVoting ? item.colorVote : item.color;

        const selectedColor = map?.isVoting
            ? map.isVotingOpen
                ? item.colorVote
                : item.colorOverride || item.colorVote
            : item.color;

        const itemColor = getVotingColors().find(
            (x) => x.value === selectedColor,
        )?.color;

        const taskPostActionStyle = itemColor
            ? {
                  backgroundImage: `linear-gradient(${itemColor}94, ${itemColor}1a)`,
              }
            : {};

        return (
            <div
                className={`task-post-container--item ${
                    editId === item.id && editId === drawer.id
                        ? "active-item"
                        : ""
                } ${
                    appliedFilter.filteredTaskPostIds.includes(item.id)
                        ? "filter-item"
                        : ""
                } ${
                    appliedFilter.filteredTaskPostIds.length &&
                    !appliedFilter.filteredTaskPostIds.includes(item.id)
                        ? "un-filter-item"
                        : ""
                } ${lockTaskPostId === item.id ? "lock" : ""}`}
                ref={provided.innerRef}
                {...provided.draggableProps}
            >
                {lockTaskPostId === item.id && (
                    <div className="display-name">{lockDisplayName}</div>
                )}

                <div
                    className="task-post-container--item--action"
                    style={taskPostActionStyle}
                    onDoubleClick={() => onEdit(item.id)}
                >
                    <div className="d-flex gap-1">
                        <AppSVGIcon
                            icon="drag-n-drop"
                            {...provided.dragHandleProps}
                            hidden={!isWrite || lockTaskPostId === item.id}
                        />

                        {!map?.isVoting &&
                            parseFloat(item.colorChange) !== 0 && (
                                <div className="d-flex align-items-center">
                                    <AppSVGIcon
                                        icon={`${
                                            parseFloat(item.colorChange) > 0
                                                ? "arrow-percentage_up"
                                                : "arrow-percentage_down"
                                        }`}
                                        size="xs"
                                        className={`${
                                            parseFloat(item.colorChange) > 0
                                                ? "green"
                                                : "red"
                                        }`}
                                    />
                                    <span className="paragraph--300 font-weight--bold">
                                        {Math.abs(parseInt(item.colorChange))}%
                                    </span>
                                </div>
                            )}
                    </div>

                    {lockTaskPostId !== item.id && (
                        <div className="d-flex align-items-center gap-1">
                            {(!map?.isVoting ||
                                (map?.isVoting && map?.isVotingOpen)) && (
                                <MapVoteColorSelector
                                    onVotingClick={() =>
                                        handleOnVotingClick(item.id)
                                    }
                                >
                                    <Row className="map-color-dropdown--content--row">
                                        {loading && (
                                            <AppLoader
                                                className="overlay-loader"
                                                size="sm"
                                            />
                                        )}

                                        {getVotingColors(map?.isVoting).map(
                                            (x) => (
                                                <Col
                                                    xs={12}
                                                    key={x.value}
                                                    className="mb-2 ps-4"
                                                >
                                                    <MapVoteCheckBox
                                                        id={`color-${x.value}`}
                                                        name="color"
                                                        checked={
                                                            map?.isVoting
                                                                ? selectedColorVote ===
                                                                  x.value
                                                                : item.color ===
                                                                  x.value
                                                        }
                                                        label={x.icon}
                                                        title={x.title}
                                                        value={x.color}
                                                        onChange={() =>
                                                            handleOnVotingChange(
                                                                item,
                                                                x.value,
                                                            )
                                                        }
                                                        disabled={loading}
                                                    />
                                                </Col>
                                            ),
                                        )}
                                    </Row>
                                </MapVoteColorSelector>
                            )}
                            {item.isTag && (
                                <AppSVGIcon
                                    icon="tag"
                                    onClick={() => onEdit(item.id)}
                                    className="cursor-pointer"
                                />
                            )}

                            {item.documents?.length > 0 && (
                                <AppSVGIcon
                                    icon="attach"
                                    onClick={() => onEdit(item.id)}
                                    className="cursor-pointer"
                                    size="sm"
                                />
                            )}
                            {isWrite && (
                                <AppSVGIcon
                                    icon="close"
                                    className="cursor-pointer"
                                    onClick={() => setShowConfirm(item.id)}
                                />
                            )}
                        </div>
                    )}
                </div>

                <div
                    className="task-post-container--item--content"
                    onDoubleClick={() => setShowInput(item.id)}
                >
                    <AppInlineInput
                        size="xs"
                        show={showInput === item.id && isWrite}
                        initialText={item.title}
                        onHide={() => setShowInput(0)}
                        onUpdate={(text) =>
                            handleEdit(item.id, { title: text })
                        }
                        maxLength={validation.title.max}
                        inputType="area"
                    >
                        <AppTooltip
                            id={item.title}
                            overlay={
                                <span className="paragraph--400">
                                    {item.title}
                                </span>
                            }
                        >
                            <span
                                className={`title ${
                                    !item.description
                                        ? "title--full-height"
                                        : ""
                                }`}
                            >
                                {item.title}
                            </span>
                        </AppTooltip>

                        <AppDisplayEditorContent
                            className="description"
                            content={item.description}
                        />
                    </AppInlineInput>

                    {/* {lockTaskPostId !== item.id && isWrite && (
                        <AppSVGIcon
                            icon="edit"
                            size="xs"
                            className="edit-icon"
                            onClick={() => onEdit(item.id)}
                        />
                    )} */}
                </div>
            </div>
        );
    };

    useEffect(() => {
        Socket.on(
            EVENTS.ON_MAP_LOCK_TASK_POST,
            (user: PUser, taskPosId: number, isLock: boolean) => {
                setLockTaskPostId(isLock ? taskPosId : 0);
                setLockDisplayName(user.firstName + " " + user.lastName);
            },
        );
    }, []);

    return (
        <>
            <AppConfirmModal
                show={!!showConfirm}
                icon="warning"
                title="app.taskPost.item:delete.confirm.modal.title"
                description="app.taskPost.item:delete.confirm.modal.description"
                onHide={() => setShowConfirm(0)}
                nextAction={() => handleDelete(showConfirm)}
            />

            <AppFormModal
                show={drawer.show && drawer.id === editId}
                icon="description"
                // title="app.taskPost.form:modal.title"
                title={titleValue}
                isDrawer
                onSubmit={handleSubmit(onSubmitHandler)}
                onHide={() => {
                    resetDrawer();
                    setEditId(0);
                }}
                isLoading={formState.isSubmitting || loading}
                disabled={!activeClient || isUploading || isRead}
                isEditMode={!!editId}
                disabledSumbit={!formState.isValid}
            >
                {renderForm()}
            </AppFormModal>

            <AppDraggableList<TaskPost>
                withoutContext
                droppableId={`task-${taskId}-${subTask.id}`}
                type={MapItemEnum.TASK_POST}
                direction="horizontal"
                data={subTask.taskPosts || []}
                renderItem={renderItem}
                renderWrapper={(children, providedMain, snapshot) => (
                    <div
                        className={`sub-task-container--task-posts task-post-container ${
                            snapshot.isDraggingOver
                                ? "draggable-container--dropable"
                                : ""
                        }`}
                        ref={providedMain.innerRef}
                        {...providedMain.droppableProps}
                    >
                        {children}

                        {isWrite && (
                            <AppButton
                                variant="secondary"
                                className="btn-square btn-sm"
                                onClick={handleCreate}
                            >
                                <AppSVGIcon icon="plus" />
                            </AppButton>
                        )}
                    </div>
                )}
            />
        </>
    );
};
