import { FC } from "react";
import { useNavigate } from "react-router-dom";

import "./assets/scss/style.scss";

import { AppLoader } from "../../../components";
import { SubscriptionPlanCards } from "../../components";
import { useSubscriptionAction } from "../../../hooks";

export const SubscriptionPricingPage: FC = () => {
    // hooks
    const navigate = useNavigate();

    // context
    const { isLoading, myClient } = useSubscriptionAction();

    // state

    if (!myClient) {
        return <></>;
    }

    return (
        <div className="subscription-pricing-page">
            {isLoading && <AppLoader isFullScreen />}

            <div className="subscription-pricing-page__content">
                <SubscriptionPlanCards
                    onSubmit={() => navigate(`/admin/subscription/checkout`)}
                />
            </div>
        </div>
    );
};
