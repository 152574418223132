import * as yup from "yup";
import { PhoneNumberUtil } from "google-libphonenumber";
import { TKey, validation } from "../../../utils";

const phoneUtil = PhoneNumberUtil.getInstance();

export const schemaClientAdmin = (t: (val: string) => string) =>
    yup.object().shape({
        email: yup
            .string()
            .email()
            .required(t(TKey.Common.Message.Validation.RequiredField)),
        firstName: yup
            .string()
            .required(t(TKey.Common.Message.Validation.RequiredField))
            .max(validation.title.max),
        lastName: yup
            .string()
            .required(t(TKey.Common.Message.Validation.RequiredField))
            .max(validation.title.max),
        company: yup
            .string()
            .required(t(TKey.Common.Message.Validation.RequiredField))
            .max(validation.title.max),
        locale: yup
            .string()
            .required(t(TKey.Common.Message.Validation.RequiredField)),
        imageName: yup
            .string()
            .transform((value) => (value?.length ? value : "")),
    });

export const schemaUser = (t: (val: string) => string) =>
    yup.object().shape({
        firstName: yup
            .string()
            .required(t(TKey.Common.Message.Validation.RequiredField))
            .max(validation.title.max),
        lastName: yup
            .string()
            .required(t(TKey.Common.Message.Validation.RequiredField))
            .max(validation.title.max),
        company: yup
            .string()
            .required(t(TKey.Common.Message.Validation.RequiredField))
            .max(validation.title.max),
        imageName: yup
            .string()
            .transform((value) => (value?.length ? value : "")),
        plainPassword: yup
            .string()
            .required(t(TKey.Common.Message.Validation.RequiredField))
            .min(8)
            .matches(
                /[0-9]/,
                t(TKey.Common.Message.Validation.RequiredOneNumber),
            )
            .matches(
                /[_\W]/,
                t(TKey.Common.Message.Validation.RequiredSpecialCharacter),
            )
            .max(32),
        confirmPassword: yup
            .string()
            .oneOf(
                [yup.ref("plainPassword")],
                t(TKey.Common.Message.Validation.RequiredPasswordMatch),
            )
            .required(t(TKey.Common.Message.Validation.RequiredField)),
        agree: yup
            .boolean()
            .oneOf(
                [true],
                t("user.register.form:validation.required.termsAndCondition"),
            )
            .required(t(TKey.Common.Message.Validation.RequiredField)),
        isPasswordStrong: yup.boolean(),
    });

export const schemaClient = (t: (val: string) => string) =>
    yup.object().shape({
        name: yup
            .string()
            .required(t(TKey.Common.Message.Validation.RequiredField))
            .max(validation.title.max),
        address: yup
            .string()
            .required(t(TKey.Common.Message.Validation.RequiredField))
            .max(validation.title.max),
        region: yup.string().nullable().max(validation.title.max),
        postCode: yup
            .string()
            .required(t(TKey.Common.Message.Validation.RequiredField)),
        city: yup
            .string()
            .required(t(TKey.Common.Message.Validation.RequiredField))
            .max(validation.title.max),
        country: yup
            .string()
            .required(t(TKey.Common.Message.Validation.RequiredField)),
        phone: yup
            .string()
            .nullable()
            .test(
                "is-valid-phone",
                t(TKey.Common.Message.Validation.InvalidPhoneNumber),
                (value) => {
                    if (value === null || value === undefined) return true;
                    try {
                        return phoneUtil.isValidNumber(
                            phoneUtil.parseAndKeepRawInput(value || ""),
                        );
                    } catch (error) {
                        return false;
                    }
                },
            ),

        contactBill: yup.string().nullable().max(validation.title.max),
        vatNo: yup.string().nullable().max(validation.title.max),
        isSameAsCompany: yup.boolean(),
        addressBill: yup.string().nullable().max(validation.title.max),
        regionBill: yup.string().nullable().max(validation.title.max),
        postCodeBill: yup.string().nullable(),
        cityBill: yup.string().nullable().max(validation.title.max),
        countryBill: yup.string().nullable(),
    });
