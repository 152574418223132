import { EntityAPI } from "./core";
import { FinalResponse, ROUTES, route } from "../config";

const {
    api_sub_tasks_get_collection: API_GET_COLLECTION,
    api_sub_tasks_post_collection: API_POST_COLLECTION,
    api_sub_tasks_get_item: API_GET_ITEM,
    api_sub_tasks_put_item: API_PUT_ITEM,
    api_sub_tasks_patch_item: API_PATCH_ITEM,
    api_sub_tasks_delete_item: API_DELETE_ITEM,
    api_sub_tasks_patch_change_order_item: API_PATCH_CHANGE_ORDER_ITEM,
} = ROUTES;

export abstract class SubTaskApi extends EntityAPI {
    protected static GET_COLLECTION = API_GET_COLLECTION;

    protected static POST_COLLECTION = API_POST_COLLECTION;

    protected static GET_ITEM = API_GET_ITEM;

    protected static PUT_ITEM = API_PUT_ITEM;

    protected static PATCH_ITEM = API_PATCH_ITEM;

    protected static DELETE_ITEM = API_DELETE_ITEM;

    public static async changeOrder<R, P>(
        id: number,
        payload: P,
    ): Promise<FinalResponse<R | null>> {
        return this.makePatch<R, P>(
            route(API_PATCH_CHANGE_ORDER_ITEM, { id }),
            JSON.stringify(payload),
        )
            .then(({ data }) => this.handleResponse<R>(data))
            .catch((e) => this.handleAllError(e));
    }
}
