import { FC, PropsWithChildren, createContext, useState } from "react";

export interface GlobalState {
    isLoading: boolean;
}

const initialState: GlobalState = {
    isLoading: false,
};

export const GlobalContext = createContext<GlobalState>(initialState);

export const GlobalProvider: FC<PropsWithChildren> = ({
    children,
}: PropsWithChildren): JSX.Element => {
    // state
    const [state] = useState(initialState);

    return (
        <GlobalContext.Provider value={state}>
            {children}
        </GlobalContext.Provider>
    );
};
