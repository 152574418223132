import {
    addDays,
    format,
    formatDistance,
    formatDistanceStrict,
    parse,
    subDays,
} from "date-fns";
import { DateTimeEnum } from "../utils";

type DateTimeType = {
    parseDate: (date: string, format: string) => Date;
    toDbDateFormat: (date: number | Date | string) => string;
    toDbTimeFormat: (date: number | Date | string) => string;
    toDbDateTimeFormat: (date: number | Date | string) => string;
    toShortDate: (date: number | Date | string) => string;
    toShortTime: (date: number | Date | string) => string;
    toLongDate: (date: number | Date | string) => string;
    toLongTime: (date: number | Date | string) => string;
    toLongDateTime: (date: number | Date | string) => string;
    toShortDateTime: (date: number | Date | string) => string;
    toGetDay: (date: number | Date | string) => string;
    toGetDayOfWeak: (date: number | Date | string) => string;
    toGetSortDayOfWeak: (date: number | Date | string) => string;
    toGetMonth: (date: number | Date | string) => string;
    toGetSortMonth: (date: number | Date | string) => string;
    toGetYear: (date: number | Date | string) => string;
    timeAgo: (date: number | Date | string) => string;
    timeLeft: (date: number | Date | string) => string;
    futureDate: (date: number | Date, days: number) => Date;
    pastDate: (date: number | Date, days: number) => Date;
};

export function useDateTime(): DateTimeType {
    const humanReadableDate = (
        date: Date,
        toFormat: string,
        defaultFormat?: string,
    ): string => {
        try {
            return format(date, toFormat);
            // return format(date, toFormat, { locale });
        } catch (e) {
            if (defaultFormat) {
                return format(date, defaultFormat);
                // return format(date, defaultFormat, { locale });
            }
            return "Invalid Date";
        }
    };

    const parseDate = (date: string, dateFormat: string): Date =>
        parse(date, dateFormat, new Date());

    const toDbDateTimeFormat = (date: number | Date | string): string =>
        humanReadableDate(
            new Date(date),
            DateTimeEnum.dbDateFormat + " " + DateTimeEnum.dbTimeFormat,
        );

    const toDbDateFormat = (date: number | Date | string): string =>
        humanReadableDate(new Date(date), DateTimeEnum.dbDateFormat);

    const toDbTimeFormat = (date: number | Date | string): string =>
        humanReadableDate(new Date(date), DateTimeEnum.dbTimeFormat);

    const toShortDate = (date: number | Date | string): string =>
        humanReadableDate(new Date(date), DateTimeEnum.shortDate);

    const toShortTime = (date: number | Date | string): string =>
        humanReadableDate(new Date(date), DateTimeEnum.shortTime);

    const toLongDate = (date: number | Date | string): string =>
        humanReadableDate(new Date(date), DateTimeEnum.longDate);

    const toLongTime = (date: number | Date | string): string =>
        humanReadableDate(new Date(date), DateTimeEnum.longTime);

    const toShortDateTime = (date: number | Date | string): string =>
        humanReadableDate(new Date(date), DateTimeEnum.shortDateTime);

    const toLongDateTime = (date: number | Date | string): string =>
        humanReadableDate(new Date(date), DateTimeEnum.longDateTime);

    const toGetDay = (date: number | Date | string): string =>
        humanReadableDate(new Date(date), DateTimeEnum.getDay);

    const toGetDayOfWeak = (date: number | Date | string): string =>
        humanReadableDate(new Date(date), DateTimeEnum.getDayOfWeak);

    const toGetSortDayOfWeak = (date: number | Date | string): string =>
        humanReadableDate(new Date(date), DateTimeEnum.getSortDayOfWeak);

    const toGetMonth = (date: number | Date | string): string =>
        humanReadableDate(new Date(date), DateTimeEnum.getMonth);

    const toGetSortMonth = (date: number | Date | string): string =>
        humanReadableDate(new Date(date), DateTimeEnum.getSortMonth);

    const toGetYear = (date: number | Date | string): string =>
        humanReadableDate(new Date(date), DateTimeEnum.getYear);

    const timeAgo = (date: number | Date | string): string => {
        try {
            return formatDistance(new Date(date), new Date(), {
                includeSeconds: true,
            });
        } catch (error) {
            return "";
        }
    };

    const timeLeft = (date: number | Date | string): string => {
        try {
            return formatDistanceStrict(new Date(), new Date(date));
        } catch (error) {
            return "";
        }
    };

    const futureDate = (date: number | Date, days: number) =>
        addDays(date, days);

    const pastDate = (date: number | Date, days: number) => subDays(date, days);

    return {
        parseDate,
        toDbDateFormat,
        toDbTimeFormat,
        toDbDateTimeFormat,
        toShortDate,
        toShortTime,
        toLongDate,
        toLongTime,
        toLongDateTime,
        toShortDateTime,
        toGetDay,
        toGetDayOfWeak,
        toGetSortDayOfWeak,
        toGetMonth,
        toGetSortMonth,
        toGetYear,
        timeAgo,
        timeLeft,
        futureDate,
        pastDate,
    };
}
