import { FC } from "react";

import "./assets/scss/style.scss";

import { AppFormActions, AppFormActionsProps } from "../AppFormActions";
import {
    AppBaseModal,
    AppBaseModalBody,
    AppBaseModalFooter,
    AppBaseModalHeader,
    AppBaseModalProps,
} from "../../AppBaseModal";

interface AppFormModalProps
    extends AppBaseModalProps,
        Pick<
            AppFormActionsProps,
            | "isLoading"
            | "isEditMode"
            | "disabled"
            | "createLabel"
            | "cancelLabel"
        > {
    onSubmit?: () => void;
    onCancelOrReset?: () => void;
    disabled?: boolean;
    disabledSumbit?: boolean;
}

export const AppFormModal: FC<AppFormModalProps> = ({
    show,
    onHide,
    size = "lg",
    icon,
    title,
    className = "",
    isLoading,
    isEditMode,
    disabled,
    disabledSumbit,
    createLabel,
    cancelLabel,
    onSubmit,
    onCancelOrReset,
    isDrawer,
    children,
}) => (
    <AppBaseModal
        show={show}
        onHide={onHide}
        className={`app-form-modal ${className}`}
        size={size}
        isDrawer={isDrawer}
    >
        <AppBaseModalHeader icon={icon} title={title} onHide={onHide} />

        <AppBaseModalBody>{children}</AppBaseModalBody>

        <AppBaseModalFooter>
            <AppFormActions
                backAction={onCancelOrReset ? onCancelOrReset : onHide}
                nextAction={onSubmit}
                isLoading={isLoading}
                disabled={disabled}
                disabledSumbit={disabledSumbit}
                isEditMode={isEditMode}
                createLabel={createLabel}
                cancelLabel={cancelLabel}
            />
        </AppBaseModalFooter>
    </AppBaseModal>
);
