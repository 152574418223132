import { FC } from "react";
import Slider, { SliderProps } from "rc-slider";

import "rc-slider/assets/index.css";
import "./assets/scss/style.scss";

export interface AppSliderProps
    extends Pick<SliderProps, "min" | "max" | "step"> {
    value?: number;
    onChange: (value: number) => void;
}

export const AppSlider: FC<AppSliderProps> = ({
    value,
    min,
    max,
    step,
    onChange,
}) => (
    <div className="app-slider">
        <Slider
            min={min}
            max={max}
            step={step}
            value={value}
            className="app-slider--input"
            onChange={(val) => onChange(val as number)}
        />
    </div>
);
