import { FC, ReactNode } from "react";

import "./assets/scss/style.scss";

export interface AppFormLayoutProps {
    xs?: string | number;
    sm?: string | number;
    md?: string | number;
    lg?: string | number;
    xl?: string | number;
    xxl?: string | number;
    block?: boolean | string | number;
    auto?: boolean;
}

interface AppFormProps
    extends React.DetailedHTMLProps<
        React.FormHTMLAttributes<HTMLFormElement>,
        HTMLFormElement
    > {
    children?: ReactNode;
}

export const AppForm: FC<AppFormProps> = ({
    children,
    className = "",
    ...props
}) => (
    <form className={`app-form-container ${className}`} {...props}>
        {children}
    </form>
);

// TODO:: to remove later on
// Wrapper component to include the Input property
// interface AppFormComponent extends FC<AppFormProps> {
//     Group: typeof AppFormGroup;
//     InputWrapper: typeof AppFormInputWrapper;
//     Content: typeof AppFormContent;
//     Label: typeof AppFormLabel;
//     Input: typeof AppFormInput;
//     Textarea: typeof AppFormTextarea;
//     PhoneNumber: typeof AppFormPhoneNumber;
//     CheckBox: typeof AppFormCheckBox;
//     Message: typeof AppFormMessage;
//     Actions: typeof AppFormActions;
// }

// const AppFormWrapper: AppFormComponent = Object.assign(AppForm, {
//     Group: AppFormGroup,
//     InputWrapper: AppFormInputWrapper,
//     Content: AppFormContent,
//     Label: AppFormLabel,
//     Input: AppFormInput,
//     Textarea: AppFormTextarea,
//     PhoneNumber: AppFormPhoneNumber,
//     CheckBox: AppFormCheckBox,
//     Message: AppFormMessage,
//     Actions: AppFormActions,
// });

// export { AppFormWrapper as AppForm };
