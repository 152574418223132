import React, {
    FC,
    PropsWithChildren,
    createContext,
    useEffect,
    useReducer,
} from "react";
import { useNavigate } from "react-router-dom";

import { Card, Client, Invoice } from "../models";
import { ClientApi, InvoiceApi } from "../apis";
import { SubscriptionActionEnum, errorToast } from "../utils";
import { useRoles } from "../hooks";
import { PLAN_ENTERPRISE } from "../config";

export interface SubscriptionState {
    isLoading: boolean;
    myClient: Client | null;
    refetch: boolean;
    currentInvoice: Invoice | null;
    invoice: Invoice | null;
    primaryCard: Card | null;
    cards: Card[];
    isPurchaseSuccess: boolean;
}

const initialState: SubscriptionState = {
    isLoading: false,
    myClient: null,
    refetch: true,
    currentInvoice: null,
    invoice: null,
    primaryCard: null,
    cards: [],
    isPurchaseSuccess: false,
};

type SubscriptionAction =
    | { type: SubscriptionActionEnum.SHOW_LOADING }
    | { type: SubscriptionActionEnum.HIDE_LOADING }
    | { type: SubscriptionActionEnum.SET_MY_CLIENT; payload: Client }
    | { type: SubscriptionActionEnum.REFETCH_MY_CLIENT }
    | {
          type: SubscriptionActionEnum.FETCH_CURRENT_INVOICE;
          payload: Invoice;
      }
    | { type: SubscriptionActionEnum.INVOICE_CREATE; payload: Invoice }
    | { type: SubscriptionActionEnum.INVOICE_PAY; payload: boolean }
    | {
          type: SubscriptionActionEnum.FETCH_CARDS;
          payload: { primaryCard: Card | null; cards: Card[] };
      };

const subscriptionReducer = (
    state: SubscriptionState,
    action: SubscriptionAction,
): SubscriptionState => {
    switch (action.type) {
        case SubscriptionActionEnum.SHOW_LOADING:
            return {
                ...state,
                isLoading: true,
            };

        case SubscriptionActionEnum.HIDE_LOADING:
            return {
                ...state,
                isLoading: false,
            };

        case SubscriptionActionEnum.SET_MY_CLIENT:
            return {
                ...state,
                isLoading: false,
                refetch: false,
                myClient: action.payload,
            };

        case SubscriptionActionEnum.REFETCH_MY_CLIENT:
            return {
                ...state,
                refetch: true,
            };

        case SubscriptionActionEnum.FETCH_CURRENT_INVOICE:
            return {
                ...state,
                currentInvoice: action.payload,
            };

        case SubscriptionActionEnum.INVOICE_CREATE:
            return {
                ...state,
                invoice: action.payload,
                isPurchaseSuccess: false,
            };

        case SubscriptionActionEnum.INVOICE_PAY:
            return {
                ...state,
                isPurchaseSuccess: action.payload,
            };

        case SubscriptionActionEnum.FETCH_CARDS:
            return {
                ...state,
                cards: action.payload.cards,
                primaryCard: action.payload.primaryCard,
            };

        default:
            return state;
    }
};

interface SubscriptionContextType {
    state: SubscriptionState;
    dispatch: React.Dispatch<SubscriptionAction>;
}

export const SubscriptionContext = createContext<SubscriptionContextType>({
    state: initialState,
    dispatch: () => null,
});

export const SubscriptionProvider: FC<PropsWithChildren> = ({ children }) => {
    // reducer
    const [state, dispatch] = useReducer(subscriptionReducer, initialState);

    // hooks
    const { isClientAdmin } = useRoles();
    const navigate = useNavigate();

    useEffect(() => {
        if (!isClientAdmin) {
            navigate("/");
        }
    }, []);

    const fetchInvoice = (clientId: number) => {
        dispatch({
            type: SubscriptionActionEnum.SHOW_LOADING,
        });

        InvoiceApi.find<Invoice>(1, {
            pagination: true,
            "client.id": clientId,
            "order[id]": "desc",
            status: ["PAID"],
            "exists[parent]": false,
            itemsPerPage: 1,
        })
            .then(({ response, errorMessage }) => {
                if (errorMessage) {
                    errorToast(errorMessage);
                } else if (response !== null) {
                    if (response.items.length > 0) {
                        dispatch({
                            type: SubscriptionActionEnum.FETCH_CURRENT_INVOICE,
                            payload: response.items[0],
                        });
                    }
                }
            })
            .finally(() =>
                dispatch({
                    type: SubscriptionActionEnum.HIDE_LOADING,
                }),
            );
    };

    const fetchClientInfo = () => {
        dispatch({
            type: SubscriptionActionEnum.SHOW_LOADING,
        });

        ClientApi.getMyClient<Client>()
            .then(({ errorMessage, response }) => {
                if (errorMessage) {
                    errorToast(errorMessage);
                } else if (response) {
                    dispatch({
                        type: SubscriptionActionEnum.SET_MY_CLIENT,
                        payload: response,
                    });

                    fetchInvoice(response.id);
                }
            })
            .finally(() =>
                dispatch({
                    type: SubscriptionActionEnum.HIDE_LOADING,
                }),
            );
    };

    useEffect(() => {
        if (state.refetch) {
            fetchClientInfo();
        }
    }, [state.refetch]);

    useEffect(() => {
        if (
            state.myClient?.currentPlan &&
            state.myClient.currentPlan === PLAN_ENTERPRISE.name
        ) {
            navigate("/", { replace: true });
        }
    }, [state.myClient]);

    return (
        <SubscriptionContext.Provider value={{ state, dispatch }}>
            {children}
        </SubscriptionContext.Provider>
    );
};
