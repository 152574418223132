import * as yup from "yup";
import { PhoneNumberUtil } from "google-libphonenumber";
import { ProfileTabEnum, TKey, validation } from "../../utils";

const phoneUtil = PhoneNumberUtil.getInstance();

export const schema = (t: (val: string) => string, activeTab: ProfileTabEnum) =>
    yup.object().shape({
        // profile
        email: yup.string(),
        firstName: yup
            .string()
            .when([], {
                is: () => activeTab === ProfileTabEnum.PROFILE,
                then: (sc) =>
                    sc.required(
                        t(TKey.Common.Message.Validation.RequiredField),
                    ),
                otherwise: (sc) => sc.optional(),
            })
            .max(validation.title.max),
        lastName: yup
            .string()
            .when([], {
                is: () => activeTab === ProfileTabEnum.PROFILE,
                then: (sc) =>
                    sc.required(
                        t(TKey.Common.Message.Validation.RequiredField),
                    ),
                otherwise: (sc) => sc.optional(),
            })
            .max(validation.title.max),
        company: yup
            .string()
            .when([], {
                is: () => activeTab === ProfileTabEnum.PROFILE,
                then: (sc) =>
                    sc.required(
                        t(TKey.Common.Message.Validation.RequiredField),
                    ),
                otherwise: (sc) => sc.optional(),
            })
            .max(validation.title.max),
        locale: yup.string().when([], {
            is: () => activeTab === ProfileTabEnum.PROFILE,
            then: (sc) =>
                sc.required(t(TKey.Common.Message.Validation.RequiredField)),
            otherwise: (sc) => sc.optional(),
        }),

        // combine
        imageName: yup
            .string()
            .transform((value) => (value?.length ? value : ""))
            .when([], {
                is: () => activeTab === ProfileTabEnum.COMPANY_PROFILE,
                then: (sc) =>
                    sc.required(
                        t(TKey.Common.Message.Validation.RequiredField),
                    ),
                otherwise: (sc) => sc.optional(),
            }),

        // company profile
        name: yup
            .string()
            .when([], {
                is: () => activeTab === ProfileTabEnum.COMPANY_PROFILE,
                then: (sc) =>
                    sc.required(
                        t(TKey.Common.Message.Validation.RequiredField),
                    ),
                otherwise: (sc) => sc.optional(),
            })
            .max(validation.title.max),
        address: yup
            .string()
            .when([], {
                is: () => activeTab === ProfileTabEnum.COMPANY_PROFILE,
                then: (sc) =>
                    sc.required(
                        t(TKey.Common.Message.Validation.RequiredField),
                    ),
                otherwise: (sc) => sc.optional(),
            })
            .max(validation.title.max),
        postCode: yup.string().when([], {
            is: () => activeTab === ProfileTabEnum.COMPANY_PROFILE,
            then: (sc) =>
                sc.required(t(TKey.Common.Message.Validation.RequiredField)),
            otherwise: (sc) => sc.optional(),
        }),
        city: yup.string().when([], {
            is: () => activeTab === ProfileTabEnum.COMPANY_PROFILE,
            then: (sc) =>
                sc.required(t(TKey.Common.Message.Validation.RequiredField)),
            otherwise: (sc) => sc.optional(),
        }),
        country: yup.string().when([], {
            is: () => activeTab === ProfileTabEnum.COMPANY_PROFILE,
            then: (sc) =>
                sc.required(t(TKey.Common.Message.Validation.RequiredField)),
            otherwise: (sc) => sc.optional(),
        }),
        phone: yup.string().when([], {
            is: () => activeTab === ProfileTabEnum.COMPANY_PROFILE,
            then: (sc) =>
                sc
                    .required(t(TKey.Common.Message.Validation.RequiredField))
                    .test(
                        "is-valid-phone",
                        t(TKey.Common.Message.Validation.InvalidPhoneNumber),
                        (value) => {
                            try {
                                return phoneUtil.isValidNumber(
                                    phoneUtil.parseAndKeepRawInput(value),
                                );
                            } catch (error) {
                                return false;
                            }
                        },
                    ),
            otherwise: (sc) => sc.optional(),
        }),

        // change password
        oldPassword: yup.string().when([], {
            is: () => activeTab === ProfileTabEnum.CHANGE_PASSWORD,
            then: (sc) =>
                sc.required(t(TKey.Common.Message.Validation.RequiredField)),
            otherwise: (sc) => sc.optional(),
        }),
        plainPassword: yup.string().when([], {
            is: () => activeTab === ProfileTabEnum.CHANGE_PASSWORD,
            then: (sc) =>
                sc
                    .required(t(TKey.Common.Message.Validation.RequiredField))
                    .min(8)
                    .matches(
                        /[0-9]/,
                        t(TKey.Common.Message.Validation.RequiredOneNumber),
                    )
                    .matches(
                        /[_\W]/,
                        t(
                            TKey.Common.Message.Validation
                                .RequiredSpecialCharacter,
                        ),
                    )
                    .max(32),
            otherwise: (sc) => sc.optional(),
        }),
        confirmPassword: yup
            .string()
            .oneOf(
                [yup.ref("plainPassword")],
                t(TKey.Common.Message.Validation.RequiredPasswordMatch),
            )
            .when([], {
                is: () => activeTab === ProfileTabEnum.CHANGE_PASSWORD,
                then: (sc) =>
                    sc.required(
                        t(TKey.Common.Message.Validation.RequiredField),
                    ),
                otherwise: (sc) => sc.optional(),
            }),
        isPasswordStrong: yup.boolean(),
    });
