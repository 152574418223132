import {
    DragDropContext,
    Draggable,
    Droppable,
    DraggableProvided,
    Direction,
    OnDragEndResponder,
    DroppableProvided,
    DraggableStateSnapshot,
    OnDragStartResponder,
    DroppableStateSnapshot,
} from "react-beautiful-dnd";

import "./assets/scss/style.scss";

interface AppDraggableListProps<T> {
    droppableId: string;
    data: T[];
    onDragEnd?: OnDragEndResponder;
    onDragStart?: OnDragStartResponder;
    renderItem: (
        item: T,
        provided: DraggableProvided,
        snapshot: DraggableStateSnapshot,
        index: number,
    ) => JSX.Element;
    renderWrapper: (
        children: JSX.Element,
        providedMain: DroppableProvided,
        snapshot: DroppableStateSnapshot,
    ) => JSX.Element;
    direction?: Direction;
    type?: string;
    withoutContext?: boolean;
}

export const AppDraggableList = <T extends { id: number }>({
    droppableId,
    data,
    onDragEnd = () => {},
    onDragStart = () => {},
    renderItem,
    renderWrapper,
    direction,
    type,
    withoutContext = false,
}: AppDraggableListProps<T>) => {
    const renderView = () => (
        <Droppable droppableId={droppableId} direction={direction} type={type}>
            {(providedMain, snapshot) =>
                renderWrapper(
                    <>
                        {data.map((item, index) => (
                            <Draggable
                                key={item.id}
                                index={index}
                                draggableId={`${droppableId}-${String(
                                    item.id, // !!Note:: don't change this string as it is dependents on API calls
                                )}`}
                            >
                                {(provided, snap) =>
                                    renderItem(item, provided, snap, index)
                                }
                            </Draggable>
                        ))}

                        {providedMain.placeholder}
                    </>,
                    providedMain,
                    snapshot,
                )
            }
        </Droppable>
    );

    if (withoutContext) {
        return renderView();
    }

    return (
        <DragDropContext onDragEnd={onDragEnd} onDragStart={onDragStart}>
            {renderView()}
        </DragDropContext>
    );
};
