import { FC } from "react";
import { useController, useFormContext } from "react-hook-form";
import PasswordStrengthBar from "react-password-strength-bar";

interface AppPasswordStrengthBarProps {
    show: boolean;
    password: string;
}

export const AppPasswordStrengthBar: FC<AppPasswordStrengthBarProps> = ({
    show,
    password,
}) => {
    // hooks
    const { control } = useFormContext();
    const { field } = useController({
        name: "isPasswordStrong",
        control,
    });

    if (!(show && password)) {
        return <></>;
    }

    return (
        <PasswordStrengthBar
            minLength={8}
            password={password}
            className={"app-password-strength-bar"}
            barColors={[
                "var(--app-primary-color-bg)",
                "var(--app-danger-color)",
                "var(--app-warning-color)",
                "var(--app-success-color)",
                "var(--app-success-color-hover)",
            ]}
            onChangeScore={(score) => field.onChange(score >= 3)}
        />
    );
};
