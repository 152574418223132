import imageCompression, { Options } from "browser-image-compression";
import { FileTypeInfo } from "../../models";

export const humanFileSize = (bytes = 0, dp = 1, si = false): string => {
    const thresh = si ? 1000 : 1024;
    let fileSize = bytes; // Create a local variable to store the modified value

    if (Math.abs(fileSize) < thresh) {
        return `${fileSize} B`;
    }

    const units = ["kB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];

    let unitIndex = -1;
    const r = 10 ** dp;

    while (
        Math.round(Math.abs(fileSize) * r) / r >= thresh &&
        unitIndex < units.length - 1
    ) {
        fileSize /= thresh;
        unitIndex += 1;
    }

    return `${fileSize.toFixed(dp)} ${units[unitIndex]}`;
};

export const prepareAllowedFileTypes = (allowType: string[] = []): string[] =>
    allowType.map((type) => `.${type}`);

export const getFileFromBlob = (blob: Blob, oldFile: File) =>
    new File([blob], oldFile.name, {
        type: oldFile.type,
        lastModified: oldFile.lastModified,
    });

export const compressImage = async (file: File, fileInfo?: FileTypeInfo) => {
    const [mediaType, mimeType] = file.type.split("/");

    if (mediaType !== "image" || mimeType === "gif") {
        return file;
    }

    let options: Options = {
        initialQuality: 1,
        maxWidthOrHeight: 1920,
        preserveExif: true, // preserve metadata as it is
    };

    if (fileInfo?.width && fileInfo?.height) {
        const { width, height } = fileInfo;

        options = {
            ...options,
            maxWidthOrHeight: +width > +height ? +width : +height,
        };
    }

    try {
        const blob = await imageCompression(file, options);
        return getFileFromBlob(blob, file);
    } catch (error) {
        return file;
    }
};
