import { FC, useEffect, useState } from "react";
import { DragStart, DraggableProvided, DropResult } from "react-beautiful-dnd";
import { FormProvider, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useRecoilState, useResetRecoilState } from "recoil";

import {
    AppActionDropDown,
    AppAvatar,
    AppButton,
    AppConfirmModal,
    AppDisplayEditorContent,
    AppDraggableList,
    AppForm,
    AppFormContent,
    AppFormInput,
    AppFormModal,
    AppFormSelect,
    AppFormTextarea,
    AppFormUploader,
    AppInlineInput,
    AppSVGIcon,
    AppTabs,
    AppTooltip,
} from "../../../components";
import { ActionTask, PActionTask, PUser, User } from "../../../models";
import {
    useDownloadFile,
    useMapAction,
    useTranslation,
    useWorkspacePermission,
} from "../../../hooks";
import { ActionTaskApi, ClientApi, MapApi, UserApi } from "../../../apis";
import {
    ActionTaskTabEnum,
    ModalTypeEnum,
    TKey,
    errorToast,
    normalizeName,
    setBackendViolations,
    successToast,
    validation,
} from "../../../utils";
import { atomActiveClient, atomDrawer, atomDrawerType } from "../../../atoms";
import { schemaActionTask } from "./schema";
import { DOCUMENT_TYPE, DocumentFileInfo } from "../../../config";
import { EVENTS, Socket } from "../../../Socket/Socket";
import { Link } from "react-router-dom";

interface MapDetailActionTasksProps {
    mapId: number;
    onDragStart: (start: DragStart) => void;
    onDragEnd: (result: DropResult) => void;
}

export const MapDetailActionTasks: FC<MapDetailActionTasksProps> = ({
    mapId,
    onDragStart,
    onDragEnd,
}) => {
    // hooks
    const { t } = useTranslation();
    const { isWrite, isRead } = useWorkspacePermission();
    const { downloadLink } = useDownloadFile();

    // context
    const {
        actionTasks,
        responsibleUserOptions,
        actionCreateActionTask,
        actionUpdateActionTask,
        actionDeleteActionTask,
    } = useMapAction();

    // atoms
    const [activeClient] = useRecoilState(atomActiveClient);
    const [drawer, setDrawer] = useRecoilState(
        atomDrawerType(ModalTypeEnum.ACTION_TASK),
    );
    const resetDrawer = useResetRecoilState(atomDrawer);

    // state
    const [editId, setEditId] = useState(0);
    const [loading, setLoading] = useState(false);
    const [showConfirm, setShowConfirm] = useState(0);
    const [isUploading, setIsUploading] = useState(false);
    const [showInput, setShowInput] = useState(0);
    const [activeTab, setActiveTab] = useState(ActionTaskTabEnum.GENERAL);
    const [lockActionTaskId, setLockActionTaskId] = useState(0);
    const [lockDisplayName, setLockDisplayName] = useState("");

    // form
    const methods = useForm({
        resolver: yupResolver(schemaActionTask(t)),
    });
    const { handleSubmit, formState, reset, setError, watch, trigger } =
        methods;

    // local state
    const titleValue = watch("title");

    const onSubmitHandler = (formData: unknown) => {
        if (!activeClient) {
            return;
        }

        const payload = formData as PActionTask;

        payload.documents = payload.documents?.map((x) => ({
            ...x,
            client: ClientApi.toResourceUrl(activeClient.id),
            type: DOCUMENT_TYPE.TYPE_ACTIONTASK,
        }));

        if (payload.user === "") {
            payload.user = null;
        }

        setLoading(true);

        ActionTaskApi.createOrUpdate<ActionTask, PActionTask>(editId, payload)
            .then(({ response, errorMessage, isInvalid, error }) => {
                if (isInvalid) {
                    setBackendViolations(error, setError);
                } else if (errorMessage) {
                    errorToast(errorMessage);
                } else if (response !== null) {
                    successToast(t("app.actionTask.list:update.toast.success"));
                    actionUpdateActionTask(response.id, response);

                    reset();
                    resetDrawer();
                    setEditId(0);
                }
            })
            .finally(() => setLoading(false));
    };

    const handleCreate = () => {
        if (!activeClient) {
            return;
        }

        const payload = ActionTask.createForm(
            ClientApi.toResourceUrl(activeClient.id),
            MapApi.toResourceUrl(mapId),
            { title: " " },
        );

        if (
            payload.additionalInfo &&
            !Object.keys(payload.additionalInfo).length
        ) {
            payload.additionalInfo = {
                description: "",
                achieve: "",
                important: "",
                keyAction: "",
                involved: "",
                keyIndicators: "",
            };
        }

        ActionTaskApi.create<ActionTask, PActionTask>(payload).then(
            ({ response, errorMessage }) => {
                if (errorMessage) {
                    errorToast(errorMessage);
                } else if (response !== null) {
                    actionCreateActionTask(response);
                    setShowInput(response.id);
                }
            },
        );
    };

    const onEdit = (id: number) => {
        if (!isWrite) {
            return;
        }

        ActionTaskApi.findById<ActionTask>(id).then(
            ({ errorMessage, response }) => {
                if (errorMessage) {
                    errorToast(errorMessage);
                } else if (response) {
                    reset({
                        title: response.title,
                        description: response.description,
                        documents: response.documents as never,
                        user: response.user
                            ? UserApi.toResourceUrl((response.user as User).id)
                            : undefined,
                        additionalInfo: response.additionalInfo,
                    });

                    setEditId(response.id);
                    setDrawer({ show: true, id: response.id });
                }
            },
        );
    };

    const handleEdit = (id: number, title: string) => {
        ActionTaskApi.update<ActionTask, PActionTask>(id, { title }).then(
            ({ response, errorMessage }) => {
                if (errorMessage) {
                    errorToast(errorMessage);
                } else if (response !== null) {
                    actionUpdateActionTask(response.id, response);
                }
            },
        );
    };

    const handleDelete = (id: number) => {
        ActionTaskApi.deleteById(id).then(({ errorMessage }) => {
            if (errorMessage) {
                errorToast(errorMessage);
            } else {
                setShowConfirm(0);
                actionDeleteActionTask(id);
            }
        });
    };

    const handlePrint = (id: number, title: string) => {
        ActionTaskApi.pdfDownload<ActionTask>(id).then(
            ({ errorMessage, response }) => {
                if (errorMessage) {
                    errorToast(errorMessage);
                } else if (response !== null) {
                    downloadLink({
                        name: `${normalizeName(title)}_${id}.pdf`,
                        type: "file/pdf",
                        file: response,
                    });
                }
            },
        );
    };

    useEffect(() => {
        setActiveTab(ActionTaskTabEnum.GENERAL);
    }, [drawer.show]);

    useEffect(() => {
        const subscription = watch((_, { name }) => {
            trigger(name);
        });
        return () => subscription.unsubscribe();
    }, [watch, trigger, formState.dirtyFields]);

    const renderForm = () => (
        <FormProvider {...methods}>
            <AppForm>
                <AppTabs
                    className="mb-4"
                    items={[
                        {
                            title: "app.actionTask.form:tab.general",
                            icon: "home",
                            url: ActionTaskTabEnum.GENERAL,
                        },
                        {
                            title: "app.actionTask.form:tab.info",
                            icon: "help",
                            url: ActionTaskTabEnum.Info,
                        },
                    ]}
                    onClick={(item) => {
                        if (item.url) {
                            setActiveTab(item.url as ActionTaskTabEnum);
                        }
                    }}
                    isActiveTab={(item) => item?.url === activeTab}
                />

                <AppFormContent>
                    <div hidden={activeTab !== ActionTaskTabEnum.GENERAL}>
                        <div className="d-flex flex-column gap-4">
                            <AppFormInput
                                id="title"
                                name="title"
                                label={t("app.actionTask.form:label.title")}
                                placeholder={t(
                                    "app.actionTask.form:placeholder.title",
                                )}
                                block
                                maxCount={validation.actionTask.title.max}
                            />

                            <AppFormTextarea
                                id="description"
                                name="description"
                                label={t(
                                    "app.actionTask.form:label.description",
                                )}
                                placeholder={t(
                                    "app.actionTask.form:placeholder.description",
                                )}
                                required={false}
                                block
                                rows={5}
                                maxCount={validation.description.max}
                                isEditor
                            />

                            <AppFormUploader
                                id="documents"
                                name="documents"
                                label={t("app.actionTask.form:label.documents")}
                                required={false}
                                block
                                fileInfo={DocumentFileInfo}
                                cbFileUploading={setIsUploading}
                                maxFiles={10}
                            />

                            <AppFormSelect
                                id="user"
                                name="user"
                                label={t(
                                    "app.actionTask.form:label.responsible",
                                )}
                                placeholder={t(
                                    "app.actionTask.form:placeholder.responsible",
                                )}
                                required={false}
                                block
                                options={[
                                    {
                                        label: t(
                                            "app.actionTask.form:none.responsible",
                                        ),
                                        value: "",
                                    },
                                    ...responsibleUserOptions,
                                ]}
                                isClearable
                            />
                        </div>
                    </div>

                    <div hidden={activeTab !== ActionTaskTabEnum.Info}>
                        <div className="d-flex flex-column gap-4">
                            <AppFormTextarea
                                id="additionalInfo.description"
                                name="additionalInfo.description"
                                label={t(
                                    "app.actionTask.form:label.additionalInfo.description",
                                )}
                                placeholder={t(
                                    "app.actionTask.form:placeholder.additionalInfo.description",
                                )}
                                required={false}
                                block
                                rows={5}
                                maxCount={validation.description.max}
                                isEditor
                            />

                            <AppFormTextarea
                                id="additionalInfo.achieve"
                                name="additionalInfo.achieve"
                                label={t(
                                    "app.actionTask.form:label.additionalInfo.achieve",
                                )}
                                placeholder={t(
                                    "app.actionTask.form:placeholder.additionalInfo.achieve",
                                )}
                                required={false}
                                block
                                rows={5}
                                maxCount={validation.description.max}
                                isEditor
                            />

                            <AppFormTextarea
                                id="additionalInfo.important"
                                name="additionalInfo.important"
                                label={t(
                                    "app.actionTask.form:label.additionalInfo.important",
                                )}
                                placeholder={t(
                                    "app.actionTask.form:placeholder.additionalInfo.important",
                                )}
                                required={false}
                                block
                                rows={5}
                                maxCount={validation.description.max}
                                isEditor
                            />

                            <AppFormTextarea
                                id="additionalInfo.keyAction"
                                name="additionalInfo.keyAction"
                                label={t(
                                    "app.actionTask.form:label.additionalInfo.keyAction",
                                )}
                                placeholder={t(
                                    "app.actionTask.form:placeholder.additionalInfo.keyAction",
                                )}
                                required={false}
                                block
                                rows={5}
                                maxCount={validation.description.max}
                                isEditor
                            />

                            <AppFormTextarea
                                id="additionalInfo.involved"
                                name="additionalInfo.involved"
                                label={t(
                                    "app.actionTask.form:label.additionalInfo.involved",
                                )}
                                placeholder={t(
                                    "app.actionTask.form:placeholder.additionalInfo.involved",
                                )}
                                required={false}
                                block
                                rows={5}
                                maxCount={validation.description.max}
                                isEditor
                            />

                            <AppFormTextarea
                                id="additionalInfo.keyIndicators"
                                name="additionalInfo.keyIndicators"
                                label={t(
                                    "app.actionTask.form:label.additionalInfo.keyIndicators",
                                )}
                                placeholder={t(
                                    "app.actionTask.form:placeholder.additionalInfo.keyIndicators",
                                )}
                                required={false}
                                block
                                rows={5}
                                maxCount={validation.description.max}
                                isEditor
                            />
                        </div>
                    </div>
                </AppFormContent>
            </AppForm>
        </FormProvider>
    );

    const renderItem = (item: ActionTask, provided: DraggableProvided) => (
        <div
            className={`action-task-container--list-container--item ${
                editId === item.id && editId === drawer.id ? "active-item" : ""
            } ${lockActionTaskId === item.id ? "lock" : ""}`}
            ref={provided.innerRef}
            {...provided.draggableProps}
        >
            {lockActionTaskId === item.id && (
                <div className="display-name">{lockDisplayName}</div>
            )}
            <div
                className="action-task-container--list-container--item--action"
                onDoubleClick={() => onEdit(item.id)}
            >
                <div>
                    <AppSVGIcon
                        icon="drag-n-drop"
                        {...provided.dragHandleProps}
                        hidden={!isWrite || lockActionTaskId === item.id}
                    />
                </div>

                {lockActionTaskId !== item.id && (
                    <div className="d-flex gap-1">
                        {item.documents?.length > 0 && (
                            <AppSVGIcon
                                icon="attach"
                                onClick={() => onEdit(item.id)}
                                className="cursor-pointer"
                            />
                        )}

                        <AppActionDropDown
                            id={item.id}
                            isGrantedControl={isWrite}
                        >
                            <li className="print">
                                <Link
                                    to={"#"}
                                    onClick={(e) => {
                                        e.preventDefault();
                                        handlePrint(item.id, item.title);
                                    }}
                                >
                                    <AppSVGIcon icon="print" />
                                    <span> {t(TKey.Common.Button.Print)}</span>
                                </Link>
                            </li>

                            {isWrite && (
                                <li className="edit">
                                    <Link
                                        to={"#"}
                                        onClick={(e) => {
                                            e.preventDefault();
                                            onEdit(item.id);
                                        }}
                                    >
                                        <AppSVGIcon icon="edit" />
                                        <span>
                                            {t(TKey.Common.Button.Edit)}
                                        </span>
                                    </Link>
                                </li>
                            )}

                            <li className="delete">
                                <Link
                                    to={"#"}
                                    onClick={(e) => {
                                        e.preventDefault();
                                        setShowConfirm(item.id);
                                    }}
                                >
                                    <AppSVGIcon icon="delete" />
                                    <span>{t(TKey.Common.Button.Delete)}</span>
                                </Link>
                            </li>
                        </AppActionDropDown>
                    </div>
                )}
            </div>

            <div
                className="action-task-container--list-container--item--content"
                onDoubleClick={() => setShowInput(item.id)}
            >
                <div className="detail">
                    <AppInlineInput
                        size="xs"
                        show={showInput === item.id && isWrite}
                        initialText={item.title}
                        onHide={() => setShowInput(0)}
                        onUpdate={(text) => handleEdit(item.id, text)}
                        maxLength={validation.actionTask.title.max}
                        inputType="area"
                        textAreaSize={item.user ? "xs" : "small"}
                    >
                        <AppTooltip
                            id={item.title}
                            overlay={
                                <span className="paragraph--400">
                                    {item.title}
                                </span>
                            }
                        >
                            <span
                                className={`title ${
                                    !item.description
                                        ? "title--full-height"
                                        : ""
                                } ${
                                    !item.description && item.user
                                        ? "title--full-height--with-user"
                                        : ""
                                }`}
                            >
                                {item.title}
                            </span>
                        </AppTooltip>

                        <AppDisplayEditorContent
                            className="description"
                            content={item.description}
                        />
                    </AppInlineInput>
                </div>

                {item.user && <AppAvatar user={item.user as User} withDetail />}

                {/* {isWrite && (
                    <AppSVGIcon
                        icon="edit"
                        size="xs"
                        className="edit-icon"
                        onClick={() => onEdit(item.id)}
                    />
                )} */}
            </div>
        </div>
    );

    useEffect(() => {
        Socket.on(
            EVENTS.ON_MAP_LOCK_ACTION_TASK,
            (user: PUser, actionTaskId: number, isLock: boolean) => {
                setLockActionTaskId(isLock ? actionTaskId : 0);
                setLockDisplayName(user.firstName + " " + user.lastName);
            },
        );
    }, []);

    return (
        <div className="map-detail-page--container--action-tasks">
            <AppConfirmModal
                show={!!showConfirm}
                icon="warning"
                title="app.actionTask.item:delete.confirm.modal.title"
                description="app.actionTask.item:delete.confirm.modal.description"
                onHide={() => setShowConfirm(0)}
                nextAction={() => handleDelete(showConfirm)}
            />

            <AppFormModal
                show={drawer.show && drawer.id === editId}
                icon="description"
                // title="app.actionTask.form:modal.title"
                title={titleValue}
                isDrawer
                onSubmit={handleSubmit(onSubmitHandler)}
                onHide={() => {
                    resetDrawer();
                    setEditId(0);
                }}
                isLoading={formState.isSubmitting || loading}
                disabled={!activeClient || isUploading || isRead}
                disabledSumbit={!formState.isValid}
                isEditMode={!!editId}
            >
                {renderForm()}
            </AppFormModal>

            <div className="action-task-container">
                <div className="action-task-container--strategy-card">
                    <span className="title">
                        {t("app.map.view:actionTask.title")}
                    </span>
                </div>

                <AppDraggableList<ActionTask>
                    droppableId="action_task"
                    direction="horizontal"
                    data={actionTasks}
                    onDragStart={onDragStart}
                    onDragEnd={onDragEnd}
                    renderItem={renderItem}
                    renderWrapper={(children, providedMain, snapshot) => (
                        <div
                            className={`action-task-container--list-container ${
                                snapshot.isDraggingOver
                                    ? "draggable-container--dropable"
                                    : ""
                            }`}
                            ref={providedMain.innerRef}
                            {...providedMain.droppableProps}
                        >
                            {children}

                            {isWrite && (
                                <AppButton
                                    variant="secondary"
                                    className="btn-square btn-sm"
                                    onClick={handleCreate}
                                >
                                    <AppSVGIcon icon="plus" />
                                </AppButton>
                            )}
                        </div>
                    )}
                />
            </div>
        </div>
    );
};
