import { Canceler } from "axios";
import { useRef, useState } from "react";

import { FinalResponse, cancelAllPrevRequest } from "../config";
import { FileTypeInfo, Upload } from "../models";
import { UploadApi } from "../apis";
import { compressImage } from "../utils";

export type UploadProgressFileType = {
    isFileUploading: boolean;
    isUploadSuccess: boolean;
    progress: number;
    rate: string;
    uploadFile: (
        file: File,
        fileInfo: FileTypeInfo,
    ) => Promise<FinalResponse<Upload | null>>;
    cancelUpload: () => void;
};

export function useUploadFile(isMultiUpload = false): UploadProgressFileType {
    const [isFileUploading, setIsFileUploading] = useState(false);
    const [isUploadSuccess, setIsUploadSuccess] = useState(false);
    const [progress, setProgress] = useState(0);
    const [rate, setRate] = useState("");

    const cancelTokenSourcesRef = useRef<Canceler[]>([]);

    const cancelUpload = () => {
        if (isFileUploading && !isUploadSuccess) {
            cancelAllPrevRequest(cancelTokenSourcesRef.current);
        }
    };

    const uploadFile = async (file: File, fileInfo: FileTypeInfo) => {
        const formData = new FormData();

        // only compress image files
        const compressedFile = await compressImage(file, fileInfo);

        formData.set("file", compressedFile, compressedFile.name);
        formData.set("fileName", compressedFile.name);
        formData.set("fileType", fileInfo.key);

        cancelUpload();

        setIsFileUploading(true);
        setIsUploadSuccess(false);
        setProgress(0);
        setRate("");

        return UploadApi.createResource<Upload, FormData>(
            formData,
            (c) => {
                cancelTokenSourcesRef.current.push(c);
            },
            setProgress,
            setRate,
        )
            .then((res) => {
                const { response } = res;

                if (response?.fileName) {
                    setIsUploadSuccess(true);
                }

                return res;
            })
            .finally(() => {
                setIsFileUploading(false);
                setProgress(isMultiUpload ? 0 : 100);
                setRate("");
            });
    };

    return {
        isFileUploading,
        isUploadSuccess,
        progress,
        rate,
        uploadFile,
        cancelUpload,
    };
}
