import { ICellRendererParams } from "ag-grid-community";
import { AppTooltip } from "../../../../components/AppTooltip";

export const AppGridToolTip = (params: ICellRendererParams) => {
    const { value } = params;

    return (
        <AppTooltip
            id={value}
            overlay={<span className="paragraph--400">{value}</span>}
        >
            <span className="app-grid-tooltip">{value}</span>
        </AppTooltip>
    );
};
