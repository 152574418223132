import { FC, useEffect } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useRecoilState, useResetRecoilState } from "recoil";
import { useParams } from "react-router-dom";
import { Col, Row } from "react-bootstrap";

import "./assets/scss/style.scss";

import { atomActiveClient, atomMapDetailFilter } from "../../../atoms";
import { useMapAction, useTranslation } from "../../../hooks";
import { TaskPost } from "../../../models";
import {
    AppForm,
    AppFormContent,
    AppFormGroup,
    AppFormLabel,
    AppFormModal,
    AppFormSelect,
} from "../../../components";
import { TKey, errorToast } from "../../../utils";
import { schema } from "./schema";
import { TaskPostApi } from "../../../apis";
import { getVotingColors } from "../../../config";
import { MapVoteCheckBox } from "../MapVoteCheckBox";

interface MapDetailFilterForm {
    colors?: string[];
    tags?: string[];
}

interface MapDetailFilterProps {
    loading: boolean;
    showForm: boolean;
    onHide: () => void;
}

export const MapDetailFilter: FC<MapDetailFilterProps> = ({
    loading,
    showForm,
    onHide,
}) => {
    // hooks
    const { t } = useTranslation();
    const { id } = useParams();

    // context
    const { map, fetchTaskPostTags, taskPostTagsOptions } = useMapAction();

    // atoms
    const [activeClient] = useRecoilState(atomActiveClient);
    const [appliedFilter, setAppliedFilter] =
        useRecoilState(atomMapDetailFilter);
    const resetFilter = useResetRecoilState(atomMapDetailFilter);

    // form
    const methods = useForm({
        resolver: yupResolver(schema()),
    });
    const { handleSubmit, formState, reset, setValue, watch } = methods;

    // local state
    const colorsValue = watch("colors");

    const onSubmitHandler = (formData: MapDetailFilterForm) => {
        if (!Object.values(formData)?.some((x) => !!x?.length) || !id) {
            return;
        }

        TaskPostApi.find<TaskPost>(1, {
            "map.id": id,
            "tags.id": formData.tags,
            [map?.isVoting ? "colorVote" : "color"]: formData.colors,
            pagination: false,
        }).then(({ response, errorMessage }) => {
            if (errorMessage) {
                errorToast(errorMessage);
            } else if (response !== null) {
                setAppliedFilter({
                    ...formData,
                    filteredTaskPostIds: response.items.map((x) => x.id),
                });
                onHide();
            }
        });
    };

    useEffect(() => {
        if (showForm) {
            fetchTaskPostTags({
                "client.id": activeClient?.id,
                "map.id": map?.id,
            });

            reset({
                colors: appliedFilter.colors,
                tags: appliedFilter.tags,
            });
        }
    }, [showForm]);

    const renderForm = () => (
        <FormProvider {...methods}>
            <AppForm className="app-map-detail-filter">
                <AppFormContent>
                    <AppFormGroup block className="vote-color">
                        <AppFormLabel
                            controlId="colors"
                            label={t("app.taskPost.form:label.color")}
                        />

                        <Row className="vote-color--container py-3 mx-0">
                            {getVotingColors().map((x) => (
                                <Col xs={6} key={x.value} className="my-2">
                                    <MapVoteCheckBox
                                        id={`color-${x.value}`}
                                        name="colors"
                                        checked={colorsValue?.includes(x.value)}
                                        label={x.icon}
                                        value={x.color}
                                        title={x.title}
                                        onChange={() => {
                                            if (
                                                colorsValue?.includes(x.value)
                                            ) {
                                                setValue("colors", [
                                                    ...(
                                                        colorsValue || []
                                                    ).filter(
                                                        (y) => y !== x.value,
                                                    ),
                                                ]);
                                            } else {
                                                setValue("colors", [
                                                    ...(colorsValue || []),
                                                    x.value,
                                                ]);
                                            }
                                        }}
                                    />
                                </Col>
                            ))}
                        </Row>
                    </AppFormGroup>

                    <AppFormSelect
                        id="tags"
                        name="tags"
                        label={t("app.taskPost.form:label.tags")}
                        placeholder={t("app.taskPost.form:placeholder.tags")}
                        required={false}
                        block
                        options={taskPostTagsOptions}
                        isMulti
                        isClearable
                    />
                </AppFormContent>
            </AppForm>
        </FormProvider>
    );

    return (
        <AppFormModal
            show={showForm}
            icon="filter"
            title="app.map.view.filter.form:modal.title"
            isDrawer
            onSubmit={handleSubmit(onSubmitHandler)}
            onHide={onHide}
            onCancelOrReset={() => {
                onHide();
                resetFilter();
            }}
            isLoading={formState.isSubmitting || loading}
            disabled={!activeClient}
            createLabel={TKey.Common.Button.Save}
            cancelLabel={TKey.Common.Button.Reset}
        >
            {renderForm()}
        </AppFormModal>
    );
};
