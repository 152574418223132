import { atom } from "recoil";

const KEY = "atom-map-portfolio-filter";

type AtomMapPortfolioFilterType = {
    responsible?: string[];
    tags?: string[];
    text?: string;
    filteredActionPostIds: number[];
};

export const atomMapPortfolioFilter = atom<AtomMapPortfolioFilterType>({
    key: KEY,
    default: {
        responsible: undefined,
        tags: undefined,
        text: undefined,
        filteredActionPostIds: [],
    },
});
