import { forwardRef } from "react";
import DatePicker from "react-datepicker";

import "react-datepicker/dist/react-datepicker.css";
import "./assest/scss/style.scss";

import { AppFormInputWrapperProps } from "../AppFormInputWrapper";

export interface AppDatePickerProps extends AppFormInputWrapperProps {
    selected?: Date;
    handleOnChange?: (val: Date) => void;
    isInline?: boolean;
}

export const AppDatePicker = forwardRef<never, AppDatePickerProps>(
    (
        {
            name,
            placeholder,
            disabled,
            defaultValue,
            className = "",
            inputClassName = "",
            handleOnChange,
            isInline,
        },
        ref,
    ) => {
        const value = defaultValue as string;
        const selected = value ? new Date(value) : null;

        const handleChange = (date: Date | null) => {
            if (date && handleOnChange) {
                handleOnChange(date);
            }
        };

        return (
            <DatePicker
                ref={ref}
                name={name}
                disabled={disabled}
                value={value}
                placeholderText={placeholder}
                wrapperClassName={`app-date-picker ${className}`}
                className={inputClassName}
                selected={selected}
                showPopperArrow={false}
                onChange={handleChange}
                inline={isInline}
                onFocus={(e) => e.target.blur()}
                // dateFormat={DateTimeEnum.shortDate}
                // timeFormat={DateTimeEnum.shortTime}
            />
        );
    },
);
