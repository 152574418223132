import { FC, ReactNode } from "react";
import { useController, useFormContext } from "react-hook-form";

import { validationMessage } from "../../utils";
import { AppFormLayoutProps } from "./AppForm";
import { AppFormGroup } from "./AppFormGroup";
import { AppFormLabel } from "./AppFormLabel";
import { AppFormMessage } from "./AppFormMessage";

export interface AppFormInputCommonProps {
    name: string;
    label?: string;
    toolTipLabel?: string;
    inputClassName?: string;
    secondaryName?: string;
    maxCount?: number;
    isCard?: boolean;
}

export interface AppFormInputWrapperProps
    extends AppFormLayoutProps,
        AppFormInputCommonProps,
        React.DetailedHTMLProps<
            React.InputHTMLAttributes<HTMLInputElement>,
            HTMLInputElement
        > {
    name: string;
    children?: ReactNode;
}

export const AppFormInputWrapper: FC<AppFormInputWrapperProps> = ({
    id,
    name,
    secondaryName = "",
    label,
    toolTipLabel,
    required = true,
    children,
    maxCount,
    isCard,
    ...props
}) => {
    // hooks
    const { formState, control } = useFormContext();
    const { field } = useController({
        name: name,
        control,
    });

    const controlId = id || name;

    const labelProps = {
        label,
        toolTipLabel,
        controlId,
        required,
        counter: field.value?.length,
        maxCount,
        isCard,
    };

    let messageProps = {
        errorMessage: validationMessage(name, formState),
    };

    if (secondaryName) {
        const secondaryMessageProps = {
            errorMessage: validationMessage(secondaryName, formState),
        };

        messageProps = {
            ...secondaryMessageProps,
            ...messageProps,
        };
    }

    const groupProps = {
        xs: props.xs,
        sm: props.sm,
        md: props.md,
        lg: props.lg,
        xl: props.xl,
        xxl: props.xxl,
        block: props.block,
        auto: props.auto,
    };

    return (
        <AppFormGroup {...groupProps}>
            <AppFormLabel {...labelProps} />

            {children}

            <AppFormMessage {...messageProps} />
        </AppFormGroup>
    );
};
