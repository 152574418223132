import { FC } from "react";

import "./assets/scss/style.scss";

import { TKey } from "../../../utils";
import { useTranslation } from "../../../hooks";
import { AppFormActions, AppLink } from "../../../components";

export const ResetPasswordConfirmationPage: FC = () => {
    // hooks
    const { t } = useTranslation();

    return (
        <div className="reset-password-confirmation-page">
            <div className="my-4">
                <span className="title ">
                    {t("user.reset-password-confirm:label.title")}
                </span>
            </div>

            <AppFormActions
                withDefault={false}
                position="center"
                className="pt-2 px-5"
            >
                <AppLink isBtn to={"/auth/login"} className="auth-button">
                    {t(TKey.Common.Button.Login)}
                </AppLink>
            </AppFormActions>
        </div>
    );
};
