import { FC, useEffect, useState } from "react";
import { DraggableProvided, DraggableStateSnapshot } from "react-beautiful-dnd";
import { useRecoilState } from "recoil";

import {
    AppButton,
    AppConfirmModal,
    AppDraggableList,
    AppInlineInput,
    AppSVGIcon,
} from "../../../components";
import { PSubTask, PUser, SubTask, Task } from "../../../models";
import { MapItemEnum, errorToast, validation } from "../../../utils";
import { MapDetailTaskPosts } from "./MapDetailTaskPosts";
import { ClientApi, SubTaskApi, TaskApi } from "../../../apis";
import { atomActiveClient } from "../../../atoms";
import { useMapAction, useWorkspacePermission } from "../../../hooks";
import { EVENTS, Socket } from "../../../Socket/Socket";

interface MapDetailSubTasksProps {
    task: Task;
}

export const MapDetailSubTasks: FC<MapDetailSubTasksProps> = ({ task }) => {
    // hooks
    const { isWrite } = useWorkspacePermission();

    // atoms
    const [activeClient] = useRecoilState(atomActiveClient);

    // context
    const { actionCreateSubTask, actionUpdateSubTask, actionDeleteSubTask } =
        useMapAction();

    // state
    const [showConfirm, setShowConfirm] = useState(0);
    const [showInput, setShowInput] = useState(0);
    const [lockSubTaskId, setLockSubTaskId] = useState(0);
    const [lockDisplayName, setLockDisplayName] = useState("");

    // local state
    const subTasks = task.subTasks || [];

    const handleCreate = () => {
        if (!activeClient) {
            return;
        }

        const payload = SubTask.createForm(
            ClientApi.toResourceUrl(activeClient.id),
            TaskApi.toResourceUrl(task.id),
        );

        SubTaskApi.create<SubTask, PSubTask>(payload).then(
            ({ response, errorMessage }) => {
                if (errorMessage) {
                    errorToast(errorMessage);
                } else if (response !== null) {
                    actionCreateSubTask(task.id, response);
                    setShowInput(response.id);
                }
            },
        );
    };

    const handleEdit = (id: number, name: string) => {
        SubTaskApi.update<SubTask, PSubTask>(id, { name }).then(
            ({ response, errorMessage }) => {
                if (errorMessage) {
                    errorToast(errorMessage);
                } else if (response !== null) {
                    actionUpdateSubTask(task.id, id, name);
                }
            },
        );
    };

    const handleDelete = (id: number) => {
        SubTaskApi.deleteById(id).then(({ errorMessage }) => {
            if (errorMessage) {
                errorToast(errorMessage);
            } else {
                setShowConfirm(0);
                actionDeleteSubTask(task.id, id);
            }
        });
    };

    const renderItem = (
        item: SubTask,
        provided: DraggableProvided,
        snapshot: DraggableStateSnapshot,
    ) => {
        const isLastElement = item.id === subTasks?.[subTasks.length - 1]?.id;
        const hideRemoveBtn = subTasks.length === 1;

        return (
            <div
                className={`sub-task-container ${
                    snapshot.isDragging ? "dragging" : ""
                }`}
                ref={provided.innerRef}
                {...provided.draggableProps}
            >
                <div
                    className={`sub-task-container--item ${
                        lockSubTaskId === item.id ? "lock" : ""
                    }`}
                >
                    {lockSubTaskId === item.id && (
                        <div className="display-name">{lockDisplayName}</div>
                    )}

                    <div className="sub-task-container--item--action">
                        <AppSVGIcon
                            icon="drag-n-drop"
                            {...provided.dragHandleProps}
                            hidden={
                                !(isWrite && !hideRemoveBtn) ||
                                lockSubTaskId === item.id
                            }
                        />

                        {isWrite &&
                            !hideRemoveBtn &&
                            lockSubTaskId !== item.id && (
                                <AppSVGIcon
                                    icon="close"
                                    className="cursor-pointer"
                                    onClick={() => setShowConfirm(item.id)}
                                />
                            )}
                    </div>

                    <div
                        className="sub-task-container--item--content"
                        onDoubleClick={() => setShowInput(item.id)}
                    >
                        <AppInlineInput
                            size="xs"
                            show={showInput === item.id && isWrite}
                            initialText={item.name}
                            onHide={() => setShowInput(0)}
                            onUpdate={(text) => handleEdit(item.id, text)}
                            maxLength={validation.subTask.title.max}
                            inputType="area"
                        >
                            <span className="title">{item.name}</span>
                        </AppInlineInput>
                    </div>

                    <div className="sub-task-container--item--add">
                        {isWrite && isLastElement && (
                            <AppButton
                                variant="secondary"
                                className="btn-square btn-sm"
                                onClick={handleCreate}
                            >
                                <AppSVGIcon icon="plus" />
                            </AppButton>
                        )}
                    </div>
                </div>

                <MapDetailTaskPosts taskId={task.id} subTask={item} />
            </div>
        );
    };

    useEffect(() => {
        Socket.on(
            EVENTS.ON_MAP_LOCK_SUB_TASK,
            (user: PUser, subTaskId: number, isLock: boolean) => {
                setLockSubTaskId(isLock ? subTaskId : 0);
                setLockDisplayName(user.firstName + " " + user.lastName);
            },
        );
    }, []);

    return (
        <>
            <AppConfirmModal
                show={!!showConfirm}
                icon="warning"
                title="app.subTask.item:delete.confirm.modal.title"
                description="app.subTask.item:delete.confirm.modal.description"
                onHide={() => setShowConfirm(0)}
                nextAction={() => handleDelete(showConfirm)}
            />

            <AppDraggableList<SubTask>
                withoutContext
                droppableId={`task-${task.id}`}
                type={MapItemEnum.SUB_TASK}
                data={subTasks}
                renderItem={renderItem}
                renderWrapper={(children, providedMain) => (
                    <div
                        className="task-container--sub-tasks"
                        ref={providedMain.innerRef}
                        {...providedMain.droppableProps}
                    >
                        {children}
                    </div>
                )}
            />
        </>
    );
};
