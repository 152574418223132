import { FC, PropsWithChildren, useState } from "react";
import { OverlayTrigger, Popover } from "react-bootstrap";
import { OverlayTriggerRenderProps } from "react-bootstrap/esm/OverlayTrigger";

import "./assets/scss/style.scss";

interface MapSizeSelectorProps extends PropsWithChildren {
    id?: number;
    className?: string;
    isGrantedControl?: boolean;
    currentValue?: string;
    disabled?: boolean;
}

export const MapSizeSelector: FC<MapSizeSelectorProps> = ({
    id = 0,
    className = "",
    isGrantedControl = true,
    currentValue,
    disabled,
    children,
}): JSX.Element => {
    // state
    const [showPopover, setShowPopover] = useState(false);

    const renderButton = ({
        ref,
        ...triggerHandler
    }: OverlayTriggerRenderProps) => (
        <div
            ref={ref}
            className={`map-size-dropdown--action`}
            {...triggerHandler}
        >
            <span className="title">{currentValue}</span>
        </div>
    );

    const renderContent = () => (
        <div className="map-size-dropdown--content">{children}</div>
    );

    if (!isGrantedControl) {
        return <></>;
    }

    return (
        <div className={`map-size-dropdown ${className}`}>
            <OverlayTrigger
                trigger={"click"}
                placement="bottom-start"
                flip
                rootClose
                show={showPopover && !disabled}
                onToggle={setShowPopover}
                overlay={(props) => (
                    <Popover
                        {...props}
                        id={`popover-basic-size-${id}`}
                        className="popover-container-size"
                        onClickCapture={() => setShowPopover(false)}
                        arrowProps={{
                            style: {
                                display: "none",
                            },
                        }}
                    >
                        {renderContent()}
                    </Popover>
                )}
            >
                {(props) => renderButton(props)}
            </OverlayTrigger>
        </div>
    );
};
