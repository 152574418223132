import { isString } from "./datatype.helper";

export const changeAspectRatioColon = (
    stringRatio: string | boolean | null,
): number | undefined => {
    if (isString(stringRatio)) {
        const [firstRatioNumber, secondRatioNumber] = stringRatio.split(":");

        return +firstRatioNumber / +secondRatioNumber;
    }

    return undefined;
};
