import { FC, memo, useCallback, useEffect, useMemo, useState } from "react";

import "./assets/scss/style.scss";

import { useTranslation } from "../../../hooks";
import {
    PDashboardStatistics,
    PrimitiveObject,
    StatisticsByResponsible,
    User,
} from "../../../models";
import { ActionPostApi, MapApi } from "../../../apis";
import { errorToast, getUserFullName } from "../../../utils";
import { AppAvatar, AppLoader, AppSearchBar } from "../../../components";
import { MapDurationTabs } from "../MapDurationTabs";

interface MapDashboardAssignedResponsibilitiesProps {
    mapId: number;
    data: StatisticsByResponsible[];
    totalPosts: number;
}

export const MapDashboardAssignedResponsibilities: FC<MapDashboardAssignedResponsibilitiesProps> =
    memo(({ mapId, data, totalPosts }) => {
        // hooks
        const { t } = useTranslation();

        // state
        const [loading, setLoading] = useState(false);
        const [stats, setStats] = useState<StatisticsByResponsible[]>(data);
        const [searchTerm, setSearchTerm] = useState<string>("");
        const [duration, setDuration] = useState("");

        const fetchStatistics = async () => {
            setLoading(true);

            ActionPostApi.getDashboardStatisticsByResponsiblePerson<
                PDashboardStatistics,
                PrimitiveObject
            >({
                map: MapApi.toResourceUrl(mapId),
                ...(duration ? { duration } : {}),
            })
                .then(({ response, errorMessage }) => {
                    if (errorMessage) {
                        errorToast(errorMessage);
                    } else if (
                        response !== null &&
                        response.byResponsible &&
                        response.byResponsible.length > 0
                    ) {
                        setStats(response.byResponsible);
                    }
                })
                .finally(() => setLoading(false));
        };

        useEffect(() => {
            fetchStatistics();
        }, [duration]);

        const handleSearchChange = useCallback((search: string) => {
            setSearchTerm(search);
        }, []);

        const filterUsers = useMemo(
            () =>
                (user: StatisticsByResponsible): boolean => {
                    const fullName = getUserFullName(
                        user as unknown as User,
                    ).toLowerCase();
                    const totalPostsString = (+user.totalPosts)
                        .toString()
                        .toLowerCase();

                    return (
                        fullName.includes(searchTerm.toLowerCase()) ||
                        totalPostsString.includes(searchTerm.toLowerCase())
                    );
                },
            [searchTerm],
        );

        const filteredStats = useMemo(
            () =>
                stats
                    .sort((a, b) => +b.totalPosts - +a.totalPosts)
                    .filter(filterUsers),
            [stats, filterUsers],
        );

        return (
            <div className="map-dashboard-assigned-responsibilities">
                {loading && <AppLoader className="overlay-loader" />}

                <div className="map-dashboard-assigned-responsibilities--header mb-5">
                    <h3 className="title">
                        {t(
                            "app.map.dashboard:label.projectAssignedResponsibilities",
                        )}
                    </h3>

                    <MapDurationTabs onChange={setDuration} />
                </div>

                <div className="map-dashboard-assigned-responsibilities--info">
                    <div className="map-dashboard-assigned-responsibilities--info--search-bar">
                        <AppSearchBar onSearchChange={handleSearchChange} />
                    </div>

                    <div className="map-dashboard-assigned-responsibilities--info--users">
                        {filteredStats.map((user) => {
                            const fillWidth =
                                (100 * +user.totalPosts) / totalPosts;

                            return (
                                <div
                                    className={`user-container ${duration.toLowerCase()}`}
                                    key={user.id}
                                >
                                    <div
                                        className={`user-container--avatar ${duration.toLowerCase()}`}
                                    >
                                        <AppAvatar
                                            user={user as unknown as User}
                                            withDetail
                                        />
                                    </div>

                                    <div className="user-container--progress">
                                        <div
                                            className={`user-container--progress--fill ${duration.toLowerCase()}`}
                                            style={{
                                                width: `calc(3rem + ${fillWidth}%)`,
                                            }}
                                        >
                                            <span className="total">
                                                {user.totalPosts}
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            );
                        })}
                    </div>
                </div>
            </div>
        );
    });
