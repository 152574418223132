import { ROLES } from "../config";
import { SimpleObject } from "../models";
import { useAuthState } from "./useAuthState";

const { USER_ROLE_USER, USER_ROLE_ADMIN, USER_ROLE_CLIENT_ADMIN } = ROLES;

export const roles: SimpleObject<string[]> = {
    ROLE_USER: [],
    ROLE_ADMIN: [USER_ROLE_USER],
    ROLE_CLIENT_ADMIN: [USER_ROLE_USER],
    ROLE_SUPER_ADMIN: [USER_ROLE_CLIENT_ADMIN, USER_ROLE_ADMIN, USER_ROLE_USER],
};

export function useIsGranted(roleName: string, isExact?: boolean) {
    const { userRole } = useAuthState();

    if (!roles[userRole]) {
        return false;
    }

    if (isExact) {
        return userRole === roleName;
    }

    return userRole === roleName || roles[userRole].indexOf(roleName) > -1;
}
