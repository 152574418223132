import { FC } from "react";
import { Modal } from "react-bootstrap";

import "./assets/scss/style.scss";

import { useTranslation } from "../../hooks";
import { AppSVGIcon } from "../AppSVGIcon";
import { AppBaseModalProps } from "./AppBaseModal";

const AppBaseModalHeader: FC<AppBaseModalProps> = ({
    icon,
    title,
    onHide,
    closeButton = true,
}) => {
    // hooks
    const { t } = useTranslation();

    return (
        <Modal.Header className="border-0">
            {(icon || title) && (
                <Modal.Title className="d-flex gap-3 align-items-start">
                    {icon && (
                        <AppSVGIcon size="xl" icon={icon} className="mt-1" />
                    )}

                    {title && <span className="title">{t(title)}</span>}
                </Modal.Title>
            )}

            {closeButton && (
                <AppSVGIcon
                    size="xl"
                    icon="close"
                    className="cursor-pointer mt-1"
                    onClick={onHide}
                />
            )}
        </Modal.Header>
    );
};

export default AppBaseModalHeader;
