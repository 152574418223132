import { ChangeEvent, FC } from "react";

import "./assets/scss/style.scss";
import { TKey } from "../../../utils";
import { AppLink } from "../../../components";
import { useTranslation } from "../../../hooks";
import { Col, Row } from "react-bootstrap";

interface MapProgressBarProps {
    id: number;
    value: number;
    onChange: (val: string) => void;
}

export const MapProgressBar: FC<MapProgressBarProps> = ({
    id,
    value,
    onChange,
}) => {
    const { t } = useTranslation();

    const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
        onChange(e.currentTarget.value);
    };

    const handleReset = () => {
        onChange("1");
    };

    return (
        <Row>
            <Col className="map-progress my-1">
                <span className="map-progress--title mb-1">Progress</span>

                <div className="map-progress--path">
                    <input
                        className="rating__input"
                        name={`rating-${id}`}
                        id={`rating-${id}-1`}
                        value={1}
                        type="radio"
                        onChange={handleChange}
                        checked={value === 1}
                    />
                    <label
                        className="rating__label neutral"
                        htmlFor={`rating-${id}-2`}
                    >
                        <span className="color-label" />
                    </label>
                    <input
                        className="rating__input"
                        name={`rating-${id}`}
                        id={`rating-${id}-2`}
                        value={2}
                        type="radio"
                        onChange={handleChange}
                        checked={value === 2}
                    />
                    <label
                        className="rating__label neutral"
                        htmlFor={`rating-${id}-3`}
                    >
                        <span className="color-label" />
                    </label>
                    <input
                        className="rating__input"
                        name={`rating-${id}`}
                        id={`rating-${id}-3`}
                        value={3}
                        type="radio"
                        onChange={handleChange}
                        checked={value === 3}
                    />
                    <label
                        className="rating__label neutral"
                        htmlFor={`rating-${id}-4`}
                    >
                        <span className="color-label" />
                    </label>
                    <input
                        className="rating__input"
                        name={`rating-${id}`}
                        id={`rating-${id}-4`}
                        value={4}
                        type="radio"
                        onChange={handleChange}
                        checked={value === 4}
                    />
                    <label
                        className="rating__label neutral"
                        htmlFor={`rating-${id}-5`}
                    >
                        <span className="color-label" />
                    </label>
                    <input
                        className="rating__input"
                        name={`rating-${id}`}
                        id={`rating-${id}-5`}
                        value={5}
                        type="radio"
                        onChange={handleChange}
                        checked={value === 5}
                    />
                    <label
                        className="rating__label orange"
                        htmlFor={`rating-${id}-6`}
                    >
                        <span className="color-label" />
                    </label>
                    <input
                        className="rating__input"
                        name={`rating-${id}`}
                        id={`rating-${id}-6`}
                        value={6}
                        type="radio"
                        onChange={handleChange}
                        checked={value === 6}
                    />
                    <label
                        className="rating__label orange"
                        htmlFor={`rating-${id}-7`}
                    >
                        <span className="color-label" />
                    </label>
                    <input
                        className="rating__input"
                        name={`rating-${id}`}
                        id={`rating-${id}-7`}
                        value={7}
                        type="radio"
                        onChange={handleChange}
                        checked={value === 7}
                    />
                    <label
                        className="rating__label orange"
                        htmlFor={`rating-${id}-8`}
                    >
                        <span className="color-label" />
                    </label>
                    <input
                        className="rating__input"
                        name={`rating-${id}`}
                        id={`rating-${id}-8`}
                        value={8}
                        type="radio"
                        onChange={handleChange}
                        checked={value === 8}
                    />
                    <label
                        className="rating__label orange"
                        htmlFor={`rating-${id}-9`}
                    >
                        <span className="color-label" />
                    </label>
                    <input
                        className="rating__input"
                        name={`rating-${id}`}
                        id={`rating-${id}-9`}
                        value={9}
                        type="radio"
                        onChange={handleChange}
                        checked={value === 9}
                    />
                    <label
                        className="rating__label yellow"
                        htmlFor={`rating-${id}-10`}
                    >
                        <span className="color-label" />
                    </label>
                    <input
                        className="rating__input"
                        name={`rating-${id}`}
                        id={`rating-${id}-10`}
                        value={10}
                        type="radio"
                        onChange={handleChange}
                        checked={value === 10}
                    />
                    <label
                        className="rating__label yellow"
                        htmlFor={`rating-${id}-11`}
                    >
                        <span className="color-label" />
                    </label>
                    <input
                        className="rating__input"
                        name={`rating-${id}`}
                        id={`rating-${id}-11`}
                        value={11}
                        type="radio"
                        onChange={handleChange}
                        checked={value === 11}
                    />
                    <label
                        className="rating__label yellow"
                        htmlFor={`rating-${id}-12`}
                    >
                        <span className="color-label" />
                    </label>
                    <input
                        className="rating__input"
                        name={`rating-${id}`}
                        id={`rating-${id}-12`}
                        value={12}
                        type="radio"
                        onChange={handleChange}
                        checked={value === 12}
                    />
                    <label
                        className="rating__label yellow"
                        htmlFor={`rating-${id}-13`}
                    >
                        <span className="color-label" />
                    </label>
                    <input
                        className="rating__input"
                        name={`rating-${id}`}
                        id={`rating-${id}-13`}
                        value={13}
                        type="radio"
                        onChange={handleChange}
                        checked={value === 13}
                    />
                    <label
                        className="rating__label green"
                        htmlFor={`rating-${id}-14`}
                    >
                        <span className="color-label" />
                    </label>
                    <input
                        className="rating__input"
                        name={`rating-${id}`}
                        id={`rating-${id}-14`}
                        value={14}
                        type="radio"
                        onChange={handleChange}
                        checked={value === 14}
                    />
                    <label
                        className="rating__label green"
                        htmlFor={`rating-${id}-15`}
                    >
                        <span className="color-label" />
                    </label>
                    <input
                        className="rating__input"
                        name={`rating-${id}`}
                        id={`rating-${id}-15`}
                        value={15}
                        type="radio"
                        onChange={handleChange}
                        checked={value === 15}
                    />
                    <label
                        className="rating__label green"
                        htmlFor={`rating-${id}-16`}
                    >
                        <span className="color-label" />
                    </label>
                    <input
                        className="rating__input"
                        name={`rating-${id}`}
                        id={`rating-${id}-16`}
                        value={16}
                        type="radio"
                        onChange={handleChange}
                        checked={value === 16}
                    />
                    <label
                        className="rating__label green"
                        htmlFor={`rating-${id}-17`}
                    >
                        <span className="color-label" />
                    </label>
                    <input
                        className="rating__input"
                        name={`rating-${id}`}
                        id={`rating-${id}-17`}
                        value={17}
                        type="radio"
                        onChange={handleChange}
                        checked={value === 17}
                    />
                </div>
            </Col>
            <Col className="d-flex align-items-end mb-1">
                <AppLink to={"#"} size="sm" onClick={handleReset}>
                    {t(TKey.Common.Button.Reset)}
                </AppLink>
            </Col>
        </Row>
    );
};
