export enum MapPageEnum {
    MAP_DASHBOARD,
    MAP_VIEW,
    MAP_PORTFOLIO,
    MAP_KANBAN,
}

export enum MapActionEnum {
    SET_MAP,
    SET_MAP_DETAIL,
    SET_MAP_PORTFOLIO,
    SET_MAP_KANBAN,
    RESET_MAP,
    CHANGE_TASK_ORDER,
    CHANGE_SUB_TASK_ORDER,
    CHANGE_TASK_POST_ORDER,
    CHANGE_ACTION_TASK_ORDER,
    CHANGE_ACTION_POST_ORDER,
    CREATE_TASK,
    UPDATE_TASK,
    DELETE_TASK,
    CREATE_SUB_TASK,
    UPDATE_SUB_TASK,
    DELETE_SUB_TASK,
    CREATE_TASK_POST,
    UPDATE_TASK_POST,
    DELETE_TASK_POST,
    CREATE_ACTION_TASK,
    UPDATE_ACTION_TASK,
    DELETE_ACTION_TASK,
    CREATE_ACTION_POST,
    UPDATE_ACTION_POST,
    DELETE_ACTION_POST,
    SET_RESPONSIBLE_USER_OPTIONS,
    SET_TASK_POST_TAGS,
    SET_ACTION_POST_TAGS,
    SET_WORKSPACE_PERMISSIONS,
}

export enum MapItemEnum {
    TASK = "TASK",
    SUB_TASK = "SUB-TASK",
    TASK_POST = "TASK-POST",
    ACTION_TASK = "ACTION-TASK",
    ACTION_POST = "ACTION-POST",
}

export enum ActionTaskTabEnum {
    GENERAL = "General",
    Info = "Info",
}

export enum ActionPostTabEnum {
    EDIT = "Edit",
    VALUATION = "Valuation",
    DESCRIPTION = "Description",
}

export enum PortfolioSettingTabEnum {
    PRIORITIES = "Priorities",
    SCHEDULE = "Schedule",
}

export enum PortfolioSizeEnum {
    SMALL = "S",
    MEDIUM = "M",
    LARGE = "L",
    EXTRA_LARGE = "XL",
}

export enum MapBudgetEnum {
    INTERNAL = "internal",
    EXTERNAL = "external",
}

export enum MapPaperSizeEnum {
    A3 = "A3",
}
