import { EntityAPI } from "./core";
import { ROUTES } from "../config";

const {
    api_votes_get_collection: API_GET_COLLECTION,
    api_votes_post_submit_vote: API_POST_COLLECTION,
    api_votes_get_item: API_GET_ITEM,
    api_votes_delete_item: API_DELETE_ITEM,
} = ROUTES;

export abstract class VoteApi extends EntityAPI {
    protected static GET_COLLECTION = API_GET_COLLECTION;

    protected static POST_COLLECTION = API_POST_COLLECTION;

    protected static GET_ITEM = API_GET_ITEM;

    protected static DELETE_ITEM = API_DELETE_ITEM;
}
