import { FC, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import "./assets/scss/style.scss";

import {
    AppButton,
    AppContentModal,
    AppLink,
    AppSVGIcon,
} from "../../../components";
import { useSubscriptionAction, useTranslation } from "../../../hooks";
import { TKey, errorToast, successToast } from "../../../utils";
import { PLAN_ENTERPRISE, PLAN_FREE, PLAN_PROFESSIONAL } from "../../../config";
import { ClientApi, InvoiceApi } from "../../../apis";
import { Invoice } from "../../../models";

interface InvoiceForm {
    plan: string;
    noOfMonths: number;
    noOfUsers: number;
}

interface SubscriptionPlanCardsProps {
    onSubmit: () => void;
    isOnboarding?: boolean;
}

export const SubscriptionPlanCards: FC<SubscriptionPlanCardsProps> = ({
    onSubmit,
    isOnboarding = false,
}) => {
    // hooks
    const { t } = useTranslation();
    const navigate = useNavigate();

    // context
    const {
        myClient,
        actionShowLoading,
        actionHideLoading,
        actionInvoiceCreate,
        isCurrentProfessionalPlan,
        currentInvoice,
        actionRefetchMyClient,
    } = useSubscriptionAction();

    // state
    const [duration, setDuration] = useState(TKey.Common.Label.DurationYear);
    const [seats, setSeats] = useState(1);
    const [showConfirm, setShowConfirm] = useState(false);
    const [showMore, setShowMore] = useState(String);

    useEffect(() => {
        if (isCurrentProfessionalPlan && currentInvoice) {
            setDuration(
                currentInvoice.noOfMonths === 12
                    ? TKey.Common.Label.DurationYear
                    : TKey.Common.Label.DurationQuarter,
            );
        }
    }, [isCurrentProfessionalPlan, currentInvoice]);

    const handleCancelAutoRenew = () => {
        if (!myClient) {
            return;
        }

        ClientApi.autoRenewSubscription(myClient.id, {
            isAutoRenew: false,
        }).then(({ response, errorMessage }) => {
            if (errorMessage) {
                errorToast(errorMessage);
            } else if (response !== null) {
                actionRefetchMyClient();
            }
        });
    };

    const handlePlanCreate = (
        planType: string,
        noOfMonths = 3,
        noOfUsers = 1,
    ) => {
        actionShowLoading();

        if (planType === PLAN_FREE.name && !isOnboarding) {
            handleCancelAutoRenew();
        }

        InvoiceApi.createPlan<{ result: { invoice: Invoice } }, InvoiceForm>({
            plan: planType,
            noOfMonths,
            noOfUsers,
        })
            .then(({ response, errorMessage }) => {
                if (errorMessage) {
                    errorToast(errorMessage);
                } else if (response !== null) {
                    // successToast(
                    //     t("admin.subscription.pricing:create.toast.success"),
                    // );

                    if (response.result.invoice) {
                        actionInvoiceCreate(response.result.invoice);
                        onSubmit();
                    }
                }
            })
            .finally(() => {
                setShowConfirm(false);
                actionHideLoading();
            });
    };

    const handleNotify = () => {
        InvoiceApi.notifyEnterprise()
            .then(({ response, errorMessage }) => {
                if (errorMessage) {
                    errorToast(errorMessage);
                } else if (response !== null) {
                    successToast(
                        t("admin.subscription.pricing:notify.toast.success"),
                    );

                    if (!isOnboarding) {
                        navigate("/");
                    }
                }
            })
            .finally(() => setShowConfirm(false));
    };

    if (!myClient) {
        return <></>;
    }

    const { currentPlan } = myClient;
    const monthLength = duration === TKey.Common.Label.DurationYear ? 12 : 3;
    const price =
        duration === TKey.Common.Label.DurationYear
            ? PLAN_PROFESSIONAL.monthlyCostForYear
            : PLAN_PROFESSIONAL.monthlyCostForQuater;

    const renderPlanTitle = (planType: string) =>
        planType === currentPlan ? (
            <span className="subscription-plan-cards__current-plan">
                {t("admin.subscription.pricing:label.currentPlan")}
            </span>
        ) : null;

    const renderShowMoreButton = (planType: string) => (
        <div className="d-flex justify-content-center mt-3">
            <AppLink
                onClick={() =>
                    setShowMore(showMore === planType ? "" : planType)
                }
                to={"#"}
                size="sm"
            >
                <div className="d-flex align-items-center justify-content-center">
                    <AppSVGIcon icon="plus" size="sm" />
                    {showMore === planType
                        ? t("admin.subscription.pricing:button.showLess")
                        : t("admin.subscription.pricing:button.showMore")}
                </div>
            </AppLink>
        </div>
    );

    return (
        <>
            <AppContentModal
                show={!!showConfirm}
                icon="success"
                title="admin.subscription.pricing:request.confirm.modal.title"
                description="admin.subscription.pricing:request.confirm.modal.description"
                onHide={() => setShowConfirm(false)}
                nextAction={() => handleNotify()}
                hideSecondary
                createLabel={TKey.Common.Button.Confirm}
            >
                <span className="paragraph--100 font-weight--light">
                    {t(
                        "admin.subscription.pricing:request.confirm.modal.description",
                    )}
                </span>
            </AppContentModal>

            <div
                className={`subscription-plan-cards__box ${
                    PLAN_FREE.name === currentPlan && !isOnboarding
                        ? "subscription-plan-cards__box--active"
                        : ""
                } ${isOnboarding ? "h-100" : ""}`}
            >
                {!isOnboarding && renderPlanTitle(PLAN_FREE.name)}

                <div className="subscription-plan-cards__box-content">
                    <div className="subscription-plan-cards__plans">
                        <span className="title">{PLAN_FREE.name}</span>
                        <span className="description">
                            {t(
                                "admin.subscription.pricing:description.plan.free",
                            )}
                        </span>
                    </div>

                    <div className="subscription-plan-cards__cost">
                        <span className="title">
                            {t("admin.subscription.pricing:label.cost")}
                        </span>
                        <div className="cost-content">
                            <span className="cost-value">
                                {PLAN_FREE.monthlyCostForQuater}{" "}
                                {t(TKey.Common.Label.Currency)}
                            </span>
                            <span className="cost-duration">
                                /{t(TKey.Common.Label.DurationQuarter)}
                            </span>
                        </div>
                        <span className="description">
                            {t("admin.subscription.pricing:description.cost")}{" "}
                            {PLAN_FREE.monthlyCostForYear}{" "}
                            {t(TKey.Common.Label.Currency)} /
                            {t(TKey.Common.Label.DurationYear)}
                        </span>
                    </div>
                </div>

                <div
                    className={`subscription-plan-cards__box-action ${
                        isCurrentProfessionalPlan ? "mt-10" : ""
                    }`}
                >
                    <AppButton
                        variant={
                            currentPlan !== PLAN_FREE.name
                                ? "primary"
                                : "success"
                        }
                        onClick={() => handlePlanCreate(PLAN_FREE.name)}
                        disabled={
                            currentPlan === PLAN_FREE.name && !isOnboarding
                        }
                    >
                        {currentPlan && currentPlan !== PLAN_FREE.name
                            ? t("admin.subscription.pricing:button.downgrade")
                            : isOnboarding
                            ? t("admin.subscription.pricing:button.get")
                            : t("admin.subscription.pricing:label.currentPlan")}
                    </AppButton>

                    <ul className="subscription-plan-cards__list">
                        <li className="subscription-plan-cards__list-item">
                            <AppSVGIcon icon="check" className="icon" />
                            <span className="title">
                                {PLAN_FREE.noOfUser}{" "}
                                {t("admin.subscription.pricing:label.feature1")}
                            </span>
                        </li>

                        <li className="subscription-plan-cards__list-item">
                            <AppSVGIcon icon="check" className="icon" />
                            <span className="title">
                                {t("admin.subscription.pricing:label.feature3")}
                            </span>
                        </li>

                        <li className="subscription-plan-cards__list-item">
                            <AppSVGIcon icon="check" className="icon" />
                            <span className="title">
                                {t("admin.subscription.pricing:label.feature4")}
                            </span>
                        </li>

                        <li
                            className="subscription-plan-cards__list-item"
                            hidden={showMore !== PLAN_FREE.name}
                        >
                            <AppSVGIcon icon="check" className="icon" />
                            <span className="title">
                                {t("admin.subscription.pricing:label.feature5")}
                            </span>
                        </li>

                        <li
                            className="subscription-plan-cards__list-item"
                            hidden={showMore !== PLAN_FREE.name}
                        >
                            <AppSVGIcon icon="check" className="icon" />
                            <span className="title">
                                {t("admin.subscription.pricing:label.feature6")}
                            </span>
                        </li>

                        {renderShowMoreButton(PLAN_FREE.name)}
                    </ul>
                </div>
            </div>

            <div
                className={`subscription-plan-cards__box ${
                    PLAN_PROFESSIONAL.name === currentPlan && !isOnboarding
                        ? "subscription-plan-cards__box--active"
                        : ""
                } ${isOnboarding ? "h-100" : ""}`}
            >
                {renderPlanTitle(PLAN_PROFESSIONAL.name)}

                <div className="subscription-plan-cards__box-content">
                    <div className="subscription-plan-cards__plans">
                        <span className="title">{PLAN_PROFESSIONAL.name}</span>
                        <span className="description">
                            {t(
                                "admin.subscription.pricing:description.plan.professional",
                            )}
                        </span>
                    </div>

                    <div className="subscription-plan-cards__cost">
                        <span className="title">
                            {t("admin.subscription.pricing:label.cost")}
                        </span>
                        <div className="cost-content">
                            <span className="cost-value">
                                {price * monthLength * seats}{" "}
                                {t(TKey.Common.Label.Currency)}
                            </span>
                            <span className="cost-duration">
                                /{t(duration)}
                            </span>
                        </div>

                        {duration === TKey.Common.Label.DurationYear && (
                            <span className="description">
                                {t(
                                    "admin.subscription.pricing:description.cost",
                                )}{" "}
                                <s>
                                    {PLAN_PROFESSIONAL.monthlyCostForQuater *
                                        12 *
                                        seats}{" "}
                                    {t(TKey.Common.Label.Currency)}
                                </s>{" "}
                                {PLAN_PROFESSIONAL.monthlyCostForYear *
                                    12 *
                                    seats}{" "}
                                {t(TKey.Common.Label.Currency)} /
                                {t(TKey.Common.Label.DurationYear)}
                            </span>
                        )}
                    </div>

                    <div
                        className={`subscription-plan-cards__seats ${
                            duration === TKey.Common.Label.DurationYear
                                ? ""
                                : "mt-3 pb-3"
                        }`}
                    >
                        <div className="seats-info">
                            <div className="title">
                                <AppSVGIcon icon="user-group" size="xxl" />
                                <span>
                                    {t(
                                        "admin.subscription.pricing:label.seats",
                                    )}
                                </span>
                            </div>
                            <div className="description">
                                {(duration === TKey.Common.Label.DurationYear
                                    ? PLAN_PROFESSIONAL.monthlyCostForYear
                                    : PLAN_PROFESSIONAL.monthlyCostForQuater) *
                                    monthLength}{" "}
                                {t(TKey.Common.Label.Currency)}/{t(duration)}{" "}
                                {t(
                                    "admin.subscription.pricing:description.seats",
                                )}
                            </div>
                        </div>

                        <div className="seats-input">
                            <input
                                type="number"
                                value={seats}
                                min={1}
                                step={1}
                                className="form-control seats-input-field"
                                onChange={(e) => {
                                    if (+e.target.value > 0) {
                                        setSeats(+e.target.value);
                                    }
                                }}
                            />
                        </div>
                    </div>

                    <div
                        className="subscription-plan-cards__pay-cycle"
                        hidden={isCurrentProfessionalPlan}
                    >
                        <div
                            className={`pill pill--monthly ${
                                isCurrentProfessionalPlan
                                    ? "pill--disabled"
                                    : ""
                            } ${
                                duration === TKey.Common.Label.DurationQuarter
                                    ? "pill--active"
                                    : ""
                            }`}
                            onClick={() => {
                                if (!isCurrentProfessionalPlan) {
                                    setDuration(
                                        TKey.Common.Label.DurationQuarter,
                                    );
                                }
                            }}
                        >
                            <span className="title">
                                {t(
                                    "admin.subscription.pricing:label.cycle.pay",
                                )}{" "}
                                {t(TKey.Common.Label.DurationQuarter)}ly
                            </span>
                            <span className="description">
                                {t(
                                    "admin.subscription.pricing:label.cycle.commit",
                                )}{" "}
                                {t(TKey.Common.Label.DurationQuarter)}ly
                            </span>
                        </div>

                        <div
                            className={`pill pill--yearly ${
                                isCurrentProfessionalPlan
                                    ? "pill--disabled"
                                    : ""
                            } ${
                                duration === TKey.Common.Label.DurationYear
                                    ? "pill--active"
                                    : ""
                            }`}
                            onClick={() => {
                                if (!isCurrentProfessionalPlan) {
                                    setDuration(TKey.Common.Label.DurationYear);
                                }
                            }}
                        >
                            <span className="title">
                                {t(
                                    "admin.subscription.pricing:label.cycle.pay",
                                )}{" "}
                                {t(TKey.Common.Label.DurationYear)}ly
                            </span>
                            <span className="discount">SAVE 15%</span>
                        </div>
                    </div>
                </div>

                <div className="subscription-plan-cards__box-action">
                    <AppButton
                        variant={
                            currentPlan === PLAN_ENTERPRISE.name
                                ? "primary"
                                : "success"
                        }
                        onClick={() =>
                            handlePlanCreate(
                                PLAN_PROFESSIONAL.name,
                                duration === TKey.Common.Label.DurationYear
                                    ? 12
                                    : 3,
                                seats,
                            )
                        }
                        disabled={currentPlan === PLAN_ENTERPRISE.name}
                    >
                        {currentPlan && currentPlan === PLAN_ENTERPRISE.name
                            ? t("admin.subscription.pricing:button.downgrade")
                            : currentPlan === PLAN_FREE.name
                            ? t("admin.subscription.pricing:button.buy")
                            : currentPlan === PLAN_PROFESSIONAL.name
                            ? t("admin.subscription.pricing:button.upgrade")
                            : t("admin.subscription.pricing:button.buy")}
                    </AppButton>

                    <ul className="subscription-plan-cards__list">
                        <li className="subscription-plan-cards__list-item">
                            <AppSVGIcon icon="check" className="icon" />
                            <span className="title">
                                {t("admin.subscription.pricing:label.feature2")}
                            </span>
                        </li>

                        <li className="subscription-plan-cards__list-item">
                            <AppSVGIcon icon="check" className="icon" />
                            <span className="title">
                                {t("admin.subscription.pricing:label.feature3")}
                            </span>
                        </li>

                        <li className="subscription-plan-cards__list-item">
                            <AppSVGIcon icon="check" className="icon" />
                            <span className="title">
                                {t("admin.subscription.pricing:label.feature4")}
                            </span>
                        </li>

                        <li
                            className="subscription-plan-cards__list-item"
                            hidden={showMore !== PLAN_PROFESSIONAL.name}
                        >
                            <AppSVGIcon icon="check" className="icon" />
                            <span className="title">
                                {t("admin.subscription.pricing:label.feature5")}
                            </span>
                        </li>

                        <li
                            className="subscription-plan-cards__list-item"
                            hidden={showMore !== PLAN_PROFESSIONAL.name}
                        >
                            <AppSVGIcon icon="check" className="icon" />
                            <span className="title">
                                {t("admin.subscription.pricing:label.feature6")}
                            </span>
                        </li>

                        {renderShowMoreButton(PLAN_PROFESSIONAL.name)}
                    </ul>
                </div>
            </div>

            <div
                className={`subscription-plan-cards__box ${
                    PLAN_ENTERPRISE.name === currentPlan && !isOnboarding
                        ? "subscription-plan-cards__box--active"
                        : ""
                } ${isOnboarding ? "h-100" : ""}`}
            >
                {renderPlanTitle(PLAN_ENTERPRISE.name)}

                <div className="subscription-plan-cards__box-content">
                    <div className="subscription-plan-cards__plans">
                        <span className="title">{PLAN_ENTERPRISE.name}</span>
                        <span className="description">
                            {t(
                                "admin.subscription.pricing:description.plan.enterprise",
                            )}
                        </span>
                    </div>

                    <div className="subscription-plan-cards__cost">
                        <span className="title">
                            {t("admin.subscription.pricing:label.cost")}
                        </span>
                        <div className="cost-content">
                            <span className="cost-value">
                                {(PLAN_ENTERPRISE.monthlyCostForYear || 0) * 12}{" "}
                                {t(TKey.Common.Label.Currency)}
                            </span>
                            <span className="cost-duration">
                                /{t(TKey.Common.Label.DurationYear)}
                            </span>
                        </div>
                        <span className="description">
                            {t("admin.subscription.pricing:description.cost")}{" "}
                            {PLAN_ENTERPRISE.monthlyCostForYear * 12}{" "}
                            {t(TKey.Common.Label.Currency)} /
                            {t(TKey.Common.Label.DurationYear)}
                        </span>
                    </div>
                </div>

                <div
                    className={`subscription-plan-cards__box-action ${
                        isCurrentProfessionalPlan ? "mt-10" : ""
                    }`}
                >
                    <AppButton
                        variant="success"
                        onClick={() => setShowConfirm(true)}
                        disabled={currentPlan === PLAN_ENTERPRISE.name}
                    >
                        {currentPlan && currentPlan !== PLAN_ENTERPRISE.name
                            ? t("admin.subscription.pricing:button.contactUs")
                            : t("admin.subscription.pricing:label.currentPlan")}
                    </AppButton>

                    <ul className="subscription-plan-cards__list">
                        <li className="subscription-plan-cards__list-item">
                            <AppSVGIcon icon="check" className="icon" />
                            <span className="title">
                                {t("admin.subscription.pricing:label.feature7")}
                            </span>
                        </li>

                        <li className="subscription-plan-cards__list-item">
                            <AppSVGIcon icon="check" className="icon" />
                            <span className="title">
                                {t("admin.subscription.pricing:label.feature8")}
                            </span>
                        </li>

                        <li className="subscription-plan-cards__list-item">
                            <AppSVGIcon icon="check" className="icon" />
                            <span className="title">
                                {t("admin.subscription.pricing:label.feature9")}
                            </span>
                        </li>

                        <li
                            className="subscription-plan-cards__list-item"
                            hidden={showMore !== PLAN_ENTERPRISE.name}
                        >
                            <AppSVGIcon icon="check" className="icon" />
                            <span className="title">
                                {t(
                                    "admin.subscription.pricing:label.feature10",
                                )}
                            </span>
                        </li>

                        <li
                            className="subscription-plan-cards__list-item"
                            hidden={showMore !== PLAN_ENTERPRISE.name}
                        >
                            <AppSVGIcon icon="check" className="icon" />
                            <span className="title">
                                {t("admin.subscription.pricing:label.feature2")}
                            </span>
                        </li>

                        <li
                            className="subscription-plan-cards__list-item"
                            hidden={showMore !== PLAN_ENTERPRISE.name}
                        >
                            <AppSVGIcon icon="check" className="icon" />
                            <span className="title">
                                {t("admin.subscription.pricing:label.feature3")}
                            </span>
                        </li>

                        <li
                            className="subscription-plan-cards__list-item"
                            hidden={showMore !== PLAN_ENTERPRISE.name}
                        >
                            <AppSVGIcon icon="check" className="icon" />
                            <span className="title">
                                {t("admin.subscription.pricing:label.feature4")}
                            </span>
                        </li>

                        <li
                            className="subscription-plan-cards__list-item"
                            hidden={showMore !== PLAN_ENTERPRISE.name}
                        >
                            <AppSVGIcon icon="check" className="icon" />
                            <span className="title">
                                {t("admin.subscription.pricing:label.feature5")}
                            </span>
                        </li>

                        <li
                            className="subscription-plan-cards__list-item"
                            hidden={showMore !== PLAN_ENTERPRISE.name}
                        >
                            <AppSVGIcon icon="check" className="icon" />
                            <span className="title">
                                {t("admin.subscription.pricing:label.feature6")}
                            </span>
                        </li>
                        {renderShowMoreButton(PLAN_ENTERPRISE.name)}
                    </ul>
                </div>
            </div>
        </>
    );
};
