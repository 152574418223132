import { FC, PropsWithChildren, useState } from "react";
import { OverlayTrigger, Popover } from "react-bootstrap";
import { OverlayTriggerRenderProps } from "react-bootstrap/esm/OverlayTrigger";

import "./assets/scss/style.scss";

import { useTranslation } from "../../hooks";
import { AppSVGIcon } from "../AppSVGIcon";
import { AppButton } from "../AppButton";

interface AppActionDropDownProps extends PropsWithChildren {
    id?: number;
    className?: string;
    btnClassName?: string;
    isPopover?: boolean;
    isEllipsis?: boolean;
    text?: string;
    icon?: string;
    iconLeft?: string;
    iconAfterText?: string;
    isGrantedControl?: boolean;
}

export const AppActionDropDown: FC<AppActionDropDownProps> = ({
    id = 0,
    className = "",
    btnClassName = "",
    isPopover = true,
    isEllipsis = true,
    text,
    icon,
    iconLeft,
    children,
    isGrantedControl = true,
}): JSX.Element => {
    // hooks
    const { t } = useTranslation();

    // ref
    // const target = useRef(null);

    // state
    const [showPopover, setShowPopover] = useState(false);

    const renderButton = ({
        ref,
        ...triggerHandler
    }: OverlayTriggerRenderProps) => {
        if (isEllipsis) {
            return (
                <AppSVGIcon
                    icon="ellipsis"
                    ref={ref}
                    onClick={(e) => {
                        e.stopPropagation();
                    }}
                    {...triggerHandler}
                />
            );
        }

        return (
            <AppButton
                ref={ref}
                variant="secondary"
                className={`app-action-dropdown--action ${btnClassName}`}
                onClick={(e) => {
                    e.stopPropagation();
                }}
                {...triggerHandler}
                iconLeft={iconLeft}
            >
                {text && t(text)}
                {icon && <AppSVGIcon icon={icon} />}
            </AppButton>
        );
    };

    const renderContent = () => (
        <ul className="app-action-dropdown--content">
            {/* 
                we can use this structure to implement styles for
                each item automatically <li><a><i></i></a></li>.
            */}
            {children}
        </ul>
    );

    if (!isGrantedControl) {
        return <></>;
    }

    return (
        <div className={`app-action-dropdown ${className}`}>
            {isPopover ? (
                <OverlayTrigger
                    trigger={"click"}
                    placement="bottom-end"
                    flip
                    rootClose
                    show={showPopover}
                    onToggle={setShowPopover}
                    overlay={(props) => (
                        <Popover
                            {...props}
                            id={`popover-basic-${id}`}
                            className="popover-container"
                            onClickCapture={() => setShowPopover(false)}
                            arrowProps={{
                                style: {
                                    display: "none",
                                },
                            }}
                        >
                            {renderContent()}
                        </Popover>
                    )}
                >
                    {(props) => renderButton(props)}
                </OverlayTrigger>
            ) : (
                <OverlayTrigger
                    trigger={["hover", "focus"]}
                    placement="bottom-end"
                    flip
                    show={showPopover}
                    onToggle={setShowPopover}
                    overlay={(props) => (
                        <Popover
                            {...props}
                            id={`popover-basic-${id}`}
                            className="popover-container"
                            onMouseEnter={() => setShowPopover(true)}
                            onMouseLeave={() => setShowPopover(false)}
                            onClickCapture={() => setShowPopover(false)}
                        >
                            {renderContent()}
                        </Popover>
                    )}
                >
                    {(props) => renderButton(props)}
                </OverlayTrigger>
            )}
        </div>
    );
};
