import { AxiosError, InternalAxiosRequestConfig } from "axios";
import { getRecoil } from "recoil-nexus";

import { APP_TOKEN_KEY } from "../../../utils";
import { atomActiveClient } from "../../../atoms";
import { AcceptableResponse } from "../models";
import { ACCEPTABLE_RESPONSE } from "../../env";

export const onRequestFulfilled = (
    config: InternalAxiosRequestConfig,
): InternalAxiosRequestConfig => {
    config.headers["Content-Type"] =
        AcceptableResponse.header(ACCEPTABLE_RESPONSE);

    const token = localStorage.getItem(APP_TOKEN_KEY);
    if (token) {
        config.headers["Authorization"] = `Bearer ${token}`;
    }

    const activeClient = getRecoil(atomActiveClient);
    if (token && activeClient?.id) {
        config.headers["APP-CKEY"] = activeClient.id;
    }

    return config;
};

// handle request error (optional)
export const onRequestRejected = (error: AxiosError) => Promise.reject(error);
