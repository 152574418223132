import * as yup from "yup";
import { TKey, validation } from "../../../utils";

export const schemaTaskPost = (t: (val: string) => string) =>
    yup.object().shape({
        title: yup
            .string()
            .required(t(TKey.Common.Message.Validation.RequiredField))
            .max(validation.title.max),
        description: yup.string().max(validation.description.max).nullable(),
        documents: yup.array().of(
            yup.object().shape({
                name: yup
                    .string()
                    .required(t(TKey.Common.Message.Validation.RequiredField)),
                fileName: yup
                    .string()
                    .required(t(TKey.Common.Message.Validation.RequiredField)),
            }),
        ),
        tags: yup.array().of(yup.object()),
        color: yup.string().nullable(),
    });

export const schemaActionTask = (t: (val: string) => string) =>
    yup.object().shape({
        title: yup
            .string()
            .required(t(TKey.Common.Message.Validation.RequiredField))
            .max(validation.actionTask.title.max),
        description: yup.string().max(validation.description.max).nullable(),
        user: yup.string().nullable(),
        documents: yup.array().of(
            yup.object().shape({
                name: yup
                    .string()
                    .required(t(TKey.Common.Message.Validation.RequiredField)),
                fileName: yup
                    .string()
                    .required(t(TKey.Common.Message.Validation.RequiredField)),
            }),
        ),
        additionalInfo: yup.object().shape({
            description: yup.string().max(validation.description.max),
            achieve: yup.string().max(validation.description.max),
            important: yup.string().max(validation.description.max),
            keyAction: yup.string().max(validation.description.max),
            involved: yup.string().max(validation.description.max),
            keyIndicators: yup.string().max(validation.description.max),
        }),
    });
