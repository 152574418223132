import { FC, useEffect, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useRecoilState, useResetRecoilState } from "recoil";
import { RowClickedEvent } from "ag-grid-community";

import {
    useAuthAction,
    useAuthState,
    useRoles,
    useTranslation,
} from "../../../hooks";
import {
    User,
    PUser,
    PrimitiveObject,
    RoleClient,
    Client,
} from "../../../models";
import {
    AppButton,
    AppConfirmModal,
    AppForm,
    AppFormContent,
    AppFormInput,
    AppFormModal,
    AppFormSelect,
    AppFormUploader,
    AppGrid,
    AppLoader,
    AppPageHeader,
} from "../../../components";
import { ClientApi, UserApi } from "../../../apis";
import {
    CropperViewEnum,
    ModalTypeEnum,
    TKey,
    errorToast,
    isNumber,
    setBackendViolations,
    setCustomViolation,
    successToast,
    validation,
} from "../../../utils";
import {
    PLAN_ENTERPRISE,
    PLAN_FREE,
    ROLES,
    UserPosterFileInfo,
} from "../../../config";
import { schema } from "./schema";
import { appGridColDef } from "./app-grid";
import { atomActiveClient, atomDrawer, atomDrawerType } from "../../../atoms";
import { UserInviteModal } from "../../components";
import { useNavigate } from "react-router-dom";

export const UserPage: FC = () => {
    // hooks
    const { t } = useTranslation();
    const { isSuperAdmin } = useRoles();
    const { userId } = useAuthState();
    const { actionRefetchAuthUser } = useAuthAction();
    const navigate = useNavigate();

    // atoms
    const [activeClient] = useRecoilState(atomActiveClient);
    const [drawer, setDrawer] = useRecoilState(
        atomDrawerType(ModalTypeEnum.USER_ADD_EDIT),
    );
    const resetDrawer = useResetRecoilState(atomDrawer);

    // state
    const [editId, setEditId] = useState(0);
    const [loading, setLoading] = useState(false);
    const [isUploading, setIsUploading] = useState(false);
    const [data, setData] = useState<User[]>([]);
    const [showConfirm, setShowConfirm] = useState(0);
    const [emailExist, setEmailExist] = useState(false);
    const [emailExistSameClient, setEmailExistSameClient] = useState(false);
    const [showInviteModal, setShowInviteModal] = useState(false);
    const [lockCreate, setLockCreate] = useState(true);

    // form
    const methods = useForm({
        resolver: yupResolver(schema(t, editId)),
    });
    const { handleSubmit, formState, reset, setError, watch, trigger } =
        methods;

    // local state
    const emailValue = watch("email");
    // const roleValue = watch("role");
    const isPasswordStrong = watch("isPasswordStrong");
    // const disableRole = [
    //     ROLES.USER_ROLE_SUPER_ADMIN,
    //     ROLES.USER_ROLE_CLIENT_ADMIN,
    // ].includes(roleValue);

    const fetchMyClient = (totalUsers: number) => {
        ClientApi.getMyClient<Client>().then(({ errorMessage, response }) => {
            if (errorMessage) {
                errorToast(errorMessage);
            } else if (response) {
                if (response.currentPlan === PLAN_FREE.name) {
                    setLockCreate(true);
                } else if (response.currentPlan === PLAN_ENTERPRISE.name) {
                    setLockCreate(false);
                } else if (response) {
                    setLockCreate(
                        isNumber(response.allowUsers) &&
                            response.allowUsers <= totalUsers,
                    );
                }
            }
        });
    };

    const fetchUsers = (search = "") => {
        if (!activeClient) {
            return;
        }

        if (isSuperAdmin) {
            setLoading(true);

            UserApi.find<User>(1, {
                pagination: false,
                ...(search ? { user_search: search } : {}),
            })
                .then(({ response, errorMessage }) => {
                    if (errorMessage) {
                        errorToast(errorMessage);
                    } else if (response !== null) {
                        setData(response.items);
                    }
                })
                .finally(() => setLoading(false));
        } else if (activeClient) {
            setLoading(true);

            UserApi.getForClient<User>(1, {
                pagination: false,
            })
                .then(({ response, errorMessage }) => {
                    if (errorMessage) {
                        errorToast(errorMessage);
                    } else if (response !== null) {
                        setData(response.items);

                        // for first time
                        fetchMyClient(response.totalItems);
                    }
                })
                .finally(() => setLoading(false));
        }
    };

    useEffect(() => {
        fetchUsers();
    }, []);

    const onSubmitHandler = (formData: PUser) => {
        setLoading(true);

        UserApi.createOrUpdate<User, PUser>(editId, formData)
            .then(({ response, errorMessage, isInvalid, error }) => {
                if (isInvalid) {
                    setBackendViolations(error, setError);
                } else if (errorMessage) {
                    errorToast(errorMessage);
                } else if (response !== null) {
                    if (editId) {
                        successToast(t("admin.user.list:update.toast.success"));

                        if (editId === userId) {
                            actionRefetchAuthUser();
                        }
                    } else {
                        successToast(t("admin.user.list:create.toast.success"));
                    }
                    reset();
                    resetDrawer();
                    setEditId(0);
                    fetchUsers();
                }
            })
            .finally(() => setLoading(false));
    };

    const handleEdit = (id: number) => {
        UserApi.findById<User>(id).then(({ errorMessage, response }) => {
            if (errorMessage) {
                errorToast(errorMessage);
            } else if (response) {
                reset({
                    email: response.email,
                    // role: response.roles[0],
                    role: undefined,
                    isOnboarded: undefined,
                    firstName: response.firstName,
                    lastName: response.lastName,
                    imageName: response.imageName,
                    company: response.company,
                    isPasswordStrong: true,
                    locale: response.locale,
                });
                setEditId(id);
                setDrawer({ show: true, id: response.id });
            }
        });
    };

    const handleRowDoubleClick = (event: RowClickedEvent) => {
        handleEdit(event.data.id);
    };

    const handleDelete = (id: number) => {
        UserApi.deleteById(id).then(({ errorMessage }) => {
            if (errorMessage) {
                errorToast(errorMessage);
            } else {
                successToast(t("admin.user.list:delete.toast.success"));
                setShowConfirm(0);
                fetchUsers();
            }
        });
    };

    const triggerEmailExistError = () => {
        setCustomViolation(
            "email",
            t("user.register.form:emailExist.toast.error"),
            setError,
            false,
        );
    };

    const handleEmailExist = async (email: string) => {
        await trigger("email");
        setEmailExistSameClient(false);

        if (email && !formState.errors.email?.message && activeClient) {
            // setEmailExist(false);

            UserApi.exist<User>({
                email,
                // "roleClients.role": roleValue,
            }).then(({ response, errorMessage }) => {
                if (errorMessage) {
                    errorToast(errorMessage);
                } else if (response !== null && response.items.length > 0) {
                    const { roleClients = [] } = response.items[0] as never;
                    const userAvailableClientList: string[] = (
                        roleClients as RoleClient[]
                    ).map((x) => x.client as string);

                    // check if user exist in same client or different client
                    if (
                        userAvailableClientList.includes(
                            ClientApi.toResourceUrl(activeClient.id),
                        )
                    ) {
                        triggerEmailExistError();
                        setEmailExistSameClient(true);
                    } else {
                        setEmailExist(true);
                    }
                }
            });
        }
    };

    const handleAttach = () => {
        UserApi.attach<User, PrimitiveObject>({
            email: emailValue,
        }).then(({ errorMessage, response }) => {
            if (errorMessage) {
                errorToast(errorMessage);
            } else if (response !== null) {
                successToast(t("admin.user.list:attach.toast.success"));
                setEmailExist(false);
                resetDrawer();
                setEditId(0);
                fetchUsers();
            }
        });
    };

    const handleReInvite = (email: string) => {
        UserApi.invite<User, PrimitiveObject>({
            email,
        })
            .then(({ errorMessage, response }) => {
                if (errorMessage) {
                    errorToast(errorMessage);
                } else if (response !== null) {
                    successToast(t("admin.user.list:invite.toast.success"));
                }
            })
            .finally(() => setLoading(false));
    };

    useEffect(() => {
        const subscription = watch((_, { name }) => {
            if (name && formState.dirtyFields) {
                trigger(name);
            }
        });
        return () => subscription.unsubscribe();
    }, [watch, trigger, formState.dirtyFields]);

    const renderForm = () => (
        <FormProvider {...methods}>
            <AppForm>
                {loading && <AppLoader isFullScreen />}

                <AppFormContent>
                    {/* <AppFormSelect
                        id="role"
                        name="role"
                        label={t("client.onboard.form:label.role")}
                        placeholder={t("client.onboard.form:placeholder.role")}
                        block
                        options={getSelectRoleOptions(disableRole)}
                        disabled={disableRole}
                    /> */}

                    <AppFormInput
                        type="email"
                        id="email"
                        name="email"
                        label={t("user.register.form:label.email")}
                        placeholder={t("user.register.form:placeholder.email")}
                        autoComplete="email"
                        block
                        disabled={!!editId}
                        onBlurCapture={(e) => handleEmailExist(e.target.value)}
                    />

                    <AppFormUploader
                        id="imageName"
                        name="imageName"
                        label={t("admin.user.form:label.imageName")}
                        required={false}
                        block
                        fileInfo={UserPosterFileInfo}
                        cbFileUploading={setIsUploading}
                        withCropper={CropperViewEnum.Default}
                    />

                    {!editId && (
                        <>
                            <AppFormInput
                                type="password"
                                id="plainPassword"
                                name="plainPassword"
                                label={t("user.register.form:label.password")}
                                placeholder={t(
                                    "user.register.form:placeholder.password",
                                )}
                                showPasswordStrength
                                autoComplete="new-password"
                                block
                            />

                            <AppFormInput
                                type="password"
                                id="confirmPassword"
                                name="confirmPassword"
                                label={t(
                                    "user.register.form:label.confirmPassword",
                                )}
                                placeholder={t(
                                    "user.register.form:placeholder.confirmPassword",
                                )}
                                autoComplete="new-password"
                                block
                                onCopy={(e) => e.preventDefault()}
                                onPaste={(e) => e.preventDefault()}
                            />
                        </>
                    )}

                    <AppFormInput
                        id="firstName"
                        name="firstName"
                        label={t("admin.user.form:label.firstName")}
                        placeholder={t("admin.user.form:placeholder.firstName")}
                        block
                        maxCount={validation.title.max}
                    />

                    <AppFormInput
                        id="lastName"
                        name="lastName"
                        label={t("admin.user.form:label.lastName")}
                        placeholder={t("admin.user.form:placeholder.lastName")}
                        block
                        maxCount={validation.title.max}
                    />

                    <AppFormInput
                        id="company"
                        name="company"
                        label={t("admin.user.form:label.company")}
                        placeholder={t("admin.user.form:placeholder.company")}
                        block
                        maxCount={validation.title.max}
                    />

                    <AppFormSelect
                        id="locale"
                        name="locale"
                        label={t("admin.user.form:label.locale")}
                        required={true}
                        options={[
                            {
                                label: t(
                                    `common.locale:${ROLES.USER_LOCALE_EN}`,
                                ),
                                value: ROLES.USER_LOCALE_EN,
                            },
                            {
                                label: t(
                                    `common.locale:${ROLES.USER_LOCALE_DE}`,
                                ),
                                value: ROLES.USER_LOCALE_DE,
                            },
                        ]}
                    />
                </AppFormContent>
            </AppForm>
        </FormProvider>
    );

    return (
        <div className="user-page">
            {loading && <AppLoader isFullScreen />}

            <AppPageHeader
                title="admin.user.list:header.title"
                onSearchChange={fetchUsers}
                showToolbar
                isGrantedControl={!!activeClient}
                createButton={false}
            >
                <AppButton
                    variant="secondary"
                    iconLeft={lockCreate ? "lock" : "mail"}
                    onClick={() => {
                        if (lockCreate) {
                            return navigate(`/admin/subscription/pricing`);
                        }
                        setShowInviteModal(true);
                    }}
                >
                    {t(TKey.Common.Button.Invite)}
                </AppButton>
                <AppButton
                    variant={lockCreate ? "secondary" : "primary"}
                    iconLeft={lockCreate ? "lock" : "plus"}
                    onClick={() => {
                        if (lockCreate) {
                            return navigate(`/admin/subscription/pricing`);
                        }
                        reset({
                            firstName: undefined,
                            lastName: undefined,
                            email: undefined,
                            role: ROLES.USER_ROLE_USER,
                            isOnboarded: true,
                            imageName: undefined,
                            company: undefined,
                            locale: undefined,
                        });
                        setDrawer({ show: true, id: 0 });
                    }}
                >
                    {t(TKey.Common.Button.Create)}
                </AppButton>
            </AppPageHeader>

            <AppFormModal
                show={drawer.show}
                icon="description"
                title="admin.user.form:modal.title"
                isDrawer
                onSubmit={handleSubmit(onSubmitHandler)}
                onHide={() => {
                    resetDrawer();
                    setEditId(0);
                }}
                isLoading={formState.isSubmitting || loading}
                disabled={
                    isUploading ||
                    emailExist ||
                    emailExistSameClient ||
                    !isPasswordStrong
                }
                isEditMode={!!editId}
                disabledSumbit={!formState.isValid}
            >
                {renderForm()}
            </AppFormModal>

            <AppConfirmModal
                show={!!showConfirm}
                icon="warning"
                title="admin.user.item:delete.confirm.modal.title"
                description="admin.user.item:delete.confirm.modal.description"
                onHide={() => setShowConfirm(0)}
                nextAction={() => handleDelete(showConfirm)}
            />

            <AppConfirmModal
                show={emailExist}
                icon="warning"
                title="admin.user.item:attach.confirm.modal.title"
                description="admin.user.item:attach.confirm.modal.description"
                onHide={() => setEmailExist(false)}
                nextAction={handleAttach}
            />

            <UserInviteModal
                showForm={showInviteModal}
                onHide={() => setShowInviteModal(false)}
            />

            <AppGrid
                dynamicHeight
                columnDefs={appGridColDef({
                    onPressEdit: handleEdit,
                    onPressDelete: setShowConfirm,
                    onPressReInvite: handleReInvite,
                })}
                gridOptions={{
                    onRowDoubleClicked: handleRowDoubleClick,
                }}
                rowData={data}
                isLoading={loading}
            />
        </div>
    );
};
