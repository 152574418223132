import { FC, useEffect, useState } from "react";
import { DraggableProvided } from "react-beautiful-dnd";
import { yupResolver } from "@hookform/resolvers/yup";
import { FormProvider, useForm } from "react-hook-form";
import { Link } from "react-router-dom";
import { useRecoilState, useResetRecoilState } from "recoil";
import { Collapse } from "react-bootstrap";

import "./assets/scss/style.scss";

import { Map, PMap, Workspace } from "../../../models";
import {
    AppActionDropDown,
    AppButton,
    AppConfirmModal,
    AppForm,
    AppFormContent,
    AppFormInput,
    AppFormModal,
    AppFormTextarea,
    AppSVGIcon,
} from "../../../components";
import { ClientApi, MapApi, WorkspaceApi } from "../../../apis";
import {
    ModalTypeEnum,
    TKey,
    errorToast,
    getRandomId,
    setBackendViolations,
    successToast,
    validation,
} from "../../../utils";
import {
    useMapSetting,
    useRoles,
    useTranslation,
    useWorkspacePermission,
} from "../../../hooks";
import { MapList } from "../MapList";
import { schema } from "../MapList/schema";
import { atomActiveClient, atomDrawer, atomDrawerType } from "../../../atoms";

interface WorkspaceItemProps {
    workspace: Workspace;
    provided: DraggableProvided;
    onEdit: (workspace: Workspace) => void;
    onDelete: () => void;
    onInvite: (workspaceId: number) => void;
}

export const WorkspaceItem: FC<WorkspaceItemProps> = ({
    workspace,
    provided,
    onEdit,
    onDelete,
    onInvite,
}) => {
    // hooks
    const { t } = useTranslation();
    const {
        getDefaultActionPostWeightLevels,
        getDefaultActionPostDurations,
        getDefaultKanBanSettings,
    } = useMapSetting();
    const { isSuperAdmin, isClientAdmin } = useRoles();

    const { isOwner, isWrite, isRead } = useWorkspacePermission(
        workspace.workspacePermissions,
    );

    // atoms
    const [activeClient] = useRecoilState(atomActiveClient);
    const [drawer, setDrawer] = useRecoilState(
        atomDrawerType(ModalTypeEnum.MAP_ADD_EDIT),
    );
    const resetDrawer = useResetRecoilState(atomDrawer);

    // state
    const [showConfirm, setShowConfirm] = useState(0);
    const [expandedId, setExpandedId] = useState(0);
    const [loading, setLoading] = useState(false);
    const [addId, setAddId] = useState(0);
    const [editId, setEditId] = useState(0);
    const [data, setData] = useState<Map[]>([]);

    // form
    const methods = useForm({
        resolver: yupResolver(schema(t)),
    });
    const { handleSubmit, formState, reset, setError, watch, trigger } =
        methods;

    const fetchMaps = (overrideId?: number): Promise<void> => {
        const targetId = overrideId || expandedId;
        if (!targetId) {
            return Promise.resolve();
        }

        setLoading(true);

        return MapApi.find<Map>(1, {
            pagination: false,
            "workspace.id": targetId,
        })
            .then(({ response, errorMessage }) => {
                if (errorMessage) {
                    errorToast(errorMessage);
                } else if (response !== null) {
                    setData(response.items);
                }
            })
            .finally(() => setLoading(false));
    };

    useEffect(() => {
        fetchMaps();
    }, [expandedId]);

    const onSubmitHandler = (formData: unknown) => {
        if (!activeClient) {
            return;
        }

        const payload = editId
            ? (formData as PMap)
            : Map.createForm(
                  ClientApi.toResourceUrl(activeClient.id),
                  WorkspaceApi.toResourceUrl(workspace.id),
                  {
                      ...(formData as PMap),
                      actionPostWeightLevels:
                          getDefaultActionPostWeightLevels(),
                      actionPostDurations: getDefaultActionPostDurations(),
                      kanbanSettings: getDefaultKanBanSettings(),
                  },
              );

        payload.days = payload?.days ? +payload.days : null;
        payload.budget = payload?.budget ? +payload.budget : null;
        payload.expense = payload?.expense ? +payload.expense : null;

        setLoading(true);

        MapApi.createOrUpdate<Map, PMap>(editId, payload)
            .then(({ response, errorMessage, isInvalid, error }) => {
                if (isInvalid) {
                    setBackendViolations(error, setError);
                } else if (errorMessage) {
                    errorToast(errorMessage);
                } else if (response !== null) {
                    if (editId) {
                        successToast(t("app.map.list:update.toast.success"));
                    } else {
                        successToast(t("app.map.list:create.toast.success"));
                    }
                    reset();
                    resetDrawer();
                    setEditId(0);
                    setAddId(0);
                    fetchMaps();
                }
            })
            .finally(() => setLoading(false));
    };

    const handleEdit = (id: number) => {
        WorkspaceApi.findById<Workspace>(id).then(
            ({ errorMessage, response }) => {
                if (errorMessage) {
                    errorToast(errorMessage);
                } else if (response) {
                    onEdit(response);
                }
            },
        );
    };

    const handleDelete = (id: number) => {
        WorkspaceApi.deleteById(id).then(({ errorMessage }) => {
            if (errorMessage) {
                errorToast(errorMessage);
            } else {
                successToast(t("app.workspace.item:delete.toast.success"));
                setShowConfirm(0);
                onDelete();
            }
        });
    };

    const onCreate = () => {
        setEditId(0);
        const createId = getRandomId();
        setAddId(createId);
    };

    useEffect(() => {
        if (addId) {
            const timer = setTimeout(() => {
                reset({
                    name: undefined,
                    subTitle: undefined,
                    description: undefined,
                    days: null,
                    budget: null,
                    expense: null,
                });
                setDrawer({ show: true, id: addId });
            }, 500);

            return () => {
                clearTimeout(timer);
            };
        }
    }, [addId]);

    useEffect(() => {
        const subscription = watch((_, { name }) => {
            if (name && formState.dirtyFields) {
                trigger(name);
            }
        });
        return () => subscription.unsubscribe();
    }, [watch, trigger, formState.dirtyFields]);

    const renderForm = () => (
        <FormProvider {...methods}>
            <AppForm className="workspace-item--maps--form">
                <AppFormContent>
                    <AppFormInput
                        id="name"
                        name="name"
                        label={t("app.map.form:label.name")}
                        placeholder={t("app.map.form:placeholder.name")}
                        block
                        maxCount={validation.title.max}
                    />

                    <AppFormInput
                        id="subTitle"
                        name="subTitle"
                        label={t("app.map.form:label.subTitle")}
                        placeholder={t("app.map.form:placeholder.subTitle")}
                        required
                        block
                        maxCount={validation.title.max}
                    />

                    <AppFormTextarea
                        id="description"
                        name="description"
                        label={t("app.map.form:label.description")}
                        placeholder={t("app.map.form:placeholder.description")}
                        required
                        block
                        rows={3}
                        maxCount={validation.description.max}
                        isEditor
                    />

                    <div className="user-title-container">
                        <span className="separator" />

                        <span className="title pb-2">
                            {t("app.map.form:label.titleBudget")}
                        </span>
                    </div>

                    <AppFormInput
                        id="days"
                        name="days"
                        label={t("app.map.form:label.days")}
                        placeholder={t("app.map.form:placeholder.days")}
                        required={false}
                        type="number"
                        block
                    />
                    <AppFormInput
                        id="budget"
                        name="budget"
                        label={t("app.map.form:label.budget")}
                        placeholder={t("app.map.form:placeholder.budget")}
                        required={false}
                        type="number"
                        block
                    />
                    <AppFormInput
                        id="expense"
                        name="expense"
                        label={t("app.map.form:label.expense")}
                        placeholder={t("app.map.form:placeholder.expense")}
                        required={false}
                        type="number"
                        block
                    />
                </AppFormContent>
            </AppForm>
        </FormProvider>
    );

    const isGrantedControl = isOwner || isClientAdmin || isSuperAdmin;

    const renderItem = () => (
        <div
            className="workspace-item mb-4"
            ref={provided.innerRef}
            {...provided.draggableProps}
        >
            <div className="workspace-item--main">
                <div
                    className="workspace-item--main--content"
                    onClick={() =>
                        setExpandedId((prev) =>
                            prev !== workspace.id ? workspace.id : 0,
                        )
                    }
                >
                    <AppSVGIcon
                        icon="drag-n-drop"
                        {...provided.dragHandleProps}
                        hidden={!isWrite}
                    />
                    <span className="title ms-3">{workspace.name}</span>
                </div>

                <div className="workspace-item--main--action gap-3">
                    <AppActionDropDown
                        btnClassName="btn-square btn-sm"
                        isEllipsis={false}
                        icon="ellipsis"
                        id={workspace.id}
                        isGrantedControl={isGrantedControl}
                    >
                        <li className="invite">
                            <Link
                                to={"#"}
                                onClick={(e) => {
                                    e.preventDefault();
                                    onInvite(workspace.id);
                                }}
                            >
                                <AppSVGIcon icon="mail" />
                                <span>{t(TKey.Common.Button.Invite)}</span>
                            </Link>
                        </li>

                        <li className="add-map">
                            <Link
                                to={"#"}
                                onClick={(e) => {
                                    e.preventDefault();
                                    onCreate();
                                }}
                            >
                                <AppSVGIcon icon="plus" />
                                <span>
                                    {t("app.workspace.item:button.createMap")}
                                </span>
                            </Link>
                        </li>

                        <li className="edit">
                            <Link
                                to={"#"}
                                onClick={(e) => {
                                    e.preventDefault();
                                    handleEdit(workspace.id);
                                }}
                            >
                                <AppSVGIcon icon="edit" />
                                <span>{t(TKey.Common.Button.Edit)}</span>
                            </Link>
                        </li>

                        {isOwner && (
                            <li className="delete">
                                <Link
                                    to={"#"}
                                    onClick={(e) => {
                                        e.preventDefault();
                                        if (workspace.id !== expandedId) {
                                            fetchMaps(workspace.id).then(() => {
                                                setShowConfirm(workspace.id);
                                            });
                                        } else {
                                            setShowConfirm(workspace.id);
                                        }
                                    }}
                                >
                                    <AppSVGIcon icon="delete" />
                                    <span>{t(TKey.Common.Button.Delete)}</span>
                                </Link>
                            </li>
                        )}
                    </AppActionDropDown>

                    <AppButton
                        variant="secondary"
                        className="btn-square btn-sm me-3"
                        onClick={() =>
                            setExpandedId((prev) =>
                                prev !== workspace.id ? workspace.id : 0,
                            )
                        }
                        disabled={loading}
                    >
                        <AppSVGIcon
                            icon={expandedId ? "chevron-down" : "chevron-right"}
                        />
                    </AppButton>
                </div>
            </div>

            <Collapse in={expandedId > 0 && !loading}>
                <div>
                    <MapList
                        className="workspace-item--maps"
                        data={data}
                        loading={loading}
                        onEdit={(response) => {
                            reset({
                                name: response.name,
                                subTitle: response.subTitle,
                                description: response.description,
                                days: response.days,
                                budget: response.budget,
                                expense: response.expense,
                            });
                            setEditId(response.id);
                            setDrawer({ show: true, id: response.id });
                            setAddId(0);
                        }}
                        onDelete={fetchMaps}
                        isOwner={isOwner}
                        isWrite={isWrite}
                        isRead={isRead}
                    />
                </div>
            </Collapse>
        </div>
    );

    return (
        <>
            <AppConfirmModal
                show={!!showConfirm}
                icon="warning"
                title={
                    data.length === 0
                        ? "app.workspace.item:delete.confirm.modal.title"
                        : "app.workspace.item:delete.removeContent.modal.title"
                }
                description={
                    data.length === 0
                        ? "app.workspace.item:delete.confirm.modal.description"
                        : "app.workspace.item:delete.removeContent.modal.description"
                }
                onHide={() => setShowConfirm(0)}
                nextAction={
                    data.length === 0
                        ? () => handleDelete(showConfirm)
                        : () => setShowConfirm(0)
                }
                createLabel={
                    data.length !== 0 ? TKey.Common.Button.Close : undefined
                }
                hideSecondary={data.length !== 0}
            />

            <AppFormModal
                show={
                    drawer.show &&
                    ((!!editId && drawer.id === editId) ||
                        (!!addId && drawer.id === addId))
                }
                icon="description"
                title="app.map.form:modal.title"
                isDrawer
                onSubmit={handleSubmit(onSubmitHandler)}
                onHide={() => {
                    resetDrawer();
                    setEditId(0);
                    setAddId(0);
                }}
                isLoading={formState.isSubmitting || loading}
                isEditMode={!!editId}
                disabled={loading}
                disabledSumbit={!formState.isValid}
            >
                {renderForm()}
            </AppFormModal>

            {renderItem()}
        </>
    );
};
