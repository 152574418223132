import { FC } from "react";

import "./assets/scss/style.scss";

import {
    AppBaseModal,
    AppBaseModalBody,
    AppBaseModalFooter,
    AppBaseModalHeader,
    AppBaseModalProps,
} from "../AppBaseModal";
import { AppFormActions, AppFormActionsProps } from "../AppForm";
import { TKey } from "../../utils";

export interface AppContentModalProps
    extends AppBaseModalProps,
        Pick<
            AppFormActionsProps,
            | "isLoading"
            | "isEditMode"
            | "disabled"
            | "cancelLabel"
            | "createLabel"
            | "backLink"
            | "backAction"
            | "nextAction"
            | "hideSecondary"
            | "position"
        > {}

export const AppContentModal: FC<AppContentModalProps> = ({
    show,
    onHide,
    icon,
    title,
    size,
    centered,
    scrollable,
    closeButton,
    className = "",
    isLoading,
    isEditMode,
    disabled,
    cancelLabel = TKey.Common.Button.No,
    createLabel = TKey.Common.Button.Yes,
    backLink,
    backAction,
    nextAction,
    hideSecondary,
    position,
    children,
}) => (
    <AppBaseModal
        show={show}
        onHide={onHide}
        className={`app-content-modal ${className}`}
        centered={centered}
        scrollable={scrollable}
        size={size}
    >
        <AppBaseModalHeader
            icon={icon}
            title={title}
            onHide={onHide}
            closeButton={closeButton}
        />

        <AppBaseModalBody>{children}</AppBaseModalBody>

        <AppBaseModalFooter>
            <AppFormActions
                backLink={backLink}
                backAction={backAction ? backAction : onHide}
                nextAction={nextAction}
                isLoading={isLoading}
                disabled={disabled}
                isEditMode={isEditMode}
                cancelLabel={cancelLabel}
                createLabel={createLabel}
                hideSecondary={hideSecondary}
                position={position}
            />
        </AppBaseModalFooter>
    </AppBaseModal>
);
