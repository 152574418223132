import { FC, useEffect } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useRecoilState, useResetRecoilState } from "recoil";
import { useParams } from "react-router-dom";

import { atomActiveClient, atomMapPortfolioFilter } from "../../../atoms";
import { useMapAction, useTranslation } from "../../../hooks";
import {
    AppForm,
    AppFormContent,
    AppFormModal,
    AppFormSelect,
} from "../../../components";
import { TKey, errorToast } from "../../../utils";
import { schema } from "./schema";
import { ActionPostApi } from "../../../apis";
import { ActionPost } from "../../../models";

interface MapPortfolioFilterForm {
    responsible?: string[];
    tags?: string[];
}

interface MapPortfolioFilterProps {
    loading: boolean;
    showForm: boolean;
    onHide: () => void;
}

export const MapPortfolioFilter: FC<MapPortfolioFilterProps> = ({
    loading,
    showForm,
    onHide,
}) => {
    // hooks
    const { t } = useTranslation();
    const { id } = useParams();

    // context
    const {
        responsibleUserOptions,
        fetchActionPostTags,
        actionPostTagsOptions,
    } = useMapAction();

    // atoms
    const [activeClient] = useRecoilState(atomActiveClient);
    const [appliedFilter, setAppliedFilter] = useRecoilState(
        atomMapPortfolioFilter,
    );
    const resetFilter = useResetRecoilState(atomMapPortfolioFilter);

    // form
    const methods = useForm({
        resolver: yupResolver(schema()),
    });
    const { handleSubmit, formState, reset } = methods;

    const onSubmitHandler = (formData: MapPortfolioFilterForm) => {
        if (!Object.values(formData)?.some((x) => !!x?.length) || !id) {
            return;
        }

        ActionPostApi.find<ActionPost>(1, {
            "map.id": id,
            "tags.id": formData.tags,
            "responsible.id": formData.responsible,
            ...(appliedFilter.text
                ? { action_post_search: appliedFilter.text }
                : {}),
            pagination: false,
        }).then(({ response, errorMessage }) => {
            if (errorMessage) {
                errorToast(errorMessage);
            } else if (response !== null) {
                setAppliedFilter((prev) => ({
                    ...prev,
                    ...formData,
                    filteredActionPostIds: response.items.map((x) => x.id),
                }));
                onHide();
            }
        });
    };

    useEffect(() => {
        if (showForm) {
            fetchActionPostTags({
                "client.id": activeClient?.id,
                "map.id": id,
            });

            reset({
                responsible: appliedFilter.responsible,
                tags: appliedFilter.tags,
            });
        }
    }, [showForm]);

    const renderForm = () => (
        <FormProvider {...methods}>
            <AppForm className="app-portfolio-filter">
                <AppFormContent>
                    <AppFormSelect
                        id="responsible"
                        name="responsible"
                        label={t("app.actionPost.form:label.responsible")}
                        placeholder={t(
                            "app.actionPost.form:placeholder.responsible",
                        )}
                        required={false}
                        block
                        options={responsibleUserOptions}
                        isMulti
                        isClearable
                    />

                    <AppFormSelect
                        id="tags"
                        name="tags"
                        label={t("app.actionPost.form:label.tags")}
                        placeholder={t("app.actionPost.form:placeholder.tags")}
                        required={false}
                        block
                        options={actionPostTagsOptions}
                        isMulti
                        isClearable
                    />
                </AppFormContent>
            </AppForm>
        </FormProvider>
    );

    return (
        <AppFormModal
            show={showForm}
            icon="filter"
            title="app.map.portfolio.filter.form:modal.title"
            isDrawer
            onSubmit={handleSubmit(onSubmitHandler)}
            onHide={onHide}
            onCancelOrReset={() => {
                onHide();
                resetFilter();
            }}
            isLoading={formState.isSubmitting || loading}
            disabled={!activeClient}
            createLabel={TKey.Common.Button.Save}
            cancelLabel={TKey.Common.Button.Reset}
        >
            {renderForm()}
        </AppFormModal>
    );
};
