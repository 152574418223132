import * as yup from "yup";
import { PhoneNumberUtil } from "google-libphonenumber";
import { TKey, validation } from "../../../utils";

const phoneUtil = PhoneNumberUtil.getInstance();

export const schema = (t: (val: string) => string) =>
    yup.object().shape({
        name: yup
            .string()
            .required(t(TKey.Common.Message.Validation.RequiredField))
            .max(validation.title.max),
        address: yup
            .string()
            .required(t(TKey.Common.Message.Validation.RequiredField))
            .max(validation.title.max),
        region: yup
            .string()
            .required(t(TKey.Common.Message.Validation.RequiredField))
            .max(validation.title.max),
        postCode: yup
            .string()
            .required(t(TKey.Common.Message.Validation.RequiredField)),
        city: yup
            .string()
            .required(t(TKey.Common.Message.Validation.RequiredField)),
        country: yup
            .string()
            .required(t(TKey.Common.Message.Validation.RequiredField)),
        phone: yup
            .string()
            .required(t(TKey.Common.Message.Validation.RequiredField))
            .test(
                "is-valid-phone",
                t(TKey.Common.Message.Validation.InvalidPhoneNumber),
                (value) => {
                    try {
                        return phoneUtil.isValidNumber(
                            phoneUtil.parseAndKeepRawInput(value),
                        );
                    } catch (error) {
                        return false;
                    }
                },
            ),
        imageName: yup
            .string()
            .transform((value) => (value?.length ? value : undefined))
            .required(t(TKey.Common.Message.Validation.RequiredField)),
    });
