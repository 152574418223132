import { FC, useEffect, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useRecoilState, useResetRecoilState } from "recoil";

import { useTranslation } from "../../../hooks";
import { Tag, PTag } from "../../../models";
import {
    AppConfirmModal,
    AppForm,
    AppFormContent,
    AppFormInput,
    AppFormModal,
    AppGrid,
    AppLoader,
    AppPageHeader,
    AppTabs,
} from "../../../components";
import {
    ActionPostTagApi,
    ClientApi,
    MapApi,
    TaskPostTagApi,
} from "../../../apis";
import {
    ModalTypeEnum,
    errorToast,
    setBackendViolations,
    successToast,
    validation,
} from "../../../utils";
import { schema } from "./schema";
import { appGridColDef } from "./app-grid";
import { atomActiveClient, atomDrawer, atomDrawerType } from "../../../atoms";
import { useLocation, useNavigate, useParams } from "react-router-dom";

export const TagPage: FC = () => {
    // hooks
    const { t } = useTranslation();
    const { id: mapId, type = "taskPost" } = useParams();
    const navigate = useNavigate();
    const { pathname } = useLocation();

    // atoms
    const [activeClient] = useRecoilState(atomActiveClient);
    const [drawer, setDrawer] = useRecoilState(
        atomDrawerType(ModalTypeEnum.TAG_ADD_EDIT),
    );
    const resetDrawer = useResetRecoilState(atomDrawer);

    // state
    const [editId, setEditId] = useState(0);
    const [loading, setLoading] = useState(false);
    const [data, setData] = useState<Tag[]>([]);
    const [showConfirm, setShowConfirm] = useState(0);

    // form
    const methods = useForm({
        resolver: yupResolver(schema(t)),
    });
    const { handleSubmit, formState, reset, setError, watch, trigger } =
        methods;

    const apiClass = type === "taskPost" ? TaskPostTagApi : ActionPostTagApi;

    const fetchTags = (search = "") => {
        if (!activeClient) {
            return;
        }

        setLoading(true);

        apiClass
            .find<Tag>(1, {
                pagination: false,
                ...(search ? { name: search } : {}),
                "client.id": activeClient.id,
                "map.id": mapId,
            })
            .then(({ response, errorMessage }) => {
                if (errorMessage) {
                    errorToast(errorMessage);
                } else if (response !== null) {
                    setData(response.items);
                }
            })
            .finally(() => setLoading(false));
    };

    useEffect(() => {
        fetchTags();
    }, [mapId, type]);

    const onSubmitHandler = (formData: PTag) => {
        setLoading(true);

        if (mapId) {
            formData.map = MapApi.toResourceUrl(+mapId);
        }
        if (activeClient) {
            formData.client = ClientApi.toResourceUrl(activeClient.id);
        }

        apiClass
            .createOrUpdate<Tag, PTag>(editId, formData)
            .then(({ response, errorMessage, isInvalid, error }) => {
                if (isInvalid) {
                    setBackendViolations(error, setError);
                } else if (errorMessage) {
                    errorToast(errorMessage);
                } else if (response !== null) {
                    if (editId) {
                        successToast(t("app.tag.list:update.toast.success"));
                    } else {
                        successToast(t("app.tag.list:create.toast.success"));
                    }
                    reset();
                    resetDrawer();
                    setEditId(0);
                    fetchTags();
                }
            })
            .finally(() => setLoading(false));
    };

    const handleEdit = (id: number) => {
        apiClass.findById<Tag>(id).then(({ errorMessage, response }) => {
            if (errorMessage) {
                errorToast(errorMessage);
            } else if (response) {
                reset({
                    name: response.name,
                });
                setEditId(id);
                setDrawer({ show: true, id: response.id });
            }
        });
    };

    const handleDelete = (id: number) => {
        apiClass.deleteById(id).then(({ errorMessage }) => {
            if (errorMessage) {
                errorToast(errorMessage);
            } else {
                successToast(t("app.tag.list:delete.toast.success"));
                setShowConfirm(0);
                fetchTags();
            }
        });
    };

    useEffect(() => {
        const subscription = watch((_, { name }) => {
            if (name && formState.touchedFields[name]) {
                trigger(name);
            }
        });
        return () => subscription.unsubscribe();
    }, [watch, trigger, formState.touchedFields]);

    const renderForm = () => (
        <FormProvider {...methods}>
            <AppForm>
                {loading && <AppLoader isFullScreen />}

                <AppFormContent>
                    <AppFormInput
                        id="name"
                        name="name"
                        label={t("app.tag.form:label.name")}
                        placeholder={t("app.tag.form:placeholder.name")}
                        block
                        maxCount={validation.title.max}
                    />
                </AppFormContent>
            </AppForm>
        </FormProvider>
    );

    return (
        <div className="tags-page">
            {loading && <AppLoader isFullScreen />}

            <div
                className="mb-4"
                style={{ backgroundColor: "#fff", width: "fit-content" }}
            >
                <AppTabs
                    items={[
                        {
                            title: "app.tag.list:tab.taskPost",
                            icon: "tag",
                            url: `/map/${mapId}/tags/taskPost`,
                        },
                        {
                            title: "app.tag.list:tab.actionPost",
                            icon: "tag",
                            url: `/map/${mapId}/tags/actionPost`,
                        },
                    ]}
                    onClick={(item) => {
                        if (item.url) {
                            navigate(item.url);
                        }
                    }}
                    isActiveTab={(item) => item?.url === pathname}
                />
            </div>

            <AppPageHeader
                title="app.tag.list:header.title"
                onCreateBtnClick={() => {
                    reset({
                        name: undefined,
                    });
                    setDrawer({ show: true, id: 0 });
                }}
                onSearchChange={fetchTags}
                showToolbar
                isGrantedControl={!!activeClient}
            />

            <AppFormModal
                show={drawer.show}
                icon="description"
                title="app.tag.form:modal.title"
                isDrawer
                onSubmit={handleSubmit(onSubmitHandler)}
                onHide={() => {
                    resetDrawer();
                    setEditId(0);
                }}
                isLoading={formState.isSubmitting || loading}
                isEditMode={!!editId}
                disabledSumbit={!formState.isValid}
            >
                {renderForm()}
            </AppFormModal>

            <AppConfirmModal
                show={!!showConfirm}
                icon="warning"
                title="app.tag.item:delete.confirm.modal.title"
                description="app.tag.item:delete.confirm.modal.description"
                onHide={() => setShowConfirm(0)}
                nextAction={() => handleDelete(showConfirm)}
            />

            <AppGrid
                columnDefs={appGridColDef({
                    onPressEdit: handleEdit,
                    onPressDelete: setShowConfirm,
                })}
                rowData={data}
                isLoading={loading}
            />
        </div>
    );
};
