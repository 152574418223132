import { BaseEntity } from "./BaseEntity";
import { Client } from "./Client";
import { Task } from "./Task";
import { TaskPost } from "./TaskPost";

export class SubTask extends BaseEntity {
    client: string | Client;

    task: string | Task;

    name: string;

    ord: number;

    taskPosts?: TaskPost[];

    constructor(
        client: string | Client,
        task: string | Task,
        { name = "", ord = 1 }: PSubTask = {},
    ) {
        super();
        this.client = client;
        this.task = task;
        this.name = name;
        this.ord = ord;
    }

    toJSON(addExtraData = false) {
        return {
            ...super.toJSON(addExtraData),
            client: this.client,
            task: this.task,
            name: this.name,
        };
    }

    static createForm(client: string, task: string): PSubTask {
        return new SubTask(client, task).toJSON();
    }
}

export type PSubTask = Partial<SubTask>;
