import { FC } from "react";

import "./assets/scss/style.scss";

import { AppSVGIcon } from "../AppSVGIcon";
import { isNumber } from "../../utils";
import { AppSlider, AppSliderProps } from "../AppSlider";

interface AppZoomSliderProps extends AppSliderProps {
    value?: number;
    onZoomIn: () => void;
    onZoomOut: () => void;
}

export const AppZoomSlider: FC<AppZoomSliderProps> = ({
    value,
    min,
    max,
    step,
    onZoomIn,
    onZoomOut,
    onChange,
}) => (
    <div className="app-zoom-slider gap-3">
        <AppSVGIcon
            icon="zoom-out"
            size="xxl"
            onClick={onZoomOut}
            disabled={isNumber(min) && isNumber(value) ? value <= min : false}
            className="cursor-pointer"
        />

        <AppSlider
            min={min}
            max={max}
            step={step}
            value={value}
            onChange={onChange}
        />

        <AppSVGIcon
            icon="zoom-in"
            size="xxl"
            onClick={onZoomIn}
            disabled={isNumber(max) && isNumber(value) ? value >= max : false}
            className="cursor-pointer"
        />
    </div>
);
