import { FC, useEffect, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import { useRecoilState, useResetRecoilState } from "recoil";
import { DropResult } from "react-beautiful-dnd";

import "./assets/scss/style.scss";

import {
    ActionPostApi,
    ActionTaskApi,
    MapApi,
    UserZoomApi,
} from "../../../apis";
import {
    ActionPost,
    ActionTask,
    Map,
    PrimitiveObject,
    UserZoom,
} from "../../../models";
import {
    MapItemEnum,
    MapPageEnum,
    ModalTypeEnum,
    TKey,
    errorToast,
} from "../../../utils";
import { AppAlertBar, AppLoader } from "../../../components";
import {
    atomDrawer,
    atomDrawerType,
    atomMapPortfolioDurationHeight,
    atomZoomLevel,
} from "../../../atoms";
import { useAuthState, useMapAction, useTranslation } from "../../../hooks";
import { MapPortfolioActionTasks } from "./MapPortfolioActionTasks";
import { MapPortfolioFilter, MapPageHeader } from "../../components";
import { ACTION_POST_DURATION } from "../../../config";

export const MapPortfolioPage: FC = () => {
    // hooks
    const { t } = useTranslation();
    const { id } = useParams();

    // context
    const {
        map,
        actionTasks,
        actionSetMapPortfolio,
        actionReSetMap,
        actionChangeActionTaskOrder,
        actionChangeActionPostOrder,
        fetchResponsibleUsers,
    } = useMapAction();

    // ref
    const zoomRef = useRef<HTMLDivElement>(null);

    // atoms
    const [zoom] = useRecoilState(atomZoomLevel);
    const [durationHeight, setDurationHeight] = useRecoilState(
        atomMapPortfolioDurationHeight,
    );
    const [drawer, setDrawer] = useRecoilState(
        atomDrawerType(ModalTypeEnum.PORTFOLIO_FILTER),
    );
    const resetDrawer = useResetRecoilState(atomDrawer);

    // state
    const { user: loggedinUser } = useAuthState();
    const [loading, setLoading] = useState(false);
    const [sorting, setSorting] = useState("app.map.view:button.manually");
    const [userZoom, setUserZoom] = useState<UserZoom>();

    // local state
    const [shortType] = useState(
        ACTION_POST_DURATION.ACTIONPOST_DURATION_SHORT.toLowerCase(),
    );
    const [mediumType] = useState(
        ACTION_POST_DURATION.ACTIONPOST_DURATION_MEDIUM.toLowerCase(),
    );
    const [longType] = useState(
        ACTION_POST_DURATION.ACTIONPOST_DURATION_LONG.toLowerCase(),
    );

    const calculateDynamicHeight = () => {
        const actionTaskContainerShort = document.getElementsByClassName(
            `action-task-container ${shortType}`,
        );
        const actionTaskContainerMedium = document.getElementsByClassName(
            `action-task-container ${mediumType}`,
        );
        const actionTaskContainerLong = document.getElementsByClassName(
            `action-task-container ${longType}`,
        );

        const shortHeights = [];
        const mediumHeights = [];
        const longHeights = [];

        for (let index = 0; index < actionTaskContainerShort.length; index++) {
            // get the height of the current element
            shortHeights.push(actionTaskContainerShort[index].clientHeight);
        }

        for (let index = 0; index < actionTaskContainerMedium.length; index++) {
            // get the height of the current element
            mediumHeights.push(actionTaskContainerMedium[index].clientHeight);
        }

        for (let index = 0; index < actionTaskContainerLong.length; index++) {
            // get the height of the current element
            longHeights.push(actionTaskContainerLong[index].clientHeight);
        }

        // update max height for each duration type
        setDurationHeight({
            shortTermHeight: Math.max(...shortHeights),
            mediumTermHeight: Math.max(...mediumHeights),
            longTermHeight: Math.max(...longHeights),
        });
    };

    useEffect(() => {
        const timer = setTimeout(() => {
            calculateDynamicHeight();
        }, 300);

        return () => {
            clearTimeout(timer);
        };
    }, [actionTasks]);

    const fetchPortfolio = async () => {
        actionReSetMap();

        if (!id) {
            return;
        }

        setLoading(true);

        UserZoomApi.find(1, {
            "user.id": loggedinUser.id,
            "map.id": id,
        }).then(({ errorMessage, response }) => {
            if (!errorMessage && response && response?.totalItems > 0) {
                setUserZoom(response?.items[0] as UserZoom);
            }
        });

        MapApi.getPortfolioDetailById<Map>(+id, {
            "order[actionPosts.actionTasks.ord]": "asc",
        })
            .then(({ errorMessage, response }) => {
                if (errorMessage) {
                    errorToast(errorMessage);
                } else if (response) {
                    actionSetMapPortfolio(response);
                    fetchResponsibleUsers(response.workspace as string);
                }
            })
            .finally(() => setLoading(false));
    };

    useEffect(() => {
        fetchPortfolio();
    }, [id]);

    const handleChangeOrderActionTask = (itemId: number, newOrd: number) => {
        ActionTaskApi.changeOrder<ActionTask, PrimitiveObject>(itemId, {
            newOrd,
        }).then(({ response, errorMessage }) => {
            if (errorMessage) {
                errorToast(errorMessage);
            } else if (response !== null) {
                //
            }
        });
    };

    const handleChangeOrderActionPost = (itemId: number, newOrd: number) => {
        ActionPostApi.changeOrder<ActionPost, PrimitiveObject>(itemId, {
            newOrd,
        }).then(({ response, errorMessage }) => {
            if (errorMessage) {
                errorToast(errorMessage);
            } else if (response !== null) {
                //
            }
        });
    };

    const handleDragEnd = (result: DropResult) => {
        const { source, destination, draggableId, type } = result;

        if (!destination) return;

        if (
            destination.index === source.index &&
            destination.droppableId === source.droppableId
        ) {
            return;
        }

        const isDifferentParent =
            destination.droppableId !== source.droppableId;

        const [, , actionTaskId, actionPostId] = draggableId.split("-");

        if (type === MapItemEnum.ACTION_TASK) {
            handleChangeOrderActionTask(+actionTaskId, destination.index + 1);
            actionChangeActionTaskOrder(source.index, destination.index);
        } else if (type === MapItemEnum.ACTION_POST) {
            if (isDifferentParent) {
                const [, sourceDragType, oldActionTaskId] =
                    source.droppableId.split("-");
                const [, destinationDragType, newActionTaskId] =
                    destination.droppableId.split("-");

                // prevent going to another section (ex. from short to medium/long)
                if (sourceDragType !== destinationDragType) {
                    return;
                }

                ActionPostApi.update<ActionPost, PrimitiveObject>(
                    +actionPostId,
                    {
                        actionTask: ActionTaskApi.toResourceUrl(
                            +newActionTaskId,
                        ),
                    },
                ).then(({ response, errorMessage }) => {
                    if (errorMessage) {
                        errorToast(errorMessage);
                    } else if (response !== null) {
                        handleChangeOrderActionPost(
                            +actionPostId,
                            destination.index + 1,
                        );
                    }
                });

                actionChangeActionPostOrder(
                    source.index,
                    destination.index,
                    +newActionTaskId,
                    +oldActionTaskId,
                );
                return;
            }

            handleChangeOrderActionPost(+actionPostId, destination.index + 1);
            actionChangeActionPostOrder(
                source.index,
                destination.index,
                +actionTaskId,
            );
        }
    };

    if (loading) {
        return <AppLoader isFullScreen />;
    }

    if (!map) {
        return <></>;
    }

    const { actionPostDurations = [] } = map;
    const [durationShort, durationMedium, durationLong] = actionPostDurations;

    const mediumLineTop = durationShort?.isEnable
        ? durationHeight.shortTermHeight + 45
        : 0;
    const longLineTop =
        mediumLineTop +
        (durationMedium?.isEnable ? durationHeight.mediumTermHeight + 45 : 0);

    return (
        <div className="map-portfolio-page">
            <MapPortfolioFilter
                loading={loading}
                showForm={drawer.show}
                onHide={resetDrawer}
            />

            <MapPageHeader
                map={map}
                userZoom={userZoom}
                pageType={MapPageEnum.MAP_PORTFOLIO}
                showToolbar
                onFilterBtnClick={() => setDrawer({ show: true, id: 0 })}
                onChange={fetchPortfolio}
                sorting={sorting}
                setSorting={setSorting}
            />

            {!actionTasks.length && !loading && (
                <AppAlertBar
                    size="lg"
                    variant="danger"
                    title={t(TKey.Common.Message.NoData)}
                    className="mt-1"
                />
            )}

            <div
                className="map-portfolio-page--container"
                ref={zoomRef}
                style={{
                    zoom: zoom.zoomPortfolio,
                    // transform: `scale(${zoom.zoomPortfolio})`,
                    // transformOrigin: "top left",
                }}
                hidden={!actionTasks.length}
            >
                {durationShort?.isEnable && (
                    <div
                        className={`map-portfolio-page--container--separator ${shortType}`}
                    >
                        <span className="text">{durationShort.name}</span>
                    </div>
                )}

                {durationMedium?.isEnable && (
                    <div
                        className={`map-portfolio-page--container--separator ${mediumType}`}
                        style={{ top: mediumLineTop }}
                    >
                        <span className="text">{durationMedium.name}</span>
                    </div>
                )}

                {durationLong?.isEnable && (
                    <div
                        className={`map-portfolio-page--container--separator ${longType}`}
                        style={{
                            top: longLineTop,
                        }}
                    >
                        <span className="text">{durationLong.name}</span>
                    </div>
                )}

                <MapPortfolioActionTasks onDragEnd={handleDragEnd} />
            </div>
        </div>
    );
};
