import React, { FC, PropsWithChildren } from "react";

import "./assets/scss/style.scss";

interface AppMainContainerProps extends PropsWithChildren {
    fluid?: boolean;
    size?: "sm" | "md" | "lg" | "xl" | "xxl";
    className?: string;
}

export const AppMainContainer: FC<AppMainContainerProps> = ({
    fluid = true,
    size,
    className = "",
    children,
}) => {
    const sizeClass = size ? `container-${size}` : "";

    return (
        <main
            className={`app-main-container ${
                fluid ? "container-fluid" : sizeClass
            } ${className}`}
        >
            {children}
        </main>
    );
};
