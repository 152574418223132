import React, { FC } from "react";

import "./assets/scss/style.scss";

interface AppFlagProps
    extends React.DetailedHTMLProps<
        React.HTMLAttributes<HTMLElement>,
        HTMLElement
    > {
    icon: string;
    type?: "country" | "language";
    className?: string;
    disabled?: boolean;
}

export const AppFlag: FC<AppFlagProps> = ({
    icon,
    className = "",
    type = "country",
    disabled,
    ...props
}) => {
    if (!icon) {
        return <></>;
    }

    const disabledClass = disabled ? "flag--disabled" : "";

    return (
        <i
            className={`flag flag--${type}--${icon.toLowerCase()} ${disabledClass} img-fluid ${className}`}
            {...props}
        />
    );
};
