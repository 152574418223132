import { FC } from "react";

import "./assets/scss/style.scss";

import { useDateTime, useTranslation } from "../../../hooks";
import { AppLink, AppSVGIcon } from "../../../components";
import { TKey, errorToast, successToast, toPascalCase } from "../../../utils";
import { SubscriptionCardModal } from "../SubscriptionCardModal";
import { Card, Invoice } from "../../../models";
import { ClientApi } from "../../../apis";

interface SubscriptionPaymentInfoProps {
    clientId: number;
    isCurrentProfessionalPlan: boolean;
    primaryCard: Card | null;
    currentInvoice: Invoice | null;
    isAutoRenew: boolean;
    refetchClientInfo: () => void;
}

export const SubscriptionPaymentInfo: FC<SubscriptionPaymentInfoProps> = ({
    clientId,
    isCurrentProfessionalPlan,
    primaryCard,
    currentInvoice,
    isAutoRenew,
    refetchClientInfo,
}) => {
    // hooks
    const { t } = useTranslation();
    const { toShortDate, futureDate } = useDateTime();

    // local state
    const duration = currentInvoice?.noOfMonths
        ? t(
              currentInvoice.noOfMonths === 12
                  ? TKey.Common.Label.DurationYear
                  : TKey.Common.Label.DurationQuarter,
          )
        : null;

    const handleAutoRenew = () => {
        ClientApi.autoRenewSubscription(clientId, {
            isAutoRenew: !isAutoRenew,
        }).then(({ response, errorMessage }) => {
            if (errorMessage) {
                errorToast(errorMessage);
            } else if (response !== null) {
                successToast(
                    isAutoRenew
                        ? t(
                              "admin.subscription.overview:autoRenew.cancel.toast.success",
                          )
                        : t(
                              "admin.subscription.overview:autoRenew.activate.toast.success",
                          ),
                );

                refetchClientInfo();
            }
        });
    };

    return (
        <div className="subscription-payment-info">
            {isCurrentProfessionalPlan && currentInvoice && (
                <div className="subscription-payment-info__content subscription-payment-info__content--description">
                    <span className="title">
                        {t(
                            "admin.subscription.overview:label.paymentInfo.title1",
                        )}{" "}
                        {currentInvoice.totalAmount}{" "}
                        {t(TKey.Common.Label.Currency)}{" "}
                        {t(
                            "admin.subscription.overview:label.paymentInfo.title2",
                        )}{" "}
                        {toShortDate(
                            futureDate(new Date(currentInvoice.endDate), 1),
                        )}
                    </span>

                    <span className="condition">
                        {t(
                            "admin.subscription.overview:label.paymentInfo.description",
                        )}
                    </span>
                </div>
            )}

            <div className="subscription-payment-info__content subscription-payment-info__content--method">
                <div className="d-flex align-items-center gap-5">
                    <span className="title">
                        {t("admin.subscription.overview:label.paymentMethod")}
                    </span>

                    <div>
                        <SubscriptionCardModal clientId={clientId} />
                    </div>
                </div>

                <div className="content">
                    {primaryCard && (
                        <>
                            <AppSVGIcon
                                icon={`card-${primaryCard.brand}`}
                                type="image"
                                size="xxl"
                            />

                            <div className="d-flex flex-column ms-2">
                                <span className="card-number">
                                    {toPascalCase(primaryCard.brand)}{" "}
                                    <p className="font-weight--light d-inline">
                                        {t(
                                            "admin.subscription.overview:card.label.ending",
                                        )}
                                    </p>{" "}
                                    {primaryCard.last4}
                                </span>
                                {/* <span className="card-name">
                                    {primaryCard.exp_month}/
                                    {primaryCard.exp_year}
                                </span> */}
                                <span className="paragraph--200 font-weight--semi-bold">
                                    {primaryCard.name}
                                </span>
                            </div>
                        </>
                    )}
                </div>
            </div>

            {isCurrentProfessionalPlan && currentInvoice && (
                <div className="subscription-payment-info__content subscription-payment-info__content--billing-cycle">
                    <span className="title">
                        {t("admin.subscription.overview:label.billingCycle")}
                    </span>

                    <span className="info">{duration}ly</span>
                </div>
            )}

            {isCurrentProfessionalPlan && currentInvoice && (
                <div className="subscription-payment-info__content subscription-payment-info__content--renew">
                    <span className="title">
                        {t("admin.subscription.overview:label.autoRenew")}{" "}
                        {toShortDate(
                            futureDate(new Date(currentInvoice.endDate), 1),
                        )}
                    </span>

                    <div>
                        <AppLink
                            to={"#"}
                            size="sm"
                            onClick={() => handleAutoRenew()}
                        >
                            {isAutoRenew
                                ? t(
                                      "admin.subscription.overview:label.cancelRenewal",
                                  )
                                : t(
                                      "admin.subscription.overview:label.activeRenewal",
                                  )}
                        </AppLink>
                    </div>
                </div>
            )}
        </div>
    );
};
