import { FC, useEffect, useState } from "react";
import { useRecoilState, useResetRecoilState } from "recoil";
import { FormProvider, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";

import "./assets/scss/style.scss";

import {
    AppButton,
    AppForm,
    AppFormContent,
    AppFormInput,
    AppFormModal,
    AppLink,
    AppSVGIcon,
} from "../../../components";
import { useSubscriptionAction, useTranslation } from "../../../hooks";
import {
    ModalTypeEnum,
    TKey,
    errorToast,
    setBackendViolations,
    successToast,
    toPascalCase,
} from "../../../utils";
import { atomDrawer, atomDrawerType } from "../../../atoms";
import { ClientApi } from "../../../apis";
import { schema } from "./schema";
import { AnyType, Client } from "../../../models";

interface CardForm {
    name?: string;
    number?: string;
    exp_month?: number;
    exp_year?: number;
    cvc?: string;
}

interface SubscriptionCardModalProps {
    clientId: number;
}

export const SubscriptionCardModal: FC<SubscriptionCardModalProps> = ({
    clientId,
}) => {
    // hooks
    const { t } = useTranslation();

    // atoms
    const [drawer, setDrawer] = useRecoilState(
        atomDrawerType(ModalTypeEnum.CARD_ADD),
    );
    const resetDrawer = useResetRecoilState(atomDrawer);

    // context
    const { isLoading, myClient, actionFetchCards, primaryCard, cards } =
        useSubscriptionAction();

    // state
    const [loading, setLoading] = useState(false);
    const [showForm, setShowForm] = useState(false);

    // form
    const methods = useForm({
        resolver: yupResolver(schema(t, showForm)),
    });
    const { handleSubmit, formState, reset, setError, watch, trigger } =
        methods;

    const onSubmitHandler = (formData: CardForm) => {
        setLoading(true);

        ClientApi.addCard<Client, AnyType>(clientId, {
            card: {
                number: formData.number,
                exp_month: formData.exp_month,
                exp_year: formData.exp_year,
                cvc: formData.cvc,
                name: formData.name,
            },
        })
            .then(({ response, errorMessage, isInvalid, error }) => {
                if (isInvalid) {
                    setBackendViolations(error, setError);
                } else if (errorMessage) {
                    errorToast(errorMessage);
                } else if (response !== null) {
                    successToast(
                        t(
                            "admin.subscription.overview:card.update.toast.success",
                        ),
                    );

                    reset();
                    resetDrawer();
                    setShowForm(false);
                }
            })
            .finally(() => setLoading(false));
    };

    const fetchCards = () => {
        if (myClient?.id) {
            actionFetchCards(myClient.id);
        }
    };

    const handleSetPrimary = (cardId: string) => {
        setLoading(true);

        ClientApi.setPrimaryCard(clientId, {
            defaultCardId: cardId,
        })
            .then(({ response, errorMessage }) => {
                if (errorMessage) {
                    errorToast(errorMessage);
                } else if (response !== null) {
                    successToast(
                        t(
                            "admin.subscription.overview:card.primary.toast.success",
                        ),
                    );
                    fetchCards();
                }
            })
            .finally(() => setLoading(false));
    };

    const handleRemove = (cardId: string) => {
        setLoading(true);

        ClientApi.deleteCard(clientId, {
            cardId: cardId,
        })
            .then(({ response, errorMessage }) => {
                if (errorMessage) {
                    errorToast(errorMessage);
                } else if (response !== null) {
                    successToast(
                        t(
                            "admin.subscription.overview:card.delete.toast.success",
                        ),
                    );
                    fetchCards();
                }
            })
            .finally(() => setLoading(false));
    };

    useEffect(() => {
        if (drawer.show) {
            fetchCards();
        }
    }, [drawer.show]);

    useEffect(() => {
        const subscription = watch((_, { name }) => {
            if (name && formState.touchedFields[name]) {
                trigger(name);
            }
        });
        return () => subscription.unsubscribe();
    }, [watch, trigger, formState.touchedFields]);

    const renderForm = () => (
        <FormProvider {...methods}>
            <AppForm className="subscription-card-modal__form">
                <div className="subscription-card-modal__content mb-5">
                    <span className="headline--300 font-weight--bold">
                        {t("admin.subscription.overview:card.label.primary")}
                    </span>

                    {primaryCard && (
                        <div
                            key={primaryCard.id}
                            className="d-flex align-items-center"
                        >
                            <AppSVGIcon
                                icon={`card-${primaryCard.brand}`}
                                type="image"
                                size="xxl"
                            />

                            <div className="d-flex flex-column ms-2">
                                <span className="paragraph--200 font-weight--semi-bold">
                                    {toPascalCase(primaryCard.brand)}{" "}
                                    <p className="font-weight--light d-inline">
                                        {t(
                                            "admin.subscription.overview:card.label.ending",
                                        )}
                                    </p>{" "}
                                    {primaryCard.last4}
                                </span>
                                {/* <span className="paragraph--200 font-weight--semi-bold">
                                    {primaryCard.exp_month}/
                                    {primaryCard.exp_year}
                                </span> */}
                                <span className="paragraph--200 font-weight--semi-bold">
                                    {primaryCard.name}
                                </span>
                            </div>
                        </div>
                    )}

                    <span className="headline--300 font-weight--bold">
                        {t("admin.subscription.overview:card.label.additional")}
                    </span>

                    {cards?.map((card) => (
                        <div
                            key={card.id}
                            className="d-flex align-items-center"
                        >
                            <AppSVGIcon
                                icon={`card-${card.brand}`}
                                type="image"
                                size="xxl"
                            />

                            <div className="d-flex flex-column ms-2">
                                <span className="paragraph--200 font-weight--semi-bold">
                                    {toPascalCase(card.brand)}{" "}
                                    <p className="font-weight--light d-inline">
                                        {t(
                                            "admin.subscription.overview:card.label.ending",
                                        )}
                                    </p>{" "}
                                    {card.last4}
                                </span>
                                {/* <span className="paragraph--200 font-weight--semi-bold">
                                    {card.exp_month}/{card.exp_year}
                                </span> */}
                                <span className="paragraph--200 font-weight--semi-bold">
                                    {card.name}
                                </span>
                            </div>

                            <div className="d-flex gap-3 ms-auto">
                                <AppLink
                                    to={"#"}
                                    size="sm"
                                    onClick={() => handleSetPrimary(card.id)}
                                >
                                    {t(
                                        "admin.subscription.overview:card.label.setPrimary",
                                    )}
                                </AppLink>

                                <AppLink
                                    to={"#"}
                                    size="sm"
                                    onClick={() => handleRemove(card.id)}
                                >
                                    {t(
                                        "admin.subscription.overview:card.label.remove",
                                    )}
                                </AppLink>
                            </div>
                        </div>
                    ))}

                    <div className="separator-line" />

                    <AppButton
                        onClick={() => setShowForm(true)}
                        variant="secondary"
                        className="ms-auto"
                        disabled={showForm}
                    >
                        {t(
                            "admin.subscription.overview:card.button.addPayment",
                        )}
                    </AppButton>
                </div>

                {showForm && (
                    <AppFormContent>
                        <AppFormInput
                            id="name"
                            name="name"
                            label={t(
                                "admin.subscription.overview:card.label.name",
                            )}
                            placeholder={t(
                                "admin.subscription.overview:card.placeholder.name",
                            )}
                            md={12}
                            lg={6}
                            required={false}
                        />

                        <AppFormInput
                            id="number"
                            name="number"
                            label={t(
                                "admin.subscription.overview:card.label.number",
                            )}
                            placeholder={t(
                                "admin.subscription.overview:card.placeholder.number",
                            )}
                            md={12}
                            lg={6}
                            isCard
                        />

                        <AppFormInput
                            type="number"
                            id="exp_month"
                            name="exp_month"
                            label={t(
                                "admin.subscription.overview:card.label.exp_month",
                            )}
                            placeholder={t(
                                "admin.subscription.overview:card.placeholder.exp_month",
                            )}
                            md={12}
                            lg={6}
                        />

                        <AppFormInput
                            type="number"
                            id="exp_year"
                            name="exp_year"
                            label={t(
                                "admin.subscription.overview:card.label.exp_year",
                            )}
                            placeholder={t(
                                "admin.subscription.overview:card.placeholder.exp_year",
                            )}
                            md={12}
                            lg={6}
                        />

                        <AppFormInput
                            id="cvc"
                            name="cvc"
                            label={t(
                                "admin.subscription.overview:card.label.cvc",
                            )}
                            placeholder={t(
                                "admin.subscription.overview:card.placeholder.cvc",
                            )}
                            md={12}
                            lg={6}
                        />
                    </AppFormContent>
                )}
            </AppForm>
        </FormProvider>
    );

    return (
        <div className="subscription-card-modal">
            <AppLink
                to={"#"}
                size="sm"
                onClick={() =>
                    setDrawer({
                        show: true,
                        id: 0,
                    })
                }
            >
                {primaryCard
                    ? t("admin.subscription.overview:label.change")
                    : t("admin.subscription.overview:label.add")}
            </AppLink>

            <AppFormModal
                show={drawer.show}
                icon="description"
                title={
                    primaryCard
                        ? "admin.subscription.overview:card.modal.title"
                        : "admin.subscription.overview:card.modal.title.add"
                }
                // isDrawer
                onSubmit={handleSubmit(onSubmitHandler)}
                onHide={() => {
                    reset();
                    resetDrawer();
                    setShowForm(false);
                }}
                isLoading={formState.isSubmitting || loading || isLoading}
                createLabel={TKey.Common.Button.Save}
                cancelLabel={TKey.Common.Button.Cancel}
                disabledSumbit={!formState.isValid}
            >
                {renderForm()}
            </AppFormModal>
        </div>
    );
};
