import { atom } from "recoil";

const KEY = "atom-map-detail-filter";

type AtomMapDetailFilterType = {
    colors?: string[];
    tags?: string[];
    filteredTaskPostIds: number[];
};

export const atomMapDetailFilter = atom<AtomMapDetailFilterType>({
    key: KEY,
    default: {
        colors: undefined,
        tags: undefined,
        filteredTaskPostIds: [],
    },
});
