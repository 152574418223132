import React, { forwardRef } from "react";

import "./assets/scss/style.scss";

interface AppSVGIconProps
    extends React.DetailedHTMLProps<
        React.HTMLAttributes<HTMLElement>,
        HTMLElement
    > {
    icon: string;
    className?: string;
    size?: "xs" | "sm" | "lg" | "xl" | "xxl";
    disabled?: boolean;
    type?: "image" | "icon";
}

export const AppSVGIcon = forwardRef<HTMLElement, AppSVGIconProps>(
    (
        { icon, className = "", size, disabled, type = "icon", ...props },
        ref,
    ) => {
        if (!icon) {
            return <></>;
        }

        const renderIcon = (iconClassName = "") => {
            const iconClass = icon ? `svg-icon--${icon}` : "";
            const typeClass = type ? `svg-icon--${type}` : "";
            const sizeClass = size ? `svg-icon--${size}` : "";
            const disabledClass = disabled ? "svg-icon--disabled" : "";

            return (
                <i
                    ref={ref}
                    className={`svg-icon ${iconClass} ${typeClass} ${sizeClass} ${disabledClass} img-fluid ${iconClassName}`}
                    {...props}
                />
            );
        };

        if (icon === "warning") {
            const iconBgClass = icon ? `svg-icon-background--${icon}` : "";
            const sizeBgClass = size ? `svg-icon-background--${size}` : "";

            return (
                <div className={`svg-icon-container ${className}`}>
                    <div
                        className={`svg-icon-background ${iconBgClass} ${sizeBgClass}`}
                    />
                    {renderIcon()}
                </div>
            );
        }

        return renderIcon(className);
    },
);
