import { ICellRendererParams } from "ag-grid-community";
import { Link } from "react-router-dom";

import "./app-grid-style.scss";

import { AppActionDropDown, AppSVGIcon } from "../../../../components";
import { AppGridColDefParams } from "../../../../models";
import { TKey } from "../../../../utils";
import { useTranslation } from "../../../../hooks";

export const AppGridAction = (
    params: ICellRendererParams & AppGridColDefParams,
) => {
    // hooks
    const { t } = useTranslation();

    // params
    const { onPressEdit, onPressDelete, data } = params;

    return (
        <AppActionDropDown id={data.id}>
            {onPressEdit && (
                <li className="edit">
                    <Link
                        to={"#"}
                        onClick={(e) => {
                            e.preventDefault();
                            onPressEdit(data.id);
                        }}
                    >
                        <AppSVGIcon icon="edit" />

                        <span>{t(TKey.Common.Button.Edit)}</span>
                    </Link>
                </li>
            )}

            {onPressDelete && (
                <li className="delete">
                    <Link
                        to={"#"}
                        onClick={(e) => {
                            e.preventDefault();
                            onPressDelete(data.id);
                        }}
                    >
                        <AppSVGIcon icon="delete" />

                        <span>{t(TKey.Common.Button.Delete)}</span>
                    </Link>
                </li>
            )}
        </AppActionDropDown>
    );
};
