export const itemsPerPage = 10;

export const validation = {
    title: {
        min: 5,
        max: 125,
    },
    description: {
        max: 300,
    },
    descriptionSecondary: {
        max: 125,
    },
    actionTask: {
        title: {
            max: 230,
        },
    },
    task: {
        title: {
            max: 130,
        },
    },
    subTask: {
        title: {
            max: 180,
        },
    },
    portfolioAction: {
        title: {
            max: 240,
        },
    },
    kanbanTask: {
        title: {
            max: 240,
        },
    },
};

export const defaultZoom = 1;
export const staticZoomRatio = 1000;
export const zoomToFixed = 3; // three zero's from staticZoomRatio

export const emailToSupport = "mailto:support@expertshare.live";
