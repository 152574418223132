import { FC, useEffect, useState } from "react";
import { yupResolver } from "@hookform/resolvers/yup";
import { FormProvider, useForm } from "react-hook-form";

import "./assets/scss/style.scss";

import {
    CropperViewEnum,
    TKey,
    errorToast,
    setBackendViolations,
    validation,
} from "../../../utils";
import { useAuthAction, useAuthState, useTranslation } from "../../../hooks";
import { UserApi } from "../../../apis";
import { User } from "../../../models";
import {
    AppConfirmModal,
    AppForm,
    AppFormActions,
    AppFormContent,
    AppFormInput,
    AppFormSelect,
    AppFormUploader,
    AppLoader,
} from "../../../components";
import { ROLES, UserPosterFileInfo } from "../../../config";
import { schemaClientAdmin } from "./schema";

interface OnBoardForm {
    email: string;
    firstName: string;
    lastName: string;
    company: string;
    locale: string;
    imageName?: string;
}

interface OnBoardClientAdminProps {
    onSubmit: () => void;
}

export const OnBoardClientAdmin: FC<OnBoardClientAdminProps> = ({
    onSubmit,
}) => {
    // hooks
    const { t } = useTranslation();
    const { user, userId } = useAuthState();
    const { actionOnBoardInit, actionLogout } = useAuthAction();

    // state
    const [loading, setLoading] = useState(false);
    const [isUploading, setIsUploading] = useState(false);
    const [showConfirm, setShowConfirm] = useState(false);

    // form
    const methods = useForm<OnBoardForm>({
        resolver: yupResolver(schemaClientAdmin(t)),
    });
    const { handleSubmit, formState, reset, setError } = methods;

    useEffect(() => {
        if (user) {
            reset({
                email: user.email,
                firstName: user.firstName,
                lastName: user.lastName,
                company: user.company,
                locale: user?.locale,
                imageName: user.imageName,
            });
        }
    }, []);

    const onSubmitHandler = (formData: OnBoardForm) => {
        if (!userId) {
            return null;
        }

        setLoading(true);

        UserApi.update<User, OnBoardForm>(userId, formData)
            .then(({ response, errorMessage, isInvalid, error }) => {
                if (isInvalid) {
                    setBackendViolations(error, setError);
                } else if (errorMessage) {
                    errorToast(errorMessage);
                } else if (response !== null) {
                    actionOnBoardInit(response);
                    reset();
                    onSubmit();
                }
            })
            .finally(() => setLoading(false));
    };

    return (
        <>
            <AppConfirmModal
                show={showConfirm}
                icon="warning"
                title="user.onboard.form:logout.confirm.modal.title"
                description="user.onboard.form:logout.confirm.modal.description"
                onHide={() => setShowConfirm(false)}
                nextAction={actionLogout}
            />

            <FormProvider {...methods}>
                <AppForm
                    onSubmit={handleSubmit(onSubmitHandler)}
                    className="w-100"
                >
                    {loading && <AppLoader isFullScreen />}

                    <AppFormContent>
                        <AppFormInput
                            id="firstName"
                            name="firstName"
                            label={t("user.onboard.form:label.firstName")}
                            placeholder={t(
                                "user.onboard.form:placeholder.firstName",
                            )}
                            block={4}
                            maxCount={validation.title.max}
                        />

                        <AppFormInput
                            id="lastName"
                            name="lastName"
                            label={t("user.onboard.form:label.lastName")}
                            placeholder={t(
                                "user.onboard.form:placeholder.lastName",
                            )}
                            block={4}
                            maxCount={validation.title.max}
                        />

                        <AppFormInput
                            id="company"
                            name="company"
                            label={t("user.onboard.form:label.company")}
                            placeholder={t(
                                "user.onboard.form:placeholder.company",
                            )}
                            block={4}
                            maxCount={validation.title.max}
                        />

                        <AppFormSelect
                            id="locale"
                            name="locale"
                            label={t("user.onboard.form:label.locale")}
                            required={true}
                            block={4}
                            options={[
                                {
                                    label: t(
                                        `common.locale:${ROLES.USER_LOCALE_EN}`,
                                    ),
                                    value: ROLES.USER_LOCALE_EN,
                                },
                                {
                                    label: t(
                                        `common.locale:${ROLES.USER_LOCALE_DE}`,
                                    ),
                                    value: ROLES.USER_LOCALE_DE,
                                },
                            ]}
                        />

                        <AppFormInput
                            type="email"
                            id="email"
                            name="email"
                            label={t("user.register.form:label.email")}
                            autoComplete="email"
                            block={4}
                            disabled
                        />

                        <AppFormUploader
                            id="imageName"
                            name="imageName"
                            label={t("user.onboard.form:label.imageName")}
                            required={false}
                            block={4}
                            fileInfo={UserPosterFileInfo}
                            cbFileUploading={setIsUploading}
                            withCropper={CropperViewEnum.Modal}
                            hasDownload={false}
                        />
                    </AppFormContent>

                    <AppFormActions
                        backAction={() => setShowConfirm(true)}
                        isLoading={formState.isSubmitting || loading}
                        disabled={isUploading}
                        secondaryIconRight="close"
                        primaryIconRight="arrow-right"
                        primaryVariant="secondary"
                        createLabel={TKey.Common.Button.Next}
                        className="mt-3"
                    />
                </AppForm>
            </FormProvider>
        </>
    );
};
