import { memo } from "react";
import { ColDef } from "ag-grid-community";

import { AppGridAction, AppGridCreator, AppGridName } from "./cell-renderer";
import { AppGridColDefParams } from "../../../../models";

export interface AppMapShareGridColDefParams extends AppGridColDefParams {
    toShortDate: (date: number | Date | string) => string;
    onPressDelete: (id: number) => void;
}

export const appGridColDef = ({
    toShortDate,
    onPressDelete,
}: AppMapShareGridColDefParams): ColDef[] => [
    {
        field: "name",
        headerName: "admin.mapShare:column.name",
        cellRenderer: memo(AppGridName),
        sort: "asc",
    },
    {
        field: "map.name",
    },
    {
        field: "creator",
        cellRenderer: memo(AppGridCreator),
    },
    {
        field: "createdAt",
        valueGetter: ({ data }) => toShortDate(data.createdAt),
    },
    {
        sortable: false,
        cellRenderer: memo(AppGridAction),
        cellRendererParams: {
            onPressDelete,
        },
        maxWidth: 50,
        cellClass: "center-column",
    },
];
