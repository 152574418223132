import { checkAndParseResponse } from "../helpers";
import { UnprocessableEntityErrorResponse } from "../models";

export const onAddEditErrorResponse = (
    data: unknown,
    acceptHydra: boolean,
): UnprocessableEntityErrorResponse => {
    const errorResponse = new UnprocessableEntityErrorResponse();
    const parsedData = checkAndParseResponse(data);

    const titleKey = acceptHydra ? "hydra:title" : "title";
    const descriptionKey = acceptHydra ? "hydra:description" : "detail";

    if (parsedData[titleKey]) {
        errorResponse.title = parsedData[titleKey];
    }

    if (parsedData[descriptionKey]) {
        errorResponse.description = parsedData[descriptionKey];
    }

    if (parsedData.violations) {
        errorResponse.setViolations(parsedData.violations);
    }

    return errorResponse;
};
