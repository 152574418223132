import { Canceler } from "axios";
import { PrimitiveObject, SimpleObject } from "../../../models";
import { isString } from "../../../utils";

export const route = (path: string, routeParams: PrimitiveObject) => {
    if (!routeParams) {
        return path;
    }
    let url = path;
    const params = Object.keys(routeParams);
    params.forEach((param) => {
        const value = routeParams[param];
        url = url.replace(`{${param}}`, `${value}`);
    });
    return url;
};

export const checkAndParseResponse = (data: unknown): SimpleObject<never> => {
    let parseData: SimpleObject<never> = {};
    if (isString(data)) {
        try {
            parseData = JSON.parse(data as string);
        } catch (e) {
            throw new Error("Invalid response");
        }
    } else {
        parseData = data as SimpleObject<never>;
    }

    return parseData;
};

export const cancelAllPrevRequest = (cancelTokenSources: Canceler[]) => {
    while (cancelTokenSources.length > 0) {
        const c = cancelTokenSources.shift();
        if (c) {
            c();
        }
    }
};
