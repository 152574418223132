import React, { memo } from "react";
import { ColDef } from "ag-grid-community";
import { AppGridAction } from "./cell-renderer";
import { AppGridColDefParams } from "../../../../models";
import { getClientPhoneNumber } from "../../../../utils";
import { useTranslation } from "../../../../hooks";
import { AppFlag } from "../../../../components";
import { AppGridToolTip } from "../../../../App/components/MapList/app-grid/AppGridToolTip";

export interface AppClientGridColDefParams extends AppGridColDefParams {
    toShortDate: (date: number | Date | string) => string;
    onPressChangePlan: (id: number, planToChange: string) => void;
    onPressAutoRenew: (id: number, isAutoRenew: boolean) => void;
}

export const appGridColDef = ({
    onPressEdit,
    onPressDelete,
    toShortDate,
    onPressChangePlan,
    onPressAutoRenew,
}: AppClientGridColDefParams): ColDef[] => {
    const StatusCellRenderer = (params: any) => {
        const { value } = params;
        const { t } = useTranslation();

        const isActive = value === "ACTIVE";

        return (
            <div className={`app-grid-status ${isActive ? "paid" : "failed"}`}>
                <div
                    className={`app-grid-status__icon ${
                        isActive ? "paid" : "failed"
                    }`}
                />
                <span className="label--200">
                    {isActive
                        ? t(
                              "admin.client.list:column.autoRenewalEnabled.active",
                          )
                        : t(
                              "admin.client.list:column.autoRenewalEnabled.canceled",
                          )}
                </span>
            </div>
        );
    };

    return [
        {
            colId: "id",
            headerCheckboxSelection: true,
            checkboxSelection: true,
            maxWidth: 50,
        },
        // {
        //     headerName: "admin.client.list:column.imageName",
        //     field: "imageName",
        //     sortable: false,
        //     cellRenderer: memo(AppGridLogo),
        // },
        { field: "name", sort: "asc", cellRenderer: memo(AppGridToolTip) },
        { field: "city", cellRenderer: memo(AppGridToolTip) },
        {
            field: "country",
            cellRenderer: memo((params: any) => (
                <AppFlag icon={params.value} className="mt-4" />
            )),
            maxWidth: 110,
        },
        {
            field: "phone",
            valueGetter: ({ data }) => getClientPhoneNumber(data),
            sortable: false,
        },
        {
            field: "createdAt",
            valueGetter: ({ data }) => toShortDate(data.createdAt),
        },
        { field: "currentPlan" },
        {
            headerName: "admin.client.list:column.allowUsers",
            field: "allowUsers",
        },
        {
            headerName: "admin.client.list:column.planEndDate",
            field: "planEndDate",
            valueGetter: ({ data }) =>
                data.planEndDate ? toShortDate(data.planEndDate) : "-",
        },
        {
            headerName: "admin.client.list:column.autoRenewalEnabled",
            field: "isAutoRenewalEnabled",
            valueGetter: ({ data }) =>
                data.isAutoRenew ? "ACTIVE" : "CANCELED",
            cellRenderer: memo(StatusCellRenderer),
            cellClass: "d-flex",
        },
        {
            sortable: false,
            cellRenderer: memo(AppGridAction),
            cellRendererParams: {
                onPressEdit,
                onPressDelete,
                onPressChangePlan,
                onPressAutoRenew,
            },
            cellClass: "center-column",
            pinned: "right",
            maxWidth: 50,
        },
    ];
};
