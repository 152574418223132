import { EntityAPI } from "./core";
import { FinalResponse, ROUTES, route } from "../config";

const {
    api_maps_get_collection: API_GET_COLLECTION,
    api_maps_post_collection: API_POST_COLLECTION,
    api_maps_get_item: API_GET_ITEM,
    // api_maps_put_item: API_PUT_ITEM,
    api_maps_patch_item: API_PATCH_ITEM,
    api_maps_delete_item: API_DELETE_ITEM,
    api_maps_get_detail_item: API_GET_DETAIL_ITEM,
    api_maps_get_detail_item_public: API_GET_DETAIL_ITEM_PUBLIC,
    api_maps_get_setting_item: API_GET_SETTING_ITEM,
    api_maps_get_portfolio_item: API_GET_PORTFOLIO_DETAIL_ITEM,
    api_maps_patch_reset_voting: API_PATCH_RESET_VOTING_ITEM,
    api_maps_patch_reset_voting_color: API_PATCH_RESET_VOTING_COLOR_ITEM,
    api_maps_patch_transfer_voting_color: API_PATCH_TRANSFER_VOTING_COLOR,
    api_maps_posts_pdf: API_POST_PDF,
    api_maps_posts_clone: API_POST_CLONE,
} = ROUTES;

export abstract class MapApi extends EntityAPI {
    protected static GET_COLLECTION = API_GET_COLLECTION;

    protected static POST_COLLECTION = API_POST_COLLECTION;

    protected static GET_ITEM = API_GET_ITEM;

    // protected static PUT_ITEM = API_PUT_ITEM;

    protected static PATCH_ITEM = API_PATCH_ITEM;

    protected static DELETE_ITEM = API_DELETE_ITEM;

    public static async getDetailById<R>(
        id: number,
        extraParams = {},
    ): Promise<FinalResponse<R | null>> {
        const path = route(API_GET_DETAIL_ITEM, { id });

        return this.makeGet<R>(path, { ...extraParams })
            .then(({ data }) => this.handleResponse<R>(data))
            .catch((e) => this.handleAllError(e));
    }

    public static async getDetailByIdPublic<R>(
        id: number,
        extraParams = {},
    ): Promise<FinalResponse<R | null>> {
        const path = route(API_GET_DETAIL_ITEM_PUBLIC, { id });

        return this.makeGet<R>(path, { ...extraParams })
            .then(({ data }) => this.handleResponse<R>(data))
            .catch((e) => this.handleAllError(e));
    }

    public static async getSettingById<R>(
        id: number,
        extraParams = {},
    ): Promise<FinalResponse<R | null>> {
        const path = route(API_GET_SETTING_ITEM, { id });

        return this.makeGet<R>(path, { ...extraParams })
            .then(({ data }) => this.handleResponse<R>(data))
            .catch((e) => this.handleAllError(e));
    }

    public static async getPortfolioDetailById<R>(
        id: number,
        extraParams = {},
    ): Promise<FinalResponse<R | null>> {
        const path = route(API_GET_PORTFOLIO_DETAIL_ITEM, { id });

        return this.makeGet<R>(path, { ...extraParams })
            .then(({ data }) => this.handleResponse<R>(data))
            .catch((e) => this.handleAllError(e));
    }

    public static async resetVoting<R, P>(
        id: number,
        payload?: P, // payload optional
    ): Promise<FinalResponse<R | null>> {
        return this.makePatch<R, P>(
            route(API_PATCH_RESET_VOTING_ITEM, { id }),
            JSON.stringify(payload),
        )
            .then(({ data }) => this.handleResponse<R>(data))
            .catch((e) => this.handleAllError(e));
    }

    public static async resetVotingAndColor<R, P>(
        id: number,
        payload?: P, // payload optional
    ): Promise<FinalResponse<R | null>> {
        return this.makePatch<R, P>(
            route(API_PATCH_RESET_VOTING_COLOR_ITEM, { id }),
            JSON.stringify(payload),
        )
            .then(({ data }) => this.handleResponse<R>(data))
            .catch((e) => this.handleAllError(e));
    }

    public static async transferVotingColor<R, P>(
        id: number,
        payload?: P, // payload optional
    ): Promise<FinalResponse<R | null>> {
        return this.makePatch<R, P>(
            route(API_PATCH_TRANSFER_VOTING_COLOR, { id }),
            JSON.stringify(payload),
        )
            .then(({ data }) => this.handleResponse<R>(data))
            .catch((e) => this.handleAllError(e));
    }

    public static async pdfDownload<R, P = null>(
        payload: P,
    ): Promise<FinalResponse<R | null>> {
        return this.makePost<R, P>(
            API_POST_PDF,
            payload,
            {},
            {
                responseType: "blob",
            },
        )
            .then(({ data }) => this.handleResponse<R>(data))
            .catch((e) => this.handleAllError(e));
    }

    public static async cloneMap<R, P = null>(
        payload: P,
    ): Promise<FinalResponse<R | null>> {
        return this.makePost<R, P>(API_POST_CLONE, payload)
            .then(({ data }) => this.handleResponse<R>(data))
            .catch((e) => this.handleAllError(e));
    }
}
