import React, { Suspense } from "react";
import ReactDOM from "react-dom/client";
import { RecoilRoot } from "recoil";
import RecoilNexus from "recoil-nexus";
// import { ErrorBoundary } from "react-error-boundary";
import { BrowserRouter } from "react-router-dom";

import "./assets/scss/main.scss";

// import i18n.ts
import { initI18n } from "./config";

import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { GlobalProvider, AuthProvider } from "./contexts";
import { AppLoader } from "./components";

const root = ReactDOM.createRoot(
    document.getElementById("root") as HTMLElement,
);

initI18n();

root.render(
    // <React.StrictMode>
    <Suspense fallback={<AppLoader isFullScreen />}>
        {/* <ErrorBoundary fallbackRender={AppErrorFallback}> */}
        <BrowserRouter>
            <RecoilRoot>
                <RecoilNexus />
                <GlobalProvider>
                    <AuthProvider>
                        <App />
                    </AuthProvider>
                </GlobalProvider>
            </RecoilRoot>
        </BrowserRouter>
        {/* </ErrorBoundary> */}
    </Suspense>,
    // </React.StrictMode>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
