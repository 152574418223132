import Axios, {
    AxiosRequestConfig,
    AxiosResponse,
    CancelTokenSource,
} from "axios";

import {
    ACCEPTABLE_RESPONSE,
    AcceptableResponse,
    axiosInstance,
    RequestParamsType,
    RequestPayloadDataType,
} from "../../config";

import { SimpleObject } from "../../models";

export abstract class API {
    public static acceptHydra = AcceptableResponse.isHydra(ACCEPTABLE_RESPONSE);

    protected static async makeGet<R>(
        url: string,
        params: RequestParamsType = {},
        config: AxiosRequestConfig = {},
    ): Promise<AxiosResponse<R>> {
        return axiosInstance.get<R>(url, {
            ...config,
            params,
        });
    }

    protected static async makePost<R, P>(
        url: string,
        data: RequestPayloadDataType | P,
        params: RequestParamsType = {},
        config: AxiosRequestConfig = {},
    ): Promise<AxiosResponse<R>> {
        return axiosInstance.post<R>(url, data, {
            ...config,
            params,
        });
    }

    protected static async makePostFormData<R, P>(
        url: string,
        data: RequestPayloadDataType | P,
        params: RequestParamsType = {},
        config: AxiosRequestConfig = {},
    ): Promise<AxiosResponse<R>> {
        const postMultiPartConfig: AxiosRequestConfig =
            this.getPostMultiPartRequestConfig<P>();

        return axiosInstance.post<R>(url, data, {
            ...config,
            ...postMultiPartConfig,
            params,
        });
    }

    protected static async makeDelete<R>(
        url: string,
        config: AxiosRequestConfig = {},
    ): Promise<AxiosResponse<R>> {
        return axiosInstance.delete<R>(url, config);
    }

    protected static async makePut<R, P>(
        url: string,
        data: RequestPayloadDataType | P,
        params: RequestParamsType = {},
        config: AxiosRequestConfig = {},
    ): Promise<AxiosResponse<R>> {
        return axiosInstance.put<R>(url, data, {
            ...config,
            params,
        });
    }

    protected static async makePatch<R, P>(
        url: string,
        data: RequestPayloadDataType | P | string,
        params: RequestParamsType = {},
        config: AxiosRequestConfig = {},
    ): Promise<AxiosResponse<R>> {
        const patchConfig: AxiosRequestConfig = this.getPatchRequestConfig<P>();

        return axiosInstance.patch<R>(url, data, {
            ...config,
            ...patchConfig,
            params,
        });
    }

    public static createCancelTokenSource(): CancelTokenSource {
        return Axios.CancelToken.source();

        // params
        // cancelToken: source.token,

        // cancel the request (the message parameter is optional)
        // source.cancel('Operation canceled by the user.');
    }

    public static createAbortController(): AbortController {
        return new AbortController();

        // params
        // signal: controller.signal

        // controller.abort(); // the message parameter is not supported
    }

    protected static getPatchRequestConfig<P = null>(): AxiosRequestConfig {
        const config: AxiosRequestConfig = {
            transformRequest: [
                (payload: P, headers: SimpleObject<string>) => {
                    // on patch request,
                    // hydra only accept particular MIME type,
                    // so we're using request transformer;
                    // to change content-type header
                    if (this.acceptHydra) {
                        headers["Content-Type"] =
                            "application/merge-patch+json";
                    }

                    return payload;
                },
            ],
        };

        return config;
    }

    protected static getPostMultiPartRequestConfig<
        P = null,
    >(): AxiosRequestConfig {
        const config: AxiosRequestConfig = {
            transformRequest: [
                (payload: P, headers: SimpleObject<string>) => {
                    headers["Content-Type"] = "multipart/form-data";
                    return payload;
                },
            ],
        };

        return config;
    }
}
