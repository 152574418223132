import { FC, useState } from "react";
import { useNavigate } from "react-router-dom";
import { yupResolver } from "@hookform/resolvers/yup";
import { FormProvider, useForm } from "react-hook-form";

import { TKey, errorToast, setBackendViolations } from "../../../utils";
import { useAuthAction, useTranslation } from "../../../hooks";
import { User } from "../../../models";
import { AuthApi } from "../../../apis";
import {
    AppButton,
    AppForm,
    AppFormActions,
    AppFormContent,
    AppFormGroup,
    AppFormInput,
    AppLink,
    AppLoader,
} from "../../../components";
import { schema } from "./schema";

interface LoginForm {
    email: string;
    password: string;
}

export const LoginPage: FC = () => {
    // hooks
    const { t } = useTranslation();
    const navigate = useNavigate();
    const { actionSetAuthToken, actionLogin } = useAuthAction();

    // state
    const [loading, setLoading] = useState(false);

    // form
    const methods = useForm<LoginForm>({
        resolver: yupResolver(schema(t)),
    });
    const { handleSubmit, formState, reset, setError } = methods;

    const onSubmitHandler = (formData: LoginForm) => {
        setLoading(true);

        AuthApi.login<{ token: string }, LoginForm>(formData)
            .then(({ response, errorMessage, isInvalid, error }) => {
                if (isInvalid) {
                    setBackendViolations(error, setError);
                } else if (errorMessage) {
                    errorToast(errorMessage);
                } else if (response !== null && response.token) {
                    const { token } = response;

                    actionSetAuthToken(token);

                    AuthApi.me<User>().then(
                        ({ response: res, errorMessage: errMsg }) => {
                            if (errMsg) {
                                errorToast(errMsg);
                            } else if (res !== null) {
                                actionLogin(res);
                                navigate("/");
                                reset();
                            }
                        },
                    );
                }
            })
            .finally(() => setLoading(false));
    };

    return (
        <FormProvider {...methods}>
            <AppForm onSubmit={handleSubmit(onSubmitHandler)}>
                {loading && <AppLoader isFullScreen />}

                <AppFormContent>
                    <AppFormInput
                        type="email"
                        id="email"
                        name="email"
                        placeholder={t("user.login.form:placeholder.email")}
                        autoComplete="email"
                        block
                    />

                    <AppFormInput
                        type="password"
                        id="password"
                        name="password"
                        placeholder={t("user.login.form:placeholder.password")}
                        autoComplete="current-password"
                        block
                    />

                    <AppFormGroup auto className="ms-auto mb-4">
                        <AppLink to={"/auth/forgot-password"} size="sm">
                            {t("user.login.form:link.forgot-password")}
                        </AppLink>
                    </AppFormGroup>
                </AppFormContent>

                <AppFormActions
                    withDefault={false}
                    position="center"
                    className="pt-2 px-5"
                >
                    <AppButton
                        type="submit"
                        isLoading={formState.isSubmitting || loading}
                        disabled={formState.isSubmitting || loading}
                        className="auth-button"
                    >
                        {t(TKey.Common.Button.Login)}
                    </AppButton>

                    <AppLink
                        isBtn
                        to={"/auth/register"}
                        variant="secondary"
                        disabled={formState.isSubmitting || loading}
                        className="auth-button"
                    >
                        {t("user.login.form:button.register")}
                    </AppLink>
                </AppFormActions>
            </AppForm>
        </FormProvider>
    );
};
