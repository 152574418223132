import { useState, useEffect } from "react";
import { AnyType } from "../models";

interface LinkProps {
    name: string;
    file: AnyType;
    type: string;
}

type DownloadFileType = {
    downloadLink: (props: LinkProps) => void;
};

export function useDownloadFile(): DownloadFileType {
    const [link, setLink] = useState<LinkProps>();

    useEffect(() => {
        if (link) {
            const l = document.createElement("a");
            l.target = "_blank";
            l.download = link.name;
            l.href = URL.createObjectURL(
                new Blob([link.file], { type: link.type }),
            );
            document.body.appendChild(l);
            l.click();
            document.body.removeChild(l);
        }
    }, [link]);

    const downloadLink = (props: LinkProps) => {
        setLink(props);
    };

    return { downloadLink };
}
