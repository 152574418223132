import { FC } from "react";

import "./assets/scss/style.scss";
import { AppSVGIcon } from "../AppSVGIcon";

interface AppAlertBarProps {
    variant?: "info" | "success" | "warning" | "danger";
    size?: "lg";
    title: string;
    description?: string;
    className?: string;
}

export const AppAlertBar: FC<AppAlertBarProps> = ({
    variant = "info",
    size,
    title,
    description = "",
    className = "",
}) => {
    const variantClass = variant ? `app-alert-bar--${variant}` : "";
    const sizeClass = size ? `app-alert-bar--${size}` : "";

    return (
        <div
            className={`app-alert-bar gap-2 ${variantClass} ${sizeClass} ${className}`}
        >
            <AppSVGIcon
                icon={variant}
                size="sm"
                className="app-alert-bar--icon mt-1"
            />

            <div>
                <span className="app-alert-bar--title">{title}</span>
                <span className="app-alert-bar--description">
                    {description}
                </span>
            </div>
        </div>
    );
};
