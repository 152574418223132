import Swal, { SweetAlertOptions } from "sweetalert2";

const Toast = Swal.mixin({
    toast: true,
    timer: 3000,
    position: "top-end",
    showConfirmButton: false,
    customClass: {
        popup: "swal-custom-popup",
        title: "swal-custom-title",
        icon: "swal-custom-icon",
        htmlContainer: "swal-custom-html-container",
    },
});

export const successToast = (text: string, config?: SweetAlertOptions) =>
    Toast.fire({
        text,
        background: "var(--app-success-color-bg)",
        title: "Success",
        icon: "success",
        ...config,
    }).then();

export const errorToast = (text: string, config?: SweetAlertOptions) =>
    Toast.fire({
        text,
        background: "var(--app-primary-color-bg)",
        title: "Error!",
        icon: "error",
        ...config,
    }).then();

export const infoToast = (text: string, config?: SweetAlertOptions) =>
    Toast.fire({
        text,
        background: "var(--app-danger-color-bg)",
        title: "Info!",
        icon: "info",
        ...config,
    }).then();
