import { FinalResponse, ROUTES, route } from "../config";
import { EntityAPI } from "./core";

const {
    api_clients_get_collection: API_GET_COLLECTION,
    api_clients_post_collection: API_POST_COLLECTION,
    api_clients_get_item: API_GET_ITEM,
    api_clients_patch_item: API_PATCH_ITEM,
    api_clients_delete_item: API_DELETE_ITEM,
    api_clients_patch_profile_item: API_UPDATE_PROFILE,
    api_clients_get_my_client_collection: API_MY_CLIENT,
    api_clients_get_cards: API_GET_CARDS,
    api_clients_get_customer: API_GET_CUSTOMER,
    api_clients_get_stat: API_GET_STAT,
    api_clients_patch_add_card: API_ADD_CARD,
    api_clients_patch_default_card: API_PRIMARY_CARD,
    api_clients_patch_remove_card: API_DELETE_CARD,
    api_clients_patch_set_auto_renew: API_AUTO_RENEW,
    api_clients_patch_plan_item: API_CHANGE_PLAN,
    api_clients_get_current_invoice_item: API_GET_CURRENT_INVOICE,
} = ROUTES;

export abstract class ClientApi extends EntityAPI {
    protected static GET_COLLECTION = API_GET_COLLECTION;

    protected static POST_COLLECTION = API_POST_COLLECTION;

    protected static GET_ITEM = API_GET_ITEM;

    // protected static PUT_ITEM = API_PUT_ITEM;

    protected static PATCH_ITEM = API_PATCH_ITEM;

    protected static DELETE_ITEM = API_DELETE_ITEM;

    public static async getMyClient<R>(): Promise<FinalResponse<R | null>> {
        return this.makeGet<R>(API_MY_CLIENT)
            .then(({ data }) => this.handleResponse<R>(data))
            .catch((e) => this.handleAllError(e));
    }

    public static async updateProfile<R, P>(
        id: number,
        payload: P,
    ): Promise<FinalResponse<R | null>> {
        return this.makePatch<R, P>(
            route(API_UPDATE_PROFILE, { id }),
            JSON.stringify(payload),
        )
            .then(({ data }) => this.handleResponse<R>(data))
            .catch((e) => this.handleAllError(e));
    }

    public static async getCustomer<R>(
        id: number,
    ): Promise<FinalResponse<R | null>> {
        const path = route(API_GET_CUSTOMER, { id });

        return this.makeGet<R>(path)
            .then(({ data }) => this.handleResponse<R>(data))
            .catch((e) => this.handleAllError(e));
    }

    public static async getStatForSubscription<R>(
        id: number,
    ): Promise<FinalResponse<R | null>> {
        const path = route(API_GET_STAT, { id });

        return this.makeGet<R>(path)
            .then(({ data }) => this.handleResponse<R>(data))
            .catch((e) => this.handleAllError(e));
    }

    public static async getCards<R>(
        id: number,
    ): Promise<FinalResponse<R | null>> {
        const path = route(API_GET_CARDS, { id });

        return this.makeGet<R>(path)
            .then(({ data }) => this.handleResponse<R>(data))
            .catch((e) => this.handleAllError(e));
    }

    public static async addCard<R, P>(
        id: number,
        payload: P,
    ): Promise<FinalResponse<R | null>> {
        return this.makePatch<R, P>(
            route(API_ADD_CARD, { id }),
            JSON.stringify(payload),
        )
            .then(({ data }) => this.handleResponse<R>(data))
            .catch((e) => this.handleAllError(e));
    }

    public static async setPrimaryCard<R, P>(
        id: number,
        payload: P,
    ): Promise<FinalResponse<R | null>> {
        return this.makePatch<R, P>(
            route(API_PRIMARY_CARD, { id }),
            JSON.stringify(payload),
        )
            .then(({ data }) => this.handleResponse<R>(data))
            .catch((e) => this.handleAllError(e));
    }

    public static async deleteCard<R, P>(
        id: number,
        payload: P,
    ): Promise<FinalResponse<R | null>> {
        return this.makePatch<R, P>(
            route(API_DELETE_CARD, { id }),
            JSON.stringify(payload),
        )
            .then(({ data }) => this.handleResponse<R>(data))
            .catch((e) => this.handleAllError(e));
    }

    public static async autoRenewSubscription<R, P>(
        id: number,
        payload: P,
    ): Promise<FinalResponse<R | null>> {
        return this.makePatch<R, P>(
            route(API_AUTO_RENEW, { id }),
            JSON.stringify(payload),
        )
            .then(({ data }) => this.handleResponse<R>(data))
            .catch((e) => this.handleAllError(e));
    }

    public static async changePlan<R, P>(
        id: number,
        payload: P,
    ): Promise<FinalResponse<R | null>> {
        return this.makePatch<R, P>(
            route(API_CHANGE_PLAN, { id }),
            JSON.stringify(payload),
        )
            .then(({ data }) => this.handleResponse<R>(data))
            .catch((e) => this.handleAllError(e));
    }

    public static async getCurrentInvoice<R>(
        id: number,
    ): Promise<FinalResponse<R | null>> {
        const path = route(API_GET_CURRENT_INVOICE, { id });

        return this.makeGet<R>(path)
            .then(({ data }) => this.handleResponse<R>(data))
            .catch((e) => this.handleAllError(e));
    }
}
