import { atom } from "recoil";
import { defaultZoom } from "../utils";

const KEY = "atom-zoom-level";

type AtomZoomLevelType = {
    zoomMap: number;
    zoomPortfolio: number;
    zoomKanban: number;
};

export const atomZoomLevel = atom<AtomZoomLevelType>({
    key: KEY,
    default: {
        zoomMap: defaultZoom,
        zoomPortfolio: defaultZoom,
        zoomKanban: defaultZoom,
    },
});
