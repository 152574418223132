import { FC, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useRecoilState } from "recoil";

import {
    AppForm,
    AppFormContent,
    AppFormInput,
    AppFormModal,
} from "../../../components";
import { useTranslation } from "../../../hooks";
import { atomActiveClient } from "../../../atoms";
import { TKey, errorToast, successToast } from "../../../utils";
import { schema } from "./schema";
import { UserApi } from "../../../apis";
import { PrimitiveObject, User } from "../../../models";

interface UserInviteForm {
    email: string;
}

interface UserInviteModalProps {
    showForm: boolean;
    onHide: () => void;
    inviteWorkspace?: number;
}

export const UserInviteModal: FC<UserInviteModalProps> = ({
    showForm,
    onHide,
    inviteWorkspace,
}) => {
    // hooks
    const { t } = useTranslation();

    // atoms
    const [activeClient] = useRecoilState(atomActiveClient);

    // state
    const [loading, setLoading] = useState(false);

    // form
    const methods = useForm({
        resolver: yupResolver(schema(t)),
    });
    const { handleSubmit, formState, reset } = methods;

    const onSubmitHandler = (formData: UserInviteForm) => {
        setLoading(true);

        UserApi.invite<User, PrimitiveObject>({
            email: formData.email,
            ...(inviteWorkspace ? { inviteWorkspace } : {}),
        })
            .then(({ errorMessage, response }) => {
                if (errorMessage) {
                    errorToast(errorMessage);
                } else if (response !== null) {
                    successToast(t("admin.user.list:invite.toast.success"));
                    onHide();
                    reset();
                }
            })
            .finally(() => setLoading(false));
    };

    const renderForm = () => (
        <FormProvider {...methods}>
            <AppForm onSubmit={(e) => e.preventDefault()}>
                <AppFormContent>
                    <AppFormInput
                        type="email"
                        id="email"
                        name="email"
                        label={t("user.register.form:label.email")}
                        placeholder={t("user.register.form:placeholder.email")}
                        autoComplete="email"
                        block
                    />
                </AppFormContent>
            </AppForm>
        </FormProvider>
    );

    if (!showForm) {
        return <></>;
    }

    return (
        <AppFormModal
            show={showForm}
            icon="mail"
            title="admin.user.list:invite.modal.title"
            onSubmit={handleSubmit(onSubmitHandler)}
            onHide={() => {
                onHide();
                reset();
            }}
            isLoading={formState.isSubmitting || loading}
            disabled={!activeClient}
            createLabel={TKey.Common.Button.Invite}
            size="sm"
        >
            {renderForm()}
        </AppFormModal>
    );
};
