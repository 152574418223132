import React, { FC } from "react";

import "./assets/scss/style.scss";

import { useBuildAssetPath } from "../../hooks";
import { FileTypeInfo } from "../../models";

interface AppImageProps {
    src: string;
    fileInfo: FileTypeInfo;
    className?: string;
    width?: string;
    height?: string;
}

export const AppImage: FC<AppImageProps> = ({
    src,
    fileInfo,
    className = "",
    width,
    height,
}) => {
    // hooks
    const { buildFilePath } = useBuildAssetPath(fileInfo);

    // constants
    const filePath = buildFilePath(src);

    return (
        <img
            src={filePath}
            className={`app-image img-fluid ${className}`}
            alt={fileInfo.path}
            style={{ width: width || "inherit", height: height || "inherit" }}
        />
    );
};
