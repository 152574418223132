import { ROLES } from "../config";
import { useIsGranted } from "./useIsGranted";

const {
    USER_ROLE_USER,
    USER_ROLE_ADMIN,
    USER_ROLE_CLIENT_ADMIN,
    USER_ROLE_SUPER_ADMIN,
} = ROLES;

type RolesType = {
    isSuperAdmin: boolean;
    isGrantedSuperAdmin: boolean;
    isClientAdmin: boolean;
    isGrantedClientAdmin: boolean;
    isAdmin: boolean;
    isGrantedAdmin: boolean;
    isUser: boolean;
};

export function useRoles(): RolesType {
    return {
        isSuperAdmin: useIsGranted(USER_ROLE_SUPER_ADMIN, true),
        isGrantedSuperAdmin: useIsGranted(USER_ROLE_SUPER_ADMIN),
        isClientAdmin: useIsGranted(USER_ROLE_CLIENT_ADMIN, true),
        isGrantedClientAdmin: useIsGranted(USER_ROLE_CLIENT_ADMIN),
        isAdmin: useIsGranted(USER_ROLE_ADMIN, true),
        isGrantedAdmin: useIsGranted(USER_ROLE_ADMIN),
        isUser: useIsGranted(USER_ROLE_USER, true),
    };
}
