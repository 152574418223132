import { FC } from "react";
import { AppAlertBar } from "../AppAlertBar";

interface AppFormMessageProps {
    errorMessage?: string;
}

export const AppFormMessage: FC<AppFormMessageProps> = ({ errorMessage }) => {
    if (!errorMessage) {
        return <></>;
    }

    return (
        <AppAlertBar variant="danger" title={errorMessage} className="mt-1" />
    );
};
