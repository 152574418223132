import { FC, useState } from "react";
import { DraggableProvided, DropResult } from "react-beautiful-dnd";
import { useRecoilState } from "recoil";

import {
    AppDraggableList,
    AppInlineInput,
    AppSVGIcon,
    AppTooltip,
} from "../../../components";
import { ActionTask, PActionTask } from "../../../models";
import { useMapAction, useWorkspacePermission } from "../../../hooks";
import { MapItemEnum, MapPageEnum, errorToast } from "../../../utils";
import { MapPortfolioActionPosts } from "./MapPortfolioActionPosts";
import { ActionTaskApi } from "../../../apis";
import {
    atomMapPortfolioDurationHeight,
    // atomMapPortfolioFilter,
} from "../../../atoms";
import { ACTION_POST_DURATION } from "../../../config";

interface MapDetailActionTasksProps {
    onDragEnd: (result: DropResult) => void;
}

export const MapPortfolioActionTasks: FC<MapDetailActionTasksProps> = ({
    onDragEnd,
}) => {
    // hooks
    const { isWrite } = useWorkspacePermission();

    // context
    const { map, actionTasks, actionUpdateActionTask } = useMapAction();

    // atoms
    // const [appliedFilter] = useRecoilState(atomMapPortfolioFilter);
    const [durationHeight] = useRecoilState(atomMapPortfolioDurationHeight);

    // state
    const [showInput, setShowInput] = useState(0);

    const handleEdit = (id: number, title: string) => {
        ActionTaskApi.update<ActionTask, PActionTask>(id, { title }).then(
            ({ response, errorMessage }) => {
                if (errorMessage) {
                    errorToast(errorMessage);
                } else if (response !== null) {
                    actionUpdateActionTask(response.id, response);
                }
            },
        );
    };

    const allActionPosts = actionTasks.flatMap((x) => x.actionPosts || []);

    if (!map) {
        return <></>;
    }

    const renderItem = (item: ActionTask, provided: DraggableProvided) => {
        const { actionPostDurations = [] } = map;
        const [durationShort, durationMedium, durationLong] =
            actionPostDurations;

        const isMediumActionPosts = allActionPosts.some(
            (x) => x.durationType === durationMedium.name,
        );
        const isLongActionPosts = allActionPosts.some(
            (x) => x.durationType === durationLong.name,
        );

        const shortTermActionPosts =
            item.actionPosts?.filter(
                (x) => x.durationType === durationShort.name,
            ) || [];

        const mediumTermActionPosts =
            item.actionPosts?.filter(
                (x) => x.durationType === durationMedium.name,
            ) || [];

        const longTermActionPosts =
            item.actionPosts?.filter(
                (x) => x.durationType === durationLong.name,
            ) || [];

        // if (Object.values(appliedFilter)?.some((x) => !!x?.length)) {
        //     shortTermActionPosts = shortTermActionPosts.filter((x) =>
        //         appliedFilter.filteredActionPostIds.includes(x.id),
        //     );

        //     mediumTermActionPosts = mediumTermActionPosts.filter((x) =>
        //         appliedFilter.filteredActionPostIds.includes(x.id),
        //     );

        //     longTermActionPosts = longTermActionPosts.filter((x) =>
        //         appliedFilter.filteredActionPostIds.includes(x.id),
        //     );
        // }

        const shortType = ACTION_POST_DURATION.ACTIONPOST_DURATION_SHORT;
        const mediumType = ACTION_POST_DURATION.ACTIONPOST_DURATION_MEDIUM;
        const longType = ACTION_POST_DURATION.ACTIONPOST_DURATION_LONG;

        return (
            <div
                className="action-task-wrapper"
                ref={provided.innerRef}
                {...provided.draggableProps}
            >
                {durationShort?.isEnable && (
                    <div
                        className={`action-task-container ${shortType.toLowerCase()}`}
                        style={{ minHeight: durationHeight.shortTermHeight }}
                    >
                        <div className="action-task-container--item">
                            <div className="action-task-container--item--action">
                                <AppSVGIcon
                                    icon="drag-n-drop"
                                    {...provided.dragHandleProps}
                                    hidden={!isWrite}
                                />

                                <div className="total-cards">
                                    <span className="title">
                                        {item.actionPosts?.length || 0}
                                    </span>
                                </div>
                            </div>

                            <div
                                className="action-task-container--item--content"
                                onDoubleClick={() =>
                                    isWrite && setShowInput(item.id)
                                }
                            >
                                <AppInlineInput
                                    show={showInput === item.id && isWrite}
                                    size="md"
                                    initialText={item.title}
                                    onHide={() => setShowInput(0)}
                                    onUpdate={(text) =>
                                        handleEdit(item.id, text)
                                    }
                                >
                                    <AppTooltip
                                        id={`action-task-${item.id}`}
                                        overlay={<span>{item.title}</span>}
                                    >
                                        <span className="title">
                                            {item.title}
                                        </span>
                                    </AppTooltip>
                                </AppInlineInput>
                            </div>
                        </div>

                        <MapPortfolioActionPosts
                            actionTaskId={item.id}
                            actionPosts={shortTermActionPosts}
                            type={shortType}
                            pageType={MapPageEnum.MAP_PORTFOLIO}
                            onDragEnd={onDragEnd}
                        />
                    </div>
                )}

                {durationMedium?.isEnable && (
                    <div
                        className={`action-task-container ${mediumType.toLowerCase()}`}
                        style={{
                            minHeight: isMediumActionPosts
                                ? durationHeight.mediumTermHeight
                                : 164,
                        }}
                    >
                        <MapPortfolioActionPosts
                            actionTaskId={item.id}
                            actionPosts={mediumTermActionPosts}
                            type={mediumType}
                            pageType={MapPageEnum.MAP_PORTFOLIO}
                            onDragEnd={onDragEnd}
                        />
                    </div>
                )}

                {durationLong?.isEnable && (
                    <div
                        className={`action-task-container ${longType.toLowerCase()}`}
                        style={{
                            minHeight: isLongActionPosts
                                ? durationHeight.longTermHeight
                                : 164,
                        }}
                    >
                        <MapPortfolioActionPosts
                            actionTaskId={item.id}
                            actionPosts={longTermActionPosts}
                            type={longType}
                            pageType={MapPageEnum.MAP_PORTFOLIO}
                            onDragEnd={onDragEnd}
                        />
                    </div>
                )}
            </div>
        );
    };

    return (
        <AppDraggableList<ActionTask>
            droppableId="action_task"
            direction="horizontal"
            type={MapItemEnum.ACTION_TASK}
            data={actionTasks}
            onDragEnd={onDragEnd}
            renderItem={renderItem}
            renderWrapper={(children, providedMain, snapshot) => (
                <div
                    className={`map-portfolio-page--container--action-tasks ${
                        snapshot.isDraggingOver
                            ? "draggable-container--dropable"
                            : ""
                    }`}
                    ref={providedMain.innerRef}
                    {...providedMain.droppableProps}
                >
                    {children}
                </div>
            )}
        />
    );
};
