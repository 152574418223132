import React, { FC, PropsWithChildren, useState } from "react";
import Chart, { ReactGoogleChartProps } from "react-google-charts";

import "./assets/scss/style.scss";

interface MapPieChartProps
    extends Omit<ReactGoogleChartProps, "chartType">,
        PropsWithChildren {
    total?: number | string;
    onSelect?: (selectedIndex: number) => void;
}

export const MapPieChart: FC<MapPieChartProps> = ({
    total = 0,
    onSelect,
    children,
    ...props
}): JSX.Element => {
    const [options] = useState({
        pieHole: 0.7,
        legend: "none",
        pieSliceText: "none",
        backgroundColor: "transparent",
        tooltip: {
            trigger: "focus",
            textStyle: {
                color: "#fff",
                fontName: "Titillium Web",
                fontSize: 12,
                bold: false,
            },
        },
        colors: [
            getComputedStyle(document.documentElement).getPropertyValue(
                "--map-dashboard-color-done",
            ),
            getComputedStyle(document.documentElement).getPropertyValue(
                "--map-dashboard-color-short",
            ),
            getComputedStyle(document.documentElement).getPropertyValue(
                "--map-dashboard-color-medium",
            ),
            getComputedStyle(document.documentElement).getPropertyValue(
                "--map-dashboard-color-long",
            ),
        ],
    });

    return (
        <div className="map-pie-chart p-3">
            <div className="chart-number">{total}</div>

            {children}

            <Chart
                {...props}
                options={{ ...options, ...props.options }}
                chartType="PieChart"
                width={250}
                chartEvents={[
                    {
                        eventName: "select",
                        callback({ chartWrapper }) {
                            if (onSelect) {
                                onSelect(
                                    chartWrapper.getChart().getSelection()[0]
                                        .row,
                                );
                            }
                        },
                    },
                ]}
            />
        </div>
    );
};
