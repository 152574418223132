import React, { FC } from "react";
import { FormCheck, FormCheckProps } from "react-bootstrap";

import "./assets/scss/style.scss";

interface AppCheckBoxProps extends FormCheckProps {
    type?: "checkbox" | "radio";
}

export const AppCheckBox: FC<AppCheckBoxProps> = ({
    className = "",
    label,
    type = "checkbox",
    ...props
}): JSX.Element => (
    <FormCheck
        {...props}
        type={type}
        className={`app-checkbox ${className} mx-4`}
    >
        <FormCheck.Input
            type={type}
            isValid
            onChange={props.onChange}
            checked={props.checked}
            value={props.value}
            disabled={props.disabled}
        />

        <FormCheck.Label htmlFor={props.id}>{label}</FormCheck.Label>
    </FormCheck>
);
