import { ICellRendererParams } from "ag-grid-community";
import { Link } from "react-router-dom";

import "./app-grid-style.scss";

import { AppActionDropDown, AppSVGIcon } from "../../../../components";
import { AppUserGridColDefParams } from "./column-defs";
import { TKey } from "../../../../utils";
import { useRoles, useTranslation } from "../../../../hooks";
import { ROLES } from "../../../../config";

export const AppGridOnBoarded = (params: ICellRendererParams) => {
    const { value } = params;
    const { t } = useTranslation();

    const isOnboarded = value === "isOnboarded";

    return (
        <div className={`app-grid-status ${isOnboarded ? "paid" : "failed"}`}>
            <div
                className={`app-grid-status__icon ${
                    isOnboarded ? "paid" : "failed"
                }`}
            />
            <span className="label--200">
                {isOnboarded
                    ? t("admin.user.list:column.status.onbaorded")
                    : t("admin.user.list:column.status.pending")}
            </span>
        </div>
    );
};

export const AppGridAction = (
    params: ICellRendererParams & AppUserGridColDefParams,
) => {
    // hooks
    const { t } = useTranslation();
    const { isSuperAdmin, isClientAdmin } = useRoles();

    // params
    const { onPressEdit, onPressDelete, onPressReInvite, data } = params;

    const isDeleteAccess = () => {
        const userRole = data.roles[0];

        if (isSuperAdmin) {
            return userRole !== ROLES.USER_ROLE_SUPER_ADMIN;
        }

        if (isClientAdmin) {
            return (
                userRole === ROLES.USER_ROLE_ADMIN ||
                userRole === ROLES.USER_ROLE_USER
            );
        }

        return false;
    };

    return (
        <AppActionDropDown id={data.id}>
            {!data.isOnboarded && onPressReInvite && (
                <li className="re-invite">
                    <Link
                        to={"#"}
                        onClick={(e) => {
                            e.preventDefault();
                            onPressReInvite(data.email);
                        }}
                    >
                        <AppSVGIcon icon="plus" />
                        <span>{t(TKey.Common.Button.ReInvite)}</span>
                    </Link>
                </li>
            )}

            {onPressEdit && (
                <li className="edit">
                    <Link
                        to={"#"}
                        onClick={(e) => {
                            e.preventDefault();
                            onPressEdit(data.id);
                        }}
                    >
                        <AppSVGIcon icon="edit" />

                        <span>{t(TKey.Common.Button.Edit)}</span>
                    </Link>
                </li>
            )}

            {onPressDelete && isDeleteAccess() && (
                <li className="delete">
                    <Link
                        to={"#"}
                        onClick={(e) => {
                            e.preventDefault();
                            onPressDelete(data.id);
                        }}
                    >
                        <AppSVGIcon icon="delete" />

                        <span>{t(TKey.Common.Button.Delete)}</span>
                    </Link>
                </li>
            )}
        </AppActionDropDown>
    );
};
