import { FC, useEffect } from "react";
import { useNavigate } from "react-router-dom";

import "./assets/scss/style.scss";

import { AppButton, AppLoader, AppSVGIcon } from "../../../components";
import { useSubscriptionAction, useTranslation } from "../../../hooks";

interface SubscriptionCompletePageProps {
    isOnboarding?: boolean;
}

export const SubscriptionCompletePage: FC<SubscriptionCompletePageProps> = ({
    isOnboarding = false,
}) => {
    // hooks
    const { t } = useTranslation();
    const navigate = useNavigate();

    // context
    const {
        isLoading,
        myClient,
        invoice,
        actionRefetchMyClient,
        isPurchaseSuccess,
    } = useSubscriptionAction();

    useEffect(() => {
        if (!invoice || !myClient) {
            navigate(`/admin/subscription/overview`);
        }
    }, [invoice, myClient]);

    useEffect(() => {
        actionRefetchMyClient();
    }, []);

    if (!myClient || !invoice) {
        return <></>;
    }

    return (
        <div className="subscription-complete-page">
            {isLoading && <AppLoader isFullScreen />}

            {isPurchaseSuccess ? (
                <div className="subscription-complete-page__title subscription-complete-page__title--success">
                    <AppSVGIcon icon="success" size="xxl" />

                    <span className="headline--100 font-weight--bold">
                        {t("admin.subscription.complete:label.success")}
                    </span>
                </div>
            ) : (
                <div className="subscription-complete-page__title subscription-complete-page__title--failure">
                    <AppSVGIcon icon="danger" size="xxl" />

                    <span className="headline--100 font-weight--bold">
                        {t("admin.subscription.complete:label.failure")}
                    </span>
                </div>
            )}

            <AppButton
                onClick={() =>
                    isOnboarding
                        ? navigate("/")
                        : navigate("/admin/subscription/overview")
                }
            >
                {t("admin.subscription.complete:button.continue")}
            </AppButton>
        </div>
    );
};
