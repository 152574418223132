import { Client, User } from "../../models";
import { concatWithSeparator } from "./string.helper";

export const getUserFullName = (user: User) =>
    concatWithSeparator(" ", user?.firstName, user?.lastName);

export const getClientPhoneNumber = (client: Client) => client?.phone;

export const getRandomId = (): number => {
    const min = Math.ceil(0);
    const max = Math.floor(new Date().getTime());
    return Math.floor(Math.random() * (max - min + 1)) + min;
};

export const getIdFromResourceUrl = (value: string | undefined): number => {
    const valueToString = value || "";
    const slashSplit = valueToString.split("/");
    return Number(slashSplit[slashSplit.length - 1]) || 0;
};

export const getUserCompany = (user: User) =>
    user?.company ? `@${user.company}` : "";

export const getTotalLevel = (totalLevel?: string) =>
    totalLevel && +totalLevel > 0 ? (+totalLevel).toFixed(1) : 0;

export const getPercentage = (
    value = 0,
    total = 0,
    decimal = 1,
    numeric = true,
) => {
    const percentage = (value * 100) / total || 0;

    // Check if the percentage has a non-zero decimal part before using toFixed
    const decimalPercentage =
        percentage % 1 !== 0 ? percentage.toFixed(decimal) : percentage;

    return numeric ? decimalPercentage : `${decimalPercentage}%`;
};
