import { FC } from "react";

import "./assets/scss/style.scss";

import { TKey } from "../../../utils";
import { useTranslation } from "../../../hooks";
import { AppFormActions, AppLink } from "../../../components";

export const ForgotPasswordConfirmationPage: FC = () => {
    // hooks
    const { t } = useTranslation();

    return (
        <div className="forgot-password-confirmation-page">
            <div className="my-4">
                <span className="title ">
                    {t("user.forgot-password-confirm:label.title")}
                </span>
            </div>

            <AppFormActions
                withDefault={false}
                position="center"
                className="pt-2 px-5"
            >
                <AppLink
                    isBtn
                    to={"/auth/forgot-password"}
                    className="auth-button"
                >
                    {t(TKey.Common.Button.TryAgain)}
                </AppLink>
            </AppFormActions>
        </div>
    );
};
