export enum DateTimeEnum {
    shortDate = "dd-MMM-yyyy",
    longDate = "eeee, d. MMMM yyyy",
    shortTime = "HH:mm",
    longTime = "HH:mm (OOO)",
    shortDateTime = "dd-MMM-yyyy HH:mm",
    longDateTime = "eeee, d. MMMM yyyy HH:mm (OOO)",
    getDay = "dd",
    getDayOfWeak = "EEEE",
    getSortDayOfWeak = "EEE",
    getMonth = "MMMM",
    getSortMonth = "MMM",
    getYear = "yyyy",
    dbDateFormat = "yyyy-MM-dd",
    dbTimeFormat = "HH:mm:ss",
}
