export const reorder = <T>(
    list: T[],
    sourceIndex: number,
    destinationIndex: number,
): T[] => {
    const result = Array.from(list);
    const [removed] = result.splice(sourceIndex, 1);
    result.splice(destinationIndex, 0, removed);

    return result;
};

export const reorderWithDiffParent = <T extends { ord: number }>(
    sourceList: T[],
    destinationList: T[],
    sourceIndex: number,
    destinationIndex: number,
): [T[], T[]] => {
    const oldResult = Array.from(sourceList);
    const newResult = Array.from(destinationList);

    const [removed] = oldResult.splice(sourceIndex, 1);

    if (removed) {
        newResult.splice(destinationIndex, 0, removed);
    }

    return [oldResult, newResult];
};
