import { FC } from "react";
import { createPortal } from "react-dom";

import "./assets/scss/style.scss";

import { AppSVGIcon } from "../AppSVGIcon";

interface AppLoaderProps {
    size?: "sm" | "lg";
    className?: string;
    isFullScreen?: boolean;
}

export const AppLoader: FC<AppLoaderProps> = ({
    size,
    className = "",
    isFullScreen,
}) => {
    const sizeClass = size ? `app-loader--${size}` : "";

    const renderLoader = (loaderClass = "") => (
        <AppSVGIcon
            className={`app-loader ${sizeClass} ${loaderClass}`}
            icon="spinner"
            type="image"
        />
    );

    return isFullScreen
        ? createPortal(renderLoader("overlay-body"), document.body)
        : renderLoader(className);
};
