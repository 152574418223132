import { ReactNode, forwardRef } from "react";
import { Link, LinkProps } from "react-router-dom";

import "../AppButton/assets/scss/style.scss"; // import btn scss for isBtn
import "./assets/scss/style.scss";
import { useTranslation } from "../../hooks";
import { AppSVGIcon } from "../AppSVGIcon";
import { TKey } from "../../utils";

interface AppLinkProps extends LinkProps {
    variant?:
        | "primary"
        | "secondary"
        | "success"
        | "danger"
        | "warning"
        | "info"
        | "dark"
        | "light"
        | "link"
        | "outline-primary"
        | "outline-secondary"
        | "outline-success"
        | "outline-danger"
        | "outline-warning"
        | "outline-info"
        | "outline-dark"
        | "outline-light";
    size?: "sm";
    isLoading?: boolean;
    loadingTxt?: string;
    isBtn?: boolean;
    disabled?: boolean;
    iconLeft?: string;
    iconRight?: string;
    children?: ReactNode;
}

export const AppLink = forwardRef<HTMLAnchorElement, AppLinkProps>(
    (
        {
            variant = "primary",
            size,
            className = "",
            isLoading,
            loadingTxt = TKey.Common.Label.Loading,
            isBtn,
            disabled,
            children,
            iconLeft,
            iconRight,
            onClick,
            ...props
        },
        ref,
    ) => {
        const { t } = useTranslation();

        const linkClass = isBtn ? "btn" : "link";

        const variantClass = variant ? `${linkClass}-${variant}` : "";
        const sizeClass = size ? `${linkClass}-${size}` : "";
        const linkDisabledClass = disabled ? "link-disabled" : "";

        const iconSize = size === "sm" ? "xs" : "sm";

        return (
            <Link
                ref={ref}
                className={`${linkClass} gap-2 ${variantClass} ${sizeClass} ${className} ${linkDisabledClass}`}
                {...props}
                onClick={(e) => {
                    if (disabled) {
                        e.preventDefault();
                        return;
                    }

                    if (onClick) {
                        onClick(e);
                    }
                }}
            >
                {iconLeft && (
                    <AppSVGIcon
                        icon={iconLeft}
                        size={iconSize}
                        disabled={disabled}
                    />
                )}

                <span className="btn-text">
                    {isLoading ? t(loadingTxt) : children}
                </span>

                {iconRight && (
                    <AppSVGIcon
                        icon={iconRight}
                        size={iconSize}
                        disabled={disabled}
                    />
                )}
            </Link>
        );
    },
);
