import { FC, useEffect, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import { DragStart, DropResult } from "react-beautiful-dnd";
import { useRecoilState, useResetRecoilState } from "recoil";

import "./assets/scss/style.scss";

import { AppLoader } from "../../../components";
import {
    ActionTaskApi,
    MapApi,
    SubTaskApi,
    TaskApi,
    TaskPostApi,
    UserZoomApi,
} from "../../../apis";
import {
    ActionTask,
    Map,
    PTaskPost,
    PUser,
    PrimitiveObject,
    SubTask,
    Task,
    TaskPost,
    UserZoom,
} from "../../../models";
import {
    MapItemEnum,
    MapPageEnum,
    ModalTypeEnum,
    errorToast,
} from "../../../utils";
import { useAuthState, useMapAction } from "../../../hooks";
import { MapDetailTasks } from "./MapDetailTasks";
import { MapDetailActionTasks } from "./MapDetailActionTasks";
import { atomDrawer, atomDrawerType, atomZoomLevel } from "../../../atoms";
import { MapPageHeader, MapDetailFilter } from "../../components";
import { EVENTS, Socket, emitter } from "../../../Socket/Socket";

export const MapDetailPage: FC = () => {
    // hooks
    const { id } = useParams();

    // context
    const {
        map,
        actionSetMapDetail,
        actionReSetMap,
        actionChangeTaskOrder,
        actionChangeSubTaskOrder,
        actionChangeTaskPostOrder,
        actionChangeActionTaskOrder,
        fetchResponsibleUsers,
        actionCreateTaskPost,
        actionUpdateTaskPost,
        actionDeleteTaskPost,
        actionCreateSubTask,
        actionUpdateSubTask,
        actionDeleteSubTask,
        actionCreateTask,
        actionUpdateTask,
        actionDeleteTask,
        actionCreateActionTask,
        actionUpdateActionTask,
        actionDeleteActionTask,
    } = useMapAction();

    // ref
    const zoomRef = useRef<HTMLDivElement>(null);

    // atoms
    const [zoom] = useRecoilState(atomZoomLevel);
    const [drawer, setDrawer] = useRecoilState(
        atomDrawerType(ModalTypeEnum.MAP_FILTER),
    );
    const resetDrawer = useResetRecoilState(atomDrawer);

    // state
    const [loading, setLoading] = useState(false);
    const { user: loggedinUser } = useAuthState();
    const [userZoom, setUserZoom] = useState<UserZoom>();

    const fetchMap = async () => {
        actionReSetMap();

        if (!id) {
            return;
        }

        setLoading(true);

        await MapApi.getDetailById<Map>(+id)
            .then(async ({ errorMessage, response }) => {
                if (errorMessage) {
                    errorToast(errorMessage);
                } else if (response) {
                    actionSetMapDetail(response);
                    fetchResponsibleUsers(response.workspace as string);
                    await UserZoomApi.find(1, {
                        "user.id": loggedinUser.id,
                        "map.id": response.id,
                    }).then(({ errorMessage: err, response: res }) => {
                        if (!err && res && res?.totalItems > 0) {
                            setUserZoom(res?.items[0] as UserZoom);
                        }
                    });
                }
            })
            .finally(() => setLoading(false));
    };

    useEffect(() => {
        fetchMap();

        if (id) {
            emitter(EVENTS.MAP_JOIN, { mapId: `${id}` });
        }

        return () => {
            emitter(EVENTS.MAP_LEAVE, { mapId: `${id}` });
        };
    }, [id]);

    useEffect(() => {
        Socket.on(
            EVENTS.ON_MAP_VOTING_OPEN_STATUS,
            (mapId: number, isOn: boolean) => {
                // eslint-disable-next-line no-console
                console.log(mapId, isOn);
                fetchMap();
            },
        );

        Socket.on(
            EVENTS.ON_MAP_CREATE_TASK_POST,
            (
                user: PUser,
                taskId: number,
                subTaskId: number,
                taskPost: PTaskPost,
            ) => {
                // eslint-disable-next-line no-console
                console.log(user, taskId, subTaskId, taskPost);
                actionCreateTaskPost(
                    taskId,
                    subTaskId,
                    taskPost as TaskPost,
                    false,
                );
            },
        );

        Socket.on(
            EVENTS.ON_MAP_UPDATE_TASK_POST,
            (
                user: PUser,
                taskId: number,
                subTaskId: number,
                taskPostId: number,
                taskPost: PTaskPost,
            ) => {
                // eslint-disable-next-line no-console
                console.log(user, taskId, subTaskId, taskPostId, taskPost);
                actionUpdateTaskPost(
                    taskId,
                    subTaskId,
                    taskPostId,
                    taskPost as TaskPost,
                    false,
                );
            },
        );

        Socket.on(EVENTS.ON_MAP_UPDATE_TASK_POST_BACKEND, (data) => {
            const { user, taskId, subTaskId, taskPostId, taskPost } = data;
            // eslint-disable-next-line no-console
            console.log(data, user, taskId, subTaskId, taskPostId, taskPost);
            actionUpdateTaskPost(
                taskId,
                subTaskId,
                taskPostId,
                taskPost as TaskPost,
                false,
            );
        });

        Socket.on(
            EVENTS.ON_MAP_DELETE_TASK_POST,
            (
                user: PUser,
                taskId: number,
                subTaskId: number,
                taskPostId: number,
            ) => {
                // eslint-disable-next-line no-console
                console.log(user, taskId, subTaskId, taskPostId);
                actionDeleteTaskPost(taskId, subTaskId, taskPostId, false);
            },
        );

        Socket.on(
            EVENTS.ON_MAP_ORDER_TASK_POST,
            (
                user: PUser,
                taskId: number,
                subTaskId: number,
                source: number,
                destination: number,
                oldTaskId: number,
                oldSubTaskId: number,
            ) => {
                // eslint-disable-next-line no-console
                console.log(
                    user,
                    taskId,
                    subTaskId,
                    source,
                    destination,
                    oldTaskId,
                    oldSubTaskId,
                );
                actionChangeTaskPostOrder(
                    taskId,
                    subTaskId,
                    source,
                    destination,
                    oldTaskId,
                    oldSubTaskId,
                    false,
                );
            },
        );

        Socket.on(
            EVENTS.ON_MAP_CREATE_SUB_TASK,
            (user: PUser, taskId: number, subTask: SubTask) => {
                // eslint-disable-next-line no-console
                console.log(user, taskId, subTask);
                actionCreateSubTask(taskId, subTask, false);
            },
        );

        Socket.on(
            EVENTS.ON_MAP_UPDATE_SUB_TASK,
            (
                user: PUser,
                taskId: number,
                subTaskId: number,
                subTaskName: string,
            ) => {
                // eslint-disable-next-line no-console
                console.log(user, taskId, subTaskId, subTaskName);
                actionUpdateSubTask(taskId, subTaskId, subTaskName, false);
            },
        );

        Socket.on(
            EVENTS.ON_MAP_DELETE_SUB_TASK,
            (user: PUser, taskId: number, subTaskId: number) => {
                // eslint-disable-next-line no-console
                console.log(user, taskId, subTaskId);
                actionDeleteSubTask(taskId, subTaskId, false);
            },
        );

        Socket.on(
            EVENTS.ON_MAP_ORDER_SUB_TASK,
            (
                user: PUser,
                taskId: number,
                source: number,
                destination: number,
                oldTaskId: number,
            ) => {
                // eslint-disable-next-line no-console
                console.log(user, taskId, source, destination, oldTaskId);
                actionChangeSubTaskOrder(
                    taskId,
                    source,
                    destination,
                    oldTaskId,
                    false,
                );
            },
        );

        Socket.on(EVENTS.ON_MAP_CREATE_TASK, (user: PUser, task: Task) => {
            // eslint-disable-next-line no-console
            console.log(user, task);
            actionCreateTask(task, false);
        });

        Socket.on(
            EVENTS.ON_MAP_UPDATE_TASK,
            (user: PUser, taskId: number, taskName: string) => {
                // eslint-disable-next-line no-console
                console.log(user, taskId, taskName);
                actionUpdateTask(taskId, taskName, false);
            },
        );

        Socket.on(EVENTS.ON_MAP_DELETE_TASK, (user: PUser, taskId: number) => {
            // eslint-disable-next-line no-console
            console.log(user, taskId);
            actionDeleteTask(taskId, false);
        });

        Socket.on(
            EVENTS.ON_MAP_ORDER_TASK,
            (user: PUser, source: number, destination: number) => {
                // eslint-disable-next-line no-console
                console.log(user, source, destination);
                actionChangeTaskOrder(source, destination, false);
            },
        );

        Socket.on(
            EVENTS.ON_MAP_CREATE_ACTION_TASK,
            (user: PUser, actionTask: ActionTask) => {
                // eslint-disable-next-line no-console
                console.log(user, actionTask);
                actionCreateActionTask(actionTask, false);
            },
        );

        Socket.on(
            EVENTS.ON_MAP_UPDATE_ACTION_TASK,
            (user: PUser, actionTaskId: number, actionTask: ActionTask) => {
                // eslint-disable-next-line no-console
                console.log(user, actionTaskId, actionTask);
                actionUpdateActionTask(actionTaskId, actionTask, false);
            },
        );

        Socket.on(
            EVENTS.ON_MAP_DELETE_ACTION_TASK,
            (user: PUser, actionTaskId: number) => {
                // eslint-disable-next-line no-console
                console.log(user, actionTaskId);
                actionDeleteActionTask(actionTaskId, false);
            },
        );

        Socket.on(
            EVENTS.ON_MAP_ORDER_ACTION_TASK,
            (user: PUser, source: number, destination: number) => {
                // eslint-disable-next-line no-console
                console.log(user, source, destination);
                actionChangeActionTaskOrder(source, destination, false);
            },
        );

        return () => {
            Socket.off(EVENTS.ON_MAP_VOTING_OPEN_STATUS);
            Socket.off(EVENTS.ON_MAP_CREATE_TASK_POST);
            Socket.off(EVENTS.ON_MAP_UPDATE_TASK_POST);
            Socket.off(EVENTS.ON_MAP_UPDATE_TASK_POST_BACKEND);
            Socket.off(EVENTS.ON_MAP_DELETE_TASK_POST);
            Socket.off(EVENTS.ON_MAP_ORDER_TASK_POST);
            Socket.off(EVENTS.ON_MAP_CREATE_SUB_TASK);
            Socket.off(EVENTS.ON_MAP_UPDATE_SUB_TASK);
            Socket.off(EVENTS.ON_MAP_DELETE_SUB_TASK);
            Socket.off(EVENTS.ON_MAP_ORDER_SUB_TASK);
            Socket.off(EVENTS.ON_MAP_CREATE_TASK);
            Socket.off(EVENTS.ON_MAP_UPDATE_TASK);
            Socket.off(EVENTS.ON_MAP_DELETE_TASK);
            Socket.off(EVENTS.ON_MAP_ORDER_TASK);
            Socket.off(EVENTS.ON_MAP_CREATE_ACTION_TASK);
            Socket.off(EVENTS.ON_MAP_UPDATE_ACTION_TASK);
            Socket.off(EVENTS.ON_MAP_DELETE_ACTION_TASK);
            Socket.off(EVENTS.ON_MAP_ORDER_ACTION_TASK);
        };
    }, []);

    // useEffect(() => {
    //     if (zoomRef.current) {
    //         zoomRef.current.style.setProperty("zoom", zoom.toString());
    //     }
    // }, [zoom]);

    const handleChangeOrderTask = (itemId: number, newOrd: number) => {
        TaskApi.changeOrder<Task, PrimitiveObject>(itemId, {
            newOrd,
        }).then(({ response, errorMessage }) => {
            if (errorMessage) {
                errorToast(errorMessage);
            } else if (response !== null) {
                // successToast(t("app.map.view:changeOrder.info.message"));
            }
        });
    };

    const handleChangeOrderSubTask = (itemId: number, newOrd: number) => {
        SubTaskApi.changeOrder<SubTask, PrimitiveObject>(itemId, {
            newOrd,
        }).then(({ response, errorMessage }) => {
            if (errorMessage) {
                errorToast(errorMessage);
            } else if (response !== null) {
                //
            }
        });
    };

    const handleChangeOrderTaskPost = (itemId: number, newOrd: number) => {
        TaskPostApi.changeOrder<TaskPost, PrimitiveObject>(itemId, {
            newOrd,
        }).then(({ response, errorMessage }) => {
            if (errorMessage) {
                errorToast(errorMessage);
            } else if (response !== null) {
                //
            }
        });
    };

    const handleChangeOrderActionTask = (itemId: number, newOrd: number) => {
        ActionTaskApi.changeOrder<ActionTask, PrimitiveObject>(itemId, {
            newOrd,
        }).then(({ response, errorMessage }) => {
            if (errorMessage) {
                errorToast(errorMessage);
            } else if (response !== null) {
                //
            }
        });
    };

    const handleDragStart = (start: DragStart) => {
        const { draggableId, type } = start;
        const [, taskId, subTaskId, taskPostId] = draggableId.split("-");
        if (type === MapItemEnum.TASK) {
            emitter(EVENTS.MAP_LOCK_TASK, {
                mapId: `${id}`,
                user: loggedinUser,
                taskId: +taskId,
                isLock: true,
            });
        } else if (type === MapItemEnum.SUB_TASK) {
            emitter(EVENTS.MAP_LOCK_SUB_TASK, {
                mapId: `${id}`,
                user: loggedinUser,
                subTaskId: +subTaskId,
                isLock: true,
            });
        } else if (type === MapItemEnum.TASK_POST) {
            emitter(EVENTS.MAP_LOCK_TASK_POST, {
                mapId: `${id}`,
                user: loggedinUser,
                taskPostId: +taskPostId,
                isLock: true,
            });
        }
    };

    const handleDragEnd = (result: DropResult) => {
        const { source, destination, draggableId, type } = result;

        const [, taskId, subTaskId, taskPostId] = draggableId.split("-");
        if (type === MapItemEnum.TASK) {
            emitter(EVENTS.MAP_LOCK_TASK, {
                mapId: `${id}`,
                user: loggedinUser,
                taskId: +taskId,
                isLock: false,
            });
        } else if (type === MapItemEnum.SUB_TASK) {
            emitter(EVENTS.MAP_LOCK_SUB_TASK, {
                mapId: `${id}`,
                user: loggedinUser,
                subTaskId: +subTaskId,
                isLock: false,
            });
        } else if (type === MapItemEnum.TASK_POST) {
            emitter(EVENTS.MAP_LOCK_TASK_POST, {
                mapId: `${id}`,
                user: loggedinUser,
                taskPostId: +taskPostId,
                isLock: false,
            });
        }

        if (!destination) return;

        if (
            destination.index === source.index &&
            destination.droppableId === source.droppableId
        ) {
            return;
        }

        const isDifferentParent =
            destination.droppableId !== source.droppableId;

        if (type === MapItemEnum.TASK) {
            handleChangeOrderTask(+taskId, destination.index + 1);
            actionChangeTaskOrder(source.index, destination.index);
        } else if (type === MapItemEnum.SUB_TASK) {
            if (isDifferentParent) {
                const [, oldTaskId] = source.droppableId.split("-");
                const [, newTaskId] = destination.droppableId.split("-");

                SubTaskApi.update<SubTask, PrimitiveObject>(+subTaskId, {
                    task: TaskApi.toResourceUrl(+newTaskId),
                }).then(({ response, errorMessage }) => {
                    if (errorMessage) {
                        errorToast(errorMessage);
                    } else if (response !== null) {
                        handleChangeOrderSubTask(
                            +subTaskId,
                            destination.index + 1,
                        );
                    }
                });

                actionChangeSubTaskOrder(
                    +newTaskId,
                    source.index,
                    destination.index,
                    +oldTaskId,
                );
                return;
            }

            handleChangeOrderSubTask(+subTaskId, destination.index + 1);
            actionChangeSubTaskOrder(+taskId, source.index, destination.index);
        } else if (type === MapItemEnum.TASK_POST) {
            if (isDifferentParent) {
                const [, oldTaskId, oldSubTaskId] =
                    source.droppableId.split("-");
                const [, newTaskId, newSubTaskId] =
                    destination.droppableId.split("-");

                TaskPostApi.update<TaskPost, PrimitiveObject>(+taskPostId, {
                    subTask: SubTaskApi.toResourceUrl(+newSubTaskId),
                }).then(({ response, errorMessage }) => {
                    if (errorMessage) {
                        errorToast(errorMessage);
                    } else if (response !== null) {
                        handleChangeOrderTaskPost(
                            +taskPostId,
                            destination.index + 1,
                        );
                    }
                });

                actionChangeTaskPostOrder(
                    +newTaskId,
                    +newSubTaskId,
                    source.index,
                    destination.index,
                    +oldTaskId,
                    +oldSubTaskId,
                );
                return;
            }

            handleChangeOrderTaskPost(+taskPostId, destination.index + 1);
            actionChangeTaskPostOrder(
                +taskId,
                +subTaskId,
                source.index,
                destination.index,
            );
        }
    };

    const handleDragStartActionTask = (start: DragStart) => {
        const { draggableId } = start;
        const [, actionTaskId] = draggableId.split("-");

        emitter(EVENTS.MAP_LOCK_ACTION_TASK, {
            mapId: `${id}`,
            user: loggedinUser,
            actionTaskId: +actionTaskId,
            isLock: true,
        });
    };

    const handleDragEndActionTask = (result: DropResult) => {
        const { source, destination, draggableId } = result;

        const [, actionTaskId] = draggableId.split("-");

        emitter(EVENTS.MAP_LOCK_ACTION_TASK, {
            mapId: `${id}`,
            user: loggedinUser,
            actionTaskId: +actionTaskId,
            isLock: false,
        });

        if (!destination) return;

        if (destination.index === source.index) return;

        handleChangeOrderActionTask(+actionTaskId, destination.index + 1);
        actionChangeActionTaskOrder(source.index, destination.index);
    };

    if (loading) {
        return <AppLoader isFullScreen />;
    }

    if (!map) {
        return <></>;
    }

    return (
        <div className="map-detail-page">
            <MapDetailFilter
                loading={loading}
                showForm={drawer.show}
                onHide={resetDrawer}
            />

            <MapPageHeader
                map={map}
                userZoom={userZoom}
                pageType={MapPageEnum.MAP_VIEW}
                showToolbar
                onFilterBtnClick={() => setDrawer({ show: true, id: 0 })}
                onChange={fetchMap}
            />

            <div
                className="map-detail-page--container"
                ref={zoomRef}
                style={{
                    zoom: zoom.zoomMap,
                    // transform: `scale(${zoom.zoomMap})`,
                    // transformOrigin: "top left",
                }}
            >
                <MapDetailTasks
                    mapId={map.id}
                    onDragStart={handleDragStart}
                    onDragEnd={handleDragEnd}
                />

                <div className="map-detail-page--container--separator" />

                <MapDetailActionTasks
                    mapId={map.id}
                    onDragStart={handleDragStartActionTask}
                    onDragEnd={handleDragEndActionTask}
                />
            </div>
        </div>
    );
};
