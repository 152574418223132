import { EntityAPI } from "./core";
import { ROUTES } from "../config";

const {
    api_user_zooms_get_collection: API_GET_COLLECTION,
    api_user_zooms_post_collection: API_POST_COLLECTION,
    api_user_zooms_get_item: API_GET_ITEM,
} = ROUTES;

export abstract class UserZoomApi extends EntityAPI {
    protected static GET_COLLECTION = API_GET_COLLECTION;

    protected static POST_COLLECTION = API_POST_COLLECTION;

    protected static GET_ITEM = API_GET_ITEM;
}
