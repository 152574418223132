import * as yup from "yup";
import { TKey } from "../../../utils";

export const schema = (t: (val: string) => string) =>
    yup.object().shape({
        email: yup
            .string()
            .email()
            .required(t(TKey.Common.Message.Validation.RequiredField)),
        password: yup
            .string()
            .required(t(TKey.Common.Message.Validation.RequiredField)),
    });
