import * as yup from "yup";
import { TKey } from "../../../utils";

export const schema = (t: (val: string) => string) =>
    yup.object().shape({
        noOfMonths: yup
            .number()
            .required(t(TKey.Common.Message.Validation.RequiredField)),
        noOfUsers: yup
            .number()
            .positive()
            .required(t(TKey.Common.Message.Validation.RequiredField))
            .test(
                "more-than-one-user",
                t(TKey.Common.Message.Validation.MoreThanOneUser),
                (value) => value > 0,
            ),
        startDate: yup
            .string()
            .required(t(TKey.Common.Message.Validation.RequiredField)),
    });
