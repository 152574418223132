import { FC, Fragment, useEffect, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useRecoilState } from "recoil";

import "./assets/scss/style.scss";

import { AppTabs } from "../AppTabs";
import {
    CropperViewEnum,
    ProfileTabEnum,
    errorToast,
    setBackendViolations,
    successToast,
    validation,
} from "../../utils";
import {
    useAuthAction,
    useAuthState,
    useRoles,
    useTranslation,
} from "../../hooks";
import {
    AppForm,
    AppFormContent,
    AppFormInput,
    AppFormModal,
    AppFormPhoneNumber,
    AppFormSelect,
    AppFormUploader,
} from "../AppForm";
import { schema } from "./schema";
import { AuthApi, ClientApi, UserApi } from "../../apis";
import { Client, PClient, PUser, User } from "../../models";
import { AppLoader } from "../AppLoader";
import {
    ClientLogoFileInfo,
    ROLES,
    UserPosterFileInfo,
    getCountryOptions,
} from "../../config";
import { atomActiveClient } from "../../atoms";
import { AppFlag } from "../AppFlag";

interface UserProfileForm {
    email?: string;
    imageName?: string;
    firstName?: string;
    lastName?: string;
    company?: string;
    name?: string;
    address?: string;
    postCode?: string;
    city?: string;
    country?: string;
    phone?: string;
    oldPassword?: string;
    plainPassword?: string;
    confirmPassword?: string;
    locale?: string;
}

interface AppUserProfileProps {
    showForm: boolean;
    onHide: () => void;
}

export const AppUserProfile: FC<AppUserProfileProps> = ({
    showForm,
    onHide,
}) => {
    // hooks
    const { t } = useTranslation();
    const { isClientAdmin } = useRoles();
    const { userId } = useAuthState();
    const { actionRefetchAuthUser } = useAuthAction();

    // atoms
    const [activeClient, setActiveClient] = useRecoilState(atomActiveClient);

    // state
    const [activeTab, setActiveTab] = useState(ProfileTabEnum.PROFILE);
    const [items, setItems] = useState([
        {
            title: "header.profile.form:tab.profile",
            icon: "user",
            url: ProfileTabEnum.PROFILE,
        },
        {
            title: "header.profile.form:tab.changePassword",
            icon: "user",
            url: ProfileTabEnum.CHANGE_PASSWORD,
        },
    ]);
    const [loading, setLoading] = useState(false);
    const [isUploading, setIsUploading] = useState(false);

    // form
    const methods = useForm({
        resolver: yupResolver(schema(t, activeTab)),
    });
    const { handleSubmit, formState, reset, setError, watch, trigger } =
        methods;

    // local state
    const isPasswordStrong = watch("isPasswordStrong");

    useEffect(() => {
        if (isClientAdmin) {
            setItems([
                {
                    title: "header.profile.form:tab.profile",
                    icon: "user",
                    url: ProfileTabEnum.PROFILE,
                },
                {
                    title: "header.profile.form:tab.companyProfile",
                    icon: "user",
                    url: ProfileTabEnum.COMPANY_PROFILE,
                },
                {
                    title: "header.profile.form:tab.changePassword",
                    icon: "user",
                    url: ProfileTabEnum.CHANGE_PASSWORD,
                },
            ]);
        }

        reset();
    }, []);

    useEffect(() => {
        if (!showForm) {
            return;
        }

        reset(); // Reset all fields when the tab changes

        if (activeTab === ProfileTabEnum.COMPANY_PROFILE) {
            ClientApi.getMyClient<Client>().then(
                ({ errorMessage, response }) => {
                    if (errorMessage) {
                        errorToast(errorMessage);
                    } else if (response) {
                        reset({
                            name: response.name,
                            address: response.address,
                            postCode: response.postCode,
                            city: response.city,
                            country: response.country,
                            phone: response.phone,
                            imageName: response.imageName,
                            isPasswordStrong: true,
                        });
                    }
                },
            );
        } else if (activeTab === ProfileTabEnum.PROFILE) {
            AuthApi.me<User>().then(({ errorMessage, response }) => {
                if (errorMessage) {
                    errorToast(errorMessage);
                } else if (response) {
                    reset({
                        email: response.email,
                        firstName: response.firstName,
                        lastName: response.lastName,
                        imageName: response.imageName,
                        company: response.company,
                        isPasswordStrong: true,
                        locale: response.locale,
                    });
                }
            });
        } else if (activeTab === ProfileTabEnum.CHANGE_PASSWORD) {
            reset({
                oldPassword: undefined,
                plainPassword: undefined,
                confirmPassword: undefined,
            });
        }
    }, [activeTab, showForm, reset]);

    const onSubmitHandler = (formData: UserProfileForm) => {
        setLoading(true);

        const {
            firstName,
            lastName,
            company,
            imageName,
            oldPassword,
            plainPassword,
            name,
            address,
            postCode,
            city,
            country,
            phone,
            locale,
        } = formData;

        if (activeTab === ProfileTabEnum.PROFILE) {
            UserApi.updateProfile<User, PUser>(userId, {
                firstName,
                lastName,
                company,
                imageName,
                locale,
            })
                .then(({ response, errorMessage, isInvalid, error }) => {
                    if (isInvalid) {
                        setBackendViolations(error, setError);
                    } else if (errorMessage) {
                        errorToast(errorMessage);
                    } else if (response !== null) {
                        successToast(
                            t(
                                "header.profile.form:userProfile.update.toast.success",
                            ),
                        );

                        actionRefetchAuthUser();

                        reset();
                        onHide();
                    }
                })
                .finally(() => setLoading(false));

            return;
        }

        if (activeTab === ProfileTabEnum.CHANGE_PASSWORD) {
            UserApi.changePassword<User, Partial<UserProfileForm>>(userId, {
                oldPassword,
                plainPassword,
            })
                .then(({ response, errorMessage, isInvalid, error }) => {
                    if (isInvalid) {
                        setBackendViolations(error, setError);
                    } else if (errorMessage) {
                        errorToast(errorMessage);
                    } else if (response !== null) {
                        successToast(
                            t(
                                "header.profile.form:changePassword.update.toast.success",
                            ),
                        );

                        // TODO::(v2) action logout needed?
                        // actionLogout()

                        reset();
                        onHide();
                    }
                })
                .finally(() => setLoading(false));

            return;
        }

        if (activeTab === ProfileTabEnum.COMPANY_PROFILE && activeClient) {
            ClientApi.updateProfile<Client, PClient>(activeClient.id, {
                name,
                address,
                postCode,
                city,
                country,
                phone,
                imageName,
            })
                .then(({ response, errorMessage, isInvalid, error }) => {
                    if (isInvalid) {
                        setBackendViolations(error, setError);
                    } else if (errorMessage) {
                        errorToast(errorMessage);
                    } else if (response !== null) {
                        successToast(
                            t(
                                "header.profile.form:companyProfile.update.toast.success",
                            ),
                        );

                        setActiveClient({
                            id: response.id,
                            imageName: response.imageName,
                        });

                        reset();
                        onHide();
                    }
                })
                .finally(() => setLoading(false));

            return;
        }
    };

    const renderTabs = () => (
        <>
            <div hidden={activeTab !== ProfileTabEnum.PROFILE}>
                <AppFormContent>
                    <AppFormUploader
                        id="imageName"
                        name="imageName"
                        label={t("admin.user.form:label.imageName")}
                        required={false}
                        block
                        fileInfo={UserPosterFileInfo}
                        cbFileUploading={setIsUploading}
                        withCropper={CropperViewEnum.Default}
                    />

                    <AppFormInput
                        type="email"
                        id="email"
                        name="email"
                        label={t("user.register.form:label.email")}
                        placeholder={t("user.register.form:placeholder.email")}
                        autoComplete="email"
                        block
                        disabled
                    />

                    <AppFormInput
                        id="firstName"
                        name="firstName"
                        label={t("admin.user.form:label.firstName")}
                        placeholder={t("admin.user.form:placeholder.firstName")}
                        block
                        maxCount={validation.title.max}
                    />

                    <AppFormInput
                        id="lastName"
                        name="lastName"
                        label={t("admin.user.form:label.lastName")}
                        placeholder={t("admin.user.form:placeholder.lastName")}
                        block
                        maxCount={validation.title.max}
                    />

                    <AppFormInput
                        id="company"
                        name="company"
                        label={t("admin.user.form:label.company")}
                        placeholder={t("admin.user.form:placeholder.company")}
                        block
                        maxCount={validation.title.max}
                    />

                    <AppFormSelect
                        id="locale"
                        name="locale"
                        label={t("admin.user.form:label.locale")}
                        required={true}
                        block
                        options={[
                            {
                                label: t(
                                    `common.locale:${ROLES.USER_LOCALE_EN}`,
                                ),
                                value: ROLES.USER_LOCALE_EN,
                            },
                            {
                                label: t(
                                    `common.locale:${ROLES.USER_LOCALE_DE}`,
                                ),
                                value: ROLES.USER_LOCALE_DE,
                            },
                        ]}
                    />
                </AppFormContent>
            </div>

            <div hidden={activeTab !== ProfileTabEnum.COMPANY_PROFILE}>
                <AppFormContent>
                    <AppFormUploader
                        id="imageName"
                        name="imageName"
                        label={t("admin.client.form:label.imageName")}
                        block
                        fileInfo={ClientLogoFileInfo}
                        cbFileUploading={setIsUploading}
                        withCropper={CropperViewEnum.Default}
                    />

                    <AppFormInput
                        id="name"
                        name="name"
                        label={t("admin.client.form:label.name")}
                        placeholder={t("admin.client.form:placeholder.name")}
                        block
                        maxCount={validation.title.max}
                    />

                    <AppFormInput
                        id="address"
                        name="address"
                        label={t("admin.client.form:label.address")}
                        placeholder={t("admin.client.form:placeholder.address")}
                        block
                        maxCount={validation.title.max}
                    />

                    <AppFormPhoneNumber
                        id="phone"
                        name="phone"
                        label={t("admin.client.form:label.phone")}
                        placeholder={t("admin.client.form:placeholder.phone")}
                        block
                    />

                    <AppFormSelect
                        id="country"
                        name="country"
                        label={t("admin.client.form:label.country")}
                        placeholder={t("admin.client.form:placeholder.country")}
                        block
                        options={getCountryOptions().map(
                            ({ label, value }) => ({
                                label: (
                                    <div className="d-flex gap-3 align-items-center">
                                        <AppFlag icon={value} />
                                        <span>{label}</span>
                                    </div>
                                ),
                                value,
                                search: label,
                            }),
                        )}
                    />

                    <AppFormInput
                        id="city"
                        name="city"
                        label={t("admin.client.form:label.city")}
                        placeholder={t("admin.client.form:placeholder.city")}
                        block
                    />

                    <AppFormInput
                        type="number"
                        id="postCode"
                        name="postCode"
                        label={t("admin.client.form:label.postCode")}
                        placeholder={t(
                            "admin.client.form:placeholder.postCode",
                        )}
                        block
                    />
                </AppFormContent>
            </div>

            <div hidden={activeTab !== ProfileTabEnum.CHANGE_PASSWORD}>
                <AppFormContent>
                    <AppFormInput
                        type="password"
                        id="oldPassword"
                        name="oldPassword"
                        label={t("header.profile.form:label.oldPassword")}
                        placeholder={t(
                            "header.profile.form:placeholder.oldPassword",
                        )}
                        autoComplete="new-password"
                        block
                    />

                    <AppFormInput
                        type="password"
                        id="plainPassword"
                        name="plainPassword"
                        label={t("header.profile.form:label.newPassword")}
                        placeholder={t(
                            "header.profile.form:placeholder.newPassword",
                        )}
                        showPasswordStrength
                        autoComplete="new-password"
                        block
                    />

                    <AppFormInput
                        type="password"
                        id="confirmPassword"
                        name="confirmPassword"
                        label={t(
                            "header.profile.form:label.confirmNewPassword",
                        )}
                        placeholder={t(
                            "header.profile.form:placeholder.confirmNewPassword",
                        )}
                        autoComplete="new-password"
                        block
                        onCopy={(e) => e.preventDefault()}
                        onPaste={(e) => e.preventDefault()}
                    />
                </AppFormContent>
            </div>
        </>
    );

    useEffect(() => {
        const subscription = watch((_, { name }) => {
            if (name && formState.touchedFields[name]) {
                trigger(name);
            }
        });
        return () => subscription.unsubscribe();
    }, [watch, trigger, formState.touchedFields]);

    const renderForm = () => (
        <FormProvider {...methods}>
            <AppForm className="app-user-profile">
                {loading && <AppLoader isFullScreen />}

                <AppTabs
                    className="mb-4"
                    items={items}
                    onClick={(item) => {
                        if (item.url) {
                            setActiveTab(item.url as ProfileTabEnum);
                        }
                    }}
                    isActiveTab={(item) => item?.url === activeTab}
                />

                <AppFormContent>{renderTabs()}</AppFormContent>
            </AppForm>
        </FormProvider>
    );

    return (
        <AppFormModal
            show={showForm}
            icon="user"
            title="header.profile.form:modal.title"
            isDrawer
            onSubmit={handleSubmit(onSubmitHandler)}
            onHide={() => onHide()}
            isLoading={formState.isSubmitting || loading}
            disabled={!activeClient || isUploading || !isPasswordStrong}
            disabledSumbit={!formState.isValid}
            isEditMode
        >
            {renderForm()}
        </AppFormModal>
    );
};
