import { EntityAPI } from "./core";
import { FinalResponse, ROUTES, route } from "../config";

const {
    api_map_share_get_collection: API_GET_COLLECTION,
    api_map_share_post_collection: API_POST_COLLECTION,
    api_map_share_get_item: API_GET_ITEM,
    api_map_share_get_detail_item_public: API_GET_DETAIL_ITEM_PUBLIC,
    api_map_share_delete_item: API_DELETE_ITEM,
} = ROUTES;

export abstract class MapShareApi extends EntityAPI {
    protected static GET_COLLECTION = API_GET_COLLECTION;

    protected static POST_COLLECTION = API_POST_COLLECTION;

    protected static GET_ITEM = API_GET_ITEM;

    protected static DELETE_ITEM = API_DELETE_ITEM;

    public static async getDetailByIdPublic<R>(
        id: number,
        extraParams = {},
    ): Promise<FinalResponse<R | null>> {
        const path = route(API_GET_DETAIL_ITEM_PUBLIC, { id });

        return this.makeGet<R>(path, { ...extraParams })
            .then(({ data }) => this.handleResponse<R>(data))
            .catch((e) => this.handleAllError(e));
    }
}
