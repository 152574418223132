import { FC, useEffect, useRef, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { useRecoilState } from "recoil";

import "./assets/scss/style.scss";

import {
    AppAvatar,
    AppButton,
    AppConfirmModal,
    AppDisplayEditorContent,
    AppForm,
    AppFormActions,
    AppFormContent,
    AppFormInput,
    AppImage,
    AppLoader,
    AppSVGIcon,
    AppTabs,
    AppTooltip,
    AppZoomSlider,
} from "../../../components";
import { AuthApi, MapApi, MapShareApi } from "../../../apis";
import { Map, MapShare, TaskPost, User } from "../../../models";
import {
    TKey,
    errorToast,
    setBackendViolations,
    zoomToFixed,
} from "../../../utils";
import { atomZoomLevel } from "../../../atoms";
import { ClientLogoFileInfo, getVotingColors } from "../../../config";
import { useTranslation } from "../../../hooks";
import { FormProvider, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";

interface AuthMapViewForm {
    mapShare?: number;
    pwd: string;
}

export const MapDetailViewPage: FC = () => {
    // hooks
    const { t } = useTranslation();
    const { key } = useParams();

    const keyData = atob(`${key}`).split("|");
    const mapShareId = keyData?.[0];

    const lKey = localStorage.getItem("AMV") || "";
    const lKeyData = atob(`${lKey}`).split("|");
    const lMapShareId = lKeyData?.[0];

    // ref
    const zoomRef = useRef<HTMLDivElement>(null);

    // atoms
    const [zoom, setZoom] = useRecoilState(atomZoomLevel);

    // state
    const [loading, setLoading] = useState(false);
    const [loadingForm, setLoadingForm] = useState(false);
    const [mapShare, setMapShare] = useState<MapShare>();
    const [map, setMap] = useState<Map>();
    const [isAuth, setIsAuth] = useState(false);
    const [isPermissionDialogVisible, setIsPermissionDialogVisible] =
        useState(false);
    const [headerCollapsed, setHeaderCollapsed] = useState(() =>
        JSON.parse(localStorage.getItem("headerCollapsed") || "false"),
    );
    useEffect(() => {
        localStorage.setItem(
            "headerCollapsed",
            JSON.stringify(headerCollapsed),
        );
    }, [headerCollapsed]);

    // form
    const methods = useForm<AuthMapViewForm>({
        resolver: yupResolver(
            yup.object().shape({
                mapShare: yup.number().optional(),
                pwd: yup
                    .string()
                    .required(t(TKey.Common.Message.Validation.RequiredField)),
            }),
        ),
    });
    const { handleSubmit, formState, setError } = methods;

    const onSubmitHandler = (formData: AuthMapViewForm) => {
        setLoadingForm(true);
        formData.mapShare = Number(mapShareId);
        AuthApi.authMapView<{ isSuccess: boolean }, AuthMapViewForm>(formData)
            .then(({ response, errorMessage, isInvalid, error }) => {
                if (isInvalid) {
                    setBackendViolations(error, setError);
                } else if (errorMessage) {
                    errorToast(errorMessage);
                } else if (response !== null) {
                    if (response.isSuccess) {
                        setIsAuth(true);
                        localStorage.setItem(
                            "AMV",
                            btoa(`${mapShareId}|${key}`),
                        );
                    } else {
                        errorToast(t("user.login.form:message.invalid"));
                    }
                }
            })
            .finally(() => setLoadingForm(false));
    };

    const fetchMap = async (mapId: number) => {
        setLoading(true);

        MapApi.getDetailByIdPublic<Map>(mapId)
            .then(({ errorMessage, response }) => {
                if (errorMessage) {
                    errorToast(errorMessage);
                } else if (response) {
                    setMap(response);
                }
            })
            .finally(() => setLoading(false));
    };

    const fetchMapShare = async () => {
        if (!mapShareId) {
            return;
        }

        setLoading(true);

        MapShareApi.getDetailByIdPublic<MapShare>(+mapShareId)
            .then(({ errorMessage, response }) => {
                if (errorMessage) {
                    errorToast(errorMessage);
                } else if (response) {
                    setMapShare(response);
                    setIsAuth(
                        Number(lMapShareId) === Number(mapShareId)
                            ? true
                            : !response?.isPwdExist,
                    );
                }
            })
            .finally(() => setLoading(false));
    };

    useEffect(() => {
        fetchMapShare();
    }, [mapShareId]);

    useEffect(() => {
        if (isAuth && mapShare?.map) {
            fetchMap(mapShare.map?.id);
        }
    }, [isAuth]);

    const [range] = useState({
        min: 0.3,
        max: 1.7,
        step: 0.1,
    });

    if (loading) {
        return <AppLoader isFullScreen />;
    }

    const renderHeader = () => (
        <header className="app-header">
            <div className="app-header--main">
                <Link className="header-logo" to={"/"}>
                    {map?.client ? (
                        <AppImage src={""} fileInfo={ClientLogoFileInfo} />
                    ) : (
                        <AppSVGIcon icon="logo" type="image" />
                    )}
                </Link>
            </div>
            <div className="app-header--center">
                <AppTabs
                    items={[
                        {
                            title: "header:navigation.dashboard",
                            icon: "lock",
                            url: `#`,
                        },
                        {
                            title: "header:navigation.map",
                            icon: "map",
                            url: `/map-view/${map?.id}`,
                        },
                        {
                            title: "header:navigation.portfolio",
                            icon: "lock",
                            url: `#`,
                        },
                        {
                            title: "header:navigation.kanban",
                            icon: "lock",
                            url: `#`,
                        },
                    ]}
                    onClick={(item) => {
                        if (item.url === "#") {
                            setIsPermissionDialogVisible(true);
                        }
                        if (item.url) {
                            // navigate(item.url);
                        }
                    }}
                    isActiveTab={(item) => item?.icon === "map"}
                />
            </div>
            <div className="app-header--action" />
        </header>
    );

    const getTaskPostColor = (taskPost: TaskPost): object => {
        const selectedColor = map?.isVoting
            ? taskPost.colorVote
            : taskPost.color;

        const itemColor = getVotingColors().find(
            (x) => x.value === selectedColor,
        )?.color;

        const taskPostActionStyle = itemColor
            ? {
                  backgroundImage: `linear-gradient(${itemColor}94, ${itemColor}1a)`,
              }
            : {};

        return taskPostActionStyle;
    };

    const getZoom = () => ({
        key: "zoomMap",
        value: zoom.zoomMap,
    });

    const handleUpdateZoomLevel = (value: number) => {
        const updatedValue = parseFloat(value.toFixed(zoomToFixed));

        setZoom((prev) => ({
            ...prev,
            [getZoom().key]: updatedValue,
        }));
    };

    const handleZoomOut = () => {
        if (getZoom().value > range.min) {
            const selectedValue = Math.max(
                getZoom().value - range.step,
                range.min,
            );

            handleUpdateZoomLevel(selectedValue);
        }
    };

    const handleZoomIn = () => {
        if (getZoom().value < range.max) {
            const selectedValue = Math.min(
                getZoom().value + range.step,
                range.max,
            );

            handleUpdateZoomLevel(selectedValue);
        }
    };

    const handleSliderChange = (value: number) => {
        handleUpdateZoomLevel(value);
    };

    const renderZoomSlider = () => (
        <AppZoomSlider
            value={getZoom().value}
            min={range.min}
            max={range.max}
            step={range.step}
            onZoomIn={handleZoomIn}
            onZoomOut={handleZoomOut}
            onChange={handleSliderChange}
        />
    );

    const renderMap = () => {
        if (!map) {
            return <></>;
        }

        return (
            <div className="app-layout-container">
                {renderHeader()}
                <main className={"app-main-container container-fluid"}>
                    <div className="map-detail-page">
                        <div className="app-map-page-header gap-5">
                            {loading && <AppLoader isFullScreen />}

                            <AppConfirmModal
                                show={!!isPermissionDialogVisible}
                                icon="warning"
                                title="map.detailView:modal.permission.title"
                                description="map.detailView:modal.permission.description"
                                cancelLabel={TKey.Common.Button.Cancel}
                                createLabel={TKey.Common.Button.Request}
                                onHide={() =>
                                    setIsPermissionDialogVisible(false)
                                }
                                nextAction={() => {
                                    setIsPermissionDialogVisible(false);
                                    window.location.href =
                                        "mailto:support@expertshare.live";
                                }}
                            />

                            <div className="app-map-page-header--left gap-3">
                                <div className="app-map-page-header--left--header">
                                    <div className="title">
                                        <AppTooltip
                                            id={map?.name}
                                            placement="bottom-end"
                                            overlay={
                                                <span className="paragraph--400">
                                                    {map?.name}
                                                </span>
                                            }
                                        >
                                            <span className="paragraph--100 font-weight--bold">
                                                {map?.name}
                                            </span>
                                        </AppTooltip>
                                    </div>

                                    <AppButton
                                        variant="secondary"
                                        className="btn-square btn-sm"
                                        onClick={() =>
                                            setHeaderCollapsed(!headerCollapsed)
                                        }
                                        disabled={loading}
                                    >
                                        <AppSVGIcon
                                            icon={
                                                headerCollapsed
                                                    ? "chevron-down"
                                                    : "chevron-right"
                                            }
                                        />
                                    </AppButton>
                                </div>
                                <div
                                    hidden={!headerCollapsed}
                                    className="app-map-page-header--left--content gap-3"
                                >
                                    <AppTooltip
                                        id={map?.name}
                                        placement="bottom-end"
                                        overlay={
                                            <div className="map-tooltip">
                                                <span className="sub-title">
                                                    {map?.subTitle}
                                                </span>

                                                <AppDisplayEditorContent
                                                    className="description"
                                                    content={map?.description}
                                                />
                                            </div>
                                        }
                                    >
                                        <div className="map-tooltip">
                                            <span className="sub-title">
                                                {map?.subTitle}
                                            </span>

                                            <AppDisplayEditorContent
                                                className="description"
                                                content={map?.description}
                                            />
                                        </div>
                                    </AppTooltip>
                                </div>
                            </div>
                            <div className="app-map-page-header--center gap-3 mt-3">
                                {renderZoomSlider()}
                            </div>
                            <div className="app-map-page-header--right gap-3">
                                <AppButton
                                    variant="secondary"
                                    iconLeft="filter"
                                    disabled={true}
                                >
                                    {t(TKey.Common.Button.Filter)}
                                </AppButton>
                                <AppButton
                                    variant="secondary"
                                    disabled={true}
                                    className="btn-square"
                                >
                                    <AppSVGIcon icon="ellipsis" />
                                </AppButton>
                            </div>
                        </div>

                        <div
                            className="map-detail-page--container"
                            ref={zoomRef}
                            style={{
                                zoom: zoom.zoomMap,
                            }}
                        >
                            <div
                                className={"map-detail-page--container--tasks"}
                            >
                                {map?.tasks?.map((item, index) => (
                                    <div
                                        className="task-container"
                                        key={`t-${index}`}
                                    >
                                        <div className={`task-container--item`}>
                                            <div className="task-container--item--action" />
                                            <div className="task-container--item--content">
                                                <span className="title">
                                                    {item.name}
                                                </span>
                                            </div>
                                        </div>
                                        <div className="task-container--sub-tasks">
                                            {item?.subTasks?.map(
                                                (subItem, subIndex) => (
                                                    <div
                                                        className={`sub-task-container`}
                                                        key={`st-${subIndex}`}
                                                    >
                                                        <div
                                                            className={`sub-task-container--item`}
                                                        >
                                                            <div className="sub-task-container--item--action" />

                                                            <div className="sub-task-container--item--content">
                                                                <span className="title">
                                                                    {
                                                                        subItem.name
                                                                    }
                                                                </span>
                                                            </div>
                                                        </div>
                                                        <div
                                                            className={`sub-task-container--task-posts task-post-container`}
                                                        >
                                                            {subItem?.taskPosts?.map(
                                                                (
                                                                    taskPost,
                                                                    taskPostIndex,
                                                                ) => (
                                                                    <div
                                                                        className={`task-post-container--item`}
                                                                        key={`tp-${taskPostIndex}`}
                                                                    >
                                                                        <div
                                                                            className="task-post-container--item--action"
                                                                            style={getTaskPostColor(
                                                                                taskPost,
                                                                            )}
                                                                        >
                                                                            {
                                                                                <div className="d-flex gap-1">
                                                                                    {taskPost
                                                                                        ?.documents
                                                                                        ?.length >
                                                                                        0 && (
                                                                                        <AppSVGIcon
                                                                                            icon="attach"
                                                                                            className="cursor-pointer"
                                                                                        />
                                                                                    )}
                                                                                </div>
                                                                            }
                                                                        </div>

                                                                        <div className="task-post-container--item--content">
                                                                            <AppTooltip
                                                                                id={
                                                                                    taskPost.title
                                                                                }
                                                                                overlay={
                                                                                    <span className="paragraph--400">
                                                                                        {
                                                                                            taskPost.title
                                                                                        }
                                                                                    </span>
                                                                                }
                                                                            >
                                                                                <span
                                                                                    className={`title ${
                                                                                        !taskPost.description
                                                                                            ? "title--full-height"
                                                                                            : ""
                                                                                    }`}
                                                                                >
                                                                                    {
                                                                                        taskPost.title
                                                                                    }
                                                                                </span>
                                                                            </AppTooltip>

                                                                            <AppDisplayEditorContent
                                                                                className="description"
                                                                                content={
                                                                                    taskPost.description
                                                                                }
                                                                            />
                                                                        </div>
                                                                    </div>
                                                                ),
                                                            )}
                                                        </div>
                                                    </div>
                                                ),
                                            )}
                                        </div>
                                    </div>
                                ))}
                            </div>
                            <div className="map-detail-page--container--separator" />
                            <div className="map-detail-page--container--action-tasks">
                                <div className="action-task-container">
                                    <div className="action-task-container--strategy-card">
                                        <span className="title">
                                            {t("app.map.view:actionTask.title")}
                                        </span>
                                    </div>
                                    <div
                                        className={`action-task-container--list-container`}
                                    >
                                        {map?.actionTasks?.map(
                                            (item, index) => (
                                                <div
                                                    className={`action-task-container--list-container--item`}
                                                    key={`t-${index}`}
                                                >
                                                    <div className="action-task-container--list-container--item--action">
                                                        {
                                                            <div className="d-flex gap-1">
                                                                {item?.documents
                                                                    ?.length >
                                                                    0 && (
                                                                    <AppSVGIcon
                                                                        icon="attach"
                                                                        className="cursor-pointer"
                                                                    />
                                                                )}
                                                            </div>
                                                        }
                                                    </div>

                                                    <div className="action-task-container--list-container--item--content">
                                                        <div className="detail">
                                                            <AppTooltip
                                                                id={item.title}
                                                                overlay={
                                                                    <span className="paragraph--400">
                                                                        {
                                                                            item.title
                                                                        }
                                                                    </span>
                                                                }
                                                            >
                                                                <span
                                                                    className={`title ${
                                                                        !item.description
                                                                            ? "title--full-height"
                                                                            : ""
                                                                    } ${
                                                                        !item.description &&
                                                                        item.user
                                                                            ? "title--full-height--with-user"
                                                                            : ""
                                                                    }`}
                                                                >
                                                                    {item.title}
                                                                </span>
                                                            </AppTooltip>

                                                            <AppDisplayEditorContent
                                                                className="description"
                                                                content={
                                                                    item.description
                                                                }
                                                            />
                                                        </div>

                                                        {item.user && (
                                                            <AppAvatar
                                                                user={
                                                                    item.user as User
                                                                }
                                                                withDetail
                                                            />
                                                        )}
                                                    </div>
                                                </div>
                                            ),
                                        )}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </main>
            </div>
        );
    };

    const renderAuth = () => (
        <div className="auth-layout-container">
            <main className={"auth-main-container container-fluid h-100 p-0"}>
                <div className="row g-0 h-100">
                    <div className="col-auto">
                        <div className="auth-layout-container--left-bar h-100">
                            <div>
                                <div className="d-flex justify-content-center py-4">
                                    <AppSVGIcon icon="logo" type="image" />
                                </div>

                                <div className="card">
                                    <div className="px-1 welcome-text">
                                        <h5 className="headline--300 font-weight--bold welcome-text">
                                            {t(
                                                "authPublic.layout:label.welcome.title",
                                            )}
                                        </h5>
                                    </div>
                                    <div className="px-1 pb-4">
                                        <p className="paragraph--100 font-weight--light text-secondary">
                                            {t(
                                                "authPublic.layout:label.welcome.description",
                                            )}
                                        </p>
                                    </div>
                                    <FormProvider {...methods}>
                                        <AppForm
                                            onSubmit={handleSubmit(
                                                onSubmitHandler,
                                            )}
                                        >
                                            {loadingForm && (
                                                <AppLoader isFullScreen />
                                            )}
                                            <AppFormContent>
                                                <AppFormInput
                                                    type="password"
                                                    id="pwd"
                                                    name="pwd"
                                                    label={t(
                                                        "user.login.form:label.password",
                                                    )}
                                                    placeholder={t(
                                                        "user.login.form:placeholder.password",
                                                    )}
                                                    autoComplete="current-password"
                                                    block={"true"}
                                                />
                                            </AppFormContent>

                                            <AppFormActions
                                                withDefault={false}
                                                position="center"
                                                className="pt-2 px-5 mt-4"
                                            >
                                                <AppButton
                                                    type="submit"
                                                    isLoading={
                                                        formState.isSubmitting ||
                                                        loading
                                                    }
                                                    disabled={
                                                        formState.isSubmitting ||
                                                        loading
                                                    }
                                                    className="auth-button"
                                                >
                                                    {t(
                                                        TKey.Common.Button
                                                            .Login,
                                                    )}
                                                </AppButton>
                                            </AppFormActions>
                                        </AppForm>
                                    </FormProvider>
                                </div>
                            </div>

                            <div className="d-flex align-items-center justify-content-center mb-4 gap-3">
                                <Link
                                    to="https://srmedia.ch"
                                    target="_blank"
                                    rel="noreferrer"
                                >
                                    <AppSVGIcon
                                        icon="sr-media-logo"
                                        type="image"
                                    />
                                </Link>
                            </div>
                        </div>
                    </div>
                    <div className="col">
                        <div className="auth-layout-container--right-bar h-100 position-relative d-none d-md-flex">
                            <div className="auth-layout-container--right-bar__top-image" />
                            <div className="auth-layout-container--right-bar__bottom-image" />
                        </div>
                    </div>
                </div>
            </main>
        </div>
    );

    return isAuth ? renderMap() : renderAuth();
};
