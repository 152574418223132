import { FC, PropsWithChildren, useEffect, useState } from "react";
import { OverlayTrigger, Popover } from "react-bootstrap";
import { OverlayTriggerRenderProps } from "react-bootstrap/esm/OverlayTrigger";

import "./assets/scss/style.scss";

import { AppSVGIcon } from "../../../components";

interface MapVoteColorSelectorProps extends PropsWithChildren {
    onVotingClick: () => void;
    className?: string;
    isGrantedControl?: boolean;
}

export const MapVoteColorSelector: FC<MapVoteColorSelectorProps> = ({
    onVotingClick,
    className = "",
    isGrantedControl = true,
    children,
}): JSX.Element => {
    // state
    const [showPopover, setShowPopover] = useState(false);

    useEffect(() => {
        if (showPopover) {
            onVotingClick();
        }
    }, [showPopover]);

    const renderButton = ({
        ref,
        ...triggerHandler
    }: OverlayTriggerRenderProps) => (
        <AppSVGIcon icon="color" ref={ref} {...triggerHandler} size="sm" />
    );

    const renderContent = () => (
        <div className="map-color-dropdown--content">{children}</div>
    );

    if (!isGrantedControl) {
        return <></>;
    }

    return (
        <div className={`map-color-dropdown ${className}`}>
            <OverlayTrigger
                trigger={"click"}
                placement="bottom-start"
                flip
                rootClose
                show={showPopover}
                onToggle={setShowPopover}
                overlay={(props) => (
                    <Popover
                        {...props}
                        id="popover-basic"
                        className="popover-container-vote-color"
                        onClickCapture={() => setShowPopover(false)}
                        arrowProps={{
                            style: {
                                display: "none",
                            },
                        }}
                    >
                        {renderContent()}
                    </Popover>
                )}
            >
                {(props) => renderButton(props)}
            </OverlayTrigger>
        </div>
    );
};
