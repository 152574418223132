export const concatWithSeparator = (separator = " ", ...strings: string[]) =>
    strings.filter((str) => !!str).join(separator);

export const normalizeName = (value: string): string =>
    value
        .trim()
        .toLowerCase()
        .replace(/[^\w ]/g, " ") // remove special characters
        .replace(/\s+/g, "-"); // replace space with dash

export const toPascalCase = (value: string): string =>
    value.replace(
        /(\w)(\w*)/g,
        (_, firstChar, restOfWord) =>
            firstChar.toUpperCase() + restOfWord.toLowerCase(),
    );
