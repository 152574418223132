import * as yup from "yup";
import { TKey, validation } from "../../../utils";

export const schema = (t: (val: string) => string) =>
    yup.object().shape({
        kanbanSettings: yup.array().of(
            yup.object().shape({
                name: yup
                    .string()
                    .required(t(TKey.Common.Message.Validation.RequiredField)),
                type: yup
                    .string()
                    .required(t(TKey.Common.Message.Validation.RequiredField)),
                limit: yup
                    .number()
                    .positive()
                    .transform((value) => value || 1),
                isEnable: yup.boolean(),
                questions: yup.object().shape({
                    question1: yup
                        .string()
                        .max(validation.description.max)
                        .trim(),
                    question2: yup
                        .string()
                        .max(validation.description.max)
                        .trim(),
                    question3: yup
                        .string()
                        .max(validation.description.max)
                        .trim(),
                    question4: yup
                        .string()
                        .max(validation.description.max)
                        .trim(),
                }),
            }),
        ),
    });
