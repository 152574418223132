import { FC } from "react";
import { Link } from "react-router-dom";

import "./assets/scss/style.scss";

import { AppFile } from "../../models";
import { AppSVGIcon } from "../AppSVGIcon";
import { TKey, humanFileSize, isString } from "../../utils";
import { AppActionDropDown } from "../AppActionDropDown";
import { useTranslation } from "../../hooks";

interface AppFileDetailProps {
    file: AppFile | string;
    onDelete: (file: AppFile | string) => void;
    onDownload?: (file: AppFile | string) => void;
    hasDownload?: boolean;
    className?: string;
}

export const AppFileDetail: FC<AppFileDetailProps> = ({
    file,
    className,
    hasDownload,
    onDelete,
    onDownload,
}) => {
    // hooks
    const { t } = useTranslation();

    const fileName = isString(file) ? file : file.name;
    const fileSize = isString(file) ? null : file.size;

    return (
        <div className={`app-file-detail ${className}`}>
            <div className="app-file-detail--content gap-2">
                <AppSVGIcon icon="description" />

                <span className="file-name">{fileName}</span>

                {fileSize && (
                    <span className="file-size">{humanFileSize(fileSize)}</span>
                )}
            </div>

            <AppActionDropDown>
                {hasDownload && (
                    <li>
                        <Link
                            to={"#"}
                            onClick={(e) => {
                                e.preventDefault();
                                e.stopPropagation();
                                onDownload?.(file);
                            }}
                        >
                            <AppSVGIcon className="mt-1" icon="download" />
                            <span>{t(TKey.Common.Button.Download)}</span>
                        </Link>
                    </li>
                )}
                <li>
                    <Link
                        to={"#"}
                        onClick={(e) => {
                            e.preventDefault();
                            e.stopPropagation();
                            onDelete(file);
                        }}
                    >
                        <AppSVGIcon className="mt-1" icon="delete" />
                        <span>{t(TKey.Common.Button.Delete)}</span>
                    </Link>
                </li>
            </AppActionDropDown>
        </div>
    );
};
