import axios, { AxiosInstance } from "axios";
import QueryString from "qs";

import {
    onRequestFulfilled,
    onRequestRejected,
    onResponseFulfilled,
    onResponseRejected,
} from "./interceptors";
import { ACCEPTABLE_RESPONSE, API_HOST } from "../env";
import { AcceptableResponse } from "./models";

// create a new Axios instance
export const axiosInstance: AxiosInstance = axios.create({
    baseURL: API_HOST,
    timeout: 1000 * 300, // Set a request timeout (optional)
    headers: {
        accept: AcceptableResponse.header(ACCEPTABLE_RESPONSE),
    },
    paramsSerializer: (params) =>
        QueryString.stringify(params, { arrayFormat: "brackets" }),
});

// add request interceptor
axiosInstance.interceptors.request.use(onRequestFulfilled, onRequestRejected);

// add response interceptor
axiosInstance.interceptors.response.use(
    onResponseFulfilled,
    onResponseRejected,
);
