import { PERMISSION } from "../config";
import { WorkspacePermission } from "../models";
import { useAuthState } from "./useAuthState";
import { useMapAction } from "./useMapAction";
import { useRoles } from "./useRoles";

export type WorkspacePermissionType = {
    isOwner: boolean;
    isWrite: boolean;
    isRead: boolean;
};

export function useWorkspacePermission(
    permissions?: WorkspacePermission[],
): WorkspacePermissionType {
    // hooks
    const { userResourceId } = useAuthState();
    const { workspacePermissions } = useMapAction();
    const { isSuperAdmin } = useRoles();

    // local state
    const isPermissionUser = [
        ...workspacePermissions,
        ...(permissions || []),
    ].find((x) => x.user === userResourceId);

    const isOwner =
        (!!isPermissionUser &&
            isPermissionUser.permission === PERMISSION.PERMISSION_OWNER) ||
        isSuperAdmin;

    const isWrite =
        (!!isPermissionUser &&
            isPermissionUser.permission === PERMISSION.PERMISSION_WRITE) ||
        isOwner;

    return {
        isOwner,
        isWrite,
        isRead:
            !!isPermissionUser &&
            isPermissionUser.permission === PERMISSION.PERMISSION_READ,
    };
}
