import React, { ReactNode, forwardRef } from "react";

import "./assets/scss/style.scss";

import { useTranslation } from "../../hooks";
import { AppSVGIcon } from "../AppSVGIcon";
import { TKey } from "../../utils";

export interface AppButtonProps
    extends React.DetailedHTMLProps<
        React.ButtonHTMLAttributes<HTMLButtonElement>,
        HTMLButtonElement
    > {
    variant?:
        | "primary"
        | "secondary"
        | "success"
        | "danger"
        | "warning"
        | "info"
        | "dark"
        | "light"
        | "link"
        | "outline-primary"
        | "outline-secondary"
        | "outline-success"
        | "outline-danger"
        | "outline-warning"
        | "outline-info"
        | "outline-dark"
        | "outline-light";
    size?: "sm";
    block?: boolean;
    xyz?: boolean;
    isLoading?: boolean;
    loadingTxt?: string;
    iconLeft?: string;
    iconRight?: string;
    badge?: number;
    children?: ReactNode;
}

export const AppButton = forwardRef<HTMLButtonElement, AppButtonProps>(
    (
        {
            variant = "primary",
            size,
            className = "",
            block,
            isLoading,
            loadingTxt = TKey.Common.Label.Loading,
            children,
            iconLeft,
            iconRight,
            badge,
            ...props
        },
        ref,
    ) => {
        const { t } = useTranslation();

        const variantClass = variant ? `btn-${variant}` : "";
        const sizeClass = size ? `btn-${size}` : "";

        return (
            <button
                ref={ref}
                className={`btn gap-2 ${variantClass} ${sizeClass} ${className}`}
                type="button"
                {...props}
            >
                {iconLeft && (
                    <AppSVGIcon
                        icon={iconLeft}
                        size="sm"
                        className="mt-1"
                        disabled={props.disabled}
                    />
                )}

                <span className="btn-text">
                    {isLoading ? t(loadingTxt) : children}
                </span>

                {iconRight && (
                    <AppSVGIcon
                        icon={iconRight}
                        size="sm"
                        className="mt-1"
                        disabled={props.disabled}
                    />
                )}

                {!!badge && (
                    <div className="btn-badge">
                        <span className="btn-badge--label">{badge}</span>
                    </div>
                )}
            </button>
        );
    },
);
