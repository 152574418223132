import { FC } from "react";
import { AppSVGIcon } from "../AppSVGIcon";
import { AppTooltip } from "../AppTooltip";
import { Col } from "react-bootstrap";

interface AppFormLabelProps {
    label?: string | JSX.Element;
    toolTipLabel?: string | JSX.Element;
    controlId?: string;
    required?: boolean;
    className?: string;
    counter?: number;
    maxCount?: number;
    isCard?: boolean;
}

export const AppFormLabel: FC<AppFormLabelProps> = ({
    label,
    toolTipLabel,
    controlId,
    required,
    className = "",
    counter = 0,
    maxCount,
    isCard,
}) => {
    if (!label) {
        return <></>;
    }

    return (
        <label className={`form-label ${className}`} htmlFor={controlId}>
            <div className="d-flex align-items-end gap-3">
                <div>
                    {label}
                    {required && <span className="required ms-2">*</span>}
                </div>

                {isCard && (
                    <div className="ms-auto d-flex gap-3">
                        <AppSVGIcon
                            icon={`card-mastercard`}
                            type="image"
                            size="xxl"
                        />

                        <AppSVGIcon
                            icon={`card-visa`}
                            type="image"
                            size="xxl"
                        />
                    </div>
                )}

                <Col
                    className={`d-flex justify-content-start ${
                        toolTipLabel == undefined ? "d-none" : ""
                    }`}
                >
                    <AppTooltip
                        id={label.toString()}
                        overlay={
                            <div className="map-tooltip">
                                <span className="sub-title">
                                    {toolTipLabel}
                                </span>
                            </div>
                        }
                    >
                        <AppSVGIcon icon="help" size="lg" />
                    </AppTooltip>
                </Col>

                {maxCount && (
                    <span
                        className={`counter ms-auto ${
                            counter > maxCount ? "max-limit" : ""
                        }`}
                    >{`(${counter}/${maxCount})`}</span>
                )}
            </div>
        </label>
    );
};
