import { FC, Fragment, useEffect, useState } from "react";
import { DraggableProvided, DropResult } from "react-beautiful-dnd";
import { useRecoilState, useResetRecoilState } from "recoil";
import { FormProvider, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { Link } from "react-router-dom";
import { Col, Row } from "react-bootstrap";

import {
    ActionPost,
    OptionType,
    PActionPost,
    PrimitiveObject,
    Tag,
    User,
} from "../../../models";
import {
    ActionPostTabEnum,
    MapItemEnum,
    MapPageEnum,
    ModalTypeEnum,
    TKey,
    errorToast,
    getRandomId,
    getTotalLevel,
    normalizeName,
    setBackendViolations,
    successToast,
    validation,
} from "../../../utils";
import {
    AppActionDropDown,
    AppAvatar,
    AppButton,
    AppCheckBox,
    AppConfirmModal,
    AppDatePicker,
    AppDisplayEditorContent,
    AppDraggableList,
    AppForm,
    AppFormContent,
    AppFormDatePicker,
    AppFormGroup,
    AppFormInput,
    AppFormLabel,
    AppFormModal,
    AppFormSelect,
    AppFormTextarea,
    AppFormUploader,
    AppInlineInput,
    AppSVGIcon,
    AppSlider,
    AppTabs,
    AppTooltip,
} from "../../../components";
import {
    useDateTime,
    useDownloadFile,
    useMapAction,
    useTranslation,
    useWorkspacePermission,
} from "../../../hooks";
import {
    atomActiveClient,
    atomDrawer,
    atomDrawerType,
    atomMapPortfolioFilter,
} from "../../../atoms";
import {
    ActionPostApi,
    ActionPostTagApi,
    ActionTaskApi,
    ClientApi,
    MapApi,
    UserApi,
} from "../../../apis";
import {
    ACTION_POST_DURATION,
    ACTION_POST_STATUS,
    DOCUMENT_TYPE,
    DocumentFileInfo,
    getActionPostsSize,
} from "../../../config";
import { schemaActionPost } from "./schema";
import { MapProgressBar, MapSizeSelector } from "../../components";

interface MapPortfolioActionPostsProps {
    actionPosts: ActionPost[];
    type: string;
    pageType: MapPageEnum;
    boardType?: string;
    onDragEnd: (result: DropResult) => void;
    actionTaskId?: number;
}

export const MapPortfolioActionPosts: FC<MapPortfolioActionPostsProps> = ({
    actionPosts,
    type,
    pageType,
    boardType,
    onDragEnd,
    actionTaskId,
}) => {
    // hooks
    const { t } = useTranslation();
    const { toShortDate, toDbDateFormat } = useDateTime();
    const { isWrite, isRead } = useWorkspacePermission();
    const { downloadLink } = useDownloadFile();

    // context
    const {
        map,
        responsibleUserOptions,
        actionCreateActionPost,
        actionUpdateActionPost,
        actionDeleteActionPost,
        fetchActionPostTags,
        actionPostTagsOptions,
    } = useMapAction();

    // atoms
    const [activeClient] = useRecoilState(atomActiveClient);
    const [appliedFilter] = useRecoilState(atomMapPortfolioFilter);
    const [drawer, setDrawer] = useRecoilState(
        atomDrawerType(ModalTypeEnum.ACTION_POST),
    );
    const resetDrawer = useResetRecoilState(atomDrawer);

    // state
    const [addId, setAddId] = useState(0);
    const [editId, setEditId] = useState(0);
    const [loading, setLoading] = useState(false);
    const [showConfirm, setShowConfirm] = useState(0);
    const [isUploading, setIsUploading] = useState(false);
    const [showInput, setShowInput] = useState(0);
    const [activeTab, setActiveTab] = useState(ActionPostTabEnum.EDIT);
    const [isOpenDatePicker, setIsOpenDatePicker] = useState(0);

    // local state
    const dragType = pageType === MapPageEnum.MAP_PORTFOLIO ? type : boardType; // don't change

    // form
    const methods = useForm({
        resolver: yupResolver(schemaActionPost(t)),
    });
    const {
        handleSubmit,
        formState,
        reset,
        setError,
        setValue,
        watch,
        trigger,
    } = methods;

    // local state
    const weightLevelValues = watch("weightLevelValues");
    const titleValue = watch("title");

    const onSubmitHandler = (formData: unknown) => {
        if (!activeClient || !map) {
            return;
        }

        const payload = editId
            ? (formData as PActionPost)
            : ActionPost.createForm(
                  ClientApi.toResourceUrl(activeClient.id),
                  MapApi.toResourceUrl(map.id),
                  ActionTaskApi.toResourceUrl(actionTaskId || 0), // handle for kan-ban page with 0 (only from portfolio this will be execute)
                  formData as PActionPost,
              );

        if (
            payload.additionalInfo &&
            !Object.keys(payload.additionalInfo).length
        ) {
            payload.additionalInfo = {
                goals: "",
                benefits: "",
                deliverables: "",
                workPackage: "",
                outOfScope: "",
                interface: "",
                personDaysInternal: null,
                personDaysExternal: null,
                cashOut: null,
            };
        }

        payload.tags = (payload.tags as unknown as OptionType[])?.map((x) =>
            x?.id
                ? x.value
                : {
                      name: x.label,
                      map: MapApi.toResourceUrl(map?.id),
                      client: ClientApi.toResourceUrl(activeClient.id),
                  },
        ) as unknown as Tag[];

        payload.weightLevelValues =
            map?.actionPostWeightLevels
                ?.filter((x) => x.per > 0)
                ?.map((x, i) => ({
                    per: payload.weightLevelValues?.[i]?.per ?? 0,
                })) || [];

        payload.documents = payload.documents?.map((x) => ({
            ...x,
            client: ClientApi.toResourceUrl(activeClient.id),
            type: DOCUMENT_TYPE.TYPE_ACTIONPOST,
        }));

        if (!editId) {
            payload.status = ACTION_POST_STATUS.ACTIONPOST_STATUS_BACKLOG;
        }

        if (payload.responsible === "") {
            payload.responsible = null;
        }

        if (payload.dueDate) {
            payload.dueDate = toDbDateFormat(payload.dueDate);
        }

        setLoading(true);

        ActionPostApi.createOrUpdate<ActionPost, PActionPost>(editId, payload)
            .then(({ response, errorMessage, isInvalid, error }) => {
                if (isInvalid) {
                    setBackendViolations(error, setError);
                } else if (errorMessage) {
                    errorToast(errorMessage);
                } else if (response !== null) {
                    successToast(t("app.actionPost.list:update.toast.success"));

                    if (editId) {
                        actionUpdateActionPost(
                            response.id,
                            response,
                            actionTaskId,
                        );
                    } else {
                        actionCreateActionPost(response, actionTaskId);
                    }

                    reset();
                    resetDrawer();
                    setEditId(0);
                    setAddId(0);
                }
            })
            .finally(() => setLoading(false));
    };

    const onCreate = () => {
        fetchActionPostTags({
            "client.id": activeClient?.id,
            "map.id": map?.id,
        });

        setEditId(0);
        const createId = getRandomId();
        setAddId(createId);
    };

    useEffect(() => {
        if (addId) {
            const timer = setTimeout(() => {
                reset({
                    title: undefined,
                    description: undefined,
                    dueDate: toDbDateFormat(new Date()),
                    responsible: undefined,
                    documents: [],
                    tags: [],
                    weightLevelValues: [],
                    additionalInfo: undefined,
                });

                setDrawer({ show: true, id: addId });
            }, 500);

            return () => {
                clearTimeout(timer);
            };
        }
    }, [addId]);

    const onEdit = (id: number) => {
        if (!isWrite || pageType !== MapPageEnum.MAP_PORTFOLIO) {
            return;
        }

        fetchActionPostTags({
            "client.id": activeClient?.id,
            "map.id": map?.id,
        });

        ActionPostApi.findById<ActionPost>(id).then(
            ({ errorMessage, response }) => {
                if (errorMessage) {
                    errorToast(errorMessage);
                } else if (response) {
                    reset({
                        title: response.title,
                        description: response.description,
                        dueDate: response.dueDate,
                        responsible: response.responsible
                            ? UserApi.toResourceUrl(
                                  (response.responsible as User).id,
                              )
                            : undefined,
                        documents: response.documents as never,
                        tags: response.tags.map((x) => ({
                            label: x.name,
                            value: ActionPostTagApi.toResourceUrl(x.id),
                            id: x.id,
                        })),
                        weightLevelValues: response.weightLevelValues,
                        additionalInfo: response.additionalInfo,
                    });

                    setEditId(response.id);
                    setDrawer({ show: true, id: response.id });
                    setAddId(0);
                }
            },
        );
    };

    const handleEdit = (id: number, item: PActionPost) => {
        ActionPostApi.update<ActionPost, PActionPost>(id, item).then(
            ({ response, errorMessage }) => {
                if (errorMessage) {
                    errorToast(errorMessage);
                } else if (response !== null) {
                    actionUpdateActionPost(response.id, response, actionTaskId);
                }
            },
        );
    };

    const handleDelete = (id: number) => {
        ActionPostApi.deleteById(id).then(({ errorMessage }) => {
            if (errorMessage) {
                errorToast(errorMessage);
            } else {
                actionDeleteActionPost(id, actionTaskId);
                setShowConfirm(0);
            }
        });
    };

    const handleClone = (id: number) => {
        ActionPostApi.clone<ActionPost, PrimitiveObject>({ cloneId: id }).then(
            ({ errorMessage, response }) => {
                if (errorMessage) {
                    errorToast(errorMessage);
                } else if (response !== null) {
                    actionCreateActionPost(response, actionTaskId);
                }
            },
        );
    };

    const handlePrint = (id: number, title: string) => {
        ActionPostApi.pdfDownload<ActionPost>(id).then(
            ({ errorMessage, response }) => {
                if (errorMessage) {
                    errorToast(errorMessage);
                } else if (response !== null) {
                    downloadLink({
                        name: `${normalizeName(title)}_${id}.pdf`,
                        type: "file/pdf",
                        file: response,
                    });
                }
            },
        );
    };

    useEffect(() => {
        setActiveTab(ActionPostTabEnum.EDIT);
    }, [drawer.show]);

    useEffect(() => {
        const subscription = watch((_, { name }) => {
            if (name) {
                trigger(name);
            }
        });
        return () => subscription.unsubscribe();
    }, [watch, trigger, formState.dirtyFields]);

    if (!map) {
        return <></>;
    }

    const renderTabs = () => {
        const { actionPostWeightLevels = [] } = map;

        return (
            <>
                <div hidden={activeTab !== ActionPostTabEnum.EDIT}>
                    <div className="d-flex flex-column gap-4">
                        <AppFormInput
                            id="title"
                            name="title"
                            label={t("app.actionPost.form:label.title")}
                            placeholder={t(
                                "app.actionPost.form:placeholder.title",
                            )}
                            block
                            maxCount={validation.portfolioAction.title.max}
                        />

                        <AppFormTextarea
                            id="description"
                            name="description"
                            label={t("app.actionPost.form:label.description")}
                            placeholder={t(
                                "app.actionPost.form:placeholder.description",
                            )}
                            required={false}
                            block
                            rows={5}
                            maxCount={validation.description.max}
                            isEditor
                        />

                        <AppFormDatePicker
                            id="dueDate"
                            name="dueDate"
                            label={t("app.actionPost.form:label.dueDate")}
                            placeholder={t(
                                "app.actionPost.form:placeholder.dueDate",
                            )}
                            block
                        />

                        <AppFormUploader
                            id="documents"
                            name="documents"
                            label={t("app.actionPost.form:label.documents")}
                            required={false}
                            block
                            fileInfo={DocumentFileInfo}
                            cbFileUploading={setIsUploading}
                            maxFiles={10}
                        />

                        <AppFormSelect
                            id="responsible"
                            name="responsible"
                            label={t("app.actionPost.form:label.responsible")}
                            placeholder={t(
                                "app.actionPost.form:placeholder.responsible",
                            )}
                            required={false}
                            block
                            options={[
                                {
                                    label: t(
                                        "app.actionPost.form:none.responsible",
                                    ),
                                    value: "",
                                },
                                ...responsibleUserOptions,
                            ]}
                            isClearable
                        />

                        <AppFormSelect
                            id="tags"
                            name="tags"
                            label={t("app.actionPost.form:label.tags")}
                            placeholder={t(
                                "app.actionPost.form:placeholder.tags",
                            )}
                            required={false}
                            block
                            options={actionPostTagsOptions}
                            isCreatable
                        />
                    </div>
                </div>

                {/* // TODO::(v2) change saved format with id later */}
                <div
                    className="valuation-slider-container"
                    hidden={activeTab !== ActionPostTabEnum.VALUATION}
                >
                    <div className="d-flex flex-column gap-4">
                        {actionPostWeightLevels
                            .filter((x) => x.per > 0)
                            .map((x, index) => (
                                <AppFormGroup block key={x.name}>
                                    <AppFormLabel
                                        controlId={`weightLevelValues[${index}].per`}
                                        label={x.name}
                                    />

                                    <AppSlider
                                        min={0}
                                        max={10}
                                        step={1}
                                        value={weightLevelValues?.[index]?.per}
                                        onChange={(val) =>
                                            setValue(
                                                `weightLevelValues.${index}.per`,
                                                val,
                                            )
                                        }
                                    />

                                    <div className="valuation-number mt-4">
                                        {new Array(11)
                                            .fill(null)
                                            .map((y, i) => (
                                                <Fragment key={y}>
                                                    {[0, 5, 10].includes(i) ? (
                                                        <span className="paragraph--100 font-weight--bold">
                                                            {i}
                                                        </span>
                                                    ) : (
                                                        <span className="valuation-number--separator mt-1">
                                                            {"-"}
                                                        </span>
                                                    )}
                                                </Fragment>
                                            ))}
                                    </div>
                                </AppFormGroup>
                            ))}
                    </div>
                </div>

                <div hidden={activeTab !== ActionPostTabEnum.DESCRIPTION}>
                    <div className="d-flex flex-column gap-4">
                        <AppFormTextarea
                            id="additionalInfo.goals"
                            name="additionalInfo.goals"
                            label={t(
                                "app.actionPost.form:label.additionalInfo.goals",
                            )}
                            toolTipLabel={t(
                                "app.actionPost.form:tooltip.additionalInfo.goals",
                            )}
                            placeholder={t(
                                "app.actionPost.form:placeholder.additionalInfo.goals",
                            )}
                            required={false}
                            block
                            rows={5}
                            maxCount={validation.description.max}
                            isEditor
                        />

                        <AppFormTextarea
                            id="additionalInfo.benefits"
                            name="additionalInfo.benefits"
                            label={t(
                                "app.actionPost.form:label.additionalInfo.benefits",
                            )}
                            toolTipLabel={t(
                                "app.actionPost.form:tooltip.additionalInfo.benefits",
                            )}
                            placeholder={t(
                                "app.actionPost.form:placeholder.additionalInfo.benefits",
                            )}
                            required={false}
                            block
                            rows={5}
                            maxCount={validation.description.max}
                            isEditor
                        />

                        <AppFormTextarea
                            id="additionalInfo.deliverables"
                            name="additionalInfo.deliverables"
                            label={t(
                                "app.actionPost.form:label.additionalInfo.deliverables",
                            )}
                            toolTipLabel={t(
                                "app.actionPost.form:tooltip.additionalInfo.deliverables",
                            )}
                            placeholder={t(
                                "app.actionPost.form:placeholder.additionalInfo.deliverables",
                            )}
                            required={false}
                            block
                            rows={5}
                            maxCount={validation.description.max}
                            isEditor
                        />

                        <AppFormTextarea
                            id="additionalInfo.workPackage"
                            name="additionalInfo.workPackage"
                            label={t(
                                "app.actionPost.form:label.additionalInfo.workPackage",
                            )}
                            placeholder={t(
                                "app.actionPost.form:placeholder.additionalInfo.workPackage",
                            )}
                            required={false}
                            block
                            rows={5}
                            maxCount={validation.description.max}
                            isEditor
                        />

                        <AppFormTextarea
                            id="additionalInfo.outOfScope"
                            name="additionalInfo.outOfScope"
                            label={t(
                                "app.actionPost.form:label.additionalInfo.outOfScope",
                            )}
                            placeholder={t(
                                "app.actionPost.form:placeholder.additionalInfo.outOfScope",
                            )}
                            required={false}
                            block
                            rows={5}
                            maxCount={validation.descriptionSecondary.max}
                            isEditor
                        />

                        <AppFormTextarea
                            id="additionalInfo.interface"
                            name="additionalInfo.interface"
                            label={t(
                                "app.actionPost.form:label.additionalInfo.interface",
                            )}
                            placeholder={t(
                                "app.actionPost.form:placeholder.additionalInfo.interface",
                            )}
                            required={false}
                            rows={5}
                            maxCount={validation.descriptionSecondary.max}
                            isEditor
                        />

                        <AppFormInput
                            type="number"
                            id="additionalInfo.personDaysInternal"
                            name="additionalInfo.personDaysInternal"
                            label={t(
                                "app.actionPost.form:label.additionalInfo.personDaysInternal",
                            )}
                            placeholder={t(
                                "app.actionPost.form:placeholder.additionalInfo.personDaysInternal",
                            )}
                            required={false}
                            block
                        />

                        <AppFormInput
                            type="number"
                            id="additionalInfo.personDaysExternal"
                            name="additionalInfo.personDaysExternal"
                            label={t(
                                "app.actionPost.form:label.additionalInfo.personDaysExternal",
                            )}
                            placeholder={t(
                                "app.actionPost.form:placeholder.additionalInfo.personDaysExternal",
                            )}
                            required={false}
                            block
                        />

                        <AppFormInput
                            type="number"
                            id="additionalInfo.cashOut"
                            name="additionalInfo.cashOut"
                            label={t(
                                "app.actionPost.form:label.additionalInfo.cashOut",
                            )}
                            placeholder={t(
                                "app.actionPost.form:placeholder.additionalInfo.cashOut",
                            )}
                            required={false}
                            block
                        />
                    </div>
                </div>
            </>
        );
    };

    const renderForm = () => (
        <FormProvider {...methods}>
            <AppForm className="map-portfolio-page--container--form">
                <AppTabs
                    className="mb-4"
                    items={[
                        {
                            title: "app.actionPost.form:tab.edit",
                            icon: "edit",
                            url: ActionPostTabEnum.EDIT,
                        },
                        {
                            title: "app.actionPost.form:tab.valuation",
                            icon: "sort",
                            url: ActionPostTabEnum.VALUATION,
                        },
                        {
                            title: "app.actionPost.form:tab.description",
                            icon: "description",
                            url: ActionPostTabEnum.DESCRIPTION,
                        },
                    ]}
                    onClick={(item) => {
                        if (item.url) {
                            setActiveTab(item.url as ActionPostTabEnum);
                        }
                    }}
                    isActiveTab={(item) => item?.url === activeTab}
                />

                <AppFormContent>{renderTabs()}</AppFormContent>
            </AppForm>
        </FormProvider>
    );

    const renderItem = (item: ActionPost, provided: DraggableProvided) => (
        <div
            className={`portfolio-container--item ${
                editId === item.id && editId === drawer.id ? "active-item" : ""
            } ${
                appliedFilter.filteredActionPostIds.includes(item.id)
                    ? "filter-item"
                    : ""
            } ${
                appliedFilter.filteredActionPostIds.length &&
                !appliedFilter.filteredActionPostIds.includes(item.id)
                    ? "un-filter-item"
                    : ""
            }`}
            ref={provided.innerRef}
            {...provided.draggableProps}
        >
            <div
                className="portfolio-container--item--action"
                onDoubleClick={() => onEdit(item.id)}
            >
                <div className="d-flex align-items-center gap-4">
                    <AppSVGIcon
                        icon="drag-n-drop"
                        {...provided.dragHandleProps}
                        hidden={!isWrite}
                    />

                    <div className="d-flex gap-3">
                        <span className="label--200">#{item.id}</span>

                        <MapSizeSelector
                            id={item.id}
                            currentValue={
                                getActionPostsSize().find(
                                    (x) => x.value === item.size,
                                )?.key
                            }
                            isGrantedControl={isWrite}
                            disabled={pageType !== MapPageEnum.MAP_PORTFOLIO}
                        >
                            <Row>
                                {getActionPostsSize().map((x) => (
                                    <Col xs={12} key={x.value} className="mb-2">
                                        <AppCheckBox
                                            id={`size-${x.value}`}
                                            name="size"
                                            checked={item.size === x.value}
                                            label={x.label}
                                            value={x.value}
                                            onChange={() => {
                                                handleEdit(item.id, {
                                                    size: x.value,
                                                });
                                            }}
                                        />
                                    </Col>
                                ))}
                            </Row>
                        </MapSizeSelector>
                    </div>
                </div>

                <div className="d-flex gap-1">
                    {item.linkActionPost && <AppSVGIcon icon="link" />}

                    {item.isTag && (
                        <AppSVGIcon
                            icon="tag"
                            onClick={() => onEdit(item.id)}
                            className="cursor-pointer"
                        />
                    )}

                    {item.documents?.length > 0 && (
                        <AppSVGIcon
                            icon="attach"
                            onClick={() => onEdit(item.id)}
                            className="cursor-pointer"
                        />
                    )}

                    <AppActionDropDown id={item.id} isGrantedControl={isWrite}>
                        {pageType === MapPageEnum.MAP_KANBAN &&
                            item.status ===
                                ACTION_POST_STATUS.ACTIONPOST_STATUS_COMPLETED && (
                                <li className="archive">
                                    <Link
                                        to={"#"}
                                        onClick={(e) => {
                                            e.preventDefault();
                                            handleEdit(item.id, {
                                                status: ACTION_POST_STATUS.ACTIONPOST_STATUS_ARCHIVE,
                                            });
                                        }}
                                    >
                                        <AppSVGIcon icon="exit" />
                                        {t("app.actionPost.item:label.archive")}
                                    </Link>
                                </li>
                            )}

                        <li className="print">
                            <Link
                                to={"#"}
                                onClick={(e) => {
                                    e.preventDefault();
                                    handlePrint(item.id, item.title);
                                }}
                            >
                                <AppSVGIcon icon="print" />
                                <span> {t(TKey.Common.Button.Print)}</span>
                            </Link>
                        </li>

                        <li className="clone">
                            <Link
                                to={"#"}
                                onClick={(e) => {
                                    e.preventDefault();
                                    handleClone(item.id);
                                }}
                            >
                                <AppSVGIcon icon="clone" />
                                <span>{t(TKey.Common.Button.Clone)}</span>
                            </Link>
                        </li>

                        {isWrite && pageType === MapPageEnum.MAP_PORTFOLIO && (
                            <li className="edit">
                                <Link
                                    to={"#"}
                                    onClick={(e) => {
                                        e.preventDefault();
                                        onEdit(item.id);
                                    }}
                                >
                                    <AppSVGIcon icon="edit" />
                                    <span>{t(TKey.Common.Button.Edit)}</span>
                                </Link>
                            </li>
                        )}

                        <li className="delete">
                            <Link
                                to={"#"}
                                onClick={(e) => {
                                    e.preventDefault();
                                    setShowConfirm(item.id);
                                }}
                            >
                                <AppSVGIcon icon="delete" />
                                <span>{t(TKey.Common.Button.Delete)}</span>
                            </Link>
                        </li>
                    </AppActionDropDown>
                </div>
            </div>

            <div className="portfolio-container--item--content">
                <div
                    className="detail"
                    onDoubleClick={() => setShowInput(item.id)}
                >
                    <AppInlineInput
                        size="xs"
                        show={showInput === item.id && isWrite}
                        initialText={item.title}
                        onHide={() => setShowInput(0)}
                        onUpdate={(text) =>
                            handleEdit(item.id, { title: text })
                        }
                        maxLength={validation.portfolioAction.title.max}
                        inputType="area"
                        textAreaSize={item.responsible ? "medium" : "large"}
                    >
                        <AppTooltip
                            id={item.title}
                            overlay={
                                <span className="paragraph--400">
                                    {item.title}
                                </span>
                            }
                        >
                            <span
                                className={`title ${
                                    !item.description
                                        ? "title--full-height"
                                        : ""
                                } ${
                                    !item.description && item.responsible
                                        ? "title--full-height--with-responsible"
                                        : ""
                                }`}
                            >
                                {item.title}
                            </span>
                        </AppTooltip>

                        <AppDisplayEditorContent
                            className="description"
                            content={item.description}
                        />
                    </AppInlineInput>
                </div>

                {pageType === MapPageEnum.MAP_KANBAN &&
                    item.status ===
                        ACTION_POST_STATUS.ACTIONPOST_STATUS_INPROGRESS && (
                        <MapProgressBar
                            id={item.id}
                            value={item.progressLevel ? +item.progressLevel : 0}
                            onChange={(value) => {
                                handleEdit(item.id, {
                                    progressLevel: value,
                                });
                            }}
                        />
                    )}

                <div className="info">
                    {item.responsible && (
                        <AppAvatar user={item.responsible as User} withDetail />
                    )}

                    <div className="info-date-with-average">
                        <div className="due-date">
                            <AppSVGIcon icon="calendar" className="me-2" />

                            {isOpenDatePicker === item.id && isWrite ? (
                                <AppDatePicker
                                    id="due-date"
                                    name="due-date"
                                    defaultValue={toShortDate(item.dueDate)}
                                    handleOnChange={(date) => {
                                        handleEdit(item.id, {
                                            dueDate: toDbDateFormat(date),
                                        });
                                        setIsOpenDatePicker(0);
                                    }}
                                />
                            ) : (
                                <span
                                    onClick={() => {
                                        if (
                                            pageType ===
                                            MapPageEnum.MAP_PORTFOLIO
                                        ) {
                                            setIsOpenDatePicker(item.id);
                                        }
                                    }}
                                >
                                    {toShortDate(item.dueDate)}
                                </span>
                            )}
                        </div>

                        <div className="average-valuation">
                            <span className="title">
                                {getTotalLevel(item.totalLevel)}
                            </span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );

    return (
        <>
            <AppConfirmModal
                show={!!showConfirm}
                icon="warning"
                title="app.actionPost.item:delete.confirm.modal.title"
                description="app.actionPost.item:delete.confirm.modal.description"
                onHide={() => setShowConfirm(0)}
                nextAction={() => handleDelete(showConfirm)}
            />

            <AppFormModal
                show={
                    drawer.show &&
                    ((!!editId && drawer.id === editId) ||
                        (!!addId && drawer.id === addId))
                }
                icon="description"
                // title="app.actionPost.form:modal.title"
                title={titleValue}
                isDrawer
                onSubmit={handleSubmit(onSubmitHandler)}
                onHide={() => {
                    resetDrawer();
                    setEditId(0);
                    setAddId(0);
                }}
                isLoading={formState.isSubmitting || loading}
                disabled={!activeClient || isUploading || isRead}
                disabledSumbit={!formState.isValid}
                isEditMode={!!editId}
            >
                {renderForm()}
            </AppFormModal>

            <AppDraggableList<ActionPost>
                withoutContext
                droppableId={`action_task-${dragType}-${actionTaskId}`}
                type={MapItemEnum.ACTION_POST}
                data={actionPosts}
                onDragEnd={onDragEnd}
                renderItem={renderItem}
                renderWrapper={(children, providedMain, snapshot) => (
                    <div
                        className={`portfolio-container ${
                            snapshot.isDraggingOver
                                ? "draggable-container--dropable"
                                : ""
                        }`}
                        ref={providedMain.innerRef}
                        {...providedMain.droppableProps}
                    >
                        {isWrite &&
                            type ===
                                ACTION_POST_DURATION.ACTIONPOST_DURATION_SHORT &&
                            pageType === MapPageEnum.MAP_PORTFOLIO && (
                                <AppButton
                                    variant="secondary"
                                    className="btn-square btn-sm"
                                    onClick={onCreate}
                                >
                                    <AppSVGIcon icon="plus" />
                                </AppButton>
                            )}

                        {children}
                    </div>
                )}
            />
        </>
    );
};
