import * as yup from "yup";
import { TKey } from "../../../utils";

export const schema = (t: (val: string) => string, isCardAdd: boolean) =>
    yup.object().shape({
        name: yup.string().optional(),
        number: yup.string().when([], {
            is: () => isCardAdd,
            then: (sc) =>
                sc.required(t(TKey.Common.Message.Validation.RequiredField)),
            otherwise: (sc) => sc.optional(),
        }),
        exp_month: yup
            .number()
            .when([], {
                is: () => isCardAdd,
                then: (sc) =>
                    sc.required(
                        t(TKey.Common.Message.Validation.RequiredField),
                    ),
                otherwise: (sc) => sc.optional(),
            })
            .positive()
            .transform((value) => value || 0),
        exp_year: yup
            .number()
            .when([], {
                is: () => isCardAdd,
                then: (sc) =>
                    sc.required(
                        t(TKey.Common.Message.Validation.RequiredField),
                    ),
                otherwise: (sc) => sc.optional(),
            })
            .positive()
            .transform((value) => value || 0),
        cvc: yup.string().when([], {
            is: () => isCardAdd,
            then: (sc) =>
                sc
                    .required(t(TKey.Common.Message.Validation.RequiredField))
                    .max(4),
            otherwise: (sc) => sc.optional(),
        }),
    });
