import { BaseEntity } from "./BaseEntity";
import { Client } from "./Client";
import { Map } from "./Map";
import { SubTask } from "./SubTask";

export class Task extends BaseEntity {
    client: string | Client;

    map: string | Map;

    name: string;

    ord: number;

    subTasks?: SubTask[];

    constructor(
        client: string | Client,
        map: string | Map,
        { name = "", ord = 1 }: PTask = {},
    ) {
        super();
        this.client = client;
        this.map = map;
        this.name = name;
        this.ord = ord;
    }

    toJSON(addExtraData = false) {
        return {
            ...super.toJSON(addExtraData),
            client: this.client,
            map: this.map,
            name: this.name,
        };
    }

    static createForm(client: string, map: string): PTask {
        return new Task(client, map).toJSON();
    }
}

export type PTask = Partial<Task>;
