import React, { FC, useState } from "react";

import { AppTabs } from "../../../components";
import { ACTION_POST_DURATION } from "../../../config";
import { isString } from "../../../utils";

interface MapDurationTabsProps {
    onChange: (val: string) => void;
}

export const MapDurationTabs: FC<MapDurationTabsProps> = ({
    onChange,
}): JSX.Element => {
    // state
    const [activeTab, setActiveTab] = useState("");

    return (
        <AppTabs
            className="mt-4"
            items={[
                {
                    title: "app.map.dashboard:tab.all",
                    url: "",
                },
                {
                    title: "app.map.dashboard:tab.short",
                    url: ACTION_POST_DURATION.ACTIONPOST_DURATION_SHORT,
                },
                {
                    title: "app.map.dashboard:tab.medium",
                    url: ACTION_POST_DURATION.ACTIONPOST_DURATION_MEDIUM,
                },
                {
                    title: "app.map.dashboard:tab.long",
                    url: ACTION_POST_DURATION.ACTIONPOST_DURATION_LONG,
                },
            ]}
            onClick={(item) => {
                if (isString(item.url)) {
                    setActiveTab(item.url);
                    onChange(item.url);
                }
            }}
            isActiveTab={(item) => item.url === activeTab}
        />
    );
};
