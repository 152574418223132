export enum SubscriptionActionEnum {
    SHOW_LOADING,
    HIDE_LOADING,
    SET_MY_CLIENT,
    REFETCH_MY_CLIENT,
    FETCH_CURRENT_INVOICE,
    INVOICE_CREATE,
    FETCH_CARDS,
    INVOICE_PAY,
}
