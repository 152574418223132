import { useContext } from "react";
import { useResetRecoilState } from "recoil";

import { MapContext, MapState } from "../contexts";
import {
    ActionPost,
    ActionTask,
    Map,
    OptionType,
    SubTask,
    Tag,
    Task,
    TaskPost,
    User,
} from "../models";
import { MapActionEnum, errorToast, getUserFullName } from "../utils";
import { ActionPostTagApi, TaskPostTagApi, UserApi } from "../apis";
import { atomMapDetailFilter, atomMapPortfolioFilter } from "../atoms";
import { EVENTS, emitter } from "../Socket/Socket";
import { useAuthState } from "./useAuthState";

interface MapActionType extends MapState {
    actionSetMap: (payload: Map) => void;
    actionSetMapDetail: (payload: Map) => void;
    actionSetMapPortfolio: (payload: Map) => void;
    actionSetMapKanBan: (payload: ActionPost[]) => void;
    actionReSetMap: () => void;
    actionChangeTaskOrder: (
        source: number,
        destination: number,
        isEmit?: boolean,
    ) => void;
    actionChangeSubTaskOrder: (
        taskId: number,
        source: number,
        destination: number,
        oldTaskId?: number,
        isEmit?: boolean,
    ) => void;
    actionChangeTaskPostOrder: (
        taskId: number,
        subTaskId: number,
        source: number,
        destination: number,
        oldTaskId?: number,
        oldSubTaskId?: number,
        isEmit?: boolean,
    ) => void;
    actionChangeActionTaskOrder: (
        source: number,
        destination: number,
        isEmit?: boolean,
    ) => void;
    actionChangeActionPostOrder: (
        source: number,
        destination: number,
        actionTaskId?: number,
        oldActionTaskId?: number,
    ) => void;
    actionCreateTask: (item: Task, isEmit?: boolean) => void;
    actionUpdateTask: (id: number, name: string, isEmit?: boolean) => void;
    actionDeleteTask: (id: number, isEmit?: boolean) => void;
    actionCreateSubTask: (
        taskId: number,
        item: SubTask,
        isEmit?: boolean,
    ) => void;
    actionUpdateSubTask: (
        taskId: number,
        id: number,
        name: string,
        isEmit?: boolean,
    ) => void;
    actionDeleteSubTask: (taskId: number, id: number, isEmit?: boolean) => void;
    actionCreateTaskPost: (
        taskId: number,
        subTaskId: number,
        item: TaskPost,
        isEmit?: boolean,
    ) => void;
    actionUpdateTaskPost: (
        taskId: number,
        subTaskId: number,
        id: number,
        item: TaskPost,
        isEmit?: boolean,
    ) => void;
    actionDeleteTaskPost: (
        taskId: number,
        subTaskId: number,
        id: number,
        isEmit?: boolean,
    ) => void;
    actionCreateActionTask: (item: ActionTask, isEmit?: boolean) => void;
    actionUpdateActionTask: (
        id: number,
        item: ActionTask,
        isEmit?: boolean,
    ) => void;
    actionDeleteActionTask: (id: number, isEmit?: boolean) => void;
    actionCreateActionPost: (item: ActionPost, actionTaskId?: number) => void;
    actionUpdateActionPost: (
        id: number,
        item: ActionPost,
        actionTaskId?: number,
    ) => void;
    actionDeleteActionPost: (id: number, actionTaskId?: number) => void;
    fetchResponsibleUsers: (workspaceResourceId: string) => void;
    fetchTaskPostTags: (params?: object) => void;
    fetchActionPostTags: (params?: object) => void;
}

export function useMapAction(): MapActionType {
    // hooks
    const resetMapDetailFilter = useResetRecoilState(atomMapDetailFilter);
    const resetMapPortfolioFilter = useResetRecoilState(atomMapPortfolioFilter);

    // contexts
    const context = useContext(MapContext);
    if (!context) {
        throw new Error("useMapContext must be used within a MapProvider");
    }
    const { state, dispatch } = context;

    const { user } = useAuthState();

    const actionSetMap = (payload: Map) => {
        dispatch({
            type: MapActionEnum.SET_MAP,
            payload,
        });
    };

    const actionSetMapDetail = (payload: Map) => {
        dispatch({
            type: MapActionEnum.SET_MAP_DETAIL,
            payload,
        });
    };

    const actionSetMapPortfolio = (payload: Map) => {
        dispatch({
            type: MapActionEnum.SET_MAP_PORTFOLIO,
            payload,
        });
    };

    const actionSetMapKanBan = (payload: ActionPost[]) => {
        dispatch({
            type: MapActionEnum.SET_MAP_KANBAN,
            payload,
        });
    };

    const actionReSetMap = () => {
        resetMapDetailFilter();
        resetMapPortfolioFilter();

        dispatch({
            type: MapActionEnum.RESET_MAP,
        });
    };

    const actionChangeTaskOrder = (
        source: number,
        destination: number,
        isEmit = true,
    ) => {
        dispatch({
            type: MapActionEnum.CHANGE_TASK_ORDER,
            payload: {
                source,
                destination,
            },
        });
        if (isEmit) {
            emitter(EVENTS.MAP_ORDER_TASK, {
                mapId: state?.map?.id,
                user,
                source,
                destination,
            });
        }
    };

    const actionChangeSubTaskOrder = (
        taskId: number,
        source: number,
        destination: number,
        oldTaskId?: number,
        isEmit = true,
    ) => {
        dispatch({
            type: MapActionEnum.CHANGE_SUB_TASK_ORDER,
            payload: {
                taskId,
                source,
                destination,
                oldTaskId,
            },
        });
        if (isEmit) {
            emitter(EVENTS.MAP_ORDER_SUB_TASK, {
                mapId: state?.map?.id,
                user,
                taskId,
                source,
                destination,
                oldTaskId,
            });
        }
    };

    const actionChangeTaskPostOrder = (
        taskId: number,
        subTaskId: number,
        source: number,
        destination: number,
        oldTaskId?: number,
        oldSubTaskId?: number,
        isEmit = true,
    ) => {
        dispatch({
            type: MapActionEnum.CHANGE_TASK_POST_ORDER,
            payload: {
                taskId,
                subTaskId,
                source,
                destination,
                oldTaskId,
                oldSubTaskId,
            },
        });
        if (isEmit) {
            emitter(EVENTS.MAP_ORDER_TASK_POST, {
                mapId: state?.map?.id,
                user,
                taskId,
                subTaskId,
                source,
                destination,
                oldTaskId,
                oldSubTaskId,
            });
        }
    };

    const actionChangeActionTaskOrder = (
        source: number,
        destination: number,
        isEmit = true,
    ) => {
        dispatch({
            type: MapActionEnum.CHANGE_ACTION_TASK_ORDER,
            payload: {
                source,
                destination,
            },
        });
        if (isEmit) {
            emitter(EVENTS.MAP_ORDER_ACTION_TASK, {
                mapId: state?.map?.id,
                user,
                source,
                destination,
            });
        }
    };

    const actionChangeActionPostOrder = (
        source: number,
        destination: number,
        actionTaskId?: number,
        oldActionTaskId?: number,
    ) => {
        dispatch({
            type: MapActionEnum.CHANGE_ACTION_POST_ORDER,
            payload: {
                source,
                destination,
                actionTaskId,
                oldActionTaskId,
            },
        });
    };

    const actionCreateTask = (item: Task, isEmit = true) => {
        dispatch({
            type: MapActionEnum.CREATE_TASK,
            payload: item,
        });
        if (isEmit) {
            emitter(EVENTS.MAP_CREATE_TASK, {
                mapId: state?.map?.id,
                user,
                task: item,
            });
        }
    };

    const actionUpdateTask = (id: number, name: string, isEmit = true) => {
        dispatch({
            type: MapActionEnum.UPDATE_TASK,
            payload: { id, name },
        });
        if (isEmit) {
            emitter(EVENTS.MAP_UPDATE_TASK, {
                mapId: state?.map?.id,
                user,
                taskId: id,
                taskName: name,
            });
        }
    };

    const actionDeleteTask = (id: number, isEmit = true) => {
        dispatch({
            type: MapActionEnum.DELETE_TASK,
            payload: id,
        });
        if (isEmit) {
            emitter(EVENTS.MAP_DELETE_TASK, {
                mapId: state?.map?.id,
                user,
                taskId: id,
            });
        }
    };

    const actionCreateSubTask = (
        taskId: number,
        item: SubTask,
        isEmit = true,
    ) => {
        dispatch({
            type: MapActionEnum.CREATE_SUB_TASK,
            payload: { taskId, item },
        });
        if (isEmit) {
            emitter(EVENTS.MAP_CREATE_SUB_TASK, {
                mapId: state?.map?.id,
                user,
                taskId,
                subTask: item,
            });
        }
    };

    const actionUpdateSubTask = (
        taskId: number,
        id: number,
        name: string,
        isEmit = true,
    ) => {
        dispatch({
            type: MapActionEnum.UPDATE_SUB_TASK,
            payload: { taskId, id, name },
        });
        if (isEmit) {
            emitter(EVENTS.MAP_UPDATE_SUB_TASK, {
                mapId: state?.map?.id,
                user,
                taskId,
                subTaskId: id,
                subTaskName: name,
            });
        }
    };

    const actionDeleteSubTask = (taskId: number, id: number, isEmit = true) => {
        dispatch({
            type: MapActionEnum.DELETE_SUB_TASK,
            payload: { taskId, id },
        });
        if (isEmit) {
            emitter(EVENTS.MAP_DELETE_SUB_TASK, {
                mapId: state?.map?.id,
                user,
                taskId,
                subTaskId: id,
            });
        }
    };

    const actionCreateTaskPost = (
        taskId: number,
        subTaskId: number,
        item: TaskPost,
        isEmit = true,
    ) => {
        dispatch({
            type: MapActionEnum.CREATE_TASK_POST,
            payload: { taskId, subTaskId, item },
        });
        if (isEmit) {
            emitter(EVENTS.MAP_CREATE_TASK_POST, {
                mapId: state?.map?.id,
                user,
                taskId,
                subTaskId,
                taskPost: item,
            });
        }
    };

    const actionUpdateTaskPost = (
        taskId: number,
        subTaskId: number,
        id: number,
        item: TaskPost,
        isEmit = true,
    ) => {
        dispatch({
            type: MapActionEnum.UPDATE_TASK_POST,
            payload: { taskId, subTaskId, id, item },
        });
        if (isEmit) {
            emitter(EVENTS.MAP_UPDATE_TASK_POST, {
                mapId: state?.map?.id,
                user,
                taskId,
                subTaskId,
                taskPostId: id,
                taskPost: item,
            });
        }
    };

    const actionDeleteTaskPost = (
        taskId: number,
        subTaskId: number,
        id: number,
        isEmit = true,
    ) => {
        dispatch({
            type: MapActionEnum.DELETE_TASK_POST,
            payload: { taskId, subTaskId, id },
        });
        if (isEmit) {
            emitter(EVENTS.MAP_DELETE_TASK_POST, {
                mapId: state?.map?.id,
                user,
                taskId,
                subTaskId,
                taskPostId: id,
            });
        }
    };

    const actionCreateActionTask = (item: ActionTask, isEmit = true) => {
        dispatch({
            type: MapActionEnum.CREATE_ACTION_TASK,
            payload: item,
        });
        if (isEmit) {
            emitter(EVENTS.MAP_CREATE_ACTION_TASK, {
                mapId: state?.map?.id,
                user,
                actionTask: item,
            });
        }
    };

    const actionUpdateActionTask = (
        id: number,
        item: ActionTask,
        isEmit = true,
    ) => {
        dispatch({
            type: MapActionEnum.UPDATE_ACTION_TASK,
            payload: { id, item },
        });
        if (isEmit) {
            emitter(EVENTS.MAP_UPDATE_ACTION_TASK, {
                mapId: state?.map?.id,
                user,
                actionTaskId: id,
                actionTask: item,
            });
        }
    };

    const actionDeleteActionTask = (id: number, isEmit = true) => {
        dispatch({
            type: MapActionEnum.DELETE_ACTION_TASK,
            payload: id,
        });
        if (isEmit) {
            emitter(EVENTS.MAP_DELETE_ACTION_TASK, {
                mapId: state?.map?.id,
                user,
                actionTaskId: id,
            });
        }
    };

    const actionCreateActionPost = (
        item: ActionPost,
        actionTaskId?: number,
    ) => {
        dispatch({
            type: MapActionEnum.CREATE_ACTION_POST,
            payload: { item, actionTaskId },
        });
    };

    const actionUpdateActionPost = (
        id: number,
        item: ActionPost,
        actionTaskId?: number,
    ) => {
        dispatch({
            type: MapActionEnum.UPDATE_ACTION_POST,
            payload: { id, item, actionTaskId },
        });
    };

    const actionDeleteActionPost = (id: number, actionTaskId?: number) => {
        dispatch({
            type: MapActionEnum.DELETE_ACTION_POST,
            payload: { id, actionTaskId },
        });
    };

    const actionSetResponsibleUserOptions = (payload: OptionType[]) => {
        dispatch({
            type: MapActionEnum.SET_RESPONSIBLE_USER_OPTIONS,
            payload,
        });
    };

    const fetchResponsibleUsers = (workspaceResourceId: string) => {
        UserApi.getForResponsible<User>(1, {
            pagination: false,
            "workspacePermissions.workspace.id": workspaceResourceId,
        }).then(({ response, errorMessage }) => {
            if (errorMessage) {
                errorToast(errorMessage);
            } else if (response !== null) {
                actionSetResponsibleUserOptions(
                    response.items.map((item) => ({
                        label: getUserFullName(item),
                        value: UserApi.toResourceUrl(item.id),
                    })),
                );
            }
        });
    };

    const actionSetTaskPostTags = (payload: OptionType[]) => {
        dispatch({
            type: MapActionEnum.SET_TASK_POST_TAGS,
            payload,
        });
    };

    const fetchTaskPostTags = (params?: object) => {
        TaskPostTagApi.find<Tag>(1, {
            ...params,
            pagination: false,
        }).then(({ response, errorMessage }) => {
            if (errorMessage) {
                errorToast(errorMessage);
            } else if (response !== null) {
                actionSetTaskPostTags(
                    response.items.map((item) => ({
                        label: item.name,
                        value: TaskPostTagApi.toResourceUrl(item.id),
                        id: item.id,
                    })),
                );
            }
        });
    };

    const actionSetActionPostTags = (payload: OptionType[]) => {
        dispatch({
            type: MapActionEnum.SET_ACTION_POST_TAGS,
            payload,
        });
    };

    const fetchActionPostTags = (params?: object) => {
        ActionPostTagApi.find<Tag>(1, {
            ...params,
            pagination: false,
        }).then(({ response, errorMessage }) => {
            if (errorMessage) {
                errorToast(errorMessage);
            } else if (response !== null) {
                actionSetActionPostTags(
                    response.items.map((item) => ({
                        label: item.name,
                        value: ActionPostTagApi.toResourceUrl(item.id),
                        id: item.id,
                    })),
                );
            }
        });
    };

    return {
        ...state,
        actionSetMap,
        actionSetMapDetail,
        actionSetMapPortfolio,
        actionSetMapKanBan,
        actionReSetMap,
        actionChangeTaskOrder,
        actionChangeSubTaskOrder,
        actionChangeTaskPostOrder,
        actionChangeActionTaskOrder,
        actionChangeActionPostOrder,
        actionCreateTask,
        actionUpdateTask,
        actionDeleteTask,
        actionCreateSubTask,
        actionUpdateSubTask,
        actionDeleteSubTask,
        actionCreateTaskPost,
        actionUpdateTaskPost,
        actionDeleteTaskPost,
        actionCreateActionTask,
        actionUpdateActionTask,
        actionDeleteActionTask,
        actionCreateActionPost,
        actionUpdateActionPost,
        actionDeleteActionPost,
        fetchResponsibleUsers,
        fetchTaskPostTags,
        fetchActionPostTags,
    };
}
