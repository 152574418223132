import Axios, { AxiosError, Canceler } from "axios";

import { API } from "./API";
import {
    onFindAllResponse,
    route,
    EntityNotFoundErrorResponse,
    FinalResponse,
    ListResponse,
    ServerError,
    onAddEditErrorResponse,
    CancelRequestErrorResponse,
} from "../../config";

export abstract class EntityAPI extends API {
    protected static GET_COLLECTION = "/";

    protected static POST_COLLECTION = "/";

    protected static GET_ITEM = "/";

    protected static PUT_ITEM = "/";

    protected static PATCH_ITEM = "/";

    protected static DELETE_ITEM = "/";

    public static async find<R>(
        page = 1,
        extraParams = {},
        cancelToken?: (c: Canceler) => void,
    ): Promise<FinalResponse<ListResponse<R> | null>> {
        // const controller = this.createAbortController();
        const source = this.createCancelTokenSource();

        // if (cancelToken) {
        //     cancelToken(controller.abort);
        // }
        if (cancelToken) {
            cancelToken(source.cancel);
        }

        return this.makeGet<R>(
            this.GET_COLLECTION,
            {
                ...extraParams,
                page,
            },
            {
                // signal: controller.signal,
                cancelToken: source.token,
            },
        )
            .then(({ data }) => this.handleListResponse<R>(data))
            .catch((e) => this.handleAllError(e));
    }

    public static async create<R, P>(
        payload: P,
    ): Promise<FinalResponse<R | null>> {
        return this.makePost<R, P>(this.POST_COLLECTION, payload)
            .then(({ data }) => this.handleResponse<R>(data))
            .catch((e) => this.handleAllError(e));
    }

    public static async findById<R>(
        id: number,
        extraParams = {},
    ): Promise<FinalResponse<R | null>> {
        const path = route(this.GET_ITEM, { id });

        return this.makeGet<R>(path, { ...extraParams })
            .then(({ data }) => this.handleResponse<R>(data))
            .catch((e) => this.handleAllError(e));
    }

    public static async replace<R, P>(
        id: number,
        payload: P,
    ): Promise<FinalResponse<R | null>> {
        return this.makePut<R, P>(route(this.PUT_ITEM, { id }), payload)
            .then(({ data }) => this.handleResponse<R>(data))
            .catch((e) => this.handleAllError(e));
    }

    public static async update<R, P>(
        id: number,
        payload: P,
    ): Promise<FinalResponse<R | null>> {
        return this.makePatch<R, P>(
            route(this.PATCH_ITEM, { id }),
            JSON.stringify(payload),
        )
            .then(({ data }) => this.handleResponse<R>(data))
            .catch((e) => this.handleAllError(e));
    }

    public static async deleteById(id: number): Promise<FinalResponse<null>> {
        return this.makeDelete(route(this.DELETE_ITEM, { id }))
            .then(() => this.handleResponse(null))
            .catch((e) => this.handleAllError(e));
    }

    public static async createOrUpdate<R, P>(
        id: number | undefined,
        payload: P,
    ): Promise<FinalResponse<R | null>> {
        if (id) {
            return this.update<R, P>(id, payload);
        }
        return this.create<R, P>(payload);
    }

    public static async createOrReplace<R, P>(
        id: number | undefined,
        payload: P,
    ): Promise<FinalResponse<R | null>> {
        if (id) {
            return this.replace<R, P>(id, payload);
        }
        return this.create<R, P>(payload);
    }

    // Error Handler
    protected static handleServerError(
        error: AxiosError | ServerError,
    ): Promise<FinalResponse<null>> {
        const { message } = error;
        if (error instanceof ServerError) {
            return Promise.resolve(new FinalResponse(null, message));
        }

        return Promise.reject(error);
    }

    protected static handleCancelApiError(
        error: AxiosError | ServerError,
    ): Promise<FinalResponse<null>> {
        if (Axios.isCancel(error)) {
            return Promise.resolve(
                new FinalResponse(null, new CancelRequestErrorResponse()),
            );
        }

        return Promise.reject(error);
    }

    protected static handleNotFoundError(
        error: AxiosError | ServerError,
    ): Promise<FinalResponse<null>> {
        const { response } = error as AxiosError;

        if (response) {
            const { status } = response;
            if (status === 404) {
                return Promise.resolve(
                    new FinalResponse(null, new EntityNotFoundErrorResponse()),
                );
            }
        }

        return Promise.reject(error);
    }

    protected static handleErrorDuringCreatingOrUpdating(
        error: AxiosError | ServerError,
    ): Promise<FinalResponse<null>> {
        const { message } = error;

        if (error instanceof ServerError) {
            return Promise.resolve(new FinalResponse(null, message));
        }

        const { response } = error as AxiosError;
        if (response) {
            const { status, data } = response;
            if (status === 422) {
                return Promise.resolve(
                    new FinalResponse(
                        null,
                        onAddEditErrorResponse(data, this.acceptHydra),
                    ),
                );
            }
        }

        return Promise.reject(error);
    }

    protected static handleUnknownError(
        error: AxiosError | ServerError,
    ): Promise<FinalResponse<null>> {
        const { message } = error;
        return Promise.resolve(new FinalResponse(null, message));
    }

    protected static handleAllError(
        error: AxiosError | ServerError,
    ): Promise<FinalResponse<null>> {
        const { message } = error;

        if (error instanceof ServerError) {
            return Promise.resolve(new FinalResponse(null, message));
        }

        if (Axios.isCancel(error)) {
            return Promise.resolve(
                new FinalResponse(null, new CancelRequestErrorResponse()),
            );
        }

        const { response } = error as AxiosError;
        if (response) {
            const { status, data } = response;

            // not found
            if (status === 404) {
                return Promise.resolve(
                    new FinalResponse(null, new EntityNotFoundErrorResponse()),
                );
            }

            // validation error
            if (status === 422) {
                return Promise.resolve(
                    new FinalResponse(
                        null,
                        onAddEditErrorResponse(data, this.acceptHydra),
                    ),
                );
            }
        }

        return Promise.resolve(new FinalResponse(null, error));
    }

    // Response Handler
    protected static handleResponse<R>(data: R): Promise<FinalResponse<R>> {
        return Promise.resolve(new FinalResponse<R>(data));
    }

    protected static handleListResponse<R>(
        data: R,
    ): Promise<FinalResponse<ListResponse<R>>> {
        const list = onFindAllResponse<R>(data, this.acceptHydra);

        return Promise.resolve(new FinalResponse<ListResponse<R>>(list));
    }

    public static toResourceUrl(id: number): string {
        return route(this.GET_ITEM, { id });
    }
}
