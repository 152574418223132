import { FC, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Col, Row } from "react-bootstrap";

import "./assets/scss/style.scss";

import { ActionPostApi, MapApi } from "../../../apis";
import { DashboardStatistics, Map, PrimitiveObject } from "../../../models";
import { MapPageEnum, errorToast } from "../../../utils";
import { AppLoader } from "../../../components";
import {
    MapPageHeader,
    MapDashboardProgressCard,
    MapDashboardDocumented,
    MapDashboardAssignedResponsibilities,
    MapDashboardThemeCard,
} from "../../components";
import { useMapAction } from "../../../hooks";

export const MapDashboardPage: FC = () => {
    // hooks
    const { id } = useParams();

    // context
    const { map, actionSetMap, actionReSetMap } = useMapAction();

    // state
    const [loading, setLoading] = useState(false);
    const [statistics, setStatistics] = useState<DashboardStatistics>();

    const fetchDashboard = async () => {
        actionReSetMap();

        if (!id) {
            return;
        }

        setLoading(true);

        MapApi.findById<Map>(+id).then(({ errorMessage, response }) => {
            if (errorMessage) {
                errorToast(errorMessage);
            } else if (response) {
                actionSetMap(response);
            }
        });

        ActionPostApi.getDashboardStatistics<
            DashboardStatistics,
            PrimitiveObject
        >({
            map: MapApi.toResourceUrl(+id),
        })
            .then(({ response, errorMessage }) => {
                if (errorMessage) {
                    errorToast(errorMessage);
                } else if (response !== null) {
                    setStatistics(response);
                }
            })
            .finally(() => setLoading(false));
    };

    useEffect(() => {
        fetchDashboard();
    }, [id]);

    if (loading) {
        return <AppLoader isFullScreen />;
    }

    if (!map || !statistics) {
        return <></>;
    }

    // local state
    const {
        byTask: [taskStatistics],
        byActionTask,
        byResponsible,
    } = statistics;

    return (
        <div className="map-dashboard-page">
            <MapPageHeader map={map} pageType={MapPageEnum.MAP_DASHBOARD} />

            <div className="map-dashboard-page--container">
                <Row>
                    <Col lg={6} className="mb-4">
                        <MapDashboardProgressCard {...taskStatistics} />
                    </Col>

                    <Col lg={6} className="mb-4">
                        <MapDashboardThemeCard
                            mapId={map.id}
                            data={byActionTask}
                        />
                    </Col>

                    <Col lg={6} className="mb-4">
                        <MapDashboardAssignedResponsibilities
                            mapId={map.id}
                            data={byResponsible}
                            totalPosts={+taskStatistics.totalPosts}
                        />
                    </Col>

                    {/* <Col md={6} lg={3} className="mb-4">
                        <MapDashboardBudget mapId={map.id} />
                    </Col> */}

                    <Col md={6} lg={6} className="mb-4">
                        <MapDashboardDocumented
                            mapId={map.id}
                            {...taskStatistics}
                        />
                    </Col>
                </Row>
            </div>
        </div>
    );
};
