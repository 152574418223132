import * as yup from "yup";
import { TKey } from "../../../utils";

export const schema = (t: (val: string) => string) =>
    yup.object().shape({
        actionPostWeightLevels: yup.array().of(
            yup.object().shape({
                name: yup.string(),
                per: yup.number().transform((value) => value || 0),
                ord: yup.number().transform((value) => value || 0),
            }),
        ),
        actionPostDurations: yup.array().of(
            yup.object().shape({
                name: yup
                    .string()
                    .required(t(TKey.Common.Message.Validation.RequiredField)),
                dueDate: yup
                    .string()
                    .required(t(TKey.Common.Message.Validation.RequiredField)),
                isEnable: yup.boolean(),
            }),
        ),
    });
