import { FC, useEffect, useState } from "react";
import { FormProvider, useFieldArray, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useRecoilState } from "recoil";
import { Col, Row } from "react-bootstrap";
import { useParams } from "react-router-dom";

import "./assets/scss/style.scss";

import {
    PortfolioSettingTabEnum,
    errorToast,
    setBackendViolations,
    setCustomViolation,
    successToast,
    validationMessage,
} from "../../../utils";
import { schema } from "./schema";
import { atomActiveClient } from "../../../atoms";
import { useDateTime, useTranslation } from "../../../hooks";
import { ActionPostDuration, Map, PMap } from "../../../models";
import { MapApi } from "../../../apis";
import {
    AppTabs,
    AppForm,
    AppFormCheckBox,
    AppFormContent,
    AppFormGroup,
    AppFormInput,
    AppFormLabel,
    AppFormMessage,
    AppFormModal,
    AppFormDatePicker,
    AppSVGIcon,
} from "../../../components";

interface MapPortfolioSettingProps {
    map: Map;
    showForm: boolean;
    onHide: () => void;
    onSettingChange?: () => void;
}

export const MapPortfolioSetting: FC<MapPortfolioSettingProps> = ({
    map,
    showForm,
    onHide,
    onSettingChange,
}) => {
    // hooks
    const { t } = useTranslation();
    const { id } = useParams();
    const { futureDate, toDbDateFormat } = useDateTime();

    // atoms
    const [activeClient] = useRecoilState(atomActiveClient);

    // state
    const [loading, setLoading] = useState(false);
    const [activeTab, setActiveTab] = useState(
        PortfolioSettingTabEnum.PRIORITIES,
    );

    // form
    const methods = useForm({
        resolver: yupResolver(schema(t)),
    });
    const { handleSubmit, formState, reset, control, setError } = methods;

    const { fields: weightLevelFields } = useFieldArray({
        control,
        name: "actionPostWeightLevels",
    });

    const { fields: durationFields } = useFieldArray({
        control,
        name: "actionPostDurations",
    });

    const onSubmitHandler = (formData: unknown) => {
        if (!activeClient || !id) {
            return;
        }

        const payload = formData as PMap;

        payload.actionPostDurations = payload.actionPostDurations?.map(
            (x) =>
                ({
                    ...x,
                    dueDate: toDbDateFormat(new Date(x.dueDate)),
                } as ActionPostDuration),
        );

        const { actionPostWeightLevels, actionPostDurations } = payload;

        // check that total percentage should be 100 for each items
        const totalWeightLevels = actionPostWeightLevels?.reduce(
            (total, x) => total + x.per,
            0,
        );

        if (totalWeightLevels !== 100) {
            setCustomViolation(
                "actionPostWeightLevels",
                t(
                    "app.map.portfolio.setting.form:message.validation.totalShouldHundred",
                ),
                setError,
                false,
            );
            return;
        }

        // check that the 1st item's days should be less than the 2nd item's days or vice versa ans so on for each items
        const isValidDueDateOrder = actionPostDurations?.every((x, i) => {
            // Return true for the last element
            if (i + 1 === actionPostDurations?.length) {
                return true;
            }

            return (
                new Date(x.dueDate) <
                new Date(actionPostDurations[i + 1].dueDate)
            );
        });

        if (!isValidDueDateOrder) {
            setCustomViolation(
                "actionPostDurations",
                t(
                    "app.map.portfolio.setting.form:message.validation.dueDateInvalidOrder",
                ),
                setError,
                false,
            );
            return;
        }

        setLoading(true);

        MapApi.update<Map, PMap>(+id, payload)
            .then(({ response, errorMessage, isInvalid, error }) => {
                if (isInvalid) {
                    setBackendViolations(error, setError);
                } else if (errorMessage) {
                    errorToast(errorMessage);
                } else if (response !== null) {
                    successToast(
                        t(
                            "app.map.portfolio.setting.form:update.toast.success",
                        ),
                    );
                    reset({
                        actionPostWeightLevels: response.actionPostWeightLevels,
                        actionPostDurations: response.actionPostDurations,
                    });
                    onHide();

                    if (onSettingChange) {
                        onSettingChange();
                    }
                }
            })
            .finally(() => setLoading(false));
    };

    useEffect(() => {
        if (showForm) {
            setActiveTab(PortfolioSettingTabEnum.PRIORITIES);

            setLoading(true);

            MapApi.getSettingById<Map>(map.id)
                .then(({ errorMessage, response }) => {
                    if (errorMessage) {
                        errorToast(errorMessage);
                    } else if (response) {
                        reset({
                            actionPostWeightLevels:
                                response.actionPostWeightLevels,
                            actionPostDurations:
                                response.actionPostDurations.map((x, i) => ({
                                    ...x,
                                    // TODO::(v2) temp solution for old record
                                    dueDate:
                                        x.dueDate ||
                                        toDbDateFormat(
                                            futureDate(
                                                new Date(),
                                                (i + 1) * 90,
                                            ),
                                        ),
                                })),
                        });
                    }
                })
                .finally(() => setLoading(false));
        }
    }, [showForm]);

    const renderTabs = () => {
        switch (activeTab) {
            case PortfolioSettingTabEnum.PRIORITIES:
                return (
                    <AppFormGroup block className="weight-levels">
                        <AppFormLabel
                            controlId="actionPostWeightLevels"
                            label={t(
                                "app.map.portfolio.setting.form:label.actionPostWeightLevels",
                            )}
                        />

                        <div className="weight-levels--header mt-3">
                            <span className="label--300 ms-5">
                                {t(
                                    "app.map.portfolio.setting.form:label.actionPostWeightLevels.name",
                                )}
                            </span>
                            <span className="label--300 me-5">
                                {t(
                                    "app.map.portfolio.setting.form:label.actionPostWeightLevels.percentage",
                                )}
                            </span>
                        </div>

                        <div className="weight-levels--content gap-3 mt-3">
                            {weightLevelFields.map((field, index) => (
                                <Row
                                    key={field.id}
                                    className="weight-levels--content--item"
                                >
                                    {/* <AppFormGroup block={1}>
                                            <AppSVGIcon
                                                icon="drag-n-drop"
                                                className="mt-1"
                                                {...provided.dragHandleProps}
                                            />
                                        </AppFormGroup> */}

                                    <AppFormInput
                                        id={`actionPostWeightLevels[${index}].name`}
                                        name={`actionPostWeightLevels[${index}].name`}
                                        block={9}
                                    />

                                    <AppFormInput
                                        type="number"
                                        id={`actionPostWeightLevels[${index}].per`}
                                        name={`actionPostWeightLevels[${index}].per`}
                                        block={3}
                                    />
                                </Row>
                            ))}

                            <AppFormMessage
                                errorMessage={validationMessage(
                                    "actionPostWeightLevels",
                                    formState,
                                )}
                            />
                        </div>
                    </AppFormGroup>
                );

            case PortfolioSettingTabEnum.SCHEDULE:
                return (
                    <AppFormGroup block className="weight-levels">
                        <AppFormLabel
                            controlId="actionPostDurations"
                            label={t(
                                "app.map.portfolio.setting.form:label.actionPostDurations",
                            )}
                        />

                        <div className="weight-levels--header mt-3">
                            <span className="label--300 ms-3">
                                {t(
                                    "app.map.portfolio.setting.form:label.actionPostDurations.name",
                                )}
                            </span>

                            <div className="d-flex gap-5 flex">
                                <span className="label--300 me-5">
                                    {t(
                                        "app.map.portfolio.setting.form:label.actionPostDurations.dueDate",
                                    )}
                                </span>

                                <span className="label--300 me-1">
                                    {t(
                                        "app.map.portfolio.setting.form:label.actionPostDurations.activate",
                                    )}
                                </span>
                            </div>
                        </div>

                        <div className="weight-levels--content gap-3 mt-3">
                            {durationFields.map((field, index) => (
                                <Row
                                    key={field.id}
                                    className="weight-levels--content--item"
                                >
                                    <AppFormInput
                                        id={`actionPostDurations[${index}].name`}
                                        name={`actionPostDurations[${index}].name`}
                                        block={6}
                                    />

                                    <Col className="d-flex align-items-center">
                                        <AppSVGIcon icon="calendar" />

                                        <AppFormDatePicker
                                            id={`actionPostDurations[${index}].dueDate`}
                                            name={`actionPostDurations[${index}].dueDate`}
                                        />
                                    </Col>

                                    <AppFormCheckBox
                                        id={`actionPostDurations[${index}].isEnable`}
                                        name={`actionPostDurations[${index}].isEnable`}
                                        inputOptions={[
                                            {
                                                label: "",
                                                value: "",
                                            },
                                        ]}
                                        block={1}
                                    />
                                </Row>
                            ))}

                            <AppFormMessage
                                errorMessage={validationMessage(
                                    "actionPostDurations",
                                    formState,
                                )}
                            />
                        </div>
                    </AppFormGroup>
                );

            default:
                return <></>;
        }
    };

    const renderForm = () => (
        <FormProvider {...methods}>
            <AppForm className="app-portfolio-setting">
                <AppTabs
                    className="mb-4"
                    items={[
                        {
                            title: "app.map.portfolio.setting.form:tab.priorities",
                            icon: "edit",
                            url: PortfolioSettingTabEnum.PRIORITIES,
                        },
                        {
                            title: "app.map.portfolio.setting.form:tab.schedule",
                            icon: "voting",
                            url: PortfolioSettingTabEnum.SCHEDULE,
                        },
                    ]}
                    onClick={(item) => {
                        if (item.url) {
                            setActiveTab(item.url as PortfolioSettingTabEnum);
                        }
                    }}
                    isActiveTab={(item) => item?.url === activeTab}
                />

                <AppFormContent>{renderTabs()}</AppFormContent>
            </AppForm>
        </FormProvider>
    );

    return (
        <AppFormModal
            show={showForm}
            icon="settings"
            title="app.map.portfolio.setting.form:modal.title"
            isDrawer
            onSubmit={handleSubmit(onSubmitHandler)}
            onHide={onHide}
            isLoading={formState.isSubmitting || loading}
            disabled={!activeClient}
            isEditMode
        >
            {renderForm()}
        </AppFormModal>
    );
};
