import { FC, memo, useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";

import "./assets/scss/style.scss";

import { useMapAction, useTranslation } from "../../../hooks";
import { AppCheckBox, AppLoader, AppProgressBar } from "../../../components";
import { MapDurationTabs } from "../MapDurationTabs";
import { MapBudgetEnum, TKey, errorToast } from "../../../utils";
import { ActionPostApi } from "../../../apis";
import { ACTION_POST_DURATION, ACTION_POST_STATUS } from "../../../config";
import { ActionPost, PrimitiveObject } from "../../../models";

const {
    ACTIONPOST_DURATION_SHORT,
    ACTIONPOST_DURATION_MEDIUM,
    ACTIONPOST_DURATION_LONG,
} = ACTION_POST_DURATION;

interface MapDashboardBudgetProps {
    mapId: number;
}

export const MapDashboardBudget: FC<MapDashboardBudgetProps> = memo(
    ({ mapId }) => {
        // hooks
        const { t } = useTranslation();

        // context
        const { map } = useMapAction();

        // state
        const [loading, setLoading] = useState(false);
        const [budgetCheckbox, setBudgetCheckbox] = useState({
            [MapBudgetEnum.INTERNAL]: true,
            [MapBudgetEnum.EXTERNAL]: false,
        });
        const [duration, setDuration] = useState("");
        const [stats, setStats] = useState<{
            totalUsed: number;
            totalDays: number;
        }>({ totalUsed: 0, totalDays: 0 });

        const fetchStatistics = async () => {
            if (!map) {
                return;
            }

            setLoading(true);

            let durationParams: PrimitiveObject = {};

            const { actionPostDurations = [] } = map;
            const [durationShort, durationMedium] = actionPostDurations;

            if (duration === ACTIONPOST_DURATION_SHORT) {
                durationParams = {
                    "dueDate[before]": durationShort.dueDate,
                };
            } else if (duration === ACTIONPOST_DURATION_MEDIUM) {
                durationParams = {
                    "dueDate[after]": durationShort.dueDate,
                    "dueDate[before]": durationMedium.dueDate,
                };
            } else if (duration === ACTIONPOST_DURATION_LONG) {
                durationParams = {
                    "dueDate[after]": durationMedium.dueDate,
                };
            }

            ActionPostApi.find<ActionPost>(1, {
                "map.id": mapId,
                pagination: false,
                status: [
                    ACTION_POST_STATUS.ACTIONPOST_STATUS_BACKLOG,
                    ACTION_POST_STATUS.ACTIONPOST_STATUS_BOARD_1,
                    ACTION_POST_STATUS.ACTIONPOST_STATUS_INPROGRESS,
                    ACTION_POST_STATUS.ACTIONPOST_STATUS_BOARD_2,
                    ACTION_POST_STATUS.ACTIONPOST_STATUS_BOARD_3,
                    ACTION_POST_STATUS.ACTIONPOST_STATUS_BOARD_4,
                    ACTION_POST_STATUS.ACTIONPOST_STATUS_COMPLETED,
                ],
                ...(duration ? { ...durationParams } : {}),
            })
                .then(({ response, errorMessage }) => {
                    if (errorMessage) {
                        errorToast(errorMessage);
                    } else if (response !== null) {
                        const isInternal =
                            budgetCheckbox[MapBudgetEnum.INTERNAL] &&
                            !budgetCheckbox[MapBudgetEnum.EXTERNAL];

                        const isExternal =
                            budgetCheckbox[MapBudgetEnum.EXTERNAL] &&
                            !budgetCheckbox[MapBudgetEnum.INTERNAL];

                        const formattedResponse = response.items.reduce(
                            (accumulator, item) => {
                                const { additionalInfo } = item;

                                let isConsiderDays =
                                    !!additionalInfo.personDaysInternal ||
                                    !!additionalInfo.personDaysExternal;

                                if (isInternal) {
                                    isConsiderDays =
                                        !!additionalInfo.personDaysInternal;
                                } else if (isExternal) {
                                    isConsiderDays =
                                        !!additionalInfo.personDaysExternal;
                                }

                                const isConsider =
                                    additionalInfo &&
                                    additionalInfo.cashOut &&
                                    isConsiderDays;

                                const used =
                                    (additionalInfo.cashOut as number) || 0;

                                let days =
                                    (additionalInfo.personDaysInternal as number) ||
                                    (additionalInfo.personDaysExternal as number) ||
                                    0;

                                if (isInternal) {
                                    days =
                                        (additionalInfo.personDaysInternal as number) ||
                                        0;
                                } else if (isExternal) {
                                    days =
                                        (additionalInfo.personDaysExternal as number) ||
                                        0;
                                }

                                return {
                                    totalUsed: isConsider
                                        ? accumulator.totalUsed + used
                                        : accumulator.totalUsed,
                                    totalDays: isConsider
                                        ? accumulator.totalDays + days
                                        : accumulator.totalDays,
                                };
                            },
                            {
                                totalUsed: 0,
                                totalDays: 0,
                            },
                        );

                        setStats(formattedResponse);
                    }
                })
                .finally(() => setLoading(false));
        };

        useEffect(() => {
            fetchStatistics();
        }, [budgetCheckbox, duration]);

        const availablePercentage = () => {
            const totalAvailable = map?.budget || 0;

            const available = totalAvailable - stats.totalUsed;

            if (available > 0) {
                return (available * 100) / totalAvailable;
            }

            return 0;
        };

        const handleCheckboxChange = (
            value: MapBudgetEnum,
            checked: boolean,
        ) => {
            const checkedCount =
                Object.values(budgetCheckbox).filter(Boolean).length;

            // Ensure at least one checkbox is selected
            if (checked || (checkedCount > 1 && !checked)) {
                setBudgetCheckbox((prev) => ({
                    ...prev,
                    [value]: checked,
                }));
            }
        };

        return (
            <div className="budget-card">
                {loading && <AppLoader className="overlay-loader" />}

                <div className="budget-card--header mb-5">
                    <h3 className="title">
                        {t("app.map.dashboard:label.projectBudget")}
                    </h3>

                    <Row className="mt-4">
                        {Object.entries(MapBudgetEnum).map(([key, value]) => (
                            <Col sm={6} key={key} className="mb-2">
                                <AppCheckBox
                                    className="ms-0"
                                    id={`budget-${value}`}
                                    name="budget"
                                    checked={budgetCheckbox[value]}
                                    label={value}
                                    value={value}
                                    onChange={(e) =>
                                        handleCheckboxChange(
                                            value,
                                            e.target.checked,
                                        )
                                    }
                                />
                            </Col>
                        ))}
                    </Row>

                    <MapDurationTabs onChange={setDuration} />
                </div>

                <div className="budget-card--info">
                    <div className="budget-card--info--used-estimated-section">
                        <div className="item">
                            <span className="title">
                                {t(
                                    "app.map.dashboard:label.projectBudget.estimated",
                                )}
                            </span>
                            <div className="pill">
                                <span className="count">{`${t(
                                    TKey.Common.Label.Currency,
                                )} ${stats.totalUsed}`}</span>
                            </div>
                        </div>

                        <div className="item">
                            <span className="title">
                                {t(
                                    "app.map.dashboard:label.projectBudget.days",
                                )}
                            </span>
                            <div className="pill">
                                <span className="count">{stats.totalDays}</span>
                            </div>
                        </div>
                    </div>

                    <div className="budget-card--info--available-section">
                        <span className="title">
                            {t(
                                "app.map.dashboard:label.projectBudget.available",
                            )}
                        </span>

                        <AppProgressBar
                            progress={availablePercentage()}
                            className="mt-2"
                        />
                    </div>
                </div>
            </div>
        );
    },
);
