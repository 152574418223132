import { FC, useEffect, useState } from "react";
import { yupResolver } from "@hookform/resolvers/yup";
import { FormProvider, useForm } from "react-hook-form";
import { Link } from "react-router-dom";
import { Col } from "react-bootstrap";

import "./assets/scss/style.scss";

import {
    CropperViewEnum,
    TKey,
    errorToast,
    setBackendViolations,
    successToast,
    validation,
} from "../../../utils";
import { useAuthAction, useAuthState, useTranslation } from "../../../hooks";
import { UserApi } from "../../../apis";
import { PUser, User } from "../../../models";
import {
    AppConfirmModal,
    AppForm,
    AppFormActions,
    AppFormCheckBox,
    AppFormContent,
    AppFormInput,
    AppFormSelect,
    AppFormUploader,
    AppLoader,
} from "../../../components";
import { UserPosterFileInfo, ROLES } from "../../../config";
import { schemaUser } from "./schema";

interface OnBoardForm {
    firstName: string;
    lastName: string;
    company: string;
    locale?: string;
    imageName?: string;
    plainPassword: string;
    confirmPassword: string;
    agree: boolean;
    isPasswordStrong?: boolean;
}

export const OnBoardUser: FC = () => {
    // hooks
    const { t } = useTranslation();
    const { user, userId } = useAuthState();
    const { actionRefetchAuthUser, actionOnBoardCompleted, actionLogout } =
        useAuthAction();

    // state
    const [loading, setLoading] = useState(false);
    const [isUploading, setIsUploading] = useState(false);
    const [showConfirm, setShowConfirm] = useState(false);

    // form
    const methods = useForm<OnBoardForm>({
        resolver: yupResolver(schemaUser(t)),
    });
    const { handleSubmit, formState, reset, setError, watch } = methods;

    // local state
    const agreeValue = watch("agree");
    const isPasswordStrong = watch("isPasswordStrong");

    useEffect(() => {
        if (user) {
            reset({
                firstName: user.firstName,
                lastName: user.lastName,
                company: user.company,
                locale: user?.locale,
                imageName: user.imageName,
                plainPassword: undefined,
                confirmPassword: undefined,
                agree: false,
                isPasswordStrong: false,
            });
        }
    }, []);

    const onSubmitHandler = (formData: OnBoardForm) => {
        if (!userId) {
            return null;
        }

        setLoading(true);

        UserApi.updateProfile<User, PUser & Partial<OnBoardForm>>(userId, {
            firstName: formData.firstName,
            lastName: formData.lastName,
            company: formData.company,
            locale: formData.locale,
            imageName: formData.imageName,
            plainPassword: formData.plainPassword,
            isOnboarded: true,
        })
            .then(({ response, errorMessage, isInvalid, error }) => {
                if (isInvalid) {
                    setBackendViolations(error, setError);
                } else if (errorMessage) {
                    errorToast(errorMessage);
                } else if (response !== null) {
                    successToast(t("user.onboard.form:update.toast.success"));
                    reset();
                    actionRefetchAuthUser().then(() => {
                        actionOnBoardCompleted();
                    });
                }
            })
            .finally(() => setLoading(false));
    };

    return (
        <>
            <AppConfirmModal
                show={showConfirm}
                icon="warning"
                title="user.onboard.form:logout.confirm.modal.title"
                description="user.onboard.form:logout.confirm.modal.description"
                onHide={() => setShowConfirm(false)}
                nextAction={actionLogout}
            />

            <FormProvider {...methods}>
                <AppForm
                    onSubmit={handleSubmit(onSubmitHandler)}
                    className="w-100"
                >
                    {loading && <AppLoader isFullScreen />}

                    <AppFormContent className="card-content--form--fields-user">
                        <AppFormInput
                            id="firstName"
                            name="firstName"
                            label={t("user.onboard.form:label.firstName")}
                            placeholder={t(
                                "user.onboard.form:placeholder.firstName",
                            )}
                            block={4}
                            maxCount={validation.title.max}
                        />

                        <AppFormInput
                            id="lastName"
                            name="lastName"
                            label={t("user.onboard.form:label.lastName")}
                            placeholder={t(
                                "user.onboard.form:placeholder.lastName",
                            )}
                            block={4}
                            maxCount={validation.title.max}
                        />

                        <AppFormInput
                            type="password"
                            id="plainPassword"
                            name="plainPassword"
                            label={t("user.onboard.form:label.password")}
                            placeholder={t(
                                "user.onboard.form:placeholder.password",
                            )}
                            showPasswordStrength
                            autoComplete="new-password"
                            block={4}
                        />

                        <AppFormInput
                            inputClassName="mb-3"
                            type="password"
                            id="confirmPassword"
                            name="confirmPassword"
                            label={t("user.onboard.form:label.confirmPassword")}
                            placeholder={t(
                                "user.onboard.form:placeholder.confirmPassword",
                            )}
                            autoComplete="new-password"
                            block={4}
                            onCopy={(e) => e.preventDefault()}
                            onPaste={(e) => e.preventDefault()}
                        />

                        <AppFormInput
                            id="company"
                            name="company"
                            label={t("user.onboard.form:label.company")}
                            placeholder={t(
                                "user.onboard.form:placeholder.company",
                            )}
                            block={4}
                            maxCount={validation.title.max}
                        />

                        <AppFormSelect
                            id="locale"
                            name="locale"
                            label={t("user.onboard.form:label.locale")}
                            required={true}
                            block={4}
                            options={[
                                {
                                    label: t(
                                        `common.locale:${ROLES.USER_LOCALE_EN}`,
                                    ),
                                    value: ROLES.USER_LOCALE_EN,
                                },
                                {
                                    label: t(
                                        `common.locale:${ROLES.USER_LOCALE_DE}`,
                                    ),
                                    value: ROLES.USER_LOCALE_DE,
                                },
                            ]}
                        />

                        <AppFormUploader
                            id="imageName"
                            name="imageName"
                            label={t("user.onboard.form:label.imageName")}
                            required={false}
                            block={4}
                            fileInfo={UserPosterFileInfo}
                            cbFileUploading={setIsUploading}
                            withCropper={CropperViewEnum.Default}
                            hasDownload={false}
                        />

                        <Col xs={8} />

                        <AppFormCheckBox
                            id="agree"
                            name="agree"
                            inputOption={
                                <span className="terms">
                                    {`${t("user.onboard.form:label.agreeTo")}`}{" "}
                                    <Link
                                        className="active-link"
                                        to="https://app.denkplan.com"
                                        target="_blank"
                                        rel="noreferrer"
                                    >
                                        {t(
                                            "user.onboard.form:label.termsAndPolicy",
                                        )}
                                    </Link>
                                </span>
                            }
                            block={4}
                        />
                    </AppFormContent>

                    <AppFormActions
                        backAction={() => setShowConfirm(true)}
                        isLoading={formState.isSubmitting || loading}
                        disabled={
                            isUploading ||
                            formState.isSubmitting ||
                            loading ||
                            !agreeValue ||
                            !isPasswordStrong
                        }
                        secondaryIconRight="close"
                        primaryIconRight="arrow-right"
                        // primaryVariant="secondary"
                        createLabel={TKey.Common.Button.Save}
                    />
                </AppForm>
            </FormProvider>
        </>
    );
};
