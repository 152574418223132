import { FC, useEffect, useState } from "react";
import { DragStart, DraggableProvided, DropResult } from "react-beautiful-dnd";
import { useRecoilState } from "recoil";

import {
    AppButton,
    AppConfirmModal,
    AppDraggableList,
    AppInlineInput,
    AppSVGIcon,
} from "../../../components";
import { PSubTask, PTask, PUser, SubTask, Task } from "../../../models";
import { MapItemEnum, errorToast, validation } from "../../../utils";
import { MapDetailSubTasks } from "./MapDetailSubTasks";
import { ClientApi, MapApi, SubTaskApi, TaskApi } from "../../../apis";
import { atomActiveClient } from "../../../atoms";
import { useMapAction, useWorkspacePermission } from "../../../hooks";
import { EVENTS, Socket } from "../../../Socket/Socket";

interface MapDetailTasksProps {
    mapId: number;
    onDragStart: (start: DragStart) => void;
    onDragEnd: (result: DropResult) => void;
}

export const MapDetailTasks: FC<MapDetailTasksProps> = ({
    mapId,
    onDragStart,
    onDragEnd,
}) => {
    // hooks
    const { isWrite } = useWorkspacePermission();

    // atoms
    const [activeClient] = useRecoilState(atomActiveClient);

    // context
    const { tasks, actionCreateTask, actionUpdateTask, actionDeleteTask } =
        useMapAction();

    // state
    const [showConfirm, setShowConfirm] = useState(0);
    const [showInput, setShowInput] = useState(0);
    const [lockTaskId, setLockTaskId] = useState(0);
    const [lockDisplayName, setLockDisplayName] = useState("");

    const handleCreate = () => {
        if (!activeClient) {
            return;
        }

        const payload = Task.createForm(
            ClientApi.toResourceUrl(activeClient.id),
            MapApi.toResourceUrl(mapId),
        );

        TaskApi.create<Task, PTask>(payload).then(
            ({ response, errorMessage }) => {
                if (errorMessage) {
                    errorToast(errorMessage);
                } else if (response !== null) {
                    // also add sub-task along with task
                    const payloadSubTask = SubTask.createForm(
                        ClientApi.toResourceUrl(activeClient.id),
                        TaskApi.toResourceUrl(response.id),
                    );

                    SubTaskApi.create<SubTask, PSubTask>(payloadSubTask).then(
                        ({ response: res, errorMessage: errMsg }) => {
                            if (errMsg) {
                                errorToast(errMsg);
                            } else if (res !== null) {
                                actionCreateTask({
                                    ...response,
                                    subTasks: [res],
                                } as Task);
                                setShowInput(response.id);
                            }
                        },
                    );
                }
            },
        );
    };

    const handleEdit = (id: number, name: string) => {
        TaskApi.update<Task, PTask>(id, { name }).then(
            ({ response, errorMessage }) => {
                if (errorMessage) {
                    errorToast(errorMessage);
                } else if (response !== null) {
                    actionUpdateTask(id, name);
                }
            },
        );
    };

    const handleDelete = (id: number) => {
        TaskApi.deleteById(id).then(({ errorMessage }) => {
            if (errorMessage) {
                errorToast(errorMessage);
            } else {
                setShowConfirm(0);
                actionDeleteTask(id);
            }
        });
    };

    const renderItem = (item: Task, provided: DraggableProvided) => {
        const hideRemoveBtn = tasks.length === 1;

        return (
            <div
                className="task-container"
                ref={provided.innerRef}
                {...provided.draggableProps}
            >
                <div
                    className={`task-container--item ${
                        lockTaskId === item.id ? "lock" : ""
                    }`}
                >
                    {lockTaskId === item.id && (
                        <div className="display-name">{lockDisplayName}</div>
                    )}

                    <div className="task-container--item--action">
                        <AppSVGIcon
                            icon="drag-n-drop"
                            {...provided.dragHandleProps}
                            hidden={
                                !(isWrite && !hideRemoveBtn) ||
                                lockTaskId === item.id
                            }
                        />

                        {isWrite &&
                            !hideRemoveBtn &&
                            lockTaskId !== item.id && (
                                <AppSVGIcon
                                    icon="close"
                                    className="cursor-pointer"
                                    onClick={() => setShowConfirm(item.id)}
                                />
                            )}
                    </div>

                    <div
                        className="task-container--item--content"
                        onDoubleClick={() => setShowInput(item.id)}
                    >
                        <AppInlineInput
                            size="sm"
                            show={showInput === item.id && isWrite}
                            initialText={item.name}
                            onHide={() => setShowInput(0)}
                            onUpdate={(text) => handleEdit(item.id, text)}
                            maxLength={validation.task.title.max}
                            inputType="area"
                        >
                            <span className="title">{item.name}</span>
                        </AppInlineInput>
                    </div>
                </div>

                <MapDetailSubTasks task={item} />
            </div>
        );
    };

    useEffect(() => {
        Socket.on(
            EVENTS.ON_MAP_LOCK_TASK,
            (user: PUser, taskId: number, isLock: boolean) => {
                setLockTaskId(isLock ? taskId : 0);
                setLockDisplayName(user.firstName + " " + user.lastName);
            },
        );
    }, []);

    return (
        <>
            <AppConfirmModal
                show={!!showConfirm}
                icon="warning"
                title="app.task.item:delete.confirm.modal.title"
                description="app.task.item:delete.confirm.modal.description"
                onHide={() => setShowConfirm(0)}
                nextAction={() => handleDelete(showConfirm)}
            />

            <AppDraggableList<Task>
                droppableId="task"
                type={MapItemEnum.TASK}
                data={tasks}
                onDragStart={onDragStart}
                onDragEnd={onDragEnd}
                renderItem={renderItem}
                renderWrapper={(children, providedMain, snapshot) => (
                    <div
                        className={`map-detail-page--container--tasks ${
                            snapshot.isDraggingOver
                                ? "draggable-container--dropable"
                                : ""
                        }`}
                        ref={providedMain.innerRef}
                        {...providedMain.droppableProps}
                    >
                        {children}

                        {isWrite && (
                            <div className="task-add">
                                <AppButton
                                    variant="secondary"
                                    className="btn-square btn-sm"
                                    onClick={handleCreate}
                                >
                                    <AppSVGIcon icon="plus" />
                                </AppButton>
                            </div>
                        )}
                    </div>
                )}
            />
        </>
    );
};
