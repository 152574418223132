import { FC } from "react";
import { Controller, useFormContext } from "react-hook-form";
import { PhoneInput } from "react-international-phone";

import "react-international-phone/style.css";
import "./assets/scss/style.scss";

import {
    AppFormInputWrapper,
    AppFormInputWrapperProps,
} from "../AppFormInputWrapper";

export const AppFormPhoneNumber: FC<AppFormInputWrapperProps> = ({
    id,
    name,
    ...props
}) => {
    // hooks
    const { register } = useFormContext();

    const controlId = id || name;

    return (
        <AppFormInputWrapper id={id} name={name} {...props}>
            <Controller
                {...props}
                {...register(name)}
                name={controlId}
                render={({ field }) => (
                    <PhoneInput
                        name={controlId}
                        placeholder={props.placeholder}
                        disableDialCodePrefill
                        value={field.value}
                        onChange={field.onChange}
                        className="app-phone-number"
                        inputClassName={`app-phone-number--input w-100`}
                        defaultCountry="ch"
                        countrySelectorStyleProps={{
                            buttonClassName: "app-phone-number--country-button",
                            dropdownStyleProps: {
                                className: "app-phone-number--country-dropdown",
                            },
                        }}
                    />
                )}
            />
        </AppFormInputWrapper>
    );
};
