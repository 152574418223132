import { BaseEntity } from "./BaseEntity";
import { Client } from "./Client";
import { Map } from "./Map";
import { TaskPost } from "./TaskPost";

export class Vote extends BaseEntity {
    client: string | Client;

    map: string | Map;

    taskPost: string | TaskPost;

    colorVote: string;

    constructor(
        client: string | Client,
        map: string | Map,
        taskPost: string | TaskPost,
        { colorVote = "" }: PVote = {},
    ) {
        super();
        this.client = client;
        this.map = map;
        this.taskPost = taskPost;
        this.colorVote = colorVote;
    }

    toJSON(addExtraData = false) {
        return {
            ...super.toJSON(addExtraData),
            client: this.client,
            map: this.map,
            taskPost: this.taskPost,
            colorVote: this.colorVote,
        };
    }

    static createForm(
        client: string,
        map: string,
        taskPost: string,
        formData: PVote,
    ): PVote {
        return new Vote(client, map, taskPost, formData).toJSON();
    }
}

export type PVote = Partial<Vote>;
