import { PERMISSION } from "../../config";
import { BaseEntity } from "./BaseEntity";

export class WorkspacePermission extends BaseEntity {
    user: string;

    permission: string;

    constructor({
        user = "",
        permission = PERMISSION.PERMISSION_WRITE,
    }: PWorkspacePermission = {}) {
        super();
        this.user = user;
        this.permission = permission;
    }

    toJSON(addExtraData = false) {
        return {
            ...super.toJSON(addExtraData),
            user: this.user,
            permission: this.permission,
        };
    }

    static createForm(user: string, permission?: string) {
        return new WorkspacePermission({ user, permission }).toJSON();
    }
}

export type PWorkspacePermission = Partial<WorkspacePermission>;
