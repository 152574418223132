export class ActionPostWeightLevel {
    name: string;

    per: number;

    ord: number;

    constructor({ name = "", per = 0, ord = 1 }: PActionPostWeightLevel = {}) {
        this.name = name;
        this.per = per;
        this.ord = ord;
    }

    toJSON() {
        return {
            name: this.name,
            per: this.per,
            ord: this.ord,
        };
    }

    static createForm(
        formData: PActionPostWeightLevel,
    ): PActionPostWeightLevel {
        return new ActionPostWeightLevel(formData).toJSON();
    }
}

export type PActionPostWeightLevel = Partial<ActionPostWeightLevel>;
