import { FC, PropsWithChildren } from "react";
import { Modal } from "react-bootstrap";

import "./assets/scss/style.scss";

const AppBaseModalFooter: FC<PropsWithChildren> = ({ children }) => (
    <Modal.Footer className="border-0">{children}</Modal.Footer>
);

export default AppBaseModalFooter;
